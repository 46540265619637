<div class="title" *ngIf="title"> {{ title }}</div>
<div *ngIf="loading$ | async; else loaded">
    <mat-spinner [diameter]="50"></mat-spinner>
    <div t7e="history-loading">Töltődik...</div>
</div>
<ng-template #loaded>
    <div *ngIf="loadError$ | async" class="error">
        <span class="error-msg">
            {{ loadError$ | async }}
        </span>
        <!-- todo: valamiért a gomb lenyomásától becsukódik az egész. A javításig display: none -->
        <span class="retry-button" style="display: none;">
            <button mat-raised-button color="primary" (click)="getHistory()" t7e="btn-reload">
                Újra
            </button>
        </span>
    </div>
        <div class="list" *ngIf="!(loadError$ | async)">
            <div class="no-history" *ngIf="!( history)?.length" t7e="not-saved-yet">
                Nem történt még mentés
            </div>
            <div class="list-item" *ngFor="let item of history">
                <div class="row1">
                    <span class="timestamp">
                        {{item.ts| date:'MMM dd HH:mm' : 'hu'}}
                    </span>
                    &nbsp;
                    <span class="user">
                        <span class="unimportant" t7e="by">by</span>
                        {{item.famname?.toUpperCase()}} {{ item.usname }}
                    </span>
                </div>
                <div class="row2">
                    <div *ngIf="item.value; else deletedValue" class="value">
                        {{ item.value }}
                        <span class="actual" *ngIf="item.akt" title="aktuális" t7e="is-actual" t7e-title>
                            <mat-icon>check</mat-icon>
                        </span>
                    </div>
                    <ng-template #deletedValue>
                        <span class="deleted-value" t7e="deleted-or-empty">Törölve vagy üres érték</span>
                    </ng-template>
                </div>
                <div class="row3" *ngIf="item.savecomment">
                    <span class="unimportant" [innerText]="item.savecomment"></span>
                </div>
            </div>
        </div>
</ng-template>