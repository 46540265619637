<ng-container *ngIf="multiCurrency">
    <div *ngIf="multiCurrency === 'fixed-rates'" class="mb-1">
        <h2 class="exchange-rate" *ngFor="let currency of allCurrencyExchRatesFiltered$ | async | keyvalue; ">
            1&nbsp;<span>{{ currency.key }}</span>&nbsp;=&nbsp;
            <span>{{ currency.value }}</span>&nbsp;HUF
        </h2>
    </div>

    <h2 class="exchange-rate" *ngIf="multiCurrency !== 'fixed-rates' && !(todaysCurrencyExchangeRateError$ | async)">
        <div>1 EUR =&nbsp;</div>
        <div *ngIf="(todaysCurrencyExchangeRateError$ | async) === false; else todaysCurrencyExchangeRateLoading">{{
            (todaysCurrencyExchangeRate$ | async)?.rate | number:'1.0-0' }}</div>
        <div>&nbsp;{{ currency }}</div>
        <ng-template #todaysCurrencyExchangeRateLoading>
            <mat-spinner [diameter]="15"></mat-spinner>
        </ng-template>
    </h2>
</ng-container>


<div class="filters toolbar" (submit)="reload()">
    <app-filter-date-range [formgroup]="form" [fromFC]="fromFC" [toFC]="toFC" [min]="minDate" [max]="maxDate"
        class="form-field ml-1">
    </app-filter-date-range>

    <app-filter-contract-type [formgroup]="form" [contractTypeFC]="contractTypeFC" class="form-field ml-1">
    </app-filter-contract-type>

    <div class="divider"></div>
    <button mat-raised-button (click)="exportToExcel()" color="primary" type="button"
        [disabled]="!(gridData$ | async)?.data?.length">
        <mat-icon>file_download</mat-icon>
        <span>{{ 'export-to-excel' | t7e:'Export to Excel' }}</span>
    </button>
    <button mat-raised-button (click)="reload()" color="primary" type="submit" [disabled]="!form.valid"
        class="mr-1"><mat-icon>refresh</mat-icon></button>
</div>

<div class="grid">
    <kendo-grid #grid [data]="gridData$ | async " [loading]="(loading$ | async) || false" [pageSize]="pageSize"
        [skip]="skip" [sort]="sort" [filter]="filter" [sortable]="true" [filterable]="false">
        <kendo-grid-column field="usname" [title]="'ts-grid.field.usname' | t7e:'Stábtag'"
            [width]="getWidth('name') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                <ng-container *ngIf="dataItem.usname; else deletedUser">
                    {{ dataItem.usname }}
                </ng-container>
                <ng-template #deletedUser>
                    <span class="deleted-user">{{ 'deleted-user' | t7e: 'Törölt felhasználó' }}</span>
                </ng-template>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="depname" [title]="'ts-grid.field.depname' | t7e:'Részleg'"
            [width]="getWidth('depname') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="role" [title]="'ts-grid.field.role' | t7e:'Munkakör'"
            [width]="getWidth('role') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="dMinStartDate" [title]="'ts-grid.field.minstartdate' | t7e:'Első nap'"
            [width]="getWidth('minstartdate') || 170" [format]="'yyyy-MM-dd'">
        </kendo-grid-column>
        <kendo-grid-column field="dMaxStartDate" [title]="'ts-grid.field.maxstartdate' | t7e:'Utolsó nap'"
            [width]="getWidth('maxstartdate') || 170" [format]="'yyyy-MM-dd'">
        </kendo-grid-column>
        <kendo-grid-column field="tscount" [title]="'ts-grid.field.tscount' | t7e:'TS db'" 
            [width]="getWidth('tscount') || 100">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.tscount | number }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="total" [title]="'ts-grid.field.total' | t7e:'Összes díj'" 
            [width]="getWidth('total') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.total | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="salary" title="{{ 'salary' | t7e }}" [width]="getWidth('dailyRate') || 200"
            filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.salary | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="othours" [title]="'ts-grid.field.othours' | t7e:'Túlóra'"
            [width]="getWidth('othours') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.othours | number }}&nbsp;<span class="currency">{{ 'hours' | t7e }}</span>  
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="otfee" [title]="'ts-grid.field.otfee' | t7e:'Túlóradíj'"
            [width]="getWidth('otfee') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.otfee | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="tahours" [title]="'ts-grid.field.tahours' | t7e:'TA'"
            [width]="getWidth('tahours') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.tahours | number }}&nbsp;<span class="currency">{{ 'hours' | t7e }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="tafee" [title]="'ts-grid.field.tafee' | t7e:'TA díj'"
            [width]="getWidth('tafee') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.tafee | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="mealpenaltyhours" [title]="'ts-grid.field.mealpenaltyhours' | t7e:'Meal Penalty'"
            [width]="getWidth('mealpenaltyhours') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.mealpenaltyhours | number }}&nbsp;<span class="currency">{{ 'hours' | t7e }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="mealpenalty" [title]="'ts-grid.field.mealpenalty' | t7e:'Meal Penalty'"
            [width]="getWidth('mealpenalty') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.mealpenalty | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="otherfee" [title]="'ts-grid.field.otherfee' | t7e:'Egyéb díj'"
            [width]="getWidth('otherfee') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.otherfee | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="perdiem" [title]="'ts-grid.field.perdiem' | t7e:'Per Diem'"
            [width]="getWidth('perdiem') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.perdiem | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="vignette" [title]="'ts-grid.field.vignette' | t7e:'Matrica'"
            [width]="getWidth('vignette') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.vignette | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="gas" [title]="'ts-grid.field.gas' | t7e:'Üzemanyag'" [width]="getWidth('gas') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.gas | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="parking" [title]="'ts-grid.field.parking' | t7e:'Parkolás'"
            [width]="getWidth('parking') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.parking | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate1name"
            [title]="'ts-grid.field.dailyrentalrate1name' | t7e:'Napi bérleti díj 1 neve'"
            [width]="getWidth('dailyrentalrate1name') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate1"
            [title]="'ts-grid.field.dailyrentalrate1' | t7e:'Napi bérleti díj 1'"
            [width]="getWidth('dailyrentalrate1') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.dailyrentalrate1 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate2name"
            [title]="'ts-grid.field.dailyrentalrate2name' | t7e:'Napi bérleti díj 2 neve'"
            [width]="getWidth('dailyrentalrate2name') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate2"
            [title]="'ts-grid.field.dailyrentalrate2' | t7e:'Napi bérleti díj 2'"
            [width]="getWidth('dailyrentalrate2') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.dailyrentalrate2 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate3name"
            [title]="'ts-grid.field.dailyrentalrate3name' | t7e:'Napi bérleti díj 3 neve'"
            [width]="getWidth('dailyrentalrate3name') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate3"
            [title]="'ts-grid.field.dailyrentalrate3' | t7e:'Napi bérleti díj 3'"
            [width]="getWidth('dailyrentalrate3') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.dailyrentalrate3 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate4name"
            [title]="'ts-grid.field.dailyrentalrate4name' | t7e:'Napi bérleti díj 4 neve'"
            [width]="getWidth('dailyrentalrate4name') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate4"
            [title]="'ts-grid.field.dailyrentalrate4' | t7e:'Napi bérleti díj 4'"
            [width]="getWidth('dailyrentalrate4') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.dailyrentalrate4 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate5name"
            [title]="'ts-grid.field.dailyrentalrate5name' | t7e:'Napi bérleti díj 5 neve'"
            [width]="getWidth('dailyrentalrate5name') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate5"
            [title]="'ts-grid.field.dailyrentalrate5' | t7e:'Napi bérleti díj 5'"
            [width]="getWidth('dailyrentalrate5') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.dailyrentalrate5 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate6name"
            [title]="'ts-grid.field.dailyrentalrate6name' | t7e:'Napi bérleti díj 6 neve'"
            [width]="getWidth('dailyrentalrate6name') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="dailyrentalrate6"
            [title]="'ts-grid.field.dailyrentalrate6' | t7e:'Napi bérleti díj 6'"
            [width]="getWidth('dailyrentalrate6') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.dailyrentalrate6 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="monthlyrentalrate1name"
            [title]="'ts-grid.field.monthlyrentalrate1name' | t7e:'Havi bérleti díj 1 neve'"
            [width]="getWidth('monthlyrentalrate1name') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="monthlyrentalrate1"
            [title]="'ts-grid.field.monthlyrentalrate1' | t7e:'Havi bérleti díj 1'"
            [width]="getWidth('monthlyrentalrate1') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.monthlyrentalrate1 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="monthlyrentalrate2name"
            [title]="'ts-grid.field.monthlyrentalrate2name' | t7e:'Havi bérleti díj 2 neve'"
            [width]="getWidth('monthlyrentalrate2name') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="monthlyrentalrate2"
            [title]="'ts-grid.field.monthlyrentalrate2' | t7e:'Havi bérleti díj 2'"
            [width]="getWidth('monthlyrentalrate2') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.monthlyrentalrate2 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="monthlyrentalrate3name"
            [title]="'ts-grid.field.monthlyrentalrate3name' | t7e:'Havi bérleti díj 3 neve'"
            [width]="getWidth('monthlyrentalrate3name') || 170">
        </kendo-grid-column>
        <kendo-grid-column field="monthlyrentalrate3"
            [title]="'ts-grid.field.monthlyrentalrate3' | t7e:'Havi bérleti díj 3'"
            [width]="getWidth('monthlyrentalrate3') || 170">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.monthlyrentalrate3 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
            </ng-template>
        </kendo-grid-column>



        <kendo-grid-excel fileName="Díjak.xlsx" [fetchData]="allDataForExcel">
            <kendo-excelexport-column field="usid" title="Stábtag azonosító / User ID"
                *ngIf="debug"></kendo-excelexport-column>
            <kendo-excelexport-column field="usname" title="Stábtag neve / User Name"></kendo-excelexport-column>
            <kendo-excelexport-column field="depname" title="Részleg neve / Department Name"></kendo-excelexport-column>
            <kendo-excelexport-column field="role" title="Munkakör / Position"></kendo-excelexport-column>
            <kendo-excelexport-column field="currency" title="Pénznem / Currency"></kendo-excelexport-column>
            <kendo-excelexport-column field="dMinStartDate" title="Első nap / First day"></kendo-excelexport-column>
            <kendo-excelexport-column field="dMaxStartDate" title="Utolsó nap / Last day"></kendo-excelexport-column>
            <kendo-excelexport-column field="total" title="Összesen / Total"></kendo-excelexport-column>
            <kendo-excelexport-column field="tscount" title="TS db / TS count"></kendo-excelexport-column>
            <kendo-excelexport-column field="salary" title="Napidíj / Daily rate"></kendo-excelexport-column>
            <kendo-excelexport-column field="otfee" title="Túlóradíj / Overtime fee"></kendo-excelexport-column>
            <kendo-excelexport-column field="tahours" title="TA óra / TA hours"></kendo-excelexport-column>
            <kendo-excelexport-column field="tafee" title="TA díj / TA fee"></kendo-excelexport-column>
            <kendo-excelexport-column field="mealpenaltyhours"
                title="Meal penalty óra / hours"></kendo-excelexport-column>
            <kendo-excelexport-column field="mealpenalty"
                title="Meal penalty díj / Meal penalty fee"></kendo-excelexport-column>
            <kendo-excelexport-column field="otherfee" title="Egyéb díj / Other fee"></kendo-excelexport-column>
            <kendo-excelexport-column field="perdiem" title="Per Diem"></kendo-excelexport-column>
            <kendo-excelexport-column field="vignette" title="Matrica / Vignette"></kendo-excelexport-column>
            <kendo-excelexport-column field="gas" title="Üzemanyag / Gas"></kendo-excelexport-column>
            <kendo-excelexport-column field="parking" title="Parkolás / Parking"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate1name"
                title="Napi bérleti díj 1 neve / Daily rental rate 1 name"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate1"
                title="Napi bérleti díj 1 / Daily rental rate 1"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate2name"
                title="Napi bérleti díj 2 neve / Daily rental rate 2 name"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate2"
                title="Napi bérleti díj 2 / Daily rental rate 2"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate3name"
                title="Napi bérleti díj 3 neve / Daily rental rate 3 name"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate3"
                title="Napi bérleti díj 3 / Daily rental rate 3"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate4name"
                title="Napi bérleti díj 4 neve / Daily rental rate 4 name"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate4"
                title="Napi bérleti díj 4 / Daily rental rate 4"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate5name"
                title="Napi bérleti díj 5 neve / Daily rental rate 5 name"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate5"
                title="Napi bérleti díj 5 / Daily rental rate 5"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate6name"
                title="Napi bérleti díj 6 neve / Daily rental rate 6 name"></kendo-excelexport-column>
            <kendo-excelexport-column field="dailyrentalrate6"
                title="Napi bérleti díj 6 / Daily rental rate 6"></kendo-excelexport-column>
            <kendo-excelexport-column field="monthlyrentalrate1name"
                title="Havi bérleti díj 1 neve / Monthly rental rate 1 name"></kendo-excelexport-column>
            <kendo-excelexport-column field="monthlyrentalrate1"
                title="Havi bérleti díj 1 / Monthly rental rate 1"></kendo-excelexport-column>
            <kendo-excelexport-column field="monthlyrentalrate2name"
                title="Havi bérleti díj 2 neve / Monthly rental rate 2 name"></kendo-excelexport-column>
            <kendo-excelexport-column field="monthlyrentalrate2"
                title="Havi bérleti díj 2 / Monthly rental rate 2"></kendo-excelexport-column>
            <kendo-excelexport-column field="monthlyrentalrate3name"
                title="Havi bérleti díj 3 neve / Monthly rental rate 3 name"></kendo-excelexport-column>
            <kendo-excelexport-column field="monthlyrentalrate3"
                title="Havi bérleti díj 3 / Monthly rental rate 3"></kendo-excelexport-column>
        </kendo-grid-excel>

    </kendo-grid>
</div>