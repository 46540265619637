import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';
import { DbCallError } from '../../app.service';
import { T7eService } from '../../t7e/t7e.service';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent implements OnInit {
  /** T7E */
  t7eSave = this.t7e.getTranslation('app-save-button', 'button-text', 'innerText', null)
  t7eError = this.t7e.getTranslation('app-save-button', 'error-text', 'innerText', null)

  @Input() buttonText?: string | null = this.t7eSave
  @Input() color: ThemePalette | null = 'primary'
  @Input() disabled: boolean = false
  @Input() saveError$ = new BehaviorSubject<DbCallError>(null)
  @Input() errorText?: string | null = this.t7eError
  @Input() showErrMsg: boolean = true
  @Input() successText?: string | null = null
  @Input() showDoneIcon?: boolean = true

  
  /**
   * Ha undefined, akkor a gomb típusa 'submit', egyébként 'button'
   */
  @Output() btnClick = new EventEmitter<void>()

  buttonType: 'submit' | 'button' = 'submit'

  constructor(
    private t7e: T7eService,
  ) { }

  ngOnInit(): void {
    //console.log(this.btnClick);
    if (this.btnClick.observed) this.buttonType = 'button'
    else this.buttonType = 'submit'
  }

  onClick(): void {
    if (this.buttonType == 'submit') return
    this.btnClick.emit()
  }

}
