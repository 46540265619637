<h1>
    Edit Dropdown Values
</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field>
        <mat-label>Dropdown</mat-label>
        <mat-select formControlName="propid" (valueChange)="onPropSelected($event)">
            <mat-option *ngFor="let option of props$ | async" [value]="option.propid">
                {{ option.propname }}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <h2 *ngIf="selectedPiid; else h2New">
        Edit Value
        <button mat-raised-button color="secondary" type="button" (click)="unselectPiid()">
            New
        </button>
    </h2>
    <ng-template #h2New>
        <h2>
            New Value
        </h2>
    </ng-template>
    <mat-form-field>
        <mat-label>Item Value (name)</mat-label>
        <input matInput formControlName="piname" required (change)="nameChanged()">
    </mat-form-field>


    <mat-form-field>
        <mat-label>Item Code</mat-label>
        <input matInput formControlName="picode" required>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Active</mat-label>
        <mat-select formControlName="pistatus">
            <mat-option value="1">Yes</mat-option>
            <mat-option value="0">No</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="save-buttons">
        <button mat-raised-button color="primary" type="submit"
            [disabled]="form.invalid">
            Submit
        </button>
    </div>


    <h2>
        Existing Values
    </h2>
    <table *ngIf="(items$[selectedPropid] | async)?.length; else noItems"
        class="table-existing-items">
        <tr>
            <th>id</th>
            <th>Item Value (name)</th>
            <th>Item Code</th>
            <th>Active</th>
        </tr>
        <tr *ngFor="let item of items$[selectedPropid] | async">
            <td>{{ item.piid }}</td>
            <td>{{ item.piname }}</td>
            <td>{{ item.picode }}</td>
            <td class="text-center">{{ item.pistatus ? '&check;' : '' }}</td>
            <td>
                <button mat-icon-button (click)="onEdit(item)" type="button">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </tr>
    </table>
    <ng-template #noItems>
        <div class="no-items">
            No items yet. Add one now by filling out the form above.
        </div>
    </ng-template>

</form>