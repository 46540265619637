import { Component, Inject, Injectable } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { BehaviorSubject, delay, take } from 'rxjs';
import { T7eService } from '../../t7e/t7e.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  appNotifList$ = new BehaviorSubject<Notif[]>([])
  private _appNotifList: Notif[] = []

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private t7e: T7eService,
  ) {
    this.appNotifList$.subscribe(n => this._appNotifList = n)
  }

  /**
   * Presents a toast displaying the message with a green background
   * @param message Message to display
   * @example
   * this.notificationService.success("confirm oked");
   */
  success(message: string) {
    this.openSnackBar(message, '', 'success-snackbar');
  }

  /**
   * Presents a toast displaying the message with a red background
   * @param message Message to display
   * @example
   * this.notificationService.error("confirm canceled");
   */
  error(message: string) {
    this.openSnackBar(message, '', 'error-snackbar');
  }

  /**
   * Shows a confirmation modal, presenting the user with
   * an OK and Cancel button. 
   * @param message Body of the modal
   * @param okCallback Optional function to call when the user clicks Ok
   * @param title Optional modal title
   * @param cancelCallback Option function to call when the user clicks Cancel
   * @example
   * //displays a success or error message depending on what button is clicked.
   * this.notificationService.confirmation(
   * 'it will be gone forever', //message body
   * () => { //okCallback
      this.notificationService.success("confirm oked");
    },
    'Are you sure?', //title
     () => { //cancelCallback
      this.notificationService.error("confirm canceled");
    });
   */
  confirm({
    message,
    title = this.t7eConfirmTitle,
    okButtonText = 'OK',
    noButtonText = 'Nem',
    cancelButtonText = this.t7eConfirmCancel,
    okCallback,
    cancelCallback,
    noCallback,
  }: ConfirmDialogData
  ) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '600px',
      data: { message, title, okButtonText, cancelButtonText, noButtonText }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'no' && noCallback) {
        noCallback();
      }
      if (result === true && okCallback) {
        okCallback();
      }
      if (result === false && cancelCallback) {
        cancelCallback();
      }
    });
  }

  /**
  * Shows a modal, presenting the user with an OK button.
  * @param message Body of the modal
  * @param okCallback Optional function to call when the user clicks Ok
  * @param title Optional modal title
  * @example
  * //displays a success when the Ok button is clicked.
  *  this.notificationService.alert("an alert", "notice", () => {
      this.notificationService.success("alert oked");
    });
  */
  alert(message: string, title = 'Notice', okCallback: () => void = () => { }) {
    // this.snackBar.openFromComponent(AlertSnackbar, {
    //   data: 'SÜLTTT', verticalPosition: 'top'
    // })
    // return
    const dialogRef = this.dialog.open(AlertDialog, {
      width: '400px',
      data: { message: message, title: title },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && okCallback) {
        okCallback();
      }
    });
  }


  /**
   * Displays a toast with provided message
   * @param message Message to display
   * @param action Action text, e.g. Close, Done, etc
   * @param className Optional extra css class to apply
   * @param duration Optional number of MILISECONDS to display the notification for
   */
  openSnackBar(
    message: string,
    action: string,
    className = '',
    duration = 1000
  ) {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass: [className]
    });
  }

  /**
   * Az oldal tetejére kitesz egy módosíthható értesítést
   * @param options az értesítés beállításai
   * @returns NotifHandler: Az értesítés azonosítója. Ezzel lehet később módosítani
   */
  addObservableNotif(options: NotifOptions): number {
    if (!options.class) options.class = 'info'
    const notif: Notif = {
      ...options,
      notifHandler: this._appNotifList.length
    }
    const newList = [
      ...this._appNotifList,
      notif
    ]
    this.appNotifList$.next(newList)
    if (options.duration) setTimeout(() => { this.hideNotif(notif.notifHandler) }, options.duration)
    return newList.length-1
  }

  modifyNotif(notifHandler: number, newOptions: NotifOptions): void {
    const newList: Notif[] = [
      ...this._appNotifList.slice(0, notifHandler),
      {
        ...this._appNotifList[notifHandler],
        ...newOptions,
      },
      ...this._appNotifList.slice(notifHandler + 1),
    ]
    if (newOptions.duration) {
      this.appNotifList$
        .pipe(
          delay(newOptions.duration),
          take(1),  
      )
        .subscribe(_ => {
        this.hideNotif(notifHandler)
      })
    }
    this.appNotifList$.next(newList)
  }

  hideNotif(notifHandler: number): void {
    const newOptions: Notif = {
      ...this._appNotifList[notifHandler],
      hidden: true,
    }
    const newList = [
      ...this._appNotifList.slice(0, notifHandler),
      newOptions,
      ...this._appNotifList.slice(notifHandler + 1),
    ]
    this.appNotifList$.next(newList)
  }
  closeNotif = this.hideNotif
  

  /** T7E */
  // 'are you sure?'
  t7eConfirmTitle = this.t7e.getTranslation('notif-msg', 'confirm-title', 'innerText', null, null, 'Biztos vagy benne?')
  t7eConfirmCancel = this.t7e.getTranslation('notif-msg', 'confirm-cancel', 'innerText', null, null, 'Mégse')
}

export interface DialogData {
  message: string;
  title: string;
  okButtonText: string;
}
export interface ConfirmDialogData extends DialogData {
  noButtonText?: string | null;
  cancelButtonText: string;
  okCallback?: (() => void) | null;
  cancelCallback?: (() => any) | null;
  noCallback?: (() => any) | null;
}

/**
 * A simple confirmation dialog
 */
@Component({
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
     {{data.message}}
    </div>
    <div mat-dialog-actions [align]="'end'" style="gap: 1rem">
       <button mat-raised-button (click)="onCancelClick()">
        {{ data.cancelButtonText }}
      </button>
      <button mat-raised-button color="primary" (click)="onOkClick()" cdkFocusInitial>
        {{ data.okButtonText }}
      </button>
      <button mat-raised-button (click)="onNoClick()" *ngIf="!!data.noButtonText">
        {{ data.noButtonText }}
      </button>
    </div>
  `
})
export class ConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) { }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close('no');
  }
}

@Component({
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
     {{data.message}}
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="onYesClick()" cdkFocusInitial>
        Ok
      </button>
    </div>
  `
})
export class AlertDialog {
  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}

@Component({
  template: `passed in {{ data }}`
})
export class AlertSnackbar {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: string
  ) {
    console.log('alert snackbar shown')
  }

  onYesClick(): void {
    console.log('alert yes clicked')
  }
}


export interface NotifOptions {
  msg: string | null
  buttonText?: string
  buttonColor?: ThemePalette
  class?: 'success' | 'warning' | 'danger' | 'info' | null
  metaData?: any
  onAction?: (event: { index: number, metaData: any }) => void
  duration?: number | false
  hidden?: boolean
}

export interface Notif extends NotifOptions {
  notifHandler: number
}