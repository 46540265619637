import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService, getReorderedConfig, getResizedConfig } from '../../state.service';
import { AggregateDescriptor, CompositeFilterDescriptor, GroupDescriptor, groupBy, process } from '@progress/kendo-data-query';
import { ColumnReorderEvent, ColumnResizeArgs, DataStateChangeEvent, GridComponent, GridDataResult, GroupKey } from '@progress/kendo-angular-grid';
import { ReportService } from '../report.service';
import { AppService } from '../../app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest, filter, map, switchMap, tap } from 'rxjs';
import { ProductionService } from '../../prod/production.service';
import { config } from '../../config';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ReportTimesheetSummaryResponse } from '../../models/timesheet-fees';
import * as moment from 'moment-timezone';
import { PropItem } from '../../models/table-property';

@Component({
  selector: 'app-timesheet-summary',
  templateUrl: './timesheet-summary.component.html',
  styleUrls: ['./timesheet-summary.component.scss']
})
export class TimesheetSummaryComponent implements OnInit {
  @ViewChild('grid') grid!: GridComponent

  multiCurrency = config.MULTIPLE_CURRENCIES
  form = this.getFG()
  gridData$ = new BehaviorSubject<GridDataResult>({ data: [], total: 0 })
  reload$ = new Subject<boolean>()

  constructor(
    private stateSvc: StateService,
    private reportSvc: ReportService,
    private appSvc: AppService,
    private prodSvc: ProductionService,
    private fb: FormBuilder,
  ) {
    this.allDataForExcel = this.allDataForExcel.bind(this)

    this.appSvc.pageTitle$.next('Díjak összesítése (EKHO)')

    if (this.multiCurrency === true) {
      this.prodSvc.fetchTodaysExchangeRate()
    }

    combineLatest([this.form.valueChanges, this.reload$])
      .pipe(
        filter(([form, reloadTrigger]) => this.form.valid && reloadTrigger),
        tap(([form, reloadTrigger]) => this.reload$.next(false)),
        switchMap(([form, _]) => this.reportSvc.getTimesheetSummaryReport(moment(form.from), moment(form.to), form.contractType?.picode as "ekho" | "hun_company" | null | undefined)),
        // map to GridDataResult
        map((report, _) => ({
          data: report as ReportTimesheetSummaryResponse[],
          total: report?.length || 0,
        })),
      )
      .subscribe(this.gridData$)

   }

  loading$ = this.reportSvc.isTimesheetSummaryReportLoading$
  currency = this.prodSvc.getCurrency()
  todaysCurrencyExchangeRate$ = this.prodSvc.todaysCurrencyExchangeRate$
  todaysCurrencyExchangeRateLoading$ = this.prodSvc.todaysCurrencyExchangeRateLoading$
  todaysCurrencyExchangeRateError$ = this.prodSvc.todaysCurrencyExchangeRateError$
  allCurrencyExchRatesFiltered$ = this.prodSvc.allCurrencyExchangeRates$
    .pipe(
      tap(x => console.log(x)),
    // go through all the rates and filter out the ones with falsy keys, and where the key is HUF
      map(rates => rates
        && Object.fromEntries(Object.entries(rates).filter(([key, value]) => key && key !== 'HUF'))),
   )
  get debug() { return this.appSvc.debug }
  get fromFC() { return this.form.get('from') as FormControl }
  get toFC() { return this.form.get('to') as FormControl }
  get contractTypeFC() { return this.form.get('contractType') as FormControl }
  get minDate() { return this.prodSvc.prodDefaults$.value?.dPreparationstartday || new Date(2000, 0, 1) }
  get maxDate() { return new Date() }
  get ekhoPropItem() { return this.appSvc.aPropItems$[40].value.find(x => x.piid === 1006) }

  ngOnInit(): void {
  }

  getFG() {
    return this.fb.group<FilterFG>({
      from: this.fb.control(this.filterSettings?.from || null, Validators.required),
      to: this.fb.control(this.filterSettings?.to || null, Validators.required),
      contractType: this.fb.control(this.filterSettings?.contractType! || this.ekhoPropItem),
    })
  }

  reload(): void {
    this.reload$.next(true)
  }

  exportToExcel(): void {
    this.grid.saveAsExcel()
  }

  allDataForExcel(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(
        this.gridData$.value.data || [],
        { sort: this.sort, }).data,
    }
    return result
  }

  /** USER PREFERENCES */

  get gridSettings() {
    return this.stateSvc.timesheetSummaryPageState$.value?.grid
  }
  get filterSettings() {
    return this.stateSvc.timesheetSummaryPageState$.value?.filters
  }
  get skip() { return this.gridSettings?.state?.skip || 0 }
  get sort() { return this.gridSettings?.state?.sort || [] }
  get filter() { return this.gridSettings?.state?.filter || { filters: [], logic: 'and' } }
  get pageSize() { return this.gridSettings?.state?.take || 100 }
  get expandedGroupKeys() { return this.gridSettings?.expandedGroupKeys || [] }
  set expandedGroupKeys(val: Array<GroupKey>) {
    this.stateSvc.setTimesheetSummaryPageState({ grid: { expandedGroupKeys: val } })
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.stateSvc.setTimesheetSummaryPageState({ grid: { state } })
  }

  filterChanged(event: CompositeFilterDescriptor): void {
    this.resetSkip();
  }
  resetSkip(): void {
    this.stateSvc.setTimesheetSummaryPageState({
      grid: { state: { skip: 0 } }
    })
  }

  public onColReorder(e: ColumnReorderEvent): void {
    // set the new orderIndex to all affected columns
    const newColumnsConfig: any = getReorderedConfig(this.grid, e)
    this.stateSvc.setTimesheetSummaryPageState({ grid: { columnsConfig: newColumnsConfig } })
    //console.log(this.stateSvc.timesheetSummaryPageState$.value?.grid?.columnsConfig)
  }

  public onColResized(e: ColumnResizeArgs[]): void {
    const newConfigs = getResizedConfig(e)
    newConfigs.forEach((newConfigs) => {
      this.stateSvc.setTimesheetSummaryPageState({ grid: { columnsConfig: newConfigs } });
    })
  }

  getWidth(field: string): number | undefined {
    const columnsConfig = this.gridSettings?.columnsConfig || {}
    return columnsConfig[field]?.width
  }

}

type FilterFG = {
  from: FormControl<Date | null>,
  to: FormControl<Date | null>,
  contractType: FormControl<PropItem | null>,
}