<div class="error-component">
    Hiba történt!

    <div  class="error-msg">
        {{ errorMsg }}
    </div>

    <div class="retry">
        <a mat-raised-button color="primary" [routerLink]="'/'">Újra</a>
    </div>
</div>
