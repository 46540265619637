import { Injectable } from '@angular/core';
import { dictionary } from './dictionary';
import { LocaleService } from '../locale.service';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class T7eService {
  _dict: T7eDictionary
  private _lang: T7eLangs = 'hu'

  readonly LOCALSTORAGE_MISSING_TRANSLATIONS_LIST = 't7e-missing';

  constructor(
    private localeSvc: LocaleService,
  ) {
    this._dict = dictionary

  }

  private readonly _lang$ = new BehaviorSubject<T7eLangs>(this._lang)
  lang$ = this._lang$.asObservable()
  get lang() { return this._lang }
  setLang(lang: T7eLangs): void {
    this._lang = lang
    this.localeSvc.setLocale(lang)
    this._lang$.next(lang)
  }
  
  get statusTextFieldName() { return this.lang === 'hu' ? 'nameHun' : 'name' }

  getTranslation(pageName: string, objName: string, attrName: string = "innerText", descr: string | null, tryOtherLang: T7eLangs | null = null, origTextt: string | null = null): string {
    const retVal = this._dict[pageName]
      && this._dict[pageName][objName]
      && this._dict[pageName][objName][attrName]
      && this._dict[pageName][objName][attrName][tryOtherLang ? tryOtherLang : this._lang]
    const skipWarnMissing = this._dict[pageName]?.[objName]?.[attrName]?.skipWarnMissing || false
    if (!retVal) {
      if (!skipWarnMissing) {
        this.addToMissingList(pageName, objName, attrName, tryOtherLang ? tryOtherLang : this._lang, descr, origTextt)
        console.warn(`Nincs %c${tryOtherLang || this._lang}%c fordítás erre. Oldal: %c${pageName}%c, html objektum: %c${objName}%c, attribútum: %c${attrName}%c.`,
          "font-weight: bold", "font-weight: normal",
          "font-weight: bold", "font-weight: normal",
          "font-weight: bold", "font-weight: normal",
          "font-weight: bold", "font-weight: normal",
        )
      }
      if (!tryOtherLang) {
        if (this._lang == 'en') return this.getTranslation(pageName, objName, attrName, descr, 'hu', origTextt)
        else return this.getTranslation(pageName, objName, attrName, descr, 'en', origTextt)
      }
      return ''
    } else {
      this.removeFromMissingList(pageName, objName, attrName, tryOtherLang ? tryOtherLang : this._lang)
      return retVal
    }
  }

  // TODO ezt a kettőt service workerbe kéne tenni
  private addToMissingList(pageName: string, objName: string, attrName: string, lang: T7eLangs, descr: string | null, origText: string | null = null): void {
    let currentList: T7eDictionary = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_MISSING_TRANSLATIONS_LIST) || '{}')

    currentList[pageName] = currentList[pageName] || {}
    currentList[pageName][objName] = currentList[pageName][objName] || {}
    currentList[pageName][objName][attrName] = currentList[pageName][objName][attrName] || {}
    
    currentList[pageName][objName][attrName][lang] = null
    currentList[pageName][objName][attrName].hu = origText
    currentList[pageName][objName][attrName].descr = descr

    localStorage.setItem(this.LOCALSTORAGE_MISSING_TRANSLATIONS_LIST, JSON.stringify(currentList))
  }
  private removeFromMissingList(pageName: string, objName: string, attrName: string, lang: T7eLangs): void {
    return
    let currentList: T7eDictionary = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_MISSING_TRANSLATIONS_LIST) || '{}')
    if (currentList[pageName] && currentList[pageName][objName] && currentList[pageName][objName][attrName] && currentList[pageName][objName][attrName][lang]) {
      delete currentList[pageName][objName][attrName][lang]
    }
    localStorage.setItem(this.LOCALSTORAGE_MISSING_TRANSLATIONS_LIST, JSON.stringify(currentList))
  }
}

export interface T7eDictionary {
  [key: string]: T7eDictionaryPage // pageName
}

export interface T7eDictionaryPage {
  [key: string]: T7eDictionaryObject // Oldalon belüli a hmtl objektum neve 
}

export interface T7eDictionaryObject {
  [key: string]: T7eDictionaryAttribute // az objektumon belül az attribute neve
}

export interface T7eDictionaryAttribute {
  hu?: string | null // a magyar fordítás
  en?: string | null // az angol fordítás
  descr?: string | null // ide jöhet magyarázó szöveg, ami segít a fordításban
  skipWarnMissing?: boolean // ha true, akkor nem jelenik meg a konzolon a figyelmeztetés, hogy nincs fordítás
}

export type T7eLangs = 'hu' | 'en'