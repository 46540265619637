<kendo-dialog [title]="questionText" (close)="onCancelled()">
    <div class="answer">
        <form [formGroup]="form">
            <mat-form-field class="form-field" appearance="outline">
                <textarea matInput formControlName="answer" cdkTextareaAutosize cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="10" #savecomment></textarea>
            </mat-form-field>
        </form>
    </div>
    <kendo-dialog-actions>
        <button mat-raised-button (click)="onCancelled()">{{ cancelButtonText }}</button>
        <button mat-raised-button (click)="onSaved()" color="primary" [disabled]="!isCommentOptional && !this.form.value.answer">{{
            saveButtonText }}</button>
    </kendo-dialog-actions>
</kendo-dialog>