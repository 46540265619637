<app-section-title [title]="questionGroup?.questionGroupName" 
    *ngIf="questionGroup?.questionGroupName !== defaultQuestionGroupName"></app-section-title>

<app-flex-fields [formGroup]="flexFieldsFG" 
    [tableid]="contractFlexTable"
    [allowedPropCodes]="allowedPropCodes"
    [showReadonlyFields]="showReadonlyFields" 
    [formFieldStyles]="formFieldStyles"
    [formGroupStyles]="formGroupStyles" 
    [isEditable]="isEditable"
    [validate$]="validate$"
    [uploadFolderName]="uploadFolderName"
    [uploadFolderId]="uploadFolderId"
    (fileUploadSuccess)="onFileUploadSuccess($event)"
    (fileUploadError)="onFileUploadError($event)"
    class="flex-form-fields">
</app-flex-fields>

