
import { AbstractControl } from "@angular/forms";
import { DataService } from "../../data.service";

export function OtRateValidator(control: AbstractControl) {
    const val = control?.value?.trim()
    if (!val) return null
    const otRate = DataService.textToDbArray(val, true)
    if (!Array.isArray(otRate)) return null
    if (otRate.some(x => x==='' || isNaN(x))) return { otRate: 'Invalid OT rate' }
    return null
}


export function NumericValidator(control: AbstractControl) {
    if (Array.isArray(control?.value)) return { numeric: 'It is an array'}
    const val = typeof control?.value === 'number' ? control.value : control?.value?.toString().trim()
    if (!val) return null
    if (isNaN(val)) return { numeric: 'Invalid number' }
    return null
}

export function IntValidator(control: AbstractControl) {
    const val = control?.value?.trim()
    if (!val) return null
    if (isNaN(val) || parseInt(val) !== parseFloat(val) || !Number.isInteger(parseFloat(val))) {
        return { int: 'Not an integer' }
    }
    return null
}
