import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distinctBy'
})
export class DistinctByPipe implements PipeTransform {
  transform(value: any[], field: string): any[] {
    if (!Array.isArray(value)) {
      return value;
    }

    const uniqueValues = new Set();

    return value.filter(item => {
      const fieldValue = item[field];
      if (uniqueValues.has(fieldValue)) {
        return false;
      } else {
        uniqueValues.add(fieldValue);
        return true;
      }
    });
  }
}