import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridComponent } from '@progress/kendo-angular-grid';
import { AppService } from '../../../app.service';
import { DataService, EditLevels } from '../../../data.service';
import { FlexProp, FlexTableProperty } from '../../../models/table-property';
import { UserService } from '../../../user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-edit-flex-fields-form',
  templateUrl: './edit-flex-fields-form.component.html',
  styleUrls: ['./edit-flex-fields-form.component.scss']
})
export class EditFlexFieldsFormComponent implements OnInit {

  flexTables$ = this.appSvc.flexTables$
  flexPropsFiltered$ = new BehaviorSubject(this.appSvc.flexProps$.value)//.pipe(map(p => p.filter(p2 => p2.propname.includes(this.propFilter))))
  formGroup: FormGroup
  dataItem: FlexTableProperty
  flexEditTypes$ = this.appSvc.flexEditTypes$
  editLevels = this.appSvc.getEditLevels()
  isNewProp: boolean = false
  get tableidFC() { return this.formGroup.get('tableid') as FormControl }
  get propidFC() { return this.formGroup.get('propid') as FormControl }
  get edittypeFC() { return this.formGroup.get('edittype') as FormControl }
  get propcodeFC() { return this.formGroup.get('propcode') as FormControl }
  get propnameEngFC() { return this.formGroup.get('engpropname') as FormControl }
  get levelFC() { return this.formGroup.get('level') as FormControl }
  get ordFC() { return this.formGroup.get('ord') as FormControl }
  get optionalFC() { return this.formGroup.get('optional') as FormControl }

  constructor(
    private appSvc: AppService,
    private dataSvc: DataService,
    private userSvc: UserService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.formGroup = this.createFG(data)
    this.dataItem = data.dataItem
  }

  ngOnInit(): void {
  }

  createFG(args: DialogData): FormGroup {
    if (args.isNew) {
      args.dataItem = this.createNewTablProp()
    }
    const item = args.dataItem;
    if (item.optional === null || item.optional === undefined) item.optional = true

    this.formGroup = this.fb.group({
      tpid: [item.tpid],
      tableid: [item.tableid],
      propid: [item.propid],
      propname: [item.propname],
      engpropname: [item.engpropname],
      propcode: [item.propcode],
      //prid: [item.prid],
      modusid: [item.modusid],
      //entryon: [item.entryon],
      ord: [item.ord],
      optional: [!!item.optional],
      level: [item.level],
      edittype: [item.edittype],
      edittypename: [item.edittypename],
    });
    this.formGroup.get('propcode')?.disable()

    console.log(this.formGroup)
    console.table(this.formGroup.value)
    return this.formGroup;
  }

  createNewTablProp(): FlexTableProperty {
    return {
      tpid: null,
      tableid: 0,
      propid: 0,
      propname: '',
      engpropname: '',
      propcode: '',
      prid: 1,
      modusid: null,
      entryon: null,
      ord: null,
      optional: null,
      level: null,
      edittype: 0,
      edittypename: 'text',
    } as FlexTableProperty
  }

  onPropChange(value: any) {
    console.log('prop legördülő változott. Új érték: ', value);
    this.isNewProp = isNaN(value) && this.appSvc.flexProps.filter(x => x.propname == value).length < 1
    //@ts-ignore
    const selectedProp: FlexProp = this.appSvc.flexProps.find(p => p.propid == value)
      || {
      propid: value,
      propcode: null,
      propname: value,
      engpropname: null,
      edittype: null,
    }
    this.formGroup.patchValue(selectedProp)
    if (this.isNewProp) {
      this.formGroup.get('propname')?.enable()
      this.formGroup.get('edittype')?.enable()
      this.formGroup.get('level')?.enable()
    } else {
      this.formGroup.get('propname')?.disable()
      this.formGroup.get('edittype')?.disable()
      this.formGroup.get('level')?.disable()

    }
  }

  save(): void {
    let saveVal: FlexTableProperty = this.formGroup.getRawValue()
    this.data.onSave(saveVal)
  }

  cancel(): void {
    this.data.onClose()
  }

  handleFilter(value: string) {
    this.flexPropsFiltered$.next(this.appSvc.flexProps.filter(
      p2 => p2.propname.toLowerCase().includes(value.toLowerCase()))
    )
  }

  getTableName(tableid: number): string {
    return this.appSvc.flexTables.find(t => t.id == tableid)?.value || 'n/a'
  }

}


export interface DialogData {
  dataItem: FlexTableProperty
  isNew: boolean
  onClose: () => void
  onSave: (dataItem: FlexTableProperty) => void
}