<div class="calendar-wrapper">
     <div class="calendar-hint hide-on-touchonly" t7e="multi-select-days-hint">
        Ctrl + click, or Shift + click to select multiple days
     </div>
    <kendo-calendar type="infinite" [value]="selectedDates$ | async" 
        selection="multiple"
        (valueChange)="_selectedDateChange($event)" 
        [max]="maxDate" [min]="minDate">
        <ng-template kendoCalendarCellTemplate let-date>
            <span class="normal-day" 
                [ngClass]="getDayProps(date)?.class"
                [title]="getDayProps(date)?.title">
                {{date.getDate()}}
            </span>
        </ng-template>
    </kendo-calendar>
    <button mat-raised-button color="secondary" class="refresh-button" 
    type="button"
    (click)="reloadClicked()">
        <mat-icon>refresh</mat-icon>
        <span t7e="btn-refresh-text">Frissít</span>
    </button>
    <div class="loader" *ngIf="loading$ | async">
    </div>

    <!-- {{ dayProps | json }} -->
</div>