<main class="main-container" *ngIf="true">
  <button mat-raised-button (click)="toggleDisabled()">En/Disabled</button>
  <div class="layout">
    <div class="component">
      <app-editable-value
        [field]="editableValueFieldText"
        suffix="HUF"
        (onSave)="saveTextValue($event, true)"
      ></app-editable-value>
    </div>
    <div class="component">
      <app-editable-value
        [field]="editableValueFieldNumber"
        suffix="óra"
        (onSave)="saveNumValue($event, false)"
      ></app-editable-value>
    </div>
    <div class="component">
      <app-editable-value 
       [field]="editableValueFieldDate"
       (onSave)="saveDateValue($event, true)"
       ></app-editable-value>
    </div>
    <div class="component">
      <app-editable-value 
      [field]="editableValueFieldTime" 
      (onSave)="saveTimeValue($event, true)"
      ></app-editable-value>
    </div>
    <div class="component">
      <app-history-icon></app-history-icon>
    </div>
    <div class="component">
      <app-icon-danger></app-icon-danger>
    </div>
    <div class="component">
      <button type="button" mat-raised-button>
        <mat-icon>info</mat-icon>
        Szöveg
      </button>
    </div>
  </div>
  <div class="component">
    <div class="proton-cinema-logo">
      <div class="proton-cinema-logo-wrapper">
        <img src="assets/proton-cinema-logo.svg" alt="">
      </div>
    </div>
  </div>

  <div class="component">
    <h1>Jó history</h1>
    <app-history [tableid]="4" [id]="584" [colname]="'salary'"></app-history>
    <hr>
    <!-- <h1>Hibás history</h1>
    <app-history [tableid]="888" [id]="584" [colname]="'salary'"></app-history> -->
    <h1>Üres history</h1>
    <app-history [tableid]="4" [id]="584" [colname]="'salaryXXX'"></app-history>
  </div>

  <div class="component">
    <a  id="salaryreason">
    <h1>Salary Reason</h1></a>
    <app-salary-reasons [tsid]="10930"></app-salary-reasons>
  </div>

  <div class="component">
    <h1>Kis naptár</h1>
    <app-day-selector [selectedDates$]="selectedDateDayselector$" 
    (selectedDateChange)="daySelectorSelectedDateChanged($event)"></app-day-selector>
    Kiválasztott nap: 
    <div *ngFor="let item of selectedDateDayselector$ |async; let index = index; trackBy:trackByIndex">
      {{ item | date}}
    </div>
  </div>
</main>


