import { SqlDataRow } from "../data.service";
import { UserPreferences } from "./handle-users-parameters";
import { FlexPropWithValue } from "./table-property";

export interface DbUser extends SqlDataRow {
        usid: number | null
        email: string

        /**
         * Fullanem_ Az adatbázisban tárolt usname-ből és famname-ből készült, de a kliens oldalon
         */
        usname: string | null
        ugm: DbGroup[]
        phonenumber: string
        usstatus: 0 | 1 | null
        preferences: string | null
        famname?: string | null

}

export interface AppUser extends DbUser {
        /**
         * Az eretedeti usname, most már csak keresztnév
         */
        usnameOrig: string | null
        /** $ jelekkel összefűzött user groupok, angolul és magyarul */
        ugmConcat: string | null
}

export interface DbGroup extends SqlDataRow {
        ugid: number
        /** user group name */
        ugname: string | null
        /** user group name angolul */
        engugname: string | null
}

export interface DbGroupMembership extends SqlDataRow {
        usid: number    // 641,
        ugid: number    // 2,
        entryon: string | Date | null // "2022-11-01T16:52:44.767237",
        modusid: number // 1,
        prid: number    // 1,
}

export enum Groups {
        null = 0,
        'CREW' = 1,
        'PRODMGR' = 2,
        'FINANCE' = 3,
        'PRODUCER' = 4,
        'DEV' = 5,
        'DEPT_HEAD' = 6,
        'DEPT_ADMIN' = 7,
        //'FOREIGN_ONLY' = 8,
}
export enum GroupsEng {
        null = 0,
        'CREW' = 1,
        'PRODMGR' = 2,
        'FINANCE' = 3,
        'PRODUCER' = 4,
        'DEV' = 5,
        'DEPT_HEAD' = 6,
        'DEPT_ADMIN' = 7,
        'FOREIGN_ONLY' = 8,
}

