import { Pipe, PipeTransform } from '@angular/core';
import { T7eService } from './t7e.service';

@Pipe({
  name: 't7e'
})
export class T7ePipe implements PipeTransform {
  constructor(
    private t7e: T7eService,
  ) { }

  transform(value: string, huDefault?: string, enDefault?: string): string {
    const translation = this.t7e.getTranslation('pipe', value, 'innerText', `from pipe: ${value}`, null, huDefault || enDefault);
    if (translation === null || translation === undefined) {
      if (this.t7e.lang === 'hu' && huDefault) return huDefault
      if (this.t7e.lang === 'en' && enDefault) return enDefault
      return value
    }
    return translation
  }

}
