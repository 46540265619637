<div class="my-timesheets">
    <h1 class="page-title" t7e="page-title">Timesheetjeim</h1>
        <div *ngIf="debug">{{ tsList$ | async | json }}</div>

    <app-day-selector (selectedDateChange)="onSelectedDateChanged($event)" [isYesNo]="false" 
        class="day-selector" [selectedDates$]="selectedDatesForDaySelector$"
        [tsList$]="tsList$" (reload)="reload()">
    </app-day-selector>

    <mat-expansion-panel class="deleted-timesheets">
        <mat-expansion-panel-header>
            Törölt Timesheetek
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <app-deleted-timesheets [userId]="loggedinUserId"></app-deleted-timesheets>
        </ng-template>
    </mat-expansion-panel>

    <section class="selected-timesheets" id="selected-timesheets">
        <h1 t7e="selected-timesheets-title">Selected Timesheet</h1>
        <div class="ts-list section-box">
            <div class="ts" *ngFor="let ts of aSelectedTs$ | async" 
                [title]="'id=' + ts.tsid"
                (click)="onTsSelected(ts)"
                [class.selected]="(selectedTs$ | async)?.tsid === ts.tsid">
                <div class="ts-header">
                    <div class="ts-date">
                        {{ ts.dStartDate | date: 'MMM.dd HH:mm' }}
                        -
                        {{ ts.dEndDate | date: 'HH:mm' }}
                    </div>
                    <div class="dept" *ngIf="!isOwnTs(ts)">
                        {{ ts.depname }}
                    </div>
                    <div class="role" *ngIf="getRolesByUser(ts?.usid!).length>1">
                        {{ ts.role }}
                    </div>
                    <div class="ts-status" [ngClass]="getTsStatusClass(ts)">
                        {{ getTsStatusName(ts.tsstatus) }}
                    </div>
                </div>
            </div>
            <div class="ts add-ts" (click)="addNewTs()" *ngIf="canAddTs"
                [class.selected]="!(selectedTs$ | async)?.tsid" t7e="new-ts-button">
                New TS
            </div>
        </div>
    </section>

    <section class="ts-editor">
        <app-new-timesheet *ngIf="(selectedTs$ | async)?.tsid === 0" 
            [dates]="selectedDatesForTsEditor"
            (selectedDateChange)="onSelectedDateChangedInTsEditor($event)"
            class="new-timesheet">
        </app-new-timesheet>
    </section>

    <section class="ts-editor">
        <app-edit-timesheet *ngIf="(selectedTs$ | async)?.tsid! > 0" 
            [timesheet]="selectedTs$ | async"
            (selectedDateChange)="onSelectedDateChangedInTsEditor($event)"
            [showStatus]="false"
            class="edit-timesheet">
        </app-edit-timesheet>
    </section>
</div>