import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { AppUser } from '../../../models/db-user';
import { UserService } from '../../../user.service';

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent implements OnInit {
  @Input() users$?: BehaviorSubject<AppUser[] | null>
  @ViewChild('searchText') searchText!: ElementRef<HTMLInputElement>

  constructor(
    private userSvc: UserService,
  ) { 
    this.filterBySearchText = this.filterBySearchText.bind(this)
    
    if  (!this.users$) this.users$ = new BehaviorSubject<AppUser[] | null>(null)
  }

  get sortedUsers$() {
    return this.users$
      ?.pipe(map(users => {
        if (!users) return []
        const clonedUsers = [
          ...users
            .filter(this.filterBySearchText)
            .sort((a, b) => {
              // bejelentkezett felhasználó előre kerüljön
              if (a.usid === this.userSvc.loggedinUserId) return -1
              if (b.usid === this.userSvc.loggedinUserId) return 1
              return (a.usname || '').localeCompare((b.usname || '')) || 0
            })
        ]
        return clonedUsers
      }))
  }

  ngOnInit(): void {
  }

  onUserSelected(user: AppUser | null) {
    this.userSvc.selectedUser = user
  }

  getUserId = (index: number, user: AppUser): number => user?.usid || -1
  
  filterBySearchText(user: AppUser): boolean {
    if (!this.searchText?.nativeElement.value) return true
    let concat = `${user.email} | ${user.usname} | ${user.famname}`
    return concat.includes(this.searchText?.nativeElement.value)
  } 
}
