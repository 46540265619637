import { Component, OnDestroy, OnInit } from '@angular/core';
import { Department } from '../../models/department';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { AppService } from '../../app.service';
import { EditableFieldOptions, FieldValue } from '../../util/editable-value/editable-value.component';
import { ProductionService } from '../../prod/production.service';
import { DeptService } from '../dept.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { config } from '../../config';

@Component({
  selector: 'app-dept-defaults',
  templateUrl: './dept-defaults.component.html',
  styleUrls: ['./dept-defaults.component.scss']
})
export class DeptDefaultsComponent implements OnInit, OnDestroy {
  MEAL_PENALTY = config.HU.TS.MEAL_PENALTY

  private _destroy$ = new Subject<void>();
  formSearch: FormGroup
  formAddDept: FormGroup
  editableValueOptions: Record<EditableFieldname, EditableFieldOptions>[] = []
  isMealPenaltyAtDeptDefault = this.MEAL_PENALTY === 'setAtProdDeptSf'

  constructor(
    private fb: FormBuilder,
    private appSvc: AppService,
    private prodSvc: ProductionService,
    private deptSvc: DeptService,
    private notifSvc: NotificationService,
  ) {
    this.appSvc.pageTitle$.next('Részleg alapértelmezései')
    
    this.formSearch = this.fb.group({
      searchString: '',
    })
    this.formAddDept = this.fb.group({
      depname: '',
    })

    this.filterDepts = this.filterDepts.bind(this)

    this.depts$
      .pipe(takeUntil(this._destroy$))
      .subscribe(depts => {
      depts?.forEach(dept => {
        if (!dept?.depid) return
        if (!this.editableValueOptions[dept.depid]) this.editableValueOptions[dept.depid] = {
          depname: this.editableValueOptionsFactory(dept, 'depname', { type: 'text' }),
          f_tarate: this.editableValueOptionsFactory(dept, 'f_tarate'),
          f_otrate: this.editableValueOptionsFactory(dept, 'f_otrate', { type: 'otrates', hint: '1st hour / 2nd hour / 3rd our / 4th hour' }),
          f_overtimestep: this.editableValueOptionsFactory(dept, 'f_overtimestep', { numberOptions: { min: 0.1, step: 0.25 } }),
          f_sleepoverrate: this.editableValueOptionsFactory(dept, 'f_sleepoverrate'),
          f_mealPenaltyRate: this.editableValueOptionsFactory(dept, 'f_mealPenaltyRate', { numberOptions: { min: 0, step: 500 }}),
          f_graceperiod: this.editableValueOptionsFactory(dept, 'f_graceperiod'),
        }
      })
    })
  }
  

  get searchString() { return this.formSearch.value.searchString }
  get currency() { return this.prodSvc.getCurrency() }
  depts$ = this.deptSvc.departments$
  saving$ = this.deptSvc.savingDept$
  

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  filterDepts(dept: Department): boolean {
    return dept.depname?.toLocaleLowerCase().includes(this.searchString?.toLocaleLowerCase()) || false
  }

  editableValueOptionsFactory(dept: Department, fieldName: EditableFieldname, options?: EditableFieldOptions | any): EditableFieldOptions {
    return {
      type: options?.type || 'number',
      value$: new BehaviorSubject(dept[fieldName]),
      isError: new BehaviorSubject<string | null>(null),
      isSuccess: new BehaviorSubject<boolean | null>(null),
      numberOptions:options?.numberOptions || {
        min: 0,
        step: 1000,
      },
      popup: options?.popup || {
        showHelp: false,
        showHistory: false,
      },
      hint: options?.hint || undefined,
    }
  }


  editableValueSaveClicked(value: FieldValue, dept: Department, fieldName: EditableFieldname) {
    if (!dept.depid) return
    if (value !== 0 && !value) {
      // delete property value from db
      if (fieldName === 'depname') {
        const msg = 'A részleg nevét nem lehet törölni'
        this.notifSvc.addObservableNotif({ msg, class: 'danger', duration: 3000 })
        this.editableValueOptions[dept.depid!][fieldName].isError.next(msg)
        return
      }
      console.log('delete property value from db')
    } else {
      dept[fieldName] = value
    }
    if (fieldName === 'f_overtimestep' && dept[fieldName] === 0) {
      const msg = 'A túlóra lépcső nem lehet nulla'
      this.notifSvc.addObservableNotif({ msg, class: 'danger', duration: 3000 })
      this.editableValueOptions[dept.depid!][fieldName].isError.next(msg)
      return
    }
    console.log('editableValueSaveClicked', value, dept, fieldName)
    
    const dbFieldName = fieldName.replace('f_', '')
    const params = (fieldName === 'depname') 
      ? { _depid: dept.depid, _depname: value as string }
      : { _depid: dept.depid, _data: JSON.stringify({ [dbFieldName]: value }) }

    this.deptSvc.handleDept(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
      next: (result) => {
        console.log('handleDepts', result)
        this.editableValueOptions[dept.depid!][fieldName].isSuccess.next(true)
        console.assert(result, 'A db nem adott vissza eredményt. Lehet, hogy nem sikerült a módosítás')
        this.editableValueOptions[dept.depid!][fieldName].value$?.next(result?.[fieldName])      },
      error: (err) => {
        console.error(err)
        this.editableValueOptions[dept.depid!][fieldName].isError.next(err)
      }
    })
  }

  otRateSaved(value: FieldValue, dept: Department) {
    let values = value?.toString().trim().split('/') || null
    // check if values are numbers
    if (Array.isArray(values) && values.some(v => isNaN(+(v.trim())))) {
      this.editableValueOptions[dept.depid!]['f_otrate'].isError.next('Invalid value')
      return
    }
    if (Array.isArray(values)) {
      values = values
        .map(v => v.trim())
        .filter(v => v)
    }
    if (!values?.length) values = null
    this.editableValueSaveClicked(values, dept, 'f_otrate')
  }

  toggleEnable(dept?: Department): void {
    if (!dept?.depid) return

    const newStatus = dept.depstatus === 0 ? 1 : 0
    const hNotif = this.notifSvc.addObservableNotif({ msg: newStatus ?  'Enabling': 'Disabling' + '...', class: 'info' })
    this.deptSvc.handleDept({ _depid: dept.depid, _depstatus: newStatus }, true)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
      next: (result) => {
        console.log('handleDepts', result)
        console.assert(result, 'A db nem adott vissza eredményt. Lehet, hogy nem sikerült a módosítás')
        this.notifSvc.modifyNotif(hNotif, { msg: 'Saved', class: 'success', duration: 3000 })
        dept.depstatus = result?.depstatus
      },
      error: (err) => {
        console.error(err)
        this.notifSvc.modifyNotif(hNotif, {msg: err, class: 'danger' })
      }
    })
  }

  addDept(): void {
    const depname = this.formAddDept.value.depname
    if (!depname) return
    const hNotif = this.notifSvc.addObservableNotif({ msg: 'Adding...', class: 'info' })
    this.deptSvc.handleDept({ _depid: null, _depname: depname, _prid: 1 })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
      next: (result) => {
        console.log('addDepts', result)
        console.assert(result, 'A db nem adott vissza eredményt. Lehet, hogy nem sikerült a hozzzáadás')
        this.notifSvc.modifyNotif(hNotif, { msg: 'Added successfully', class: 'success', duration: 3000 })
        this.formAddDept.reset()
      },
      error: (err) => {
        console.error(err)
        this.notifSvc.modifyNotif(hNotif, {msg: err, class: 'danger' })
      }
    })
  }
}

export type EditableFieldname =
  'depname'
  | 'f_tarate'
  | 'f_otrate'
  | 'f_overtimestep'
  | 'f_sleepoverrate'
  | 'f_mealPenaltyRate'
  | 'f_graceperiod'

interface VisibleEditables {
  depid: number
  fieldName: EditableFieldname
  visible: boolean
}