import { ApplicationRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, catchError, combineLatest, concat, delay, distinctUntilChanged, filter, first, firstValueFrom, forkJoin, map, Observable, of, takeUntil, tap } from 'rxjs';
import { CTHttpResponse, DataService, EditLevels, FlexTables, SqlDataRow } from './data.service';
import { AppUser, Groups, GroupsEng } from './models/db-user';
import { FieldHistory, FieldHistoryParsed, ListItemHistoryParams } from './models/field-history';
import { HandleFlexPropertiesParams, HandleFlexTablePropertiesParams, HandlePropItemsParams } from './models/handle-table-properties-params';
import { FlexProp, FlexPropLangAgnostic, FlexTable, FlexTableProperty, PropItem } from './models/table-property';
import { UserService } from './user.service';
import { SwUpdate } from '@angular/service-worker';
import { environment } from './../environments/environment';
import { T7eLangs, T7eService } from './t7e/t7e.service';
import { SendMailParams } from './models/mail';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  /** Ha true, akkor olyan dolgokat is enged, amik szabálytalanok */
  private _debug: boolean = false 
  get debug() { return this._debug }
  set debug(val: boolean) { this._debug = val }
  get impersonate() { return sessionStorage.getItem(SESSION_STORAGE_IMPERSONATE) }
  set impersonate(val: string | null | undefined) { 
    val = val?.trim()
    if (val) {
      sessionStorage.setItem(SESSION_STORAGE_IMPERSONATE, val)
      this.userSvc.setLoggedOnUserByEmail(val)
    }
    else sessionStorage.removeItem(SESSION_STORAGE_IMPERSONATE)
   }
  isAppStable$: Observable<boolean>
  appVersionCheckingMessage$ = new BehaviorSubject<string | null>(null)
  isAppVersionChecking$ = new BehaviorSubject<boolean>(false)
  isUpdateAvailable$ = new BehaviorSubject<SwAppData | null>(null)
  canUpdateApp$ = new BehaviorSubject<boolean>(false)

  get environment() { 
    if (environment.apiProjectName === '') return 'vg'
    else return environment.apiProjectName
  }
  readonly pageTitle$ = new BehaviorSubject<string>(environment.apiProjectName)

  isDataLoading$ = new BehaviorSubject<boolean>(true)
  isDataLoaded$ = new BehaviorSubject<boolean>(false)
  appDataRetryCount = 0

  private _tableProperties: FlexTableProperty[] | null = null
  get tableProperties() { return this._tableProperties }
  readonly tableProperties$ = new BehaviorSubject<FlexTableProperty[] | null>(null)
  readonly saveTablePropertiesError$ = new BehaviorSubject<DbCallError>(null)
  readonly savingTableProperties$ = new BehaviorSubject<boolean>(false)

  flexProps: FlexProp[] = []
  readonly flexProps$ = new BehaviorSubject<FlexProp[] | null>(null) 
  private readonly _flexPropMap$ = new BehaviorSubject<Map<string, FlexPropLangAgnostic>>(new Map())
  /** Map<propCode: string, prop: FlexPropLangAgnostic> */
  readonly flexPropMap$ = this._flexPropMap$.pipe(delay(0)) // azért így, hogy ne lehessen neki .next()-et nyomni
  /** Map<propCode: string, prop: FlexPropLangAgnostic> */
  get flexPropMap() { return this._flexPropMap$.value }
  readonly saveFlexPropertiesError$ = new BehaviorSubject<DbCallError>(null)
  readonly savingFlexProperties$ = new BehaviorSubject<boolean>(false)

  flexTables = [] as { id: number, value: string }[]
  readonly flexTables$ = this.dataSvc.listFlexTables$()
  readonly flexEditTypes$ = this.dataSvc.listFlexEditTypes()

  /** Property Itemek PropId szerint csoportosítva */
  aPropItems$: BehaviorSubject<PropItem[]>[] = []
  readonly savePropItemsError$ = new BehaviorSubject<DbCallError>(null)
  readonly savingPropItems$ = new BehaviorSubject<boolean>(false)

  aStatuses$ = new Map<string, BehaviorSubject<PropItem[]>>()

  constructor(
    private dataSvc: DataService,
    private userSvc: UserService,
    private router: Router,
    private swUpdates: SwUpdate,
    private t7e: T7eService,
    appRef: ApplicationRef,

  ) {
    this.handleLoadedTableProperties = this.handleLoadedTableProperties.bind(this)
    this.handleLoadedFlexProperties = this.handleLoadedFlexProperties.bind(this)
    this.handleLoadedPropItems = this.handleLoadedPropItems.bind(this)

    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      }
    })

    this.isAppStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    concat(this.isAppStable$, this.isUpdateAvailable$.pipe(map(appData => !!appData))).subscribe(this.canUpdateApp$)

    this.swUpdates.versionUpdates.subscribe(evt => {
      this.isAppVersionChecking$.next(false)
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          if (this.t7e.lang==='en') this.appVersionCheckingMessage$.next(`Downloading new app version: ${evt.version.hash.substring(0, 7)}`)
          else this.appVersionCheckingMessage$.next(`Új app verzió letöltése: ${evt.version.hash.substring(0, 7)}`)
          break;
        case 'NO_NEW_VERSION_DETECTED':
          console.log(`No new app version detected`);
          if (this.t7e.lang === 'en') this.appVersionCheckingMessage$.next(`No new app version detected`)
          else this.appVersionCheckingMessage$.next(`Nincs új app verzió`)
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          console.log('New version info: ' + JSON.stringify(evt.latestVersion.appData));
          console.log('User: ' + JSON.stringify(userSvc.loggedinUser), )
          if (this.t7e.lang === 'en') this.appVersionCheckingMessage$.next(`New app version ready for use: ${evt.latestVersion.hash.substring(0, 7)}`)
          else this.appVersionCheckingMessage$.next(`Az új app verzió telepítve: ${evt.latestVersion.hash.substring(0, 7)}`)
          this.isUpdateAvailable$.next(evt.latestVersion.appData || null)
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          if (this.t7e.lang === 'en') this.appVersionCheckingMessage$.next(`Failed to install app version '${evt.version.hash.substring(0, 7)}': ${evt.error}`)
          else this.appVersionCheckingMessage$.next(`Az új app verzió telepítése sikertelen: ${evt.error}`)
          break;
      }
    })

    swUpdates.unrecoverable.subscribe(event => {
      this.router.navigate(['error', 'An error occurred that we cannot recover from:\n' +
        event.reason + '\n\nPlease reload the page.'
      ]);
    });

    this.userSvc.user$
      .subscribe(async user => {
        if (!user && this.isDataLoaded$.value) {
          dataSvc.clearCache()
        } else {
          this.loadAllFromCache()
        }
      })
    
    dataSvc.initData$
      .pipe(filter(data => !!data))
      .subscribe(appData => {
        this.isDataLoaded$.next(true)
        try {
          this.handleLoadedTableProperties(appData?.data?.list_tableproperties)
          this.handleLoadedFlexProperties(appData?.data?.list_properties)
          this.handleLoadedPropItems(appData?.data?.list_propertyitems)
        } catch (ex) {
          console.error(ex);
          this.router.navigate(['error', t7e.getTranslation("app.service", "initdata", "loaderror",'App alapadatbetöltés hiba')])
        } finally {
          this.isDataLoading$.next(false)
        }

      })
    
    dataSvc.initDataError$
      .pipe(filter(err => !!err))
      .subscribe(err => {
        this.isDataLoading$.next(false)
        console.error('App alapadatbetöltés hiba')
        console.error(err)
        this.router.navigate(['error', t7e.getTranslation("app.service", "initdata", "loaderror2",'App alapadatbetöltés hiba')])

      })
    
    this.flexTables$.subscribe(x => this.flexTables = x)
    this.flexProps$.subscribe(x => {
      if (!x?.length) return
      this.handleLoadedPropItems(this.dataSvc.getCache(FlexTables.propitems))
    })

    combineLatest([this.t7e.lang$, this.flexProps$])
      .pipe(delay(0))
      .subscribe(([lang, flexProps]) => {
        this._flexPropMap$.next(this.flexPropsToMap(flexProps, lang))
      })
  }
  
  private flexPropsToMap(props: FlexProp[] | null, lang: T7eLangs): Map<string, FlexPropLangAgnostic> {
    if (lang === "hu") return new Map<string, FlexPropLangAgnostic>(props?.map(p => ([p.propcode, {
      propId: p.propid,
      propCode: p.propcode,
      propName: p.propname,
      editType: p.edittype,
      editLevel: p.level,
      propDesc: p.propdesc,
      origProp: p,
    }])))
    else return new Map<string, FlexPropLangAgnostic>(props?.map(p => ([p.propcode, {
      propId: p.propid,
      propCode: p.propcode,
      propName: p.engpropname || p.propname,
      editType: p.edittype,
      editLevel: p.level,
      propDesc: p.propdesceng || p.propdesc,
      origProp: p,
    }])))
  }

  private loadAllFromCache(): void {
    this.handleLoadedTableProperties(this.dataSvc.getCache(FlexTables.tableproperties))
    this.handleLoadedFlexProperties(this.dataSvc.getCache(FlexTables.properties))
    this.handleLoadedPropItems(this.dataSvc.getCache(FlexTables.propitems))
  }


  private handleLoadedTableProperties(tableProperties?: FlexTableProperty[] | null): void {
    if (!tableProperties?.length) return
    this._tableProperties = tableProperties
      ?.sort((a, b) => (b?.tpid || -1) - (a?.tpid || -1))
      ?.map(tp => {
      tp.entryon = tp.entryon ? new Date(tp.entryon) : null
      tp.optional = tp.optional === 1 || tp.optional === null
      return tp
    }) || []
    this.tableProperties$.next(tableProperties)
    this._tableProperties?.length && this.dataSvc.setCache(FlexTables.tableproperties, this._tableProperties)
  }
  private handleLoadedFlexProperties(props?: FlexProp[] | null): void {
    if (!props?.length) return
    this.flexProps = props || []
    this.flexProps$.next(props)
    this.flexProps?.length && this.dataSvc.setCache(FlexTables.properties, this.flexProps)
  }
  private handleLoadedPropItems(propItems?: PropItem[] | null): void {
    if (propItems) this.dataSvc.setCache(FlexTables.properties, propItems)
    else propItems = this.dataSvc.getCache(FlexTables.propitems)
    
    if (!propItems?.length) return

    this.flexProps.forEach((fp, i) => {
      const items = propItems!.filter(pi => pi.propid === fp.propid)
      if (this.aPropItems$[fp.propid!]) {
        this.aPropItems$[fp.propid!].next(items || [])
      } else {
        this.aPropItems$[fp.propid!] = new BehaviorSubject<PropItem[]>(items || [])
      }
    })

    const statuses = propItems.filter(pi => pi.propid < 0)
    const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
      array.reduce((map, value, index, array) => {
        const key = predicate(value, index, array);
        map.get(key)?.push(value) ?? map.set(key, [value]);
        return map;
      }, new Map<Q, T[]>());
    const grouppedStatuses = groupByToMap(statuses, s => s.propid.toString())
    grouppedStatuses.forEach((s, key) => {
      if (this.aStatuses$.get(key)) this.aStatuses$.get(key)?.next(s)
      else this.aStatuses$.set(key, new BehaviorSubject(s))
    })
  }

  sendMail(params: SendMailParams) {
    const retVal = this.dataSvc.sendMail(params)
    retVal.subscribe(_ => { })
    return retVal
  }

  modifyAndSaveTableProperties(params: HandleFlexTablePropertiesParams) {
    this.savingTableProperties$.next(true)
    this.dataSvc.handleTableProperties(params).subscribe({
      next: (tablProps) => {
        this.saveTablePropertiesError$.next(false)
        this.savingTableProperties$.next(false)
        this.dataSvc.listTableProperties()
          .subscribe(this.handleLoadedTableProperties)
      },
      error: (err) => {
        this.saveTablePropertiesError$.next(err)
        this.savingTableProperties$.next(false)
        this.dataSvc.listTableProperties()
          .subscribe(this.handleLoadedTableProperties)
      }
    })
  }

  modifyAndSaveFlexProperties(params: HandleFlexPropertiesParams) {
    this.savingFlexProperties$.next(true)
    this.dataSvc.handleFlexProperties(params).subscribe({
      next: (tablProps) => {
        this.saveFlexPropertiesError$.next(false)
        this.savingFlexProperties$.next(false)
        this.dataSvc.listFlexProps()
          .subscribe(this.handleLoadedFlexProperties)
      },
      error: (err) => {
        this.saveFlexPropertiesError$.next(err)
        this.savingFlexProperties$.next(false)
        this.dataSvc.listFlexProps()
          .subscribe(this.handleLoadedFlexProperties)
      }
    })
  }

  modifyAndSavePropItems(params: HandlePropItemsParams) {
    this.savingPropItems$.next(true)
    this.dataSvc.handlePropItems(params).subscribe({
      next: (propItem) => {
        this.savePropItemsError$.next(false)
        this.savingPropItems$.next(false)
        this.dataSvc.listPropItems()
          .subscribe(this.handleLoadedPropItems)
      },
      error: (err) => {
        this.savePropItemsError$.next(err)
        this.savingPropItems$.next(false)
        this.dataSvc.listPropItems()
          .subscribe(this.handleLoadedPropItems)
      }
    })
  }

  getEditLevels() {
    return Object.keys(EditLevels)
      .map((key: any) => ({
        id: EditLevels[key],
        value: key as string,
      }
      ))
      .filter(obj => isNaN(obj.value as unknown as number))
  }

  getUserGroups() {
    //console.log(Object.keys(Groups));
    
    const retVal = Object.keys(Groups)
      .filter(key => !isNaN(key as unknown as number))
      .filter(key => key != '0')
      .filter(key => this.userSvc.isDev || parseInt(key) != 5 ) // a DEV group csak a DEV-nek látsszon
      .map((key: any) => ({
        ugid: parseInt(key),
        ugname: Groups[key],
        engugname: GroupsEng[key],
      }
      ))
    //console.log(retVal)
    return retVal
  }

  getPropItemByPicode(propid?: number, picode?: string): PropItem | null {
    if (!propid || !picode) return null
    return this.aPropItems$[propid]?.value?.find(pi => pi.picode === picode) || null
  }
  getPropByPropcode(propcode: string): FlexProp | null {
    return this.flexProps.find(fp => fp.propcode === propcode) || null
  }
  /**
   * Kisbetűssé és ékezet nélkülivé alakítja a beadott stringet
   * @param s A megcsonkítani kívánt string
   * @returns 
   */
  normalizeString(s?: string | null): string {
    if (!s) return ''
    return s.normalize('NFD').replace(/\p{Diacritic}/gu, "")
  }

  trackByIndex(index: number) {
    return index;
  }

  appendQS(origUrl: string | null | undefined, QSName: string, QSValue: string | null): string | null {
    if (!origUrl) return origUrl || null
    if (origUrl.includes('?')) origUrl += '&'
    else origUrl += '?'
    return origUrl + QSName + "=" + QSValue?.toString()
  }
  extractQSValue(url: string | null | undefined, QSName: string): string | null {
    if (!url) return null
    try {
      return (new URL(url)).searchParams.get(QSName)
    } catch (_) {
      return null
    }
  }

  getRowClass(event: { dataItem: any, index: number }): string {
    if (event.dataItem.usstatus === 0) return 'deleted-row'
    if (event.dataItem.usstatus === -1) return 'disabled-row'
    return ''
  }

  replaceDocItems<T>(updatedDocs: T[] | null, origDocs: T[] | null, idField: string): T[] {
    return AppService.replaceDocItems(updatedDocs, origDocs, idField)
  }
  static replaceDocItems<T>(updatedDocs: T[] | null, origDocs: T[] | null, idField: string): T[] {
    if (!origDocs) origDocs = []
    updatedDocs?.forEach(tsUpdated => {
      //@ts-ignore
      const origIndex = origDocs.findIndex(tsOrig => tsOrig[idField] == tsUpdated[idField])
      if (origIndex < 0) {
        origDocs!.push(tsUpdated)
      } else {
        origDocs = [
          ...origDocs!.slice(0, origIndex),
          tsUpdated,
          ...origDocs!.slice(origIndex + 1),
        ]
      }

    })
    return origDocs || []
  }

  getFlexHistory(tableid: FlexTables, id: number | null, propcode: string): Observable<FieldHistory[] | null> {
    if (!id) return of([])
    if (propcode.startsWith('f_')) propcode = propcode.substring(2)
    const propid = this.flexProps.find(fp => fp.propcode === propcode)?.propid
    if (!propid) {
      console.error('Értéktörténet hiba. Ismeretlen propcode: ' + propcode);
      return of([])
    }
    const params: ListItemHistoryParams = {
      _tableid: tableid,
      _id: id,
      _propid: propid,
    }
    const retVal = this.dataSvc.listItemHistory(params)
      ?.pipe(map(x => x?.length ? x : []))
    return retVal
  }
  
  getFixHistory(tableid: FlexTables, id: number | null, colname: string): Observable<FieldHistoryParsed[] | null> {
    if (!id) return of([])
    const params: ListItemHistoryParams = {
      _tableid: tableid,
      _id: id,
      _colname: colname,
    }
    const retVal = this.dataSvc.listItemHistory(params)
      ?.pipe(map(x => x?.length ? x : []))
    return retVal
  }

  isNumber(val: any): boolean {
    if (val === null || val === undefined) return false
    if (val === '') return false
    if (val === true || val === false) return false
    if (typeof val === 'number') return true
    if (typeof val === 'object') return false
    return !isNaN(Number(val))
  }

  getEndOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)
  }

  async checkForAppUpdate(): Promise<boolean> {
    if (this.t7e.lang === 'en') this.appVersionCheckingMessage$.next('Checking for app updates...')
    else this.appVersionCheckingMessage$.next('Frissítés keresése...')
    this.isAppVersionChecking$.next(true)
    try {
      return await this.swUpdates.checkForUpdate()
    } catch (error) {
      if (this.t7e.lang === 'en') this.appVersionCheckingMessage$.next('Updating is not supported.')
      else this.appVersionCheckingMessage$.next('Az app ütemezett frissítése nem támogatott.')
      this.isAppVersionChecking$.next(false)
      return false
    }
  }


}

// type AppDataObservables = {
//   tableProperties: Observable<FlexTableProperty[] | null>,
//   departments: Observable<Department[] | null>,
//   users: Observable<DbUser[] | null>,
//   props: Observable<FlexProp[] | null>,
//   flexTables: Observable<FlexTable[] | null>,
//   prodDefaults: Observable<Production[] | null>,
//   errorMsg?: string,
// }

/**
 * null: még nem történt mentés;
 * false: a mentés hiba nélkül lefutott;
 * string: a mentéskor fellépő hibaüzenet
 */
export type DbCallError = false | string | object | null

export type ButtonStatus = { disabled: boolean, title: string }

export const NUMERIC_FIELD_TO_DELETE = -99999.99;

export const SESSION_STORAGE_IMPERSONATE = 'impersonate';
 
export type SwAppData = {
  isCritical?: VersionUpdateType
  isImportant?: VersionUpdateType
  whatsNew?: string
}

/**
 * false (senkinek) | true (mindenkinek) | <emailcím> | [{ target: 'email' | 'deptId' | 'userGroupId', value: string (ha email) | number (ha nem email) }]
 */
export type VersionUpdateType = boolean | string | VersionUpdateTarget[]
export type VersionUpdateTarget = { target: 'email' | 'deptId' | 'userGroupId', value: string | number }