<form [formGroup]="formGroup" (submit)="save()">

    <h3 *ngIf="isNewProp">Új kérdés</h3>

    <div class="form-field">
        <mat-form-field>
            <mat-label>Rövid név</mat-label>
            <input [formControl]="propcodeFC" matInput (change)="onPropChange()">
        </mat-form-field>
        <div class="propcode-error" *ngIf="!isPropCodeUnique">This field already exists!</div>
    </div>

    <div class="form-field">
        <mat-form-field>
            <mat-label>Mező magyarul</mat-label>
            <input [formControl]="propnameFC" matInput>
        </mat-form-field>
    </div>

    <div class="form-field">
        <mat-form-field>
            <mat-label>Mező angolul</mat-label>
            <input [formControl]="propnameEngFC" matInput>
        </mat-form-field>
    </div>

    <div class="form-field">
        <mat-form-field>
            <mat-label>Leírás magyarul</mat-label>
            <input [formControl]="propdescFC" matInput>
        </mat-form-field>
    </div>

    <div class="form-field">
        <mat-form-field>
            <mat-label>Leírás angolul</mat-label>
            <input [formControl]="propdescEngFC" matInput>
        </mat-form-field>
    </div>

    <div class="form-field">
        <kendo-floatinglabel text="Válasz típusa" class="dd-field">
            <kendo-dropdownlist [data]="flexEditTypes$ | async" textField="value" valueField="id"
                [valuePrimitive]="true" [formControl]="edittypeFC">
            </kendo-dropdownlist>
        </kendo-floatinglabel>
    </div>

    <div class="form-field">
        <kendo-floatinglabel text="Szerkesztési jog" class="dd-field">
            <kendo-dropdownlist [data]="editLevels" textField="value" valueField="id" [valuePrimitive]="true"
                [formControl]="levelFC">
            </kendo-dropdownlist>
        </kendo-floatinglabel>
    </div>

    <div class="form-field buttons">
        <button mat-raised-button type="submit" color="primary" [disabled]="!isPropCodeUnique || (saving$ | async)">Save</button>
        <button mat-raised-button type="button" color="secondary" (click)="cancel()" [disabled]="(saving$ | async)">Cancel</button>
    </div>

    <div class="save-error" *ngIf="saveError$ | async">
        <p>Save error!</p>
        <code class="details">{{ (saveError$ | async | json) }}</code>
    </div>

</form>