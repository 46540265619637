<div *ngIf="!dataItem?.arrDoclink?.length; else hasDocs" class="text-muted">
    Még nem generáltál számlamellékletet
</div>
<div *ngIf="getIsGenerating(dataItem)" class="d-flex">
    <mat-spinner [diameter]="20" class="mr-05"></mat-spinner>
    A számlamellékletet generálása folyamatban...
</div>
<ng-container *ngIf="getGeneratingError(dataItem)?.length">
    <div *ngFor="let err$ of getGeneratingError(dataItem); trackBy: trackByIndex" class=" text-danger px-1 mb-05">
        {{ err$ | async }}
    </div>
</ng-container>
<ng-template #hasDocs>
    <div class="doc-list">
        <app-doclink 
            (removeFileClicked)="removeFile($event)" 
            (sendOnlyPdfClicked)="sendOnlyPdf($event)"
            [data]="dataItem">
        </app-doclink>
    </div>
</ng-template>
<div class="doc-generate-buttons" *ngIf="isFinance && dataItem; else hideButtons">
    <button mat-raised-button type="button" (click)="generatePdf([dataItem!], false, 'user', true)"
        [disabled]="canGeneratePdf(dataItem!).disabled" [title]="canGeneratePdf(dataItem!, 'Egy ideiglenes Google Sheetet hozol létre. Nem küldöd ki emailen').title" class="btnGenerate">
        Piszkozat
    </button>
    <button mat-raised-button type="button" (click)="generatePdf([dataItem!], false, 'user')"
        [disabled]="canGeneratePdf(dataItem!).disabled" [title]="canGeneratePdf(dataItem!, 'Egy WTS Google Sheetett generálsz a Drive-ra, de nem küldöd ki emailen').title" class="btnGenerate">
        Létrehozás
    </button>
    <button mat-raised-button type="button" (click)="generatePdf([dataItem!], true, 'user')"
        [disabled]="canGeneratePdf(dataItem!).disabled" [title]="canGeneratePdf(dataItem!, 'Egy WTS Google Sheetet és PDF-et generálsz a Drive-ra, és kiküldöd emailen').title" class="btnGenerate">
        Létrehozás és kiküldés
    </button>
</div>

<ng-template #hideButtons>
    <div *ngIf="!dataItem">Ismeretlen adat</div>
</ng-template>