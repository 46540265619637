<div class="">
    <button mat-raised-button [color]="color" [type]="buttonType" [disabled]="disabled" (click)="onClick()">
        <span *ngIf="showDoneIcon && ((saveError$ | async) === false)" class="mat-icon text-success">
            <mat-icon>done</mat-icon> &nbsp;
        </span>
        {{ buttonText }}
    </button>
</div>
<div>
    <mat-error *ngIf="(saveError$ | async)">
        {{ errorText }}
        <div class="error-msg" style="width: 400px; overflow-y: auto;">
            <pre *ngIf="showErrMsg">
                        {{ saveError$ | async }}
                    </pre>
        </div>
    </mat-error>
    <div *ngIf="(saveError$ | async) === false && successText" class="bg-success">
        {{ successText }}
    </div>
</div>
