import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Contract, ContractParsed } from '../../models/contract';
import { ContractService } from '../contract.service';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, map, of, take, takeUntil } from 'rxjs';
import { AppUser } from '../../models/db-user';
import { UserService } from '../../user.service';
import { T7eService } from '../../t7e/t7e.service';
import { NotifOptions, NotificationService } from '../../util/notifications/notification.service';

@Component({
  selector: 'app-select-contract',
  templateUrl: './select-contract.component.html',
  styleUrls: ['./select-contract.component.scss']
})
export class SelectContractComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activeContract$?: BehaviorSubject<ContractParsed | null>
  @Input() selectable?: boolean = true
  @Input() showAddButton?: boolean = true
  @Input() hideStatus?: boolean = false
  @Input() user?: AppUser | null | 'dept-admin' = null
  @Input() showName?: boolean = false
  @Input() allowSelectUnapproved?: boolean = !this.contractSvc.isFinanceApprovalNeededConfig || this.userSvc.isProdMgr || this.userSvc.isFinance
  @Output() newContractClicked = new EventEmitter<void>()

  _destroy = new Subject<void>()
  notAllowedNotifHandler: number | null = null

  constructor(
    private contractSvc: ContractService,
    private userSvc: UserService,
    private t7e: T7eService,
    private notifSvc: NotificationService,
  ) {
    this.selectedOnly = this.selectedOnly.bind(this)
    this.selectContract = this.selectContract.bind(this)
    this.isContractClickable = this.isContractClickable.bind(this)
  }

  contracts$?: Observable<ContractParsed[]>
  loadContractError$ = this.contractSvc.loadContractError$
  selectedContract$ = this.contractSvc.selectedContract$
  isAllClickable$?: Observable<boolean>

  ngOnDestroy(): void {
    this._destroy.next()
    this._destroy.complete()
  }

  ngOnInit(): void {
    if (!this.activeContract$) {
      this.activeContract$ = this.selectedContract$
    }

    this.contracts$ = this.user === 'dept-admin'
      ? this.contractSvc.deptContracts$
        .pipe(
          map(contracts => contracts
            .sort((a, b) => (a.usname || '').localeCompare(b.usname || ''))
          )
        )
      : this.user?.usid
        ? of(this.contractSvc.getContractsByUser(this.user.usid))
        : this.contractSvc.userContracts$
          .pipe(
            map(contracts => contracts.filter(c => c.constatus! > 0))
        )
    
    this. isAllClickable$ = combineLatest([this.contracts$!, this.activeContract$!])
      ?.pipe(
        takeUntil(this._destroy),
        filter(([contracts, activeContract]) => !!contracts),
        map(([contracts, activeContract]) => this.allowSelectUnapproved || !!activeContract || contracts
          .filter(c => {
            return this.selectedOnly(c)
          })
          .every(this.isContractClickable))
      )

    this.contracts$
      .pipe(take(1))
      .subscribe(cons => { if (cons.length === 1) this.selectContract(cons[0]) })
  }

  selectContract(c: ContractParsed): void {
    if (!c.conid) return
    if (!this.allowSelectUnapproved && !this.contractSvc.isConsideredFinanceApprovedOrAfter(this.contractSvc.allContracts, c)) {
      const msg = 'Ez a tevékenység még nincs jóváhagyva, ezért nem vihetsz rá be timesheetet: ' + c.role
      const notifOptions: NotifOptions = { class: 'warning', msg }
      if (this.notAllowedNotifHandler === null) this.notAllowedNotifHandler = this.notifSvc.addObservableNotif(notifOptions)
      else this.notifSvc.modifyNotif(this.notAllowedNotifHandler, notifOptions)
      return
    }
    if (this.notAllowedNotifHandler !== null) {
      this.notifSvc.closeNotif(this.notAllowedNotifHandler)
      this.notAllowedNotifHandler = null
    }
    this.contractSvc.selectContract(c.conid)
    this.userSvc.selectUserById(this.contractSvc.selectedContract$.value?.usid)
  }

  addContract(): void {
    this.contractSvc.selectContract(null)
    this.newContractClicked.emit()
  }

  selectedOnly(contract: ContractParsed): boolean {
    if (this.selectable) return true
    return contract.conid === this.activeContract$?.value?.conid
  }

  // <conid, isClickable>
  aIsContractClickable = new Map<number, boolean>()
  isContractClickable(contract: ContractParsed): boolean {
    if (this.allowSelectUnapproved) return true
    if (!contract?.conid) return true
    const cached = this.aIsContractClickable.get(contract.conid!)
    if (cached !== undefined) return cached
    const retVal = this.contractSvc.isConsideredFinanceApprovedOrAfter(this.contractSvc.allContracts, contract)
    this.aIsContractClickable.set(contract.conid, retVal)
    return retVal
  }

  ngOnChanges() {
    //console.log('showStatus2', this.hideStatus);
  }

  /** T7E */
  t7eNoContractWarningTitle = this.t7e.getTranslation('app-select-contract', 'no-contract-warning', 'title', 'Még nincs start formod')
  t7eNoContractWarningDesc = this.t7e.getTranslation('app-select-contract', 'no-contract-warning', 'description', 'Hozz létre egyet most!')
  t7eNotAllClickableTitle = this.t7e.getTranslation('app-select-contract', 'not-all-clickable', 'title', null, null, 'Jóváhagyás nélküli start form')
  t7eNotAllClickableDesc = this.t7e.getTranslation('app-select-contract', 'not-all-clickable', 'description', null, null, 'Mostantól csak a Pénzügy által jóváhagyott start formokra lehet timesheetet bevinni. Ha nem küldted még be a start formodat, kérlek küldd be mihamarabb. Ha már beküldted, kérjük várj türelemmel, amíg a gyártásvezető és a pénzügy jóváhagyja ')
}
