import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { ContractService } from '../contract/contract.service';
import { Contract } from '../models/contract';
import { UserService } from '../user.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { T7eService } from '../t7e/t7e.service';
import { delay } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('mainMenuTrigger') mainMenuTrigger?: MatMenuTrigger;
  
  get debug() { return this.appSvc.debug }
  set debug(val: boolean) { this.appSvc.debug = val }
  get impersonate() { return this.appSvc.impersonate }
  environment = this.appSvc.environment
  pageTitle$ = this.appSvc.pageTitle$.pipe(delay(0))
  
  constructor(
    private userSvc: UserService,
    private appSvc: AppService,
    private contractSvc: ContractService,
    private t7e: T7eService,
  ) { }

  readonly user$ = this.userSvc.loggedinUser$
  get profilePic() { return this.userSvc.pic }
  get isFinance() { return this.userSvc.isFinance }
  get isProdMgr() { return this.userSvc.isProdMgr }
  get isDev() { return this.userSvc.isDev } 
  get isMoreThanDeptHead() { return this.userSvc.isMoreThanDeptHead }


  ngOnInit(): void {
  }

  get roles() {
    if (!this.userSvc.roles?.length) return ['Jóváhagyásra vár']
    return this.userSvc.roles
      .map(r => r.ugname )
    //   .map(r => ({
    //   ...r,
    //   color: (r.ugid===1 ? undefined : (r.ugid===2 ? 'primary' : (r.ugid===3 ? 'accent' : 'warn'))),
    // }))
  }

  get selectedPosition() {
    if (!this.contractSvc.selectedContract?.role) return 'Válassz szerepkört!'
    return this.contractSvc.selectedContract?.role
  }

  isSelectedPosition(pos: Contract): boolean {
    return this.contractSvc.selectedContract?.conid === pos.conid
  }

  get positions$() {
    return this.contractSvc.userContracts$
  }

  selectPosition(contract: Contract): void {
    this.contractSvc.selectContract(contract.conid)
  }

  logout(): void {
    this.userSvc.logout(location.origin)
  }

  closeMenu(menuTriggerRef: MatMenu | any): void {
    menuTriggerRef.closeMenu()
  }

  removeImpersonate(): void {
    this.appSvc.impersonate = null
  }
  
  /** T7E */
  t7eMyProfile = this.t7e.getTranslation('app-header', 'my-profile', 'innerText', null)
  t7eLogout = this.t7e.getTranslation('app-header', 'logout', 'innerText', null)
  t7eMyContracts = this.t7e.getTranslation('app-header', 'my-contracts', 'innerText', null)
  t7eMyTimesheets = this.t7e.getTranslation('app-header', 'my-ts', 'innerText', null)
}
