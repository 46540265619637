import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppService, DbCallError } from '../../app.service';
import { ContractService } from '../../contract/contract.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { TimesheetService } from '../timesheet.service';
import { CheckTimesheetParams, CheckTimesheetResponse, HandleTimesheetsParams, Timesheet } from '../../models/timesheet';
import { BehaviorSubject, Subject, debounceTime, of, takeUntil } from 'rxjs';
import { UserService } from '../../user.service';
import { T7eService } from '../../t7e/t7e.service';

@Component({
  selector: 'app-adjacent-timesheets',
  templateUrl: './adjacent-timesheets.component.html',
  styleUrls: ['./adjacent-timesheets.component.scss']
})
export class AdjacentTimesheetsComponent implements OnInit, OnDestroy {
  @Input() params$?: BehaviorSubject<HandleTimesheetsParams | null>
  @Output() overlapseChecked = new EventEmitter<CheckTs>()
  checkTsResult: CheckTs | null = null
  tsChecking$ = new BehaviorSubject<boolean>(true)
  _destroy$ = new Subject<void>()

  constructor(
    private tsSvc: TimesheetService,
    private notifSvc: NotificationService,
    private appSvc: AppService,
    private userSvc: UserService,
    private t7e: T7eService,
  ) { }

  get debug() {return this.appSvc.debug }
  isMoreThanDeptHead = this.userSvc.isMoreThanDeptHead

  ngOnInit(): void {
    this.params$
      ?.pipe(
        takeUntil(this._destroy$),
      //debounceTime(1000)
    )
    .subscribe({
      next: params => {
        if (params) {
          this.checkTs(params)
        } else {
          this.tsChecking$.next(false)
          this.checkTsResult = null
        }

      }
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  checkTs(params: CheckTimesheetParams): void {
    if (!params._startdate || !params._enddate || !params._conid) return
    this.tsChecking$.next(true)
    this.tsSvc.checkTs(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.tsChecking$.next(false)
          console.log('check_ts válasz:', response)
          this.checkTsResult = { ...response, loadError: null }
          this.overlapseChecked.emit(this.checkTsResult)
        },
        error: err => {
          this.tsChecking$.next(false)
          console.error(err)
          this.checkTsResult = {
            loadError: this.t7eNotifLoadError + JSON.stringify(err),
            overlaptsid: null,
            sametsid: null,
            next: { hours: null, startdate: null, tsid: null, },
            prev: { enddate: null, hours: null, tsid: null, },
            daytype: null,
          }
          this.notifSvc.addObservableNotif({ msg: this.t7eNotifLoadError, class: 'warning', duration: 2000 })
        }
      })
  }


  get lastTsText() {
    let num = -1
    let dimension: 'hours' | 'days'
    let isOverlap = false
    if (this.abs(this.checkTsResult?.prev?.hours) <= 36) {
      num = Math.round(this.abs(this.checkTsResult?.prev?.hours))
      dimension = 'hours'
    } else {
      num = Math.round(this.abs(this.checkTsResult?.prev?.hours) / 24)
      dimension = 'days'
    }
    if (this.checkTsResult?.prev?.hours! < 0) isOverlap = true
    if (isOverlap) {
      if (this.t7e.lang == 'hu') {
        return `${num} ${dimension === 'hours' ? 'óra' : 'nap'} átfedés`
      } else {
        return `${num} ${dimension === 'hours' ? 'hours' : 'days'} overlap`
      }
    } else {
      if (this.t7e.lang == 'hu') {
        return `${num} ${dimension === 'hours' ? 'órája' : 'napja'}`
      } else {
        return `${num} ${dimension === 'hours' ? 'hours ago' : 'days ago'}`
      }
    }
  }

  get nextTsText() {
    let num = -1
    let dimension: 'hours' | 'days'
    let isOverlap = false
    if (this.abs(this.checkTsResult?.next?.hours) <= 36) {
      num = Math.round(this.abs(this.checkTsResult?.next?.hours))
      dimension = 'hours'
    } else {
      num = Math.round(this.abs(this.checkTsResult?.next?.hours) / 24)
      dimension = 'days'
    }
    if (this.checkTsResult?.next?.hours! < 0) isOverlap = true
    if (isOverlap) {
      if (this.t7e.lang == 'hu') {
        return `${num} ${dimension === 'hours' ? 'óra' : 'nap'} átfedés`
      } else {
        return `${num} ${dimension === 'hours' ? 'hours' : 'days'} overlap`
      }
    } else {
      if (this.t7e.lang == 'hu') {
        return `${num} ${dimension === 'hours' ? 'óra múlva' : 'nap múlva'}`
      } else {
        return `${num} ${dimension === 'hours' ? 'hours from now' : 'days from now'}`
      }
    }
  }

  abs(num?: number | null) { return num ? Math.abs(num) : 0 }


  /** T7E */
  t7eNotifLoadError = this.t7e.getTranslation('app-adjacent-timesheets', 'notif-msg', 'load-error', 'Nem lehetett ellenőrizni az időpont átfedéseket: ') 
  
}

export interface CheckTs extends CheckTimesheetResponse {
  loadError: string | null

}
