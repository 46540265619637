import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocService } from '../../doc.service';
import { ContractParsed, DocLink } from '../../models/contract';
import { RemoveFileClickedEvent } from '../../util/doclink/doclink.component';
import { TimesheetWeekly } from '../../models/timesheet';

@Component({
  selector: 'app-gen-contract-file',
  templateUrl: './gen-contract-file.component.html',
  styleUrls: ['./gen-contract-file.component.scss']
})
export class GenFileComponent implements OnInit {
  @Input() dataItem?: ContractParsed
  @Output() dataItemChange = new EventEmitter<ContractParsed>()

  getIsGenerating = (conid?: number | null) => { return conid && this.docSvc.getIsContractGenerating(conid) }
  generatePdf = this.docSvc.generateContract
  canGeneratePdf = this.docSvc.canGenerateContractPdf
  generatingError$ = this.docSvc.generatingContractError$ // azon a conid-k listája, aminek a generálása hibára futott

  constructor(
    private docSvc: DocService,

  ) { }

  ngOnInit(): void {
    console.assert(this.dataItem, 'GenFileComponent: @Input() dataItem kötelező')
  }

  removeFile(event: RemoveFileClickedEvent): void {
    this.docSvc.removeContractFile(event.doc, event.data as ContractParsed)
      .subscribe({
        next: (response) => {
          if (response === null) { 
            return
          }
          this.dataItemChange.emit(event.data as ContractParsed)
        },
        error: (error) => {
        }
    })
  }

  getFolderUrl(): string | null {
    if (!this.dataItem) {
      return null
    }
    return this.docSvc.getDriveLink(this.dataItem.f_folderid || null, 'folder')
  }

  get isFolderExists(): boolean {
    return !!this.dataItem?.f_folderid
  }

  sendOnlyPdfClicked(event: { doc: DocLink, data: ContractParsed | TimesheetWeekly }): void {
    this.generatePdf(event.data as ContractParsed,true, false, event.doc.fileid, true)
      ?.subscribe({
        next: res => {
          console.log('generateContractDoc válasz', res);
          
        },
        error: err => {
          console.error('generateContractDoc error', err);
        }
      })

  }

}
