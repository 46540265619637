<div class="ts" [title]="'id=' + timesheet.tsid"  *ngIf="timesheet"
    [class.selected]="isSelected">
    <div class="ts-header">
        <div class="ts-date">
            {{ timesheet.dStartDate | date: 'MMM.dd HH:mm' }}
            -
            {{ timesheet.dEndDate | date: 'HH:mm' }}
        </div>
        <div class="dept" *ngIf="!isOwnTs(timesheet) && showDept">
            {{ timesheet.depname }}
        </div>
        <div class="role" *ngIf="showRole">
            {{ timesheet.role }}
        </div>
        <div class="ts-status" [ngClass]="getTsStatusClass(timesheet)">
            {{ getTsStatusName(timesheet.tsstatus) }}
        </div>
    </div>
</div>
