import { Component, OnInit } from '@angular/core';
import { Contract } from '../../models/contract';
import { ContractService } from '../contract.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-dept-contracts',
  templateUrl: './dept-contracts.component.html',
  styleUrls: ['./dept-contracts.component.scss']
})
export class DeptContractsComponent implements OnInit {

  constructor(
    private contractSvc: ContractService,
    private userSvc: UserService,
  ) { }

  contract$ = this.contractSvc.selectedContract$
  users$ = this.contractSvc.usersInLoggedInUsersDept$
  selectedUser$ = this.userSvc.selectedUser$

  ngOnInit(): void {
  }

}
