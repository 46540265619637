import { LocaleService } from "./locale.service";
import { LOCALE_ID, Provider } from '@angular/core';

/**
 * An way to provide LOCALE_ID via a Service.
 */
export class LocaleId extends String {
  constructor(private localeService: LocaleService) {
    super();
  }

  override toString(): string {
    return this.localeService.currentLocale;
  }

  override valueOf(): string {
    return this.toString();
  }
}

/**
 * Provides [[LOCALE_ID]] via [[LocaleService]].
 */
export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [LocaleService],
};