import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractParsed } from '../../models/contract';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
})
export class ContractComponent implements OnInit {
  @Input() contract$ = new BehaviorSubject<ContractParsed | null>(null)
  @Input() isSelected$ = new  BehaviorSubject<boolean>(false)
  @Input() isSelectable: boolean = false
  @Output() click = new EventEmitter<ContractParsed>()

  constructor(
  ) { }

  ngOnInit(): void {
  }

  selectContract(): void {
    if (!this.isSelectable) return
    if (!this.contract$.value) return
    this.click.emit(this.contract$.value)
  }

}
