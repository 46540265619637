import { Component, Input, OnInit } from '@angular/core';
import { ProductionService } from '../production.service';
import { AppService } from '../../app.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HandleShootingLocationParams, ShootingLocation } from '../../models/location';
import { forkJoin } from 'rxjs';
import { NotificationService } from '../../util/notifications/notification.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  @Input() locationsFG!: FormGroup<LocationsFG>

  locations$ = this.prodSvc.locations$
  locationsLoading$ = this.prodSvc.locationsLoading$
  locationsLoadingError$ = this.prodSvc.locationsLoadingError$
  locationSaving$ = this.prodSvc.locationSaving$
  saveError$ = this.prodSvc.locationSavingError$

  constructor(
    private prodSvc: ProductionService,
    private appSvc: AppService,
    private notifSvc: NotificationService,
    private fb: FormBuilder,
  ) {
    if (!prodSvc.locations$.value?.length) prodSvc.fetchShootingLocations()
  }

  get debug() { return this.appSvc.debug }
  get locationsFA() { return this.locationsFG.get('savedLocations') as FormArray<FormGroup<LocationFG>> }

  ngOnInit(): void {
    if (!this.locationsFG) throw new Error('locationsFG not set')
    if (!this.locationsFA) throw new Error('locationsFA not set')
    if (!this.locationsFG.get('newLocation')) throw new Error('newLocation not set')
    if (!this.locationsFG.get('newKm')) throw new Error('newKm not set')
    if (!this.locationsFG.get('newKmFee')) throw new Error('newKmFee not set')

    this.prodSvc.locations$.subscribe({
      next: locations => {
        if (!locations) return
        this.fillLocationsFA(locations)
      }
    })

  }
  

  fillLocationsFA(locations: ShootingLocation[]): void {
    this.locationsFA.clear()
    setTimeout((locations) => {
      locations.forEach(location => {
        this.locationsFA.push(this.getLocationFG(location))
      })
    }, 0, locations)
  }

  getLocationFG(location: ShootingLocation): FormGroup<LocationFG> {
    const retVal = this.fb.group({
      locationId: [location.locId, [Validators.required]],
      locationName: [location.locName, [Validators.required]],
      km: [location.km, [Validators.required]],
      kmFee: [location.kmFee, [Validators.required]],
      enabled: [location.enabled],
    })
    if (!location.enabled) retVal.disable()
    return retVal
  }
      
  disableLocation(location: LocationFGValues): void {
    this.prodSvc.enableDisableLocation(location.locationId!, false)
  } 
  enableLocation(location: LocationFGValues): void {
    this.prodSvc.enableDisableLocation(location.locationId!, true)
  }
  
  saveLocations(location: LocationFGValues, e?: Event): void {
    e?.preventDefault()
    const aErr = [] as string[]
    if (!location.locationId) aErr.push('Ismeretlen lokációt szerkesztesz.')
    if (!location.locationName?.trim()) aErr.push('A helyszín megadása kötelező.')
    if (!location.km) aErr.push('A távolság megadása kötelező.')
    if (!location.kmFee) aErr.push('A kilométerdíj megadása kötelező.')
    if (aErr.length) {
      const msg = aErr.join(' ')
      this.notifSvc.addObservableNotif({ msg, class: 'danger' })
      return
    }
    const params: HandleShootingLocationParams = {
      locId: location.locationId || null,
      locCode: location.locationName!.trim(),
      locName: location.locationName!.trim(),
      km: location.km!,
      kmFee: location.kmFee!,
    }
    this.prodSvc.saveShootingLocation(params)
  }

  addLocation(e?: Event): void {
    e?.preventDefault()
    const params: HandleShootingLocationParams = {
      locId: null,
      locCode: this.locationsFG.get('newLocation')?.value!,
      locName: this.locationsFG.get('newLocation')?.value!,
      km: this.locationsFG.get('newKm')?.value!,
      kmFee: this.locationsFG.get('newKmFee')?.value!,
    }
    this.prodSvc.saveShootingLocation(params)
      .subscribe({
        next: () => {
          this.locationsFG.get('newLocation')?.reset()
          this.locationsFG.get('newKm')?.reset()
          this.locationsFG.get('newKmFee')?.reset()
        },
      })
  }

  calcKmFee(): void {
    const f = this.locationsFG.value
    if (f.hufPerKm) {
      if (f.newKm) {
        const hufPerKm = f.hufPerKm
        this.locationsFG.get('newKmFee')?.setValue(f.hufPerKm! * f.newKm!)
      }
    }
  }
  
  trackByIndex = this.appSvc.trackByIndex
}

export interface LocationsFG {
  savedLocations: FormArray<FormGroup<LocationFG>>
  newLocation: FormControl<string | null>;
  newKm: FormControl<number | null>;
  newKmFee: FormControl<number | null>;
  hufPerKm: FormControl<number | null>;
}

export interface LocationFG {
  locationId: FormControl<number | null>;
  locationName: FormControl<string | null>;
  km: FormControl<number | null>;
  kmFee: FormControl<number | null>;
  enabled: FormControl<boolean | null>;
}

export interface LocationFGValues {
  locationId?: number | null;
  locationName?: string | null;
  km?: number | null;
  kmFee?: number | null;
  enabled?: boolean | null;
}