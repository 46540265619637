import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../data.service';
import { ContractService } from './contract.service';
import { ContractParsed } from '../models/contract';
import { T7eService } from '../t7e/t7e.service';

@Pipe({
  name: 'otRate'
})
export class OtRatePipe implements PipeTransform {
  constructor(private dataSvc: DataService) {}

  transform(value: {} | any[] | null | undefined, ...args: unknown[]): string {
    return this.dataSvc.dbArrayToText(value)
  }

}

@Pipe({
  name: 'contractStatusName'
})
export class ContractStatusNamePipe implements PipeTransform {
  constructor(
    private contractSvc: ContractService,
    private t7e: T7eService
  ) { }

  transform(value?: ContractParsed | null, ...args: unknown[]): string {
    if (!value) return this.t7e.lang==='en' ? 'Unknown status' : 'Ismeretlen státusz'
    return this.contractSvc.getStatusName(value.constatus)
  }

}


@Pipe({
  name: 'contractStatusClass'
})
export class ContractStatusClassPipe implements PipeTransform {
  constructor(private contractSvc: ContractService) {}

  transform(value?: ContractParsed | null, ...args: unknown[]): string {
    if (!value) return ''
    return this.contractSvc.getStatusClass(value)
  }

}
