<div class="select-user">
    <!-- <div class="search-wrapper">
        <input #searchText placeholder="keresés...">
    </div> -->
    <div class="users-wrapper">
        <app-user-card
            [user]="user"
            *ngFor="let user of sortedUsers$ | async; trackBy:getUserId"
            (click)="onUserSelected(user)"
            class=""
            >
        </app-user-card>
    </div>
</div>