

<kendo-grid #grid [kendoGridBinding]="allUsers || []" class="grid full-height"
    [pageable]="true" [skip]="skip" [pageSize]="50"
    [sortable]="true"  [sort]="sort"
    [filterable]="true" [filter]="filter" (filterChange)="filterChanged($event)"
    [navigable]="true" [reorderable]="true" [resizable]="true" 
    [selectable]="true" [(selectedKeys)]="selectedUserIds" kendoGridSelectBy="usid"
    [loading]="(allUsersLoading$ | async) || (saving$ | async) || (contractsLoading$ | async) || false"
    (dataStateChange)="dataStateChange($event)" (columnReorder)="onColReorder($event)"
    (columnResize)="onColResized($event)"
    [rowClass]="getRowClass" >
    <ng-template kendoGridToolbarTemplate>
        <form class="toolbar" [formGroup]="form">
                <kendo-dropdownlist #statusFilter id="status-filter"
                    [formControl]="statusFilterFC"
                    [data]="userStatusesForFilter"
                    (valueChange)="filterUsersByStatus($event)"
                    [valuePrimitive]="true"
                    [textField]="'text'"
                    [valueField]="'value'"
                    [defaultItem]="{ text: 'all' | t7e:'Összes', value: null }"
                    >
                </kendo-dropdownlist>
            <div class="divider"></div>
            <button mat-raised-button color="primary" type="button" (click)="reload()">
                <mat-icon>refresh</mat-icon>
            </button>
        </form>
    </ng-template>

    <kendo-grid-column field="usid" [title]="'user-grid.field.usid' | t7e:'Adatbázis azonosító'" *ngIf="debug"></kendo-grid-column>
    <kendo-grid-column field="famname" [title]="'user-grid.field.famname' | t7e:'Vezetéknév'"> </kendo-grid-column>
    <kendo-grid-column field="usnameOrig" [title]="'user-grid.field.usname' | t7e:'Keresztnév'"> </kendo-grid-column>
    <kendo-grid-column field="email" title="Email">
        <ng-template kendoGridCellTemplate let-dataItem>
            <a [href]="'mailto:' + dataItem.email">{{dataItem.email}}</a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="phonenumber" [title]="'user-grid.field.phonenumber' | t7e:'Telefonszám'">
        <ng-template kendoGridCellTemplate let-dataItem>
            <a [href]="'tel:' + dataItem.phonenumber">{{dataItem.phonenumber}}</a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="deptAndPosConcat" [title]="'user-grid.field.deptAndPosConcat' | t7e:'Részleg & Pozíció'">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p *ngFor="let contract of getContracts(dataItem.usid) | callback:filterContractNotDisabled">
                {{ contract.depname }} / {{ contract.role }}
            </p>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="ugmConcat" [title]="'user-grid.field.ugm' | t7e:'App szerepek'">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="usergroups" *ngIf="dataItem.usstatus !==0">
                <div class="usergroup ml-2 mb-1" (click)="groupChanged(dataItem, crewUserGroup)">
                    <mat-checkbox [checked]="hasRole(dataItem, crewUserGroup)">{{crewUserGroup.ugname}}</mat-checkbox>
                </div>
                <div class="other-groups-list ml-2">
                    <div class="other-group" *ngFor="let g of getUsersOtherGroups(dataItem)">
                         + {{ g }}
                    </div>
                </div>
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'more' | t7e: 'Több' }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="usergroup" *ngFor="let ug of getUserGroups" (click)="groupChanged(dataItem, ug)">
                        <mat-checkbox [checked]="hasRole(dataItem, ug)" *ngIf="ug.ugid!=1 && (isDev || ug.ugid!=5)">{{ug.ugname}}</mat-checkbox>
                    </div>
                </mat-expansion-panel>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" [width]="200">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
            <div *ngIf="dataItem.usstatus===0; else notDeleted" t7e="deleted">
                TÖRÖLVE
            </div>
            <ng-template #notDeleted>
                <div class="grid-row-buttons">
                    <button mat-raised-button (click)="editUser(dataItem)"
                        class="grid-row-button">
                        <mat-icon>edit</mat-icon>
                        {{ 'edit' | t7e:'Szerkesztés' }}
                    </button>
                    <button mat-raised-button (click)="removeUser(dataItem)"
                        class="grid-row-button">
                        <mat-icon>delete</mat-icon>
                        {{ 'delete' | t7e:'Törlés' }}
                    </button>
                    <button mat-raised-button (click)="enableUser(dataItem)" [disabled]="dataItem.usstatus!=-1"
                        class="grid-row-button">
                        <mat-icon>check</mat-icon>
                        {{ 'enable' | t7e:'Engedélyezés' }}
                    </button>
                    <button mat-raised-button (click)="disableUser(dataItem)" [disabled]="dataItem.usstatus==-1"
                        class="grid-row-button">
                        <mat-icon>block</mat-icon>
                        {{ 'disable' | t7e:'Tiltás' }}
                    </button>
                </div>
            </ng-template>
        </ng-template>
    </kendo-grid-command-column>

    <ng-template kendoGridDetailTemplate let-dataItem>
        <div *ngIf="debug">{{ dataItem | json }}</div>
        
        <div class="row-details">
            <ng-template #noValue>
                <p>-</p>
            </ng-template>
            <div>
                <span class="detail-box-title" t7e="contract-fee">{{getBoxTitle(dataItem.details, 'vehiclenumber')}}</span>
                <p *ngIf="getPropValue(dataItem.details, 'vehiclenumber'); else noValue">
                    {{ getPropValue(dataItem.details, 'vehiclenumber') }}
                </p>
            </div>
        
            <div>
                <span class="detail-box-title" t7e="contract-fee">{{getBoxTitle(dataItem.details, 'emergencycontact')}}</span>
                <p *ngIf="getPropValue(dataItem.details, 'emergencycontact'); else noValue">
                    <a [href]="'tel:' + getPropValue(dataItem.details, 'emergencycontact')">
                        {{ getPropValue(dataItem.details, 'emergencycontact') }}
                    </a>
                </p>
            </div>
        </div>

        <app-manage-contracts [userId]="dataItem.usid" [showFilters]="false" [height]="null">
        </app-manage-contracts>
    </ng-template>
</kendo-grid>

<kendo-dialog [title]="(selectedUser$ | async)?.usname || ''" *ngIf="isUserEditorOpen" (close)="close()" 
    [width]="550" [height]="'90vh'" [animation]="true">
    <div class="saving" *ngIf="saving$ | async">{{ 'notifSaving' | t7e:'Mentés folyamatban...'}}</div>
    <div class="save-error" *ngIf="saveError$ | async">{{ 'notifSaveError' | t7e:'Mentés hiba'}}</div>
<app-profile [user$]="selectedUser$" [showGotoContractButton]="false" [showUserGroups]="false"></app-profile>
</kendo-dialog>