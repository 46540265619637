import { Injectable } from '@angular/core';
import { ColumnReorderEvent, ColumnResizeArgs, GridComponent, GroupKey } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import * as deepmerge from 'deepmerge';
import { BehaviorSubject } from 'rxjs';
import { TimesheetWeekly } from './models/timesheet';
import { GenInvRepGroupBy } from './doc.service';
import { ContractStatusSelectItem } from './util/filters/filter-con-status/filter-con-status.component';
import { PropItem } from './models/table-property';
import { TimesheetStatusFilterItem } from './util/filters/filter-tsstatus/filter-tsstatus.component';
import { AppUser } from './models/db-user';
import { Department } from './models/department';
import { ReportTimesheetSummaryResponse } from './models/timesheet-fees';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor() { }

  /** Manage Contracts Page */
  manageContractsPageState$ = new BehaviorSubject<ManageContractsPageState | null>(null)
  get manageContractsPageState() {
    return this.manageContractsPageState$.value
  }

  setManageContractsPageState(state: ManageContractsPageState) {
    //console.log('oldState', this.manageContractsPageState, 'state', state)
    const newState = deepmerge(this.manageContractsPageState || {}, state || {}, { arrayMerge: this.overwriteMerge })
    //console.log('newState', newState)
    this.manageContractsPageState$.next(newState)
  }

  /** Manage Timesheets Page */
  manageTimesheetsPageState$ = new BehaviorSubject<ManageTimesheetsPageState | null>(null)
  get manageTimesheetsPageState() {
    return this.manageTimesheetsPageState$.value
  }

  setManageTimesheetsPageState(state: ManageTimesheetsPageState) {
    //console.log('oldState', this.manageTimesheetsPageState, 'state', state)
    const newState = deepmerge(this.manageTimesheetsPageState || {}, state || {}, { arrayMerge: this.overwriteMerge })
    //console.log('newState', newState)
    this.manageTimesheetsPageState$.next(newState)
  }

  /** Timesheets Grid a GenInv táblázat details-ként */
  genInvTsDetailsPageState$ = new BehaviorSubject<GenInvTsDetailsPageState | null>(null)
  get genInvTsDetailsPageState() {
    return this.genInvTsDetailsPageState$.value
  }

  setGenInvTsDetailsPageStatePageState(state: GenInvTsDetailsPageState) {
    //console.log('oldState', this.genInvTsDetailsPageStatePageState, 'state', state)
    const newState = deepmerge(this.genInvTsDetailsPageState || {}, state || {}, { arrayMerge: this.overwriteMerge })
    //console.log('newState', newState)
    this.genInvTsDetailsPageState$.next(newState)
  }

 /** Gen Inv Report Page */
  genInvReportPageState$ = new BehaviorSubject<GenInvReportPageState | null>(null)
  get genInvReportPageState() {
    return this.genInvReportPageState$.value
  }
  
  setGenInvReportPageState(state: GenInvReportPageState) {
    //console.log('oldState', this.genInvReportPageState, 'state', state)
    const newState = deepmerge(this.genInvReportPageState || {}, state || {}, { arrayMerge: this.overwriteMerge })
    //console.log('newState', newState)
    this.genInvReportPageState$.next(newState)
  }

  /** Manage Users Page */
  manageUsersPageState$ = new BehaviorSubject<ManageUsersPageState | null>(null)
  get manageUsersPageState() {
    return this.manageUsersPageState$.value
  }

  setManageUsersPageState(state: ManageUsersPageState) {
    //console.log('oldState', this.manageUsersPageState, 'state', state)
    const newState = deepmerge(this.manageUsersPageState || {}, state || {}, { arrayMerge: this.overwriteMerge })
    //console.log('newState', newState)
    this.manageUsersPageState$.next(newState)
  }


  /** Salary Report */
  salaryReportPageState$ = new BehaviorSubject<SalaryReportPageState | null>(null)
  get salaryReportPageState() {
    return this.salaryReportPageState$.value
  }

  setSalaryReportPageState(state: SalaryReportPageState) {
    //console.log('oldState', this.salaryReportPageState, 'state', state)
    const newState = deepmerge(this.salaryReportPageState || {}, state || {}, { arrayMerge: this.overwriteMerge })
    //console.log('newState', newState)
    this.salaryReportPageState$.next(newState)
  }


  /**  Timesheet Summary Report */
  timesheetSummaryPageState$ = new BehaviorSubject<TimesheetSummaryPageState | null>(null)
  get timesheetSummaryPageState() {
    return this.timesheetSummaryPageState$.value
  }

  setTimesheetSummaryPageState(state: TimesheetSummaryPageState) {
    //console.log('oldState', this.timesheetSummaryPageState, 'state', state)
    const newState = deepmerge(this.timesheetSummaryPageState || {}, state || {}, { arrayMerge: this.overwriteMerge })
    //console.log('newState', newState)
    this.timesheetSummaryPageState$.next(newState)
  }
  
  private overwriteMerge = (destinationArray: any[], sourceArray: [], options: any) => sourceArray
}

export type ManageContractsPageState = {
  filters?: {
    search?: string | null,
    contractStatuses?: ContractStatusSelectItem[] | null,
    dateApproval?: Date | null,
    contractType?: PropItem | null,
  },
  grid?: {
    state?: State,
    selectedContractIds?: number[],
    expandedDetailKeys?: number[],
    columnsConfig?: ColumnConfig
  }
}

export type ManageTimesheetsPageState = {
  filters?: {
    from?: Date | null,
    to?: Date | null,
    user?: AppUser | null,
    dept?: Department | null,
    workhours?: number | null,
    tsStatuses?: TimesheetStatusFilterItem[] | null
  },
  grid?: {
    state?: State,
    selectedTimesheetIds?: number[],
    columnsConfig?: ColumnConfig,
    expandedGroupKeys?: Array<GroupKey>,
    expandedDetailKeys?: number[],
  }
}

export type GenInvTsDetailsPageState = {
  grid?: {
    state?: State,
    selectedTimesheetIds?: number[],
    columnsConfig?: ColumnConfig,
    expandedDetailKeys?: number[],
  }
}

export type GenInvReportPageState = {
  filters?: {
    from: Date,
    to: Date,
    contractType: string,
    groupBy: GenInvRepGroupBy,
    hideSent: boolean,
    hideInvoiceAccepted: boolean,
  },
  grid?: {
    state?: State,
    selectedContractIds?: number[],
    expandedDetailKeys?: number[],
    columnsConfig?: ColumnConfig,
    data?: TimesheetWeekly[],
  }
}

export type ManageUsersPageState = {
  filters?: {
    status?: number | null,
  },
  grid?: {
    state?: State,
    columnsConfig?: ColumnConfig,
    selectedUserIds?: number[],
  }
}

export type SalaryReportPageState = {
  filters?: {
    from?: Date | null,
    to?: Date | null,
  },
  grid?: {
    state?: State,
    columnsConfig?: ColumnConfig,
    expandedGroupKeys?: Array<GroupKey>,
    data?: TimesheetWeekly[],
  }
}

export type TimesheetSummaryPageState = {
  filters?: {
    from?: Date | null,
    to?: Date | null,
    contractType?: PropItem | null,
  },
  grid?: {
    state?: State,
    columnsConfig?: ColumnConfig,
    expandedGroupKeys?: Array<GroupKey>,
    data?: ReportTimesheetSummaryResponse[]
  }
}

export type ColumnConfig = {
  [field: string]: {
    width?: number,
    orderIndex?: number,
  }
}

export function isObject(item: any) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/*export function mergeDeep(target: any, source: any) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, { [key]: source[key] });
        else
          output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}*/

export function getReorderedConfig(grid: GridComponent, e: ColumnReorderEvent) {
  const newColumnsConfig: any = {}
  grid.columns.forEach((col, i) => {
    const orderIndex = col.orderIndex || i
    if ((orderIndex) < Math.min(e.oldIndex, e.newIndex)) return
    if ((orderIndex) > Math.max(e.oldIndex, e.newIndex)) return
    //@ts-ignore item.column.field létezik, csak a ts nem tud róla
    const origField = col.field, changedField = e.column.field
    if (origField === changedField) {
      newColumnsConfig[origField] = { orderIndex: e.newIndex }
      return
    }
    const increment = e.oldIndex < e.newIndex ? -1 : 1
    newColumnsConfig[origField] = { orderIndex: orderIndex + increment }
  })
  return newColumnsConfig
}

export function getResizedConfig(e: ColumnResizeArgs[]) {
  return e.map((item) => {
    if (item.newWidth === null || item.newWidth === undefined) return
    ///@ts-ignore item.column.field létezik, csak a ts nem tud róla
    const field = item.column.field as string
    //const column = columnsConfig[field] || {}//.find((c) => c['field'] === item.column.field);
    const newConfig: ColumnConfig = {}
    //column.width = item.newWidth;
    newConfig[field] = { width: item.newWidth }
    return newConfig
  })
}