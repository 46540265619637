<div class="d-flex d-flex-align-items-center gap-05" *ngIf="isTsLoading$ | async">
    <mat-spinner [diameter]="25" class="spinner-inline-block"></mat-spinner> 
    Betöltés...
</div>

<table *ngIf="(deletedTimesheets$ | async)?.length! > 0">
    <thead>
        <tr>
            <th *ngIf="debug">tsid</th>
            <th>Dátum</th>
            <th>Kezdés</th>
            <th>Végézs</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let ts of deletedTimesheets$ | async; trackBy:trackByIndex">
            <td *ngIf="debug">{{ ts.tsid }}</td>
            <td>{{ ts.dStartDate | date }}</td>
            <td>{{ ts.dStartDate | date: SQL_TIME_FORMAT}}</td>
            <td>{{ ts.dEndDate | date: SQL_TIME_FORMAT}}</td>
            <td class="commands">
                <button mat-icon-button [title]="'Visszaállítás'" (click)="restoreTs(ts)"
                    [disabled]="isTsLoading$ | async">
                    <mat-icon>lock_open</mat-icon>
                </button>
            </td>
        </tr>
    </tbody>
</table>

<div class="no-record" *ngIf="(deletedTimesheets$ | async)?.length === 0">
    Nincs törölt timesheeted
</div>

<div class="refresh mt-1">
    <button mat-raised-button color="secondary" class="refresh-button" 
      type="button" [disabled]="isTsLoading$ | async"
      (click)="reload()">
        <mat-icon>refresh</mat-icon>
        <span t7e="btn-refresh-text">Frissít</span>
    </button>
</div>