import { Component, Input, OnInit } from '@angular/core';
import { AppUser } from '../../models/db-user';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() user?: AppUser | null
  
  constructor(
    private userSvc: UserService,
  ) { }

  get initials() {
    return this.user?.usname
      ?.split(' ')
      ?.filter(n => !!n.trim())
      ?.map(n => n.trim()[0].toUpperCase())
      ?.join('')
  }

  get avatar() {
    // TODO: get avatar from user
    return null
  }

  get isSelected() {
    return this.user?.usid && this.user?.usid == this.userSvc.selectedUser?.usid
  }

  ngOnInit(): void {
  }

}
