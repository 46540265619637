<form [formGroup]="formGroup" (submit)="save()">
    <div class="form-field">
        <kendo-floatinglabel text="Kérdés helye">
            <kendo-dropdownlist [data]="flexTables$ | async" textField="value" valueField="id" [valuePrimitive]="true"
                [formControl]="tableidFC">
            </kendo-dropdownlist>
        </kendo-floatinglabel>
    </div>

    <div class="form-field">
        <kendo-floatinglabel text="Kérdés">
            <kendo-combobox [data]="flexPropsFiltered$ | async" textField="propname" valueField="propid"
                [valuePrimitive]="true" [allowCustom]="false" [formControl]="propidFC" [filterable]="true"
                (filterChange)="handleFilter($event)" (valueChange)="onPropChange($event)">
            </kendo-combobox>
        </kendo-floatinglabel>
    </div>
    <!-- <mat-form-field class="form-field">
        <mat-label>Kérdés</mat-label>
        <input type="text" matInput [formControl]="propidFC" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="getPropName" (optionSelected)="onPropChange($event)">
            <mat-option *ngFor="let prop of flexPropsFiltered$ | async" [value]="prop.propid">
                {{prop.propname}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field> -->

    <!-- <h3 *ngIf="isNewProp">Új kérdés</h3>
    <mat-form-field class="form-field">
        <mat-label>Angolul</mat-label>
        <input [formControl]="propnameEngFC" matInput>
    </mat-form-field>
    <mat-form-field class="form-field">
        <mat-label>Rövid név</mat-label>
        <input [formControl]="propcodeFC" matInput>
    </mat-form-field>
    <div class="form-field">
        <kendo-floatinglabel text="Válasz típusa">
            <kendo-dropdownlist [data]="flexEditTypes$ | async" textField="value" valueField="id"
                [valuePrimitive]="true" [formControl]="edittypeFC">
            </kendo-dropdownlist>
        </kendo-floatinglabel>
    </div>

    <div class="form-field">
        <kendo-floatinglabel text="Szerkesztési jog">
            <kendo-dropdownlist [data]="editLevels" textField="value" valueField="id" [valuePrimitive]="true"
                [formControl]="levelFC">
            </kendo-dropdownlist>
        </kendo-floatinglabel>
    </div> -->

    <mat-form-field class="form-field">
        <mat-label>Sorrend</mat-label>
        <input [formControl]="ordFC" matInput type="number" step="1">
    </mat-form-field>
    <div class="form-field">
        <mat-checkbox [formControl]="optionalFC" matInput>
            Opcionális
        </mat-checkbox>
    </div>
    <div class="form-field" *ngIf="!data.isNew">
        <label>Kérdést rögzítette</label>
        {{ dataItem.modusid}}
    </div>
    <div class="form-field" *ngIf="!data.isNew">
        <label>Rögzítés ideje</label>
        {{ dataItem.entryon }}
    </div>

    <section class="form-field save-buttons">
        <button mat-raised-button type="submit" color="primary" [disabled]="!formGroup.valid">Mentés</button>
        <button mat-raised-button type="button" color="secondary" (click)="cancel()">Mégse</button>
    </section>

</form>