<div class="card" [class.selected]="isSelected" *ngIf="user" [title]="user.usid">
    <div class="header">
        <div class="col1 avatar">
            <img [src]="avatar" alt="avatar" *ngIf="avatar; else noAvatar">
            <ng-template #noAvatar>
                <div class="initials">
                    {{ initials }}

                </div>
            </ng-template>
        </div>
        <div class="col2 name">
            {{ user.usname }}
        </div>
    </div>

    <div class="body">
        <div class="content">
            <div class="col1 icon">
                <a [href]="'mailto:' + user.email">
                    <mat-icon>mail_outline</mat-icon>
                </a>
            </div>
            <div class="col2 data">
                <a [href]="'mailto:' + user.email">
                    {{ user.email }}
                </a>
            </div>
        </div>
        <div class="content">
            <div class="col1 icon">
                <a [href]="'tel:' + user.phonenumber">
                    <mat-icon>phonelink_ring</mat-icon>
                </a>
            </div>
            <div class="col2 data">
                <a [href]="'tel:' + user.phonenumber">
                    {{ user.phonenumber }}
                </a>
            </div>
        </div>
    </div>
</div>