import { EditLevels, SqlDataRow } from "../data.service"
import { T7eLangs } from "../t7e/t7e.service"

export interface FlexTableProperty extends FlexProp {
    "tpid": number | null
    "tableid": number,
    "propid": number,
    "prid": number,
    "modusid": number | null,
    "entryon": Date | string | null,
    "ord": number | null,
    "optional":  number | boolean | null,
    "level": EditLevels | null, // 0: dev only, 1: production can edit, még nincs, de kéne: 2: stábtag can edit
    "propname": string,
    "engpropname": string | null
    "propcode": string,
    "edittype": number,
    "edittypename": editTypeName
}

export type editTypeName = "date" | "text" | "float" | "combo" | "int" | "upload"

export interface FlexTable extends SqlDataRow {
    tableId: number
    tablename: string
    ord: number | null
}

export interface FlexProp extends SqlDataRow {
    propid: number | null;
    propname: string;
    edittype?: number;
    propcode: string;
    level: EditLevels | null // 0: dev only, 1: production can edit, még nincs, de kéne: 2: stábtag can edit
    engpropname: string | null
    propdesc: string | null
    propdesceng: string | null
}

export interface FlexPropLangAgnostic {
    propId: number | null;
    propName: string;
    editType?: number;
    propCode: string;
    editLevel: EditLevels | null // 0: dev only, 1: production can edit, még nincs, de kéne: 2: stábtag can edit
    propDesc: string | null
    origProp: FlexProp
}

export interface FlexPropWithValue extends FlexProp {
    effvalue: any
    propvalue: any
}

export interface PropItem extends SqlDataRow {
    "piid": number,
    "propid": number,
    "piname": string,
    "pinameeng": string | null,
    "picode": string,
    "pirules": string | { notification: Record<T7eLangs, string> } | null, // JSON? string?, { notif: string }
    "entryon": string | null,
    "modusid": number | null,
    "pistatus": number | null,
}

export enum Props {
    ContractStatus = '-4',
    "contractType" = 40,
    "companyType" = 55,
}