import { SendMailParams } from "./models/mail";
import { environment as env } from '../environments/environment';

export const productionTitle = 'Vienna Game'
const crewtimeLink = 'https://vg.crewtime.app'
const financeEmail = 'vgfinance23@gmail.com'

export const config = {
    SHOW_GROWING_OT_RATE: false,
    CONFIG_PERSONAL_END_DATES: false,
    HAS_FOREIGN_CREW: false,

    ALLOW_CREW_MEMBER_EDIT_FEES: true,
    ALLOW_CREW_MEMBER_EDIT_OT_STEP: false,
    ALLOW_CREW_MEMBER_EDIT_TA_FEE: true,
    ALLOW_CREW_MEMBER_EDIT_CONTRACT_DATES: true,
    ALLLOW_CONTRACT_END_DATE: false,
    ALLOW_PERDIEM: false,

    MULTIPLE_CURRENCIES: true as 'fixed-rates' | boolean,

    DISABLED_TS_HISTORY_TEXT: 'Letiltva',
    
    HU: {
        CONTRACT: {
            SEND_MAIL_FOR_SENDBACK_TO_CREW: {
                subject: `[${productionTitle}] Start Form visszaküldve`,
                body: `Kedves {USERNAME}!<br><br>A Start Formodat visszaküldték javításra. Kérlek, javítsd ki a hibákat, és küldd be újra a ${crewtimeLink} címen.`,
                bodyEnding: '<br><br>Köszönjük!<br>CrewTime',
            },
            SEND_MAIL_FOR_APPROVED: {
                enabled: false,
                sendTo: 'frederiksberg2021@gmail.com',
                subject: `[${productionTitle}] Start Form elfogadva`,
                body: `Kedves Andi!<br><br>A következő Start Formot jóváhagyták: {START_FORM}. Megtekintheted a ${crewtimeLink} címen.<br><br>Üdvözlettel!<br>CrewTime`,
            },
            SEND_MAIL_FINANCE_NOTIF_NEW_POSITION: {
                sendTo: financeEmail,
                subject: `[${productionTitle}] Új pozíció jóváhagyva`,
                body: `Sziasztok! <br><br> A következő Start Formot új pozícióval, de megegyező céggel jóváhagyták: {START_FORM}. Megtekintheted a ${crewtimeLink} címen.<br><br>Üdvözlettel!<br>CrewTime`,
            }
        },
        USER: {
            SEND_MAIL_FOR_REG_ACCEPTED: {
                subject: `[${productionTitle}] Regisztráció elfogadva`,
                body: `Kedves {USERNAME}!<br><br>A regisztrációdat elfogadtuk. Kérlek, töltsd ki a start formodat a ${crewtimeLink} címen.<br><br>Köszönjük!<br>CrewTime`,
            },
        },
        TS: {
            SEND_MAIL_FOR_SENDBACK_TO_CREW: {
                subject: `[${productionTitle}] Timesheet visszaküldve`,
                body: `Kedves {USERNAME}!<br><br>A Timesheetedet visszaküldték javításra. Kérlek, javítsd ki a hibákat, és küldd be újra a ${crewtimeLink} címen.`,
                bodyEnding: '<br><br>Köszönjük!<br>CrewTime',
            },
            MEAL_PENALTY: 'setAtProdDeptSf' as 'firstValueableOtRate' | 'setAtProdDeptSf' | false,
            DISPLAY_KM_PARKING_VIGN_IN_CALCULATED_SECTION: true,
        },
    },
} as const