import { Injectable } from '@angular/core';
import { FieldHistory, FieldHistoryParsed } from './models/field-history';
import { CalcSalaryParams, CalculatedFeesForDay, DayOptions } from './models/timesheet';
import { AppUser, DbUser, Groups, GroupsEng } from './models/db-user';
import * as moment from 'moment-timezone';
import { DataService, ProcRequestParams, SqlDataRow } from './data.service';
import { Contract } from './models/contract';
import { Department } from './models/department';
import { Production } from './models/production';
import { DbDispo, Dispo, ShootingDay } from './models/dispo';
import { HandleShootingLocationParams, ShootingLocation } from './models/location';
import { PropItem } from './models/table-property';
import { HandlePropItemsParams } from './models/handle-table-properties-params';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParserService {

  constructor() { 
    this.parseHistoryItem = this.parseHistoryItem.bind(this)
    this.parseHistory = this.parseHistory.bind(this)
    this.toCalcSalaryFromSql = this.toCalcSalaryFromSql.bind(this)
    this.calcSalaryParametersToSql = this.calcSalaryParametersToSql.bind(this)
    this.parseUsersEmptyToNull = this.parseUsersEmptyToNull.bind(this)
    this.parseUsers = this.parseUsers.bind(this)
    this.parseUser = this.parseUser.bind(this)
    this.parseDbDispos = this.parseDbDispos.bind(this)
    this.parseDbDispo = this.parseDbDispo.bind(this)
    this.datetimeForSql = this.datetimeForSql.bind(this)
    this.dateForSql = this.dateForSql.bind(this)
    this.parseDbLocations = this.parseDbLocations.bind(this)
    this.parseDbLocation = this.parseDbLocation.bind(this)
    this.handleShootingLocationParams = this.handleShootingLocationParams.bind(this)
    this.dbArrayToArray = this.dbArrayToArray.bind(this)
  }

  parseHistoryItem(history?: FieldHistory): FieldHistoryParsed | null {
    if (!history) return null
    const retVal = {
      ...history,
      dTs: new Date(history.ts!),
      fullName: `${history.famname?.toUpperCase()} ${history.usname}`
    }
    if (history.itemname === 'dayoptions') {
      const dayoptions = JSON.parse(history.value! as string)
      let dayoptionsText: string[] = []
      dayoptionsText.push(dayoptions?.includes(DayOptions.TravelDay) ? 'Utazási nap' : 'Nem utazási nap')
      dayoptionsText.push(dayoptions?.includes(DayOptions.PerDiem) ? 'Per Diem' : 'Nincs Per Diem')
      dayoptionsText.push(dayoptions?.includes(DayOptions.HalfDay) ? 'Fél nap' : 'Teljes nap')
      dayoptionsText.push(dayoptions?.includes(DayOptions.MonthlyRentsDue) ? 'Havi bérleti díj esedékes' : 'Havi bérleti díj nem esedékes')
      dayoptionsText.push(dayoptions?.includes(DayOptions.MealPenalty) ? 'Meals Penalty' : 'Nincs Meals Penalty')
      retVal.value = dayoptionsText.join(', ')
    }
   
   return retVal
  }
  parseHistory(history?: FieldHistory[]): FieldHistoryParsed[] {
    if (!history?.length) return []
    return history
      .map(h => this.parseHistoryItem(h))
      .filter(h => h !== null) as FieldHistoryParsed[]
  }

  parseUsersEmptyToNull(dbUsers: AppUser[] | null | {}): AppUser[] | null {
    if (!dbUsers || !Array.isArray(dbUsers) || !dbUsers.length) return null
    //@ts-ignore
    return this.parseUsers(dbUsers)
  }
  parseUsers(dbUsers: DbUser[] | null | {}): AppUser[] {
    if (!dbUsers || !Array.isArray(dbUsers) || !dbUsers.length) return []
    //@ts-ignore
    return dbUsers.map(this.parseUser)
  }

  parseUser(dbUser: DbUser | null | undefined): AppUser | null {
    if (!dbUser) return null
    const groupsEnum = Object.keys(Groups)
      .filter(key => !isNaN(key as unknown as number))
      .filter(key => key != '0')
      .map((key: any) => ({
        ugid: parseInt(key),
        ugname: Groups[key],
        engugname: GroupsEng[key],
      }
      ))

    return {
      ...dbUser,
      usnameOrig: dbUser.usname,
      usname: ((dbUser.famname || '').trim().toUpperCase() + ' ' + (dbUser.usname || '').trim()).trim(),
      ugmConcat: dbUser.ugm?.map(ugm => `${ugm?.ugname || ''}$${ugm?.engugname || ''}$${groupsEnum.find(g => g.ugid == ugm.ugid)?.ugname || ''}$${groupsEnum.find(g => g.ugid == ugm.ugid)?.engugname || ''}`).join('$$')
    }
  }

  parseDbDispos(dbDispos: DbDispo[] | null | {}): Dispo[] {
    if (!dbDispos || !Array.isArray(dbDispos) || !dbDispos.length) return []
    return dbDispos.map(this.parseDbDispo)
  }
  parseDbDispo(dbDispo: DbDispo): Dispo {
    return {
      shId: dbDispo.shid,
      startDate: new Date(dbDispo.shstartdate),
      endDate: dbDispo.shenddate ? new Date(dbDispo.shenddate) : null,
      values: dbDispo.details?.filter(d => d.propid)?.map(d => ({
        propCode: d.propcode,
        propId: d.propid!,
        propValue: d.propvalue,
      })) || []
    }
  }

  parseDbLocations(dbLocations: PropItem[]): ShootingLocation[] {
      return dbLocations?.map(this.parseDbLocation) || []
  }

  parseDbLocation(dbLocation: PropItem): ShootingLocation {
    return {
      locId: dbLocation.piid!,
      locCode: dbLocation.picode!,
      locName: dbLocation.piname!,
      km: dbLocation.details?.find(x => x.propcode === 'distance')?.propvalue as number,
      kmFee: dbLocation.details?.find(x => x.propcode === 'distanceFee')?.propvalue as number,
      enabled: dbLocation.pistatus === 1 || dbLocation.pistatus === null,
    }
  }

  handleShootingLocationParams(params: HandleShootingLocationParams): HandlePropItemsParams {
    return {
      _piid: params.locId,
      _picode: params.locName,
      _piname: params.locName,
      _data: JSON.stringify({
        distance: params.km,
        distanceFee: params.kmFee,
      }),
      _propid: 80,
      _pistatus: params.enabled === false ? 0 : 1,
    }
  }

  disposToShootingDays(dispos: Dispo[], allLocations: ShootingLocation[]): ShootingDay[] {
    return dispos
      .sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
      .filter(day => {
        if (day.startDate === null) return false
        //if (day.endDate === null) return false
        if (moment(day.startDate).isSame(day.endDate)) return false
        if (moment(day.startDate).add(1, 'days').isSame(day.endDate)) return false
        return true
      })
      .map((d, i, arr) => {
        const location = allLocations.find(loc => loc.locCode == d.values.find(v => v.propId == 80)?.propValue)
        const distance = location?.km ? location?.km + ' km' : ''
        const kmFee = location?.kmFee ? location?.kmFee + ' Ft' : ''
        const separator = !!(distance && kmFee) ? ' / ' : ''
        return {
          shId: d.shId || null,
          numDay: arr.length - i,
          date: d.startDate,
          crewCallTime: d.startDate,
          cameraWrapTime: d.endDate || null,
          locId: location?.locId || null,
          locCode: location?.locCode || null,
          locationName: location?.locName || null,
          locationAttribs: (distance && kmFee) ? `${distance}${separator}${kmFee}` : null,
        }
    })
  }


  /** UTILITY FUNCTIONS */
    
  datetimeForSql(datetime: Date | moment.Moment | string | null | undefined): string | null | undefined {
    if (!datetime) return datetime
    return moment(datetime).tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT)
  }

  dateForSql(date: Date | moment.Moment | string | null | undefined): string | null | undefined {
    if (!date) return date
    return moment(date).tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT)
  }

  parseNumericArrays<T extends Contract | Production | Department>(response: T[] | null): T[] | null {
    if (!response) return null
    const numericArrayFields = ['otrate', 'saved_otrate']

    // otrate string to array
    numericArrayFields.forEach(field => {
      for (let i = 0; i < response.length; i++) {
        // ha már ki lett emelve a details-ból
        if (response[i]['f_' + field]) {
          const otrate = response[i]['f_' + field]
          if (otrate && typeof otrate === 'string') {
            try {
              response[i]['f_' + field] = JSON.parse(otrate)
            } catch (ex) {
              const convertedNum = DataService.convertHumanNumberToNumber(otrate)
              if (convertedNum === null) response[i]['f_' + field] = null
              else if (!isNaN(convertedNum)) response[i]['f_' + field] = [convertedNum]
              else {
                console.error(ex)
                response[i]['f_' + field] = null
              }
            }
          }
        }
        // ha még nincs kiemelve
        const detailIndex = response[i].details?.findIndex(p => p.propcode == field) || -1
        if (detailIndex > -1) {
          const otrate = response[i]?.details![detailIndex]?.propvalue
          if (otrate && typeof otrate === 'string') {
            try {
              response[i].details![detailIndex].propvalue = JSON.parse(otrate)
            } catch (ex) {
              console.error(ex)
            }
          }
        }
      }
    })
    return response
  }

  dbArrayToText(array?: any[] | {} | null): string {
    if (!array) return ''
    if (Array.isArray(array)) return array.join(' / ')
    if (typeof array === 'string') {
      try {
        const a = JSON.parse(array)
        if (Array.isArray(a)) return a.join(' / ')
      } catch (ex) {
        console.warn(ex)
        return array
      }
    }
    return ''
  }
  textToDbArray(text?: string | null, convertEmptyToNull?: boolean): any[] | {} | null {
    return ParserService.textToDbArray(text, convertEmptyToNull)
  }

  static textToDbArray(text?: string | number | null, convertEmptyToNull?: boolean): any[] | {} | null {
    const emptyValue = convertEmptyToNull ? null : {}
    if (typeof text === 'number') text = text?.toString()
    if (!text?.trim()) return emptyValue
    const retVal = text?.split('/')
      .map(x => x.trim())
      .filter(x => x)
    if (!retVal?.length) return emptyValue
    return retVal
  }

  arrayToDbArray<T>(array?: T[] | null, convertEmptyToNull?: boolean): any[] | {} | null {
    const emptyValue = convertEmptyToNull ? null : {}
    if (!array?.length) return emptyValue
    return array
  }

  dbArrayToArray<T>(array?: string | T[] | {} | null): T[] | null {
    if (!array) return null
    //@ts-ignore
    if (!array?.length) return []
    let a = null
    if (typeof array === 'string') {
      if (array.trim().startsWith('[')) {
        a = JSON.parse(array)
      } else {
        a = JSON.parse('[' + array + ']')
      }
    } else if (Array.isArray(array)) {
      a = array
    } else {
      throw new Error(`Unknown array type. Array: ${array}`)
    }
    return a
  }

  // CalcSalaryResponseSql to CalculatedFeesForDay
  toCalcSalaryFromSql(calc: CalcSalaryResponseSql): CalculatedFeesForDay {
    return {
      calculatedSalary: calc.calcsal,
      calculatedOtFee: calc.calcot,
      calculatedTsFee: calc.calcta,
      calculatedOtHours: calc.calcothours,
      calculatedTaHours: calc.calctahours,
    }
  }
  calcSalaryParametersToSql(params: CalcSalaryParams): CalcSalaryParamsSql {
    return {
      _conid: params.contractId,
      _startdate: this.datetimeForSql(params.startDate),
      _enddate: this.datetimeForSql(params.endDate),
      _dayoptions: !params.dayOptions?.length ? {} : params.dayOptions,
      _workinghours: params.workingHours,
      _otrate: JSON.stringify(this.arrayToDbArray(params.otRates)),
      _otstep: params.otStep,
      _graceperiod: params.gracePeriod,
    }
  }

  parseSqlErrMsg(errMsg: string | HttpErrorResponse): string | null {
    if (typeof errMsg === 'string') {
      // Extract the string between "'M': '" and ', 'W': 
      const regex = /'M': '(.*)', 'W':/
      const match = regex.exec(errMsg)
      if (!match) return null
      return match[1]
    } else if (errMsg.statusText) {
      return errMsg.statusText
    } else {
      return null
    }
  }
}

export const SQL_TIMEZONE = 'Europe/Budapest'
export const SQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const SQL_DATE_FORMAT = 'YYYY-MM-DD'
export const UI_DATE_MMM_D_FORMAT = 'MMM D.'
export const SQL_TIME_FORMAT = 'HH:mm'
export const SQL_MONTH_DAY_FORMAT = 'MM-DD'

/** CALC SALARY FOR DAY */
export interface CalcSalaryParamsSql extends ProcRequestParams {
  _conid: number | null                   // integer DEFAULT NULL:: integer,
  _dayoptions: number[] | {} | null       // integer[] DEFAULT NULL:: integer[],
  _basesal?: number | null                // numeric DEFAULT NULL:: numeric,
  _otrate?: string | number[] | string[] | {} | null // string DEFAULT NULL::character varying,
  _perdiemrate?: number | null            // numeric DEFAULT NULL:: numeric,
  _startdate?: string | null              // timestamp with time zone DEFAULT NULL::timestamp with time zone,
  _enddate?: string | null                // timestamp with time zone DEFAULT NULL::timestamp with time zone,
  _workinghours?: number | null           // numeric DEFAULT NULL:: numeric,
  _otstep?: number | null                 // numeric DEFAULT NULL:: numeric,
  _shortresthours?: number | null         // numeric DEFAULT NULL:: numeric,
  _longresthours?: number | null          // numeric DEFAULT NULL:: numeric,
  _tarate?: number | null                 // numeric DEFAULT NULL:: numeric,
  _tastep?: number | null                 // numeric DEFAULT NULL:: numeric,
  _tadays?: number | null                 // numeric DEFAULT NULL:: numeric,
  _prevenddate?: string | null            // timestamp with time zone DEFAULT NULL::timestamp with time zone,
  _firstworkstartdate?: string | null     // timestamp with time zone DEFAULT NULL::timestamp with time zone,
  _graceperiod?: number | null            // numeric DEFAULT NULL:: numeric,
}

export interface CalcSalaryResponseSql extends SqlDataRow {
  calcsal: number | null
  calcot: number | null
  calcta: number | null
  calcothours: number | null
  calctahours: number | null
}
