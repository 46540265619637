<div class="my-contract">
    <h1 class="page-title">Részleg Start Formjai</h1>

    <app-select-contract [user]="'dept-admin'" [showName]="true" 
        [allowSelectUnapproved]="true" class="d-block ml-1 mr-1">
    </app-select-contract>

    <!-- HA ÚJ SZERZŐDÉST AKAR LÉTREHOZNI, KELL TUDNI, HOGY KI A USER -->
    <app-select-user *ngIf="!(contract$ | async)" [users$]="users$" >
    </app-select-user>

    <app-edit-contract 
        *ngIf="!!(contract$ | async) || !!(selectedUser$ | async)"
        [user$]="selectedUser$"
        [contract]="contract$ | async" 
        [showContractStatus]="false">
    </app-edit-contract>
    
</div>