
<kendo-grid #grid [kendoGridBinding]="(filteredContractsForGrid$ | async) || []" 
    [pageable]="true" [skip]="skip" [pageSize]="50"
    [sortable]="true" [sort]="sort" 
    [filterable]="true" [filter]="filter" (filterChange)="filterChanged($event)"
    [navigable]="true" [reorderable]="true" [resizable]="true"
    [loading]="(allContractsLoading$ | async) || (contractSaving$ | async) || false" [rowClass]="getRowClass"
    [selectable]="{enabled: true, checkboxOnly: true, mode: 'multiple'}" [kendoGridSelectBy]="'conid'"
    [(selectedKeys)]="selectedContractIds" 
    (dataStateChange)="dataStateChange($event)" (columnReorder)="onColReorder($event)"
    (columnResize)="onColResized($event)"
    [kendoGridExpandDetailsBy]="'conid'" [(expandedDetailKeys)]="expandedDetailKeys"
    class="grid" [ngClass]="height">
    <ng-template kendoGridToolbarTemplate>
            <form class="toolbar" [formGroup]="filtersFG">
                <ng-container *ngIf="showFilters">               
                    <app-filter-contract-type [formgroup]="filtersFG" [contractTypeFC]="contractTypeFC">
                    </app-filter-contract-type>
                
                    <app-filter-con-status [formgroup]="filtersFG" [statusFC]="contractStatusFC"></app-filter-con-status>

                    <app-filter-date  [formgroup]="filtersFG" [fc]="dateApprovalFC" [label]="t7eFilterContractDateApprovedLabel"></app-filter-date>

                    <app-search [formgroup]="filtersFG" [searchFC]="searchFC"></app-search>
                </ng-container>

                <div class="divider"></div>

                <button mat-raised-button color="primary" type="button" (click)="generateContracts()"
                    *ngIf="selectedContractIds.length && isFinance">
                    <mat-icon>file_copy</mat-icon>
                    <span t7e="btn-generate">Generate contract</span>
                </button>

                <!-- CANNOT SEND IT WITHOUT EDITING <button mat-raised-button color="primary" type="button" (click)="generateContracts()"
                    *ngIf="selectedContractIds.length && isFinance">
                    <mat-icon>send</mat-icon>
                    <span t7e="btn-generate-and-send">Generate and send</span>
                </button> -->

                <button mat-raised-button color="secondary" type="button" (click)="sendBackToCrew()"
                    *ngIf="!canSendBackToCrewMultiple().disabled && isProdMgr"
                    [title]="canSendBackToCrewMultiple().title">
                    <mat-icon>reply_all</mat-icon>
                    <span t7e="btn-send-back-to-crew">Send back</span>
                </button>

                <button mat-raised-button color="secondary" type="button" (click)="approve()"
                    *ngIf="!canApproveMultiple().disabled && isProdMgr"
                    [title]="canApproveMultiple().title">
                    <mat-icon>check</mat-icon>
                    <span t7e="btn-approve">Approve</span>
                </button>

                <button mat-raised-button color="secondary" type="button" (click)="sendToProdMgr()"
                    *ngIf="!canSetAtProdMgrMultiple().disabled && isProdMgr"
                    [title]="canSetAtProdMgrMultiple().title">
                    <mat-icon>reply</mat-icon>
                    <span t7e="btn-retract-approval">Retract approval</span>
                </button>

                <button mat-raised-button color="primary" type="button" (click)="reload()">
                    <mat-icon>refresh</mat-icon>
                </button>

                <button mat-icon-button [matMenuTriggerFor]="statusHeaderMenu" aria-label="More options">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #statusHeaderMenu="matMenu">
                    <button mat-menu-item color="secondary" type="button" (click)="sendBackToCrew()"
                        *ngIf="!canSendBackToCrewMultiple().disabled && isFinance"
                        [title]="canSendBackToCrewMultiple().title">
                        <mat-icon>reply_all</mat-icon>
                        <span t7e="btn-send-back-to-crew">Send back to crew member</span>
                    </button>

                    <button mat-menu-item color="secondary" type="button" (click)="sendToProdMgr()"
                        *ngIf="!canSetAtProdMgrMultiple().disabled && isFinance"
                        [title]="canSetAtProdMgrMultiple().title">
                        <mat-icon>reply</mat-icon>
                        <span t7e="btn-send-back-to-prodmgr">Send back to ProdMgr.</span>
                    </button>

                    <button mat-menu-item color="primary" type="button" (click)="approve()"
                        *ngIf="!canApproveMultiple().disabled && isFinance"
                        [title]="canApproveMultiple().title">
                        <mat-icon>check</mat-icon>
                        <span t7e="btn-set-approved">Set: Approved</span>
                    </button>

                    <button mat-menu-item color="primary" type="button" (click)="setReadyToSign()"
                        *ngIf="!canSetReadyToSignMultiple().disabled && isFinance"
                        [title]="canSetReadyToSignMultiple().title">
                        <mat-icon>check</mat-icon>
                        <span t7e="btn-set-readytosign">Set: Ready to sign</span>
                    </button>

                    <button mat-menu-item color="primary" type="button" (click)="setSigned()"
                        *ngIf="!canSetSignedMultiple().disabled && isFinance"
                        [title]="canSetSignedMultiple().title">
                        <mat-icon>done_all</mat-icon>
                        <span t7e="btn-set-signed">Set: Signed</span>
                    </button>

                    <button mat-menu-item color="primary" type="button" (click)="openOtTaBulkChanger()"
                        *ngIf="this.selectedContractIds.length && isProdMgr" [title]="t7eButtonTitleOpenOtTaBulkChanger">
                        <mat-icon>watch</mat-icon>
                        {{ this.selectedContractIds.length }}x <span t7e="button-open-otta-bulk-changer">OT/TA megadása...</span>
                    </button>

                    <button mat-menu-item kendoGridExcelCommand color="primary" type="button"
                        *ngIf="(filteredContractsForGrid$ | async)?.length">
                        <mat-icon>file_download</mat-icon>
                        <span t7e="btn-export-excel">Export to Excel</span>
                    </button>
                </mat-menu>
            </form>
    </ng-template>
    <ng-template kendoGridNoRecordsTemplate>
        <div class="no-records">
            <p t7e="no-records">Nincs megjeleníthető adat.</p>
        </div>
    </ng-template>

    <kendo-grid-checkbox-column title="" [showSelectAll]="true" [width]="70"></kendo-grid-checkbox-column>
    <kendo-grid-column field=conid [title]="'contract-grid.field.conid' | t7e:'Adatbázis azonosító'" [width]="180" *ngIf="debug"></kendo-grid-column>
    <kendo-grid-column field=usid [title]="'contract-grid.field.usid' | t7e:'Stábtag azonosító'" [width]="180" *ngIf="debug"></kendo-grid-column>
    <kendo-grid-column field="crewColumn" [title]="'contract-grid.field.crewColumn' | t7e:'Stábtag':'Crew member'" [width]="getWidth('crewColumn') || 200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container *ngIf="dataItem.email; else deletedUser">
                <p>
                    <a href="mailto:{{dataItem.email}}" class="email">
                        <mat-icon>mail_outline</mat-icon>
                    </a>
                    <span class="text-with-maticon">{{dataItem.usname ? dataItem.usname : 'nincs megadva'}} </span>
                </p>
                <p>
                    <a href="tel:{{dataItem.phonenumber}}" class="phonenumber">
                        <mat-icon>phone</mat-icon>
                    </a>
                    {{dataItem.phonenumber ? dataItem.phonenumber : 'Nincs megadva'}}
                </p>
            </ng-container>
            <ng-template #deletedUser>
                <span class="deleted-user">{{ 'deleted-user' | t7e: 'Törölt felhasználó' }}</span>
            </ng-template>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="depname" [title]="'contract-grid.field.depname' | t7e:'Részleg':'Department'" [width]="getWidth('depname') || 200">
    </kendo-grid-column>
    <kendo-grid-column field=role [title]="'contract-grid.field.role' | t7e:'Munkakör'" [width]="getWidth('role') || 200">
    </kendo-grid-column>
    <kendo-grid-column field=f_companyname [title]="'contract-grid.field.f_companyname' | t7e:'Cégnév'" [width]="getWidth('f_companyname') || 200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex d-flex-col gap-1">
                <div>{{ dataItem.f_companyname }}</div>
                <div *ngIf="dataItem.f_invoice_contact">
                    <a href="mailto:{{dataItem.f_invoice_contact}}" class="email">
                        <mat-icon>mail_outline</mat-icon>
                        <span class="text-with-maticon">{{ dataItem.f_invoice_contact }}</span>
                    </a>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field=contractTypeAsPropItem.piname [title]="'contract-grid.field.contractTypeAsPropItem.piname' | t7e:'Szerződés típus'" [width]="getWidth('contractTypeAsPropItem.piname') || 150">

    </kendo-grid-column>
    <kendo-grid-column field="links" title="Szerződések Google Drive-on" [width]="getWidth('links') || 400">
        <ng-template kendoGridCellTemplate let-dataItem>
            <app-gen-contract-file [dataItem]="dataItem"></app-gen-contract-file>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" [width]="310">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="last-column">
                <div class="d-inline-block mr-1">
                    <div class="has-history">
                        <app-status [statusClass]="dataItem | contractStatusClass"
                            [statusName]="dataItem | contractStatusName"
                            [saveComment]="dataItem.savecomment">
                        </app-status>
                        <button mat-icon-button (click)="showHistory($event, dataItem, 'constatus', false)" 
                            type="button" class="history-button"
                            tabindex="-1" kendoPopoverAnchor [popover]="popHistory" >
                            <mat-icon>history</mat-icon>
                        </button>
                    </div>
                    <div>
                        <button mat-raised-button (click)="approve(dataItem)" *ngIf="isAtProdMgr(dataItem) && !canApprove(dataItem).disabled"
                            [title]="canApprove(dataItem).title" class="btnStatusChange">
                            <mat-icon>check</mat-icon>
                            {{ 'btn-contract-approve' | t7e: 'Jóváhagy'}}
                            <span *ngIf="isFinance">{{ 'btn-approve-as-prodmgr' | t7e: ' (Gyártásvezető)' }}</span>
                        </button>
                        <button mat-raised-button (click)="approveFinance(dataItem)" 
                            *ngIf="isApproved(dataItem) && !canFinanceApprove(dataItem).disabled"
                            [title]="canApprove(dataItem).title" class="btnStatusChange">
                            <mat-icon>check</mat-icon>
                            {{ 'btn-finance-approve' | t7e: 'Jóváhagy (Pénzügy)'}}
                        </button>
                        <button mat-raised-button (click)="setReadyToSign(dataItem)"
                            *ngIf="isConsideredFinanceApprovedOrAfter(dataItem) && !canSetReadyToSign(dataItem).disabled" [title]="canSetReadyToSign(dataItem).title"
                            class="btnStatusChange">
                            <mat-icon>check</mat-icon>
                            {{ 'btn-contract-set-readyToSign' | t7e: 'Aláírásra kész' }}
                        </button>
                        <button mat-raised-button (click)="setSigned(dataItem)" *ngIf="(isConsideredFinanceApprovedOrAfter(dataItem) || isReadyToSign(dataItem)) && !canSetSigned(dataItem).disabled"
                            [title]="canSetSigned(dataItem).title" class="btnStatusChange">
                            <mat-icon>done_all</mat-icon>
                            {{ 'btn-contract-set-signed' | t7e: 'Aláírva'  }}
                        </button>
                    </div>
                </div>
                <button mat-icon-button [matMenuTriggerFor]="statusMenu" aria-label="More options">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #statusMenu="matMenu">
                    <a mat-menu-item [routerLink]="['/', 'contract', dataItem.conid]" color="primary" class="btnStatusChange">
                        <mat-icon>edit</mat-icon>
                        {{ 'btn-contract-edit' | t7e: 'Szerkeszt' }}
                    </a>
                    <button mat-menu-item (click)="sendBackToCrewClicked(dataItem)" *ngIf="!canSendBackToCrew(dataItem).disabled"
                        [title]="canSendBackToCrew(dataItem).title">
                        <mat-icon>reply_all</mat-icon>
                        {{ 'btn-contract-return-to-crew' | t7e: 'Stabátagnak visszaküld' }}
                    </button>
                    <button mat-menu-item (click)="sendToProdMgr(dataItem)" *ngIf="!canSetAtProdMgr(dataItem).disabled">
                        <mat-icon>reply</mat-icon>
                        {{ 'btn-contract-return-to-prodmgr' | t7e: 'Gyártásvezetőhöz küld' }}
                    </button>
                    <button mat-menu-item (click)="approve(dataItem)" *ngIf="!canApprove(dataItem).disabled"
                        [title]="canApprove(dataItem).title">
                        <mat-icon>check</mat-icon>
                        {{ 'btn-contract-set-approved' | t7e: 'Jóváhagyott statusba tesz' }}
                    </button>
                    <button mat-menu-item (click)="approveFinance(dataItem)" *ngIf="!isFinanceApproved(dataItem) && !canFinanceApprove(dataItem).disabled"
                        [title]="canFinanceApprove(dataItem).title">
                        <mat-icon>check</mat-icon>
                        {{ 'btn-contract-set-finance-approved' | t7e: 'Új státusz: Pénzügy jóváhagyta' }}
                    </button>
                    <button mat-menu-item (click)="setReadyToSign(dataItem)"
                        *ngIf="!isConsideredFinanceApprovedOrAfter(dataItem) && !canSetReadyToSign(dataItem).disabled" [title]="canSetReadyToSign(dataItem).title"
                        class="btnStatusChange">
                        <mat-icon>check</mat-icon>
                        {{ 'btn-contract-set-readyToSign' | t7e: 'Aláírásra kész' }}
                    </button>
                    <button mat-menu-item (click)="setSigned(dataItem)" *ngIf="!isConsideredFinanceApprovedOrAfter(dataItem) && !canSetSigned(dataItem).disabled"
                        [title]="canSetSigned(dataItem).title" class="btnStatusChange">
                        <mat-icon>done_all</mat-icon>
                        {{ 'btn-contract-set-signed' | t7e: 'Aláírva' }}
                    </button>
                    <button mat-menu-item (click)="removeContract(dataItem)" *ngIf="!canDelete(dataItem).disabled"
                        [title]="canDelete(dataItem).title" class="btnStatusChange">
                        <mat-icon>delete</mat-icon>
                        {{ 'btn-contract-delete' | t7e: 'Törlés' }}
                    </button>
                    <button mat-menu-item (click)="disableContract(dataItem)" *ngIf="!canDisable(dataItem).disabled"
                        [title]="canDisable(dataItem).title" class="btnStatusChange">
                        <mat-icon>archive</mat-icon>
                        {{ 'btn-contract-disable' | t7e: 'Inaktiválás' }}
                    </button>
                </mat-menu>
            </div>
        </ng-template>
    </kendo-grid-command-column>
    <div *kendoGridDetailTemplate="let dataItem">
        <div *ngIf="debug">{{ dataItem | json }}</div>
        <div *ngIf="debug">{{ getUserById(dataItem.usid) | json }}</div>
        <ng-template #noValue>
            <p>-</p>
        </ng-template>
        <div class="row-details">

            <div><span class="detail-box-title" t7e="contract-fee">Szerződéses díj</span>
            <p *ngIf="dataItem.contractfee; else noValue">{{dataItem.contractfee |
                    number:'1.0-2'}}&nbsp;{{ getCurrency(dataItem )}}</p>
            </div>


            <div><span class="detail-box-title" t7e="preprod-rate">Napidíj (előkészítés)</span>
                <p *ngIf="dataItem.preprodrate; else noValue">{{dataItem.preprodrate|
                    number:'1.0-2'}}&nbsp;{{ getCurrency(dataItem )}}
                </p>
            </div>


            <div><span class="detail-box-title" t7e="shooting-rate">Napidíj (forgatás)</span>
                <p *ngIf="dataItem.dailyrate; else noValue">{{dataItem.dailyrate|
                    number:'1.0-2'}}&nbsp;{{ getCurrency(dataItem )}}</p>
            </div>


            <div><span class="detail-box-title" t7e="perdiem-fee">Per Diem díj</span><div>
                    <p *ngIf="hasValue(dataItem.f_saved_sleepoverrate); else noValue">
                        {{dataItem.f_saved_sleepoverrate| number:'1.0-2'}}&nbsp;{{ getCurrency(dataItem )}}
                    </p>
                    <p *ngIf="hasValue(dataItem.f_sleepoverrate) && dataItem.f_sleepoverrate !== dataItem.f_saved_sleepoverrate"
                        class="unimportant"
                        [title]="t7eEffectiveTitle">
                        Eff:&nbsp;{{dataItem.f_sleepoverrate| number:'1.0-2'}}&nbsp;{{ getCurrency(dataItem )}}
                    </p>
                </div>
            </div>


            <div><span class="detail-box-title">Munkanap hossza [óra] </span><div>
                    <p *ngIf="hasValue(dataItem.f_saved_workhours); else noValue">{{dataItem.f_saved_workhours| number:'1.0-2'}} óra
                    </p>
                    <p *ngIf="hasValue(dataItem.f_workhours) && dataItem.f_sleepoverrate !== dataItem.f_saved_sleepoverrate"
                        class="unimportant"
                        [title]="t7eEffectiveTitle">
                        Eff:&nbsp;{{dataItem.f_workhours| number:'1.0-2'}}&nbsp;óra
                    </p>
                </div>
            </div>


            <div><span class="detail-box-title" t7e="ot-rate">Túlóradíj </span><div>
                <p *ngIf="debug">saved:{{dataItem.f_saved_otrate | json}}</p>
                <p *ngIf="debug">calc: {{dataItem.f_otrate| json}}</p>
                    <p *ngIf="hasValue(dataItem.f_saved_otrate); else noValue">{{dataItem.f_saved_otrate | otRate}}&nbsp;{{ getCurrency(dataItem )}}
                    </p>
                    <p *ngIf="hasValue(dataItem.f_otrate) && !isSaveAndCalcOtRatesEqual(dataItem)" class="unimportant"
                        [title]="t7eEffectiveTitle">
                        Eff:&nbsp;{{dataItem.f_otrate| otRate:'1.0-2'}}&nbsp;{{ getCurrency(dataItem )}}
                    </p>
                </div>
            </div>


            <div><span class="detail-box-title" t7e="ot-step">OT lépcső [óra] </span><div>
                    <p *ngIf="hasValue(dataItem.f_saved_overtimestep); else noValue">{{dataItem.f_saved_overtimestep| number:'1.0-2'}} óra</p>
                    <p *ngIf="hasValue(dataItem.f_overtimestep) && dataItem.f_overtimestep !== dataItem.f_saved_overtimestep"
                        class="unimportant"
                        [title]="t7eEffectiveTitle">
                        Eff:&nbsp;{{dataItem.f_overtimestep| number:'1.0-2'}}&nbsp;óra
                    </p>
                </div>
            </div>


            <div><span class="detail-box-title" t7e="ta-short">Rövid pihenőidő [óra] </span><div>
                    <p *ngIf="hasValue(dataItem.f_saved_shortresthours); else noValue">{{dataItem.f_saved_shortresthours| number:'1.0-2'}} óra</p>
                    <p *ngIf="hasValue(dataItem.f_shortresthours) && dataItem.f_shortresthours !== dataItem.f_saved_shortresthours"
                        class="unimportant"
                        [title]="t7eEffectiveTitle">
                        Eff:&nbsp;{{dataItem.f_shortresthours| number:'1.0-2'}}&nbsp;óra
                    </p>
                </div>
            </div>


            <div><span class="detail-box-title" t7e="ta-long">Hosszú pihenőidő [óra] </span><div>
                    <p *ngIf="hasValue(dataItem.f_saved_longresthours); else noValue">{{dataItem.f_saved_longresthours| number:'1.0-2'}} óra</p>
                    <p *ngIf="hasValue(dataItem.f_longresthours) && dataItem.f_longresthours !== dataItem.f_saved_longresthours"
                        class="unimportant"
                        [title]="t7eEffectiveTitle">
                        Eff:&nbsp;{{dataItem.f_longresthours| number:'1.0-2'}}&nbsp;óra
                    </p>
                </div>
            </div>


            <div><span class="detail-box-title" t7e="ta-rate">TA óradíj </span><div>
                <p *ngIf="debug">saved:{{dataItem.f_saved_tarate | json}}</p>
                <p *ngIf="debug">calc: {{dataItem.f_tarate| json}}</p>
                    <p *ngIf="hasValue(dataItem.f_saved_tarate); else noValue">{{dataItem.f_saved_tarate |
                        number:'1.0-2'}}&nbsp;{{ getCurrency(dataItem )}}</p>
                    <p *ngIf="hasValue(dataItem.f_tarate) && dataItem.f_tarate !== dataItem.f_saved_tarate"
                        class="unimportant"
                        [title]="t7eEffectiveTitle">
                        Eff:&nbsp;{{dataItem.f_tarate| number:'1.0-2'}}&nbsp;{{ getCurrency(dataItem )}}
                    </p>
                </div>
            </div>
            
            <div><span class="detail-box-title" t7e="contract-start-date">Kezdés időpontja</span>
                <div>
                    {{ dataItem.startdate }}
                </div>
            </div>
            <div *ngIf="CONFIG_PERSONAL_END_DATES"><span class="detail-box-title" t7e="contract-end-date">Várható végzés időpontja</span>
                <div>
                    {{ dataItem.enddate }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="licenseplate-nr">Rendszám</span>
                <div>
                    {{ dataItem.vehiclenumber || '-' }}
                </div>
            </div>

            <div><span class="detail-box-title" t7e="code-daily-rate">Kód: Napidíj</span>
                <div>
                    {{ dataItem.code_salary || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="code-otta">Kód: OT&TA</span>
                <div>
                    {{ dataItem.code_otta || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="code-km">Kód: Üzemenyag</span>
                <div>
                    {{ dataItem.code_gas || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="code-park">Kód: Parkolás</span>
                <div>
                    {{ dataItem.code_park || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="code-vignette">Kód: Matrica</span>
                <div>
                    {{ dataItem.code_vignette || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="code-perdiem">Kód: Per Diem</span>
                <div>
                    {{ dataItem.code_perdiem || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="code-other">Kód: Egyéb</span>
                <div>
                    {{ dataItem.code_other || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="address">Lakcím</span>
                <div>
                    {{  dataItem.f_addresszip || '-' }}&nbsp;
                    {{  dataItem.f_addresscity || '-' }},&nbsp;
                    {{  dataItem.f_addressstreet || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="mothers-name">Anyja neve</span>
                <div>
                    {{  dataItem.f_mothername || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="birth-place">Születési hely, idő</span>
                <div>
                    {{  dataItem.f_birthplace || '-' }},&nbsp;
                    {{  dataItem.f_birthdate | date:'yyyy-MM-dd' || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="tax-id">Adóazonosító jel</span>
                <div>
                    {{  dataItem.f_taxnumber || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="taj">TAJ szám</span>
                <div>
                    {{  dataItem.f_taj || '-' }}
                </div>
            </div>
            <div><span class="detail-box-title" t7e="bank-account">Bankszámlaszám</span>
                <div>
                    {{ dataItem.f_personalBankAccountNr || '-' }}
                </div>
            </div>
        </div>
    </div>
    <kendo-grid-excel fileName="Start Forms.xlsx" [fetchData]="allDataForExcel">
        <kendo-excelexport-column field="conid" title="Adatbázis azonosító / Database ID" *ngIf="debug"></kendo-excelexport-column>
        <kendo-excelexport-column field="usid" title="Stábtag azonosító / User ID" *ngIf="debug"></kendo-excelexport-column>
        <kendo-excelexport-column field="usname" title="Stábtag neve / User Name" ></kendo-excelexport-column>
        <kendo-excelexport-column field="famname" title="Stábtag családi neve / User Family Name" ></kendo-excelexport-column>
        <kendo-excelexport-column field="email" title="Stábtag email címe / User email"></kendo-excelexport-column>
        <kendo-excelexport-column field="phonenumber" title="Stábtag telefonszáma / User phone number"></kendo-excelexport-column>
        <kendo-excelexport-column field="depid" title="Részleg azonosító / Department ID" *ngIf="debug"></kendo-excelexport-column>
        <kendo-excelexport-column field="depname" title="Részleg neve / Department Name" ></kendo-excelexport-column>
        <kendo-excelexport-column field="role" title="Munkakör / Position" ></kendo-excelexport-column>
        <kendo-excelexport-column field="constatus" title="Start Form státusz ID / Start Form Status ID" *ngIf="debug"></kendo-excelexport-column>
        <kendo-excelexport-column field="constatuscode" title="Start Form státusz kódja / Start Form Status Code" *ngIf="debug"></kendo-excelexport-column>
        <kendo-excelexport-column field="constatusname" title="Start Form státusz / Start Form Status" ></kendo-excelexport-column>
        <kendo-excelexport-column field="code_salary" title="Kód: Napidíj / Code: Salary" ></kendo-excelexport-column>
        <kendo-excelexport-column field="code_otta" title="Kód: OT&TA / Code: OT&TA" ></kendo-excelexport-column>
        <kendo-excelexport-column field="code_gas" title="Kód: Üzemenyag / Code: Gas" ></kendo-excelexport-column>
        <kendo-excelexport-column field="code_park" title="Kód: Parkolás / Code: Parking" ></kendo-excelexport-column>
        <kendo-excelexport-column field="code_vignette" title="Kód: Matrica / Code: Vignette" ></kendo-excelexport-column>
        <kendo-excelexport-column field="code_other" title="Kód: Egyéb / Code: Other" ></kendo-excelexport-column>
        <kendo-excelexport-column field="code_perdiem" title="Kód: Per Diem / Code: Per Diem" ></kendo-excelexport-column>
        <!-- TODO: át kell alakítani normális formátumra. DataItem minta: [{"fileid": "1AxkGKXeIIZwGbmeCnRqvcIFB55E0SyD6AWEi1tMEPiQ", "ts": "2023-09-04T20:29:29Z", "usemail": "sergeikov@gmail.com", "log": null}, {"fileid": "1tVZB10rabQxq1X4DkqvzMla1zsaAFnfpKXO8gPYyDqU", "ts": "2023-09-10T19:15:53Z", "usemail": "sergeikov@gmail.com", "log": null}, {"fileid": "1CUv6_KRjdZ7Em83X2Yj-YxEEDKS5Zg_2ECcV3HmaUJc", "ts": "2023-09-10T19:17:03Z", "usemail": "sergeikov@gmail.com", "log": null}] -->
        <kendo-excelexport-column field="doclink" title="Dokumentum link / Document link"></kendo-excelexport-column>
        <kendo-excelexport-column field="personal" title="Szerződés sablon / Contract Template"></kendo-excelexport-column>
        <kendo-excelexport-column field="entryon" title="Létrehozás dátuma / Creation date"></kendo-excelexport-column>
        <kendo-excelexport-column field="contractfee" title="Szerződés díj / Contract fee"></kendo-excelexport-column>
        <kendo-excelexport-column field="startdate" title="Várható kezdés időpontja / Expected start date"></kendo-excelexport-column>
        <kendo-excelexport-column field="enddate" title="Várható végzés időpontja / Expected end date"></kendo-excelexport-column>
        <kendo-excelexport-column field="modusid" title="Utoljára mentette: felhasználó azonosító / Last saved by: User ID" *ngIf="debug"></kendo-excelexport-column>
        <kendo-excelexport-column field="dailyrate" title="Napidíj (forgatás) / Daily rate (production)"></kendo-excelexport-column>
        <kendo-excelexport-column field="preprodrate" title="Napidíj (előkészítés alatt) / Daily rate (preproduction)"></kendo-excelexport-column>

        <kendo-excelexport-column *ngFor="let item of contractAndUserFields"
            [field]="item.field" [title]="item.title" ></kendo-excelexport-column>
    </kendo-grid-excel>

</kendo-grid>
<ng-template #noDriveLink>
    <p>-</p>
</ng-template>

<kendo-popover #popHistory position="left">
    <ng-template kendoPopoverTitleTemplate>
        <div class="pop-history title" t7e="history">
            Előzmények 
        </div>
    </ng-template>
    <ng-template kendoPopoverBodyTemplate>
        <div class="pop-history body">
           
            <app-history [tableid]="history.tableid" [id]="history.id" [colname]="history.colname"
                [propcode]="history.propcode" *ngIf="history; else noHistory"></app-history>
            <ng-template #noHistory>
                <div class="history-error" t7e="history-error">Előzmény hiba</div>
            </ng-template>
        </div>
    </ng-template>
</kendo-popover>

<app-save-comment *ngIf="showSaveComment" [questionText]="showSaveComment.title"
    [isCommentOptional]="showSaveComment.isCommentOptional" (saveClicked)="saveCommentReceived($event)"
    (cancelClicked)="showSaveComment = null">
</app-save-comment>

<kendo-dialog [title]="'OT/TA'" *ngIf="isOtTaBulkChangerOpen" (close)="closeOtTaBulkChanger()" [width]="'90vw'"
    [height]="'90vh'" [animation]="true">
    <app-ot-ta-bulk-changer [contractsIds]="selectedContractIds"></app-ot-ta-bulk-changer>
</kendo-dialog>