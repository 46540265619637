<div class="wrapper" [ngClass]="cssClass" *ngIf="showMe">

    <div class="icon">
        <mat-icon>{{ icon }}</mat-icon>
    </div>
    <div class="text">
        <div class="title" [ngClass]="cssClass" [innerText]="title">
        </div>
        <div class="description" [ngClass]="cssClass" *ngIf="description" [innerText]="description">
        </div>
        <ng-content select="[appPageWarningDescription]"></ng-content>
    </div>

    <div class="divider"></div>

    <div class="close-button">
        <mat-icon (click)="closeMe($event)">close</mat-icon>
    </div>
</div>