import { Component, Input, OnInit } from '@angular/core';
import { DayOptions, TimesheetParsed } from '../../../models/timesheet';
import { ContractService } from '../../../contract/contract.service';
import { UserService } from '../../../user.service';
import { config } from '../../../config';
import { TimesheetService } from '../../timesheet.service';

@Component({
  selector: 'app-ts-editor-readonly',
  templateUrl: './ts-editor-readonly.component.html',
  styleUrls: ['./ts-editor-readonly.component.scss']
})
export class TsEditorReadonlyComponent implements OnInit {
  _ts?: TimesheetParsed | null
  @Input()
  set ts(value: TimesheetParsed | null | undefined) {
    this._ts = value
    // ha 1 óra OT-t kap, akkor az nem MP-ként számolódik, hanem OT-ként
    this.hasMealPenalty = !!this.ts && this.contractSvc.hasMealPenalty_setAtProdDeptSf(this.contractSvc.getContractById(this.ts.conid))
    this.setCalculatedValues(this._ts)
  }
  get ts() { return this._ts}
  @Input() field: FeeFields = 'calc'
  
  get hasInvoiceContact() { return !this.userSvc.isMoreThanCrew && this.contractSvc.hasInvoiceContact(this.contractSvc.getContractById(this.ts?.conid)) }
  get classForHasInvContact() { return this.hasInvoiceContact ? 'has-invoice-contact' : '' }
  hasMealPenalty = false
  DISPLAY_KM_PARKING_VIGN = !!config.HU.TS.DISPLAY_KM_PARKING_VIGN_IN_CALCULATED_SECTION

  constructor(
    private contractSvc: ContractService,
    private tsSvc: TimesheetService,
      private userSvc: UserService,
  ) { }

  perdiem?: number | null
  calcPerdiem?: number | null
  dailyRentals?: number | null
  dailyRentalsCount?: number | null
  monthlyRentals?: number | null
  monthlyRentalsCount?: number | null
  private setCalculatedValues(ts: TimesheetParsed | null | undefined): void {
    if (!ts) {
      this.perdiem = undefined
      this.calcPerdiem = undefined
    } else {
      const contract = this.contractSvc.getContractById(ts.conid)
      this.perdiem =
        this.tsSvc.isApprovedOrHigher(ts)
          ? ts.dayoptions?.includes(DayOptions.PerDiem) ? contract?.f_sleepoverrate : 0
          : null
      this.calcPerdiem = ts.dayoptions?.includes(DayOptions.PerDiem) ? contract?.f_sleepoverrate : 0
      
      this.dailyRentals = (this.ts?.f_dailybasedrentalrate1 || 0)
        + (this.ts?.f_dailybasedrentalrate2 || 0)
        + (this.ts?.f_dailybasedrentalrate3 || 0)
        + (this.ts?.f_dailybasedrentalrate4 || 0)
        + (this.ts?.f_dailybasedrentalrate5 || 0)
        + (this.ts?.f_dailybasedrentalrate6 || 0)
      this.dailyRentalsCount = (this.ts?.f_dailybasedrentalrate1 ? 1 : 0)
        + (this.ts?.f_dailybasedrentalrate2 ? 1 : 0)
        + (this.ts?.f_dailybasedrentalrate3 ? 1 : 0)
        + (this.ts?.f_dailybasedrentalrate4 ? 1 : 0)
        + (this.ts?.f_dailybasedrentalrate5 ? 1 : 0)
        + (this.ts?.f_dailybasedrentalrate6 ? 1 : 0)
      if (this.ts?.dayoptions?.includes(DayOptions.MonthlyRentsDue)) {
        const c = this.contractSvc.getContractById(this.ts!.conid)
        this.monthlyRentals = (c?.f_monthlybasedrentalrate1 || 0)
          + (c?.f_monthlybasedrentalrate2 || 0)
          + (c?.f_monthlybasedrentalrate3 || 0)
      } else {
        this.monthlyRentals = 0
      }
      if (this.ts?.dayoptions?.includes(DayOptions.MonthlyRentsDue)) {
        const c = this.contractSvc.getContractById(this.ts!.conid)
        this.monthlyRentalsCount = (c?.f_monthlybasedrentalrate1 ? 1 : 0)
          + (c?.f_monthlybasedrentalrate2 ? 1 : 0)
          + (c?.f_monthlybasedrentalrate3 ? 1 : 0)
      } else {
        this.monthlyRentalsCount = 0
      }

    }
  }

  ngOnInit(): void {
  }

}

export type FeeFields = 'calc' | 'saved'