import { Pipe, PipeTransform } from '@angular/core';
import { TimesheetParsed } from '../models/timesheet';

@Pipe({
  name: 'getTsDate'
})
export class GetTsDatePipe implements PipeTransform {

  transform(value: TimesheetParsed, ...args: unknown[]): string {
    if (!value?.dStartDate) return 'unknown timesheet'
    //return month and day
    return value.dStartDate.toLocaleDateString('hu-HU', { month: '2-digit', day: '2-digit' })
  }

}


