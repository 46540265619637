import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import { Contract } from '../../models/contract';
import { AppUser } from '../../models/db-user';
import { FlexProp, FlexPropWithValue, FlexTableProperty } from '../../models/table-property';
import { FlexEditTypes } from '../../data.service';
import { ParserService } from '../../parser.service';
import { T7eService } from '../../t7e/t7e.service';

@Injectable({
  providedIn: 'root'
})
export class FlexFieldsService {
  readonly KENDOCONTROL_NAME = '$KendoControl';

  constructor(
    private appSvc: AppService,
    private parser: ParserService,
    private t7e: T7eService,
  ) { }

  addFieldsToFG(form: FormGroup, fields: FlexTableProperty[] | null | undefined): void {
    const flexFieldsFG = form.get('flexFields') as FormGroup
    fields?.forEach(field => {
      let fc = new FormControl(null, (!field.optional && Validators.required) || null)
      flexFieldsFG.addControl(field.propcode, fc)
    })
    console.log('Létrehoztam a rugalmas mezőket: ', fields)
  }

  setFlexValues(flexFG: FormGroup, fields: FlexTableProperty[] | null, values: Contract | AppUser, f_: boolean = true) {
    fields?.forEach(field => {
      const contractFieldName = f_
        ? 'f_' + field.propcode
        : values.details?.find(d => d.propcode === field.propcode)?.propcode || '???'
      console.assert(contractFieldName !== '???', 'Nem található a szerződés mezője a flex mezők között')
      let value = f_ 
        ? values[contractFieldName]
        : values.details?.find(d => d.propcode === field.propcode)?.propvalue
      //if (field.tpid == 62) console.table(this.appSvc.aPropItems$[field.propid].value)
      const picodeFromDuplicate = this.appSvc.aPropItems$[field.propid].value.find(x => x.piid == value)?.picode
      if(picodeFromDuplicate) value = picodeFromDuplicate
      flexFG.get(field.propcode)?.setValue(value)
    })
  }

  prefixFlexFields(fields:{ [key: string]: any }): { [key: string]: any } | null {
    if (!fields) return null
    // prefix field names with 'f_'
    let prefixedFields: { [key: string]: any } = {}
    Object.keys(fields).forEach(key => {
      prefixedFields['f_' + key] = fields[key]
    })
    return prefixedFields
  }


  isUploadField(field: FlexTableProperty): boolean {
    return field.edittype === 6 // 6: upload
  }

  getFlexFieldsWithValues(formValues: any): { [key: string]: any } {
    let flexData: { [key: string]: any } = {}
    formValues.flexFields && Object.keys(formValues.flexFields)
      .filter(key => formValues.flexFields[key] !== undefined)
      .forEach(key => {
        // a kendo controlok nevei végén van egy kis string, azt le kell vágni
        const keyToSave = (key.endsWith(this.KENDOCONTROL_NAME)) ? key.slice(0, -this.KENDOCONTROL_NAME.length) : key
        // ha a kendo control egy upload, akkor a kiválasztott file-t kell elmenteni, nem a control értékét, ami egy bonyolult objectum, ami nem tartalmazza a fájlnevet
        const editType = this.appSvc.tableProperties?.find(p => p.propcode === keyToSave)?.edittype
        let val;
        switch (editType) {
          case FlexEditTypes.upload:
            val = formValues.flexFields[keyToSave]
            break;
          case FlexEditTypes.date:
            val = this.parser.dateForSql(formValues.flexFields[key])
            break;
          default:
            val = formValues.flexFields[key]
            break;
        }
        flexData[keyToSave] = val
      })
    return flexData
  }

  getValue(fields: FlexPropWithValue[], propcode: string): string | number | null | undefined {
    return fields.find(f => f.propcode === propcode)?.propvalue
  }

  getBoxTitle(fields: FlexProp[], propcode: string): string | null {
    return this.t7e.lang === 'hu'
      ? fields.find(f => f.propcode === propcode)?.propname || null
      : fields.find(f => f.propcode === propcode)?.engpropname || null
  }
}
