import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { Department, HandleDeptsParams } from '../models/department';
import { AppService, DbCallError } from '../app.service';
import { DataService, FlexTables } from '../data.service';
import { Router } from '@angular/router';
import { T7eService } from '../t7e/t7e.service';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class DeptService {
  readonly departments$ = new BehaviorSubject<Department[] | null>(null)
  departmentList: Department[] | null = null
  saveError$ = new BehaviorSubject<DbCallError>(null)
  saveStatusError$ = new BehaviorSubject<DbCallError>(null)
  savingDept$ = new BehaviorSubject<boolean>(false)

  constructor(
    private dataSvc: DataService,
    private appSvc: AppService,
    private router: Router,
    private t7e: T7eService,
    private userSvc: UserService,
  ) { 
    this.handleLoadedDepartments = this.handleLoadedDepartments.bind(this)
    this.handleDept = this.handleDept.bind(this)
    this.sortDeptsCallback = this.sortDeptsCallback.bind(this)

    dataSvc.initData$
      .pipe(filter(data => !!data))
      .subscribe(appData => {
        this.departmentList = this.dataSvc.flattenFlexFieldsArray(appData?.data?.list_departments || null) || null
        try {
          this.handleLoadedDepartments(appData?.data?.list_departments)
        } catch (ex) {
          console.error(ex);
          this.router.navigate(['error', this.t7e.lang === 'hu' ? 'App alapadatbetöltés hiba' : 'App load error'])
        }
      })

  }

  handleDept(objParams: HandleDeptsParams, changeStatus: boolean = false): Observable<Department | null> {
    this.saveError$.next(null)
    this.saveStatusError$.next(null)
    this.savingDept$.next(true)
    const params = this.getParams(objParams, changeStatus)
    const retVal = this.dataSvc.handleDepts(params)
      .pipe(map(this.parseDept))
    retVal.subscribe({
      next: (result) => {
        this.savingDept$.next(false)
        if (!result) {
          this.saveError$.next(this.t7e.getTranslation("dept.service", "handleDept", "saveerror",'A mentés nem adott vissza részleget, ezért lehet, hogy nem mentődött el'))
          return
        }
        if (changeStatus) this.saveStatusError$.next(false)
        else this.saveError$.next(false)

        this.departmentList = this.appSvc.replaceDocItems([result], this.departmentList, 'depid')
        this.departments$.next(this.dataSvc.flattenFlexFieldsArray(this.departmentList.sort(this.sortDeptsCallback)) || null)
        this.dataSvc.setCache(FlexTables.departments, this.departmentList)

      },
      error: (err) => {
        this.savingDept$.next(false)
        console.error(err)
        this.saveError$.next(err)
      }
    })
    return retVal
  }

  private getParams(paramsOrig: HandleDeptsParams, changeStatus: boolean): HandleDeptsParams {
    let params: HandleDeptsParams
    if (changeStatus) {
      params = {
        _depid: paramsOrig._depid,
        _depstatus: paramsOrig._depstatus,
      }
    } else {
      params = { ...paramsOrig }
      delete params._depstatus
    }
    return params
  }

  private parseDept(dept: Department | null): Department | null {
    // otrate string to array
      const detailIndex = dept?.details?.findIndex(x => x.propcode === 'otrate') || -1
      if (detailIndex > -1) {
        const otrate = dept?.details![detailIndex]?.propvalue
        if (otrate && typeof otrate === 'string') {
          try {
            dept.details![detailIndex].propvalue = JSON.parse(otrate)
            if (dept['f_otrate']) dept['f_otrate'] = dept.details![detailIndex].propvalue
          } catch (ex) {
            console.error(ex)
          }
        }
      }

    return dept || null
  }


  private handleLoadedDepartments(depts?: Department[] | null): void {
    if (!depts?.length) throw 'Nincsenek részlegek'
    // otrate string to array
    for (let i = 0; i < (depts?.length || 0); i++) {
      if (!depts[i]) continue
      depts[i] = this.parseDept(depts[i])!
    }
    depts = this.dataSvc.flattenFlexFieldsArray(depts) || null
    this.departments$.next(depts?.sort(this.sortDeptsCallback) || [])
    depts?.length && this.dataSvc.setCache(FlexTables.departments, depts || [])
  }

  getDeptById(depid: number): Department | null {
    return this.departmentList?.find(x => x.depid === depid) || null
  }

  isDeptHeadOf(depid?: number | null, userId?: number): boolean {
    if (userId === undefined) userId = this.userSvc.loggedinUserId || 0
    if (!userId) return false
    if (!depid) return false
    const dept = this.getDeptById(depid)
    if (!dept) return false
    return !!dept.depleaders?.find(x => x.usid == userId)
  }

  sortDeptsCallback(a?: Department | null, b?: Department | null): number {
    if (!a?.depname) return 1
    if (!b?.depname) return -1
    if (a.depname?.toLowerCase().trim() == 'other') return 1
    // sort by depname
    return a.depname?.toLowerCase().trim() < b.depname?.toLowerCase().trim() ? -1 : 1
  }
}
