<h1 class="page-title" t7e="shootingDays">Forgatási napok</h1>

<kendo-grid [kendoGridBinding]="(gridData$ | async)!" [skip]="skip" [sort]="sort"
    [sortable]="true" (dataStateChange)="dataStateChange($event)" (edit)="editHandler($event)"
    (cancel)="cancelHandler($event)" (save)="saveHandler($event)" (remove)="removeHandler($event)"
    (add)="addHandler($event)" [navigable]="true" [loading]="!!(loadingGrid$ | async)" [resizable]="true">
    <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand>{{ 'add-new' | t7e:'Új' }}</button>
    </ng-template>
    <kendo-grid-column field="shId" title="ID" [editable]="false" *ngIf="debug"></kendo-grid-column>
    <kendo-grid-column field="numDay" title="Forgatási nap" [editable]="false" [width]="40"></kendo-grid-column>
    <kendo-grid-column field="date" title="Nap" editor="date" format="yyyy-MM-dd"></kendo-grid-column>
    <kendo-grid-column field="crewCallTime" title="Crew Call">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.crewCallTime | date: 'HH:mm' }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
            <kendo-timepicker [formControl]="getFormControl(dataItem, column.field)" [format]="'HH:mm'"></kendo-timepicker>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="cameraWrapTime" title="Camera wrap">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.cameraWrapTime | date: 'HH:mm' }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem"  let-column="column" let-formGroup="formGroup">
            <kendo-timepicker [formControl]="getFormControl(dataItem, column.field)" [format]="'HH:mm'"></kendo-timepicker>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="locationText" title="Helyszín">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.locationName }} 
            <div class="unimportant nowrap" *ngIf="dataItem.locationAttribs" [title]="dataItem.locationAttribs">
                {{ dataItem.locationAttribs}}
            </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
            <kendo-dropdownlist [formControl]="getFormControl(dataItem, 'locCode')" [data]="locations$ | async" 
                [textField]="'locName'" [valueField]="'locCode'" [valuePrimitive]="true">
            </kendo-dropdownlist>
            <div *ngIf="debug">locations$: {{ locations$ | async | json }}</div>
            <div *ngIf="debug">formGroup: {{ formGroup.getRawValue() | json }}</div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="" [width]="220">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
            <div class="d-flex gap-1">
                <button kendoGridEditCommand [primary]="true" class="button-sm"><mat-icon [inline]="true" class>edit</mat-icon></button>
                <button kendoGridRemoveCommand class="button-sm"><mat-icon [inline]="true">delete</mat-icon></button>
                <button kendoGridSaveCommand [disabled]="!!formGroup?.invalid" [primary]="true" class="button-sm">
                    <mat-icon [inline]="true">save</mat-icon>
                </button>
                <button kendoGridCancelCommand class="button-sm">
                    <mat-icon [inline]="true">cancel</mat-icon>
                </button>
            </div>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

