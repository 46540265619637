import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { DbCallError } from '../../app.service';
import { DayOptions } from '../../models/timesheet';
import { TsCalcInfo, TsCalcInfoParsed } from '../../models/ts-calc-info';
import { TimesheetService } from '../timesheet.service';
import { T7eService } from '../../t7e/t7e.service';
import { ProductionService } from '../../prod/production.service';
import { ContractService } from '../../contract/contract.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-salary-reasons',
  templateUrl: './salary-reasons.component.html',
  styleUrls: ['./salary-reasons.component.scss']
})
export class SalaryReasonsComponent implements OnInit, OnDestroy {
  @Input() tsid?: number
  @Input() showSalary?: boolean = true
  @Input() showOt?: boolean = true
  @Input() showTa?: boolean = true

  reasons$ = new Observable<TsCalcInfoParsed>()
  loading$ = new BehaviorSubject<boolean>(true)
  loadError$ = new BehaviorSubject<DbCallError>(null)

  isHalfDay$: Observable<boolean> | undefined
  isPerdiem$: Observable<boolean> | undefined
  isTravelday$: Observable<boolean> | undefined
  isMealPenalty$: Observable<boolean> | undefined

  _destroy$ = new Subject<void>()

  constructor(
    private t7e: T7eService,
    private tsSvc: TimesheetService,
    private prodSvc: ProductionService,
    private contractSvc: ContractService,
    private userSvc: UserService,
  ) {
  }

  get hasInvoiceContact() {
    if (this.userSvc.isMoreThanCrew) return false
    const conid = this.tsSvc.getTsById(this.tsid || null)?.conid
    return this.contractSvc.hasInvoiceContact(this.contractSvc.getContractById(conid))
  }

  get lang() { return this.t7e.lang }
  
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  ngOnInit(): void {
    if (!this.tsid) {
      console.error('SalaryReasonsComponent: tsid kötelező');
      return
    }

    this.getReasons()
  }

  getReasons() {
    if (!this.tsid) return
    this.reasons$ = this.tsSvc.getSalaryReasons(this.tsid)
      .pipe(
        shareReplay(), // enélkül 3x hívódik meg a backend. 
        takeUntil(this._destroy$),
        distinctUntilChanged(),
        map(this.tsSvc.parseTsCalcInfo)
      )
    this.reasons$
      .subscribe({
        next: data => {
          this.loading$.next(false)
          this.loadError$.next(false)
        }, error: err => {
          this.loading$.next(false)
          this.loadError$.next(err)
        }
      })
    this.isHalfDay$ = this.reasons$.pipe(map(r => r.dayoptions?.includes(DayOptions.HalfDay) || false))
    this.isPerdiem$ = this.reasons$.pipe(map(r => r.dayoptions?.includes(DayOptions.PerDiem) || false))
    this.isTravelday$ = this.reasons$.pipe(map(r => r.dayoptions?.includes(DayOptions.TravelDay) || false))
    this.isMealPenalty$ = this.reasons$.pipe(map(r => r.dayoptions?.includes(DayOptions.MealPenalty) || false))
  }

  get dateFormat() { return 'MMM dd HH:mm' }

  get numberFormat() { return '0.0-2' }

}
