<div *ngIf="!dataItem?.arrDoclink?.length; else hasDocs" class="text-muted" t7e="no-generated-contract">
    Még nem generáltál szerződést
</div>
<div *ngIf="getIsGenerating(dataItem?.conid)" class="d-flex">
    <mat-spinner [diameter]="20" class="mr-05"></mat-spinner>
    <span t7e="generating-contract">A szerződés generálása folyamatban...</span>
</div>
<div *ngIf="generatingError$[dataItem?.conid!] | async" class=" text-danger px-1 mb-05">
    {{ generatingError$[dataItem?.conid!] | async }}
</div>
<ng-template #hasDocs>
    <div class="doc-list">
        <app-doclink (removeFileClicked)="removeFile($event)" [data]="dataItem"
            (sendOnlyPdfClicked)="sendOnlyPdfClicked($event)"></app-doclink>
    </div>
</ng-template>
<div class="doc-generate-buttons">
    <button mat-raised-button type="button" (click)="generatePdf(dataItem, false, false)"
        [disabled]="canGeneratePdf(dataItem).disabled" [title]="canGeneratePdf(dataItem).title" 
        class="btnGenerate" t7e="generate">
        Létrehozás
    </button>
    <!-- Link to Google Drive folder -->
    <a mat-raised-button [href]="getFolderUrl()" target="_blank" *ngIf="isFolderExists">
        <span t7e="folder">Mappa</span>
    </a>
</div>