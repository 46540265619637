import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FlexProp, PropItem } from '../../models/table-property';
import { AppService } from '../../app.service';
import { map } from 'rxjs';
import { HandlePropItemsParams } from '../../models/handle-table-properties-params';

@Component({
  selector: 'app-edit-propitems',
  templateUrl: './edit-propitems.component.html',
  styleUrls: ['./edit-propitems.component.scss']
})
export class EditPropitemsComponent implements OnInit {
  @Input() prop?: FlexProp
  
  form: FormGroup
  selectedPiid: number | null = null

  get selectedPropid() { return this.form.value.propid }


  constructor(
    private fb: FormBuilder,
    private appSvc: AppService,
  ) {
    this.form = this.createForm()

  }

  props$ = this.appSvc.flexProps$
  items$ = this.appSvc.aPropItems$

  ngOnInit(): void {
    if (this.prop) {
      this.form.get('prop')!.patchValue(this.prop.propid)
    }
    if (!this.form.get('propid')!.value) {
      this.disableForm()
    }
  }

  private createForm() {
    return this.fb.group({
      propid: 0,
      piname: '',
      picode: '',
      pistatus: 1,
    })
  }

  onPropSelected(event: any): void {
    console.log(this.form.value.propid)
    if (event) {
      this.enableForm()
    } else {
      this.disableForm()
    }
  }

  nameChanged(): void {
    console.log(this.form.value.piname)
    if (!this.form.value.picode) {
      this.form.get('picode')!.patchValue(this.appSvc.normalizeString(this.form.value.piname))
    }
  }

  onEdit(item: PropItem): void {
    console.log('Edit', item)
    this.selectedPiid = item.piid
    const itemParsed = {
      ...item,
      pistatus: item.pistatus ? '1' : '0',
    }
    this.form.patchValue(itemParsed)
  }

  unselectPiid(): void {
    this.selectedPiid = null
    this.form.get('piname')!.reset()
    this.form.get('picode')!.reset()
    this.form.get('pistatus')!.reset()
  }

  onSubmit(): void {
    console.log('Saving', this.form.value)
    const params: HandlePropItemsParams = {
      _piid: this.selectedPiid,
      _propid: this.form.value.propid,
      _piname: this.form.value.piname,
      _picode: this.form.value.picode,
      _pistatus: this.form.value.pistatus,
    }
    this.appSvc.modifyAndSavePropItems(params)
  }

  private enableForm(): void {
    this.form.get('piname')!.enable()
    this.form.get('picode')!.enable()
    this.form.get('pistatus')!.enable()
  }

  private disableForm(): void {
    this.form.get('piname')!.disable()
    this.form.get('picode')!.disable()
    this.form.get('pistatus')!.disable()
  }
}
      
