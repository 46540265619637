import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { T7eService } from '../../../t7e/t7e.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() formgroup?: FormGroup
  @Input() searchFC?: FormControl


  constructor(
    private t7e: T7eService,
  ) { }

  ngOnInit(): void {
  }


  /** T7E */
  t7eLabel = this.t7e.getTranslation('app-search', 'label', 'innerText', null, null, 'Keresés')

}
