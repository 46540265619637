import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AppService } from '../../../app.service';
import { ContractService } from '../../../contract/contract.service';
import { Department } from '../../../models/department';
import { DeptService } from '../../../dept/dept.service';
import { T7eService } from '../../../t7e/t7e.service';

@Component({
  selector: 'app-filter-dept',
  templateUrl: './filter-dept.component.html',
  styleUrls: ['./filter-dept.component.scss']
})
export class FilterDeptComponent implements OnInit {
  @Input() formgroup?: FormGroup
  @Input() deptFC?: FormControl

  depts$ = new BehaviorSubject<Department[]>([])
  isDeptsLoading: boolean = true

  constructor(
    private deptSvc: DeptService,
    private t7e: T7eService,
  ) { }

  ngOnInit(): void {
    console.assert(this.deptFC, 'Dept Filter komponens: @Input deptFC kötelező')
    console.assert(this.formgroup, 'Dept Filter komponense: A formgroup megadása kötelező')

    this.deptSvc.departments$.subscribe(data => {
      this.depts$.next(data || [])
      this.isDeptsLoading = !data?.length
    })
  }

  handleDeptFilter(value: string) {
    this.depts$.next(this.deptSvc.departmentList?.filter(d => (
      d.depname?.toLowerCase().includes(value.toLowerCase())
    )) || [])
  }

  /** T7E */
  t7eLabel = this.t7e.getTranslation('app-filter-dept', 'label', 'innerText', null, null, 'Részleg')

}
