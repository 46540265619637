import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PropItem, Props } from '../../../models/table-property';
import { BehaviorSubject, Subject, map, take, takeUntil } from 'rxjs';
import { DataService } from '../../../data.service';
import { T7eService } from '../../../t7e/t7e.service';

@Component({
  selector: 'app-filter-contract-type',
  templateUrl: './filter-contract-type.component.html',
  styleUrls: ['./filter-contract-type.component.scss']
})
export class FilterContractTypeComponent implements OnInit {
  @Input() formgroup?: FormGroup
  @Input() contractTypeFC?: FormControl

  _destroy$ = new Subject()
  contractTypes$ = new BehaviorSubject<PropItem[]>([])
  defaultItem = { piid: null, piname: 'All' }
  loading$ = new BehaviorSubject<boolean>(true)
  
  constructor(
    private t7e: T7eService,
  private dataSvc: DataService
  ) {
    this.dataSvc.listPropItems(Props.contractType)
      .pipe(
        takeUntil(this._destroy$),
        map(cts => cts.filter(ct => ct.pistatus != 0)),
      )
      .subscribe({ 
        next: data => {
          this.loading$.next(false)
          this.contractTypes$.next(data)
        },
        error: err => {
          this.loading$.next(false)
          console.error('Hiba a szerződéstípusok betöltése közben')
          console.error(err)
          // todo: notif
        }
      })
   }

  ngOnInit(): void {
  }


  /** T7E */
  t7eLabel = this.t7e.getTranslation('app-filter-contract-type', 'label', 'innerText', null, null, 'Szerződés típus')

}
