import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddEvent, RemoveEvent, EditEvent } from '@progress/kendo-angular-grid';
import { AppService } from '../../../app.service';
import { HandleFlexPropertiesParams } from '../../../models/handle-table-properties-params';
import { FlexProp } from '../../../models/table-property';
import { UserService } from '../../../user.service';
import { NotificationService } from '../../../util/notifications/notification.service';
import { DialogData } from '../edit-flex-fields-form/edit-flex-fields-form.component';
import { EditPropFormComponent } from '../edit-prop-form/edit-prop-form.component';
import { Groups } from '../../../models/db-user';

@Component({
  selector: 'app-manage-properties',
  templateUrl: './manage-properties.component.html',
  styleUrls: ['./manage-properties.component.scss']
})
export class ManagePropertiesComponent implements OnInit {
  dlgProp: MatDialogRef<EditPropFormComponent, any> | undefined

  constructor(
    private appSvc: AppService,
    private userSvc: UserService,
    private notifSvc: NotificationService,
    private fb: FormBuilder,
    private dialog: MatDialog,

  ) { 
    this.saveProperty = this.saveProperty.bind(this)
    this.onCloseEditor = this.onCloseEditor.bind(this)

  }

  properties$ = this.appSvc.flexProps$
  isDeveloper: boolean = true || !!this.userSvc.loggedinUser?.ugm.find(g => g.ugid == Groups.DEV) // TODO dinamikusan kell meghatározni


  ngOnInit(): void {
  }

  addProp(event: AddEvent): void {
    this.dlgProp = this.dialog.open(EditPropFormComponent, {
      data: {
        dataItem: event.dataItem,
        isNew: true,
        onClose: this.onCloseEditor,
        onSave: this.saveProperty,

      } as DialogData
    })


  }

  removeProp({ dataItem, isNew, rowIndex, sender }: RemoveEvent): void {

  }

  editProp(event: EditEvent): void {
    this.dlgProp = this.dialog.open(EditPropFormComponent, {
      data: {
        dataItem: event.dataItem,
        isNew: false,
        onClose: this.onCloseEditor,
        onSave: this.saveProperty,

      } as DialogData
    })
  }


  onCloseEditor(): void {
    this.dlgProp?.close()
  }

  private saveProperty(prop: FlexProp): void {
    const propcode = prop.propcode
      || this.appSvc.normalizeString(prop.propname)
        .replace(/\s/g, '')   // minden szóközt törlök
        .replace(/\W+/g, '')  // minden nem-alfanumerikus char-t törlök
    // a combo-box a begépelt szöveget a prodid-be teszi. Törölni kell
    if (isNaN(prop.propid as number)) prop.propid = null
    let params: HandleFlexPropertiesParams = {
      _propid: prop.propid || null,
      _propname: prop.propname,
      _engpropname: prop.engpropname,
      _propdesc: prop.propdesc,
      _propdesceng: prop.propdesceng,
      _propcode: propcode,
      _edittype: prop.edittype || null,
      _level: prop.level,
      _prid: 1,
    }
    this.appSvc.modifyAndSaveFlexProperties(params)
  }

  getEditLevel(level: number | null): string {
    //@ts-ignore
    return this.appSvc.getEditLevels().find(l => l.id == level)?.value || 'n/a'
  }

}
