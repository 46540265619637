import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, delay, filter, map, mergeMap, Observable, Subject, takeUntil } from 'rxjs';
import { AppService, DbCallError } from '../../app.service';
import { DataService, FlexTables } from '../../data.service';
import { FlexFieldsService } from '../../util/flex-fields/flex-fields.service';
import { AppUser } from '../../models/db-user';
import { HandleUsersParameters } from '../../models/handle-users-parameters';
import { FlexTableProperty } from '../../models/table-property';
import { CrewCountryCode, FOREIGN_CREW_COUNTRY_CODE, HUN_CREW_COUNTRY_CODE, UserService } from '../../user.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { T7eService } from '../../t7e/t7e.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  @Input() user$?: BehaviorSubject<AppUser | null>
  @Input() showGotoContractButton = true
  @Input() showUserGroups = true

  private user: AppUser | null = null
  private _destroy$ = new Subject<void>();
  saveError$ = this.userSvc.saveError$
  savingUser$ = this.userSvc.savingUser$
  get userGroups() { return this.userSvc.roles.map(ug => this.t7e.lang === 'hu' ? ug.ugname : ug.engugname).join(', ') }
  loadingUserAllData$ = new BehaviorSubject<boolean>(false)

  crewCountry$ = this.userSvc.crewCountry$
  allowedFields$ = new BehaviorSubject<string[]>([])
  get flexFieldsFG() { return this.form.get('flexFields') as FormGroup }
  readonly usersFlexTable = FlexTables.users
  readonly formFieldStyles = {}
  readonly formGroupStyles = {
    display: 'flex',
    'flex-direction': 'column',
  }

  constructor(
    private fb: FormBuilder,
    private userSvc: UserService,
    private appSvc: AppService,
    private dataSvc: DataService,
    private flexFieldSvc: FlexFieldsService,
    private notifSvc: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private t7e: T7eService,
  ) {
    this.appSvc.pageTitle$.next(this.t7ePageTitle)
    
    this.addFieldsToFG = this.addFieldsToFG.bind(this)

  }

  get debug() { return this.appSvc.debug }
  get lang() { return this.t7e.lang }

  form: FormGroup = this.fb.group({
    name: ['', [Validators.required,]],
    famname: ['', [Validators.required,]],
    email: [{ value: null, disabled: true }],
    phonenumber: ['', [Validators.required, Validators.pattern("^([+])[\\s0-9]{7,20}$")]],

    flexFields: this.fb.group({}),
  })

  ngOnInit(): void {
    if (!this.user$) {
      this.notifSvc.addObservableNotif({ msg: 'Nincs user', class: 'danger' })
      return
    }

    const hNotifLoad = this.notifSvc.addObservableNotif({ msg: 'Loading profile data...', duration: 500 })
    this.user$?.pipe(
      takeUntil(this._destroy$),
      filter(user => !!user?.usid),
      mergeMap(user => {
        this.user = user
        this.form.patchValue({
          name: this.user?.usnameOrig || '',
          famname: this.user?.famname || '',
          email: this.user?.email || '',
          phonenumber: this.user?.phonenumber || '',
        })
        if (!user?.usid) { console.error('Nincs user', user); throw 'Nincs user'; }
        this.notifSvc.modifyNotif(hNotifLoad, { msg: 'Loading profile data...', duration: false })
        this.loadingUserAllData$.next(true)
        return this.dataSvc.listUsers({ _usid: user?.usid })
      })
    ).subscribe({
      next: (userAllData) => {
        this.loadingUserAllData$.next(false)
        this.notifSvc.closeNotif(hNotifLoad)
        if (this.flexFieldsFG.controls && userAllData?.length) {
          console.log(userAllData[0])
          for (let propCode in this.flexFieldsFG.controls) {
            console.log(propCode, userAllData[0].details?.find(df => df.propcode == propCode)?.propvalue)
            const prop = { ...this.appSvc.getPropByPropcode(propCode), ...userAllData[0].details?.find(df => df.propcode == propCode)}
            const propValue = prop?.edittype == 1 // combo
              ? this.appSvc.getPropItemByPicode(this.appSvc.getPropByPropcode(propCode)?.propid!, prop?.propvalue)?.picode.toString()
              : prop?.propvalue
            this.flexFieldsFG.get(propCode)?.patchValue(propValue)
          }
        }

      },
      error: err => {
        this.loadingUserAllData$.next(false)
        this.notifSvc.modifyNotif(hNotifLoad, { msg: 'Loading failed. Try to reload the page', class: 'danger' })
      }
    })
    
    this.appSvc.tableProperties$
      .pipe(
        takeUntil(this._destroy$),
        map(fields => fields?.filter(f => f.tableid == FlexTables.users)),
        filter(fields => !!fields?.length),
      )
      .subscribe(this.addFieldsToFG)
    
    combineLatest([this.appSvc.tableProperties$, this.crewCountry$])
      .pipe(
        takeUntil(this._destroy$),
        filter(([fields, crewCountry]) => !!fields?.length),
        map(([fields, crewCountry]) => fields
          ?.filter(f => f.tableid == FlexTables.users
            && this.userSvc.isToDisplayByCrewCountry(f, crewCountry))
          ?.map(f => f.propcode) || [])
      )
      .subscribe(this.allowedFields$)
    
    this.form.get('flexFields.crew_country')?.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(val => {
        const crewCountryPropId = this.appSvc.tableProperties$?.value?.find(p => p.propcode == 'crew_country')?.propid
        if (!crewCountryPropId) throw new Error('crew_country propid not found')
        //const code = this.appSvc.aPropItems$[crewCountryPropId]?.value?.find(c => c.piid == val)?.picode || null
        console.log('flexField changed', val)
        this.userSvc.crewCountry$.next(val as CrewCountryCode)
      })
  }
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  submit(): void {
    if (!this.user) {
      return
    }
    const f = this.form.value
    const flexData: { [key: string]: any } = this.flexFieldSvc.getFlexFieldsWithValues(f)
    const u: HandleUsersParameters = {
      _usid: this.user.usid,
      _usname: !!f.usname?.trim() ? f.usname?.trim() : this.form.value.name?.trim(),
      _famname: !!f.famname?.trim() ? f.famname?.trim() : this.form.value.famname?.trim(),
      _email: this.user.email?.trim(),
      _phonenumber: !!f.phonenumber?.trim() ? f.phonenumber?.trim() : this.form.value.phonenumber?.trim(),
      _data: Object.keys(flexData).length ? JSON.stringify(flexData) : undefined,
    }
    const hNotifSave = this.notifSvc.addObservableNotif({ msg: this.lang == 'en' ? 'Saving' : 'Mentés folyamatban', duration: false })
    this.userSvc.handleUser(u)
      .subscribe({
        next: _ => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.lang == 'en' ? 'Saved' : 'Elmentve', class: 'success', duration: 1000 })
        },
        error: err => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.lang == 'en' ? 'Save failed' : 'Mentés nem sikerült', class: 'danger' })
          console.error(err);
        }
      })
  }

  private addFieldsToFG(fields: FlexTableProperty[] | null | undefined): void {
    this.flexFieldSvc.addFieldsToFG(this.form, fields)
  }

  invalidControls() {
    console.log(this.form.controls);
    for (let c in this.form.controls) {
      if (this.form.controls[c].invalid) console.log(c, this.form.controls[c].getRawValue())
    }
    //@ts-ignore
    const flexFields = this.form.controls.flexFields as FormGroup
    for (let c in flexFields.controls) {
      if (flexFields.controls[c].invalid) console.log(c, flexFields.controls[c].getRawValue())
    }

    return true

  }

  navToMyContracts() {
    if (this.route.component?.name === 'EditProfileComponent') this.router.navigate(['/dept/contracts'])
    else this.router.navigate(['/my/contracts'])
  }

  /** T7E */
  t7eSaveButtonErrorText = this.t7e.getTranslation('app-profile', 'app-save-button', 'errorText', null)
  t7eSaveButtonSuccessText = this.t7e.getTranslation('app-profile', 'app-save-button', 'successText', null)
  t7ePageTitle = this.t7e.getTranslation('app-header', 'my-profile', 'innerText', null)
}
