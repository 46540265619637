<div class="view-value " [ngClass]="getViewClass()" [ngSwitch]="field?.type" *ngIf="!isEditing"
     (click)="editClicked($event)">


  <!--view-value — Date-->
  <div class="field-box" *ngSwitchCase="'date'" [title]="field?.valueTitle">
    <div class="field-value-box">
      <div class="field-label-box">
        <div class="field-label">
          {{field?.label }}
        </div>
      </div>
      <div class="field-value" [ngStyle]="{'width': readWidth}">
        {{ ($any(field?.value$) | async | date) || '&nbsp;' }}
        <span class="suffix" *ngIf="suffix">{{suffix}}</span>
      </div>
    </div>

    <!--    <div class="field-status-icons">-->
    <!--      <div class="icon success" *ngIf="(saveStatus$ | async) == 'save-success'">-->
    <!--        <mat-icon>check_circle_outline</mat-icon>-->
    <!--      </div>-->
    <!--      <div class="icon save-error" >-->
    <!--        <mat-icon>error_outline</mat-icon>-->
    <!--      </div>-->
    <!--    </div>-->

  </div>


  <!--view-value — Time-->
  <div class="field-box" *ngSwitchCase="'time'" [title]="field?.valueTitle">
    <div class="field-value-box">
      <div class="field-label-box">
        <div class="field-label">
          {{field?.label}}
        </div>
      </div>
      <div class="field-value" [ngStyle]="{'width': readWidth}">
        {{ ($any(field?.value$) | async | date:'shortTime') || '&nbsp;' }}
        <span class="suffix" *ngIf="suffix">{{suffix}}</span>
      </div>
    </div>

    <!--    <div class="field-status-icons">-->
    <!--      <div class="icon success" *ngIf="(saveStatus$ | async) == 'save-success'">-->
    <!--        <mat-icon>check_circle_outline</mat-icon>-->
    <!--      </div>-->
    <!--      <div class="icon save-error" *ngIf="(saveStatus$ | async) == 'save-error'" [title]="field?.isError | async">-->
    <!--        <mat-icon>error_outline</mat-icon>-->
    <!--      </div>-->
    <!--    </div>-->

  </div>

  <!--view-value — OT Rates-->
  <div class="field-box otrates" *ngSwitchCase="'otrates'" [title]="field?.valueTitle">
    <div class="field-value-box">
      <div class="field-label-box">
        <div class="field-label">
          {{field?.label}}
        </div>
      </div>
      <div class="field-value" [ngStyle]="{'width': readWidth}">
        <div class="values" *ngFor="let val of $any(field?.value$) | async; let last=last; trackBy: trackByIndex"
        [ngClass]="getValueClass(field?.value$ | async)">
          {{ val }}
          <span *ngIf="!last" class="otrate-devider"> / </span>
        </div>
        <span class="suffix" *ngIf="suffix">{{suffix}}</span>
      </div>
    </div>
  </div>

  <!--view-value — Default-->
  <div class="field-box" *ngSwitchDefault [title]="field?.valueTitle">
    <div class="field-value-box">
      <div class="field-label-box">
        <div class="field-label">
          {{field?.label}}
        </div>
      </div>
      <div class="field-value" [ngClass]="getValueClass(field?.value$ | async)">
        {{ (field?.value$ | async) || '&nbsp;' }}
        <span class="suffix" *ngIf="suffix">{{suffix}}</span>
      </div>
    </div>


    <!--    <div class="field-status-icons">-->
    <!--      <div class="icon success" *ngIf="(saveStatus$ | async) == 'save-success'">-->
    <!--        <mat-icon>check_circle_outline</mat-icon>-->
    <!--      </div>-->
    <!--      <div class="icon save-error" *ngIf="(saveStatus$ | async) == 'save-error'" [title]="field?.isError | async">-->
    <!--        <mat-icon>error_outline</mat-icon>-->
    <!--      </div>-->
    <!--    </div>-->

  </div>

  <app-popup-menu *ngIf="showMenu" (onCancelClicked)="cancel()"
    [disabled]="(saveStatus$ | async) == 'saving'" [options]="field?.popup"></app-popup-menu>

</div>


<div class="write-value" *ngIf="isEditing" [ngClass]="getEditorClass()">
  <form [ngSwitch]="field?.type" [formGroup]="form" (submit)="save()" class="write-value-form">


    <!--write value — Text-->
    <mat-form-field *ngSwitchCase="'text'" class="full-width" [ngStyle]="{'width': editWidth}">
      <mat-label *ngIf="field?.label">
        {{field?.label}}
      </mat-label>
      <input #inputTag matNativeControl formControlName="inp" appearance="outline">
      <span matSuffix *ngIf="suffix">{{suffix}}</span>
      <span matSuffix *ngIf="(saveStatus$ | async) == 'saving'" class="saving-indicator"></span>
      <mat-hint>{{field?.hint}}</mat-hint>
    </mat-form-field>


    <!--write-value — Number-->
    <mat-form-field *ngSwitchCase="'number'" class="full-width" [ngStyle]="{'width': editWidth}">
      <mat-label *ngIf="field?.label">
        {{field?.label}}
      </mat-label>
      <input #inputTag matNativeControl formControlName="inp" type="number" [min]="field?.numberOptions?.min || null"
             [max]="field?.numberOptions?.max || null" [step]="field?.numberOptions?.step || null" appearance="outline">
      <span matSuffix *ngIf="suffix">{{suffix}}</span>
      <span matSuffix *ngIf="(saveStatus$ | async) == 'saving'" class="saving-indicator"></span>
      <mat-hint *ngIf="field?.hint">{{field?.hint}}</mat-hint>
    </mat-form-field>


    <!--write-value — Date-->
    <mat-form-field *ngSwitchCase="'date'" appearance="outline" class="full-width" [ngStyle]="{'width': editWidth}">
      <mat-label *ngIf="field?.label">
        {{field?.label}}
      </mat-label>
      <input matInput [matDatepicker]="picker" formControlName="inp" [min]="field?.dateOptions?.min || null"
             [max]="field?.dateOptions?.max || null">
      <span matSuffix *ngIf="suffix">{{suffix}}</span>
      <span matSuffix *ngIf="(saveStatus$ | async) == 'saving'" class="saving-indicator"></span>
      <mat-hint *ngIf="field?.hint">{{field?.hint}}</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>


    <!--write-value — Time-->
    <mat-form-field *ngSwitchCase="'time'" appearance="outline" class="full-width" [ngStyle]="{'width': editWidth}">
      <mat-label *ngIf="field?.label">
        {{field?.label}}
      </mat-label>
      <input matInput type="time" formControlName="inp">
      <span matSuffix *ngIf="suffix">{{suffix}}</span>
      <span matSuffix *ngIf="(saveStatus$ | async) == 'saving'" class="saving-indicator"></span>
      <mat-hint *ngIf="field?.hint">{{field?.hint}}</mat-hint>
    </mat-form-field>
    
    <!--write-value — OT Rates-->
    <mat-form-field *ngSwitchCase="'otrates'" class="full-width" [ngStyle]="{'width': editWidth}">
      <mat-label *ngIf="field?.label">
        {{field?.label}}
      </mat-label>
      <input #inputTag matNativeControl formControlName="inp" appearance="outline" class="input-otrates">
      <span matSuffix *ngIf="suffix">{{suffix}}</span>
      <span matSuffix *ngIf="(saveStatus$ | async) == 'saving'" class="saving-indicator"></span>
      <mat-hint>{{field?.hint}}</mat-hint>
    </mat-form-field>


    <div *ngSwitchDefault class="unknown-input-type">
      Ismeretlen mezőtípus: {{ field?.type }}
    </div>


    <div class="danger-icon" *ngIf="(saveStatus$ | async) == 'save-error'" [title]="field?.isError | async">
      <img src="/assets/icons/icon-danger-24.svg"/>
    </div>


  </form>
  <app-popup-menu *ngIf="showMenu" (onCancelClicked)="cancel()" 
    [disabled]="(saveStatus$ | async) == 'saving'"
    [options]="field?.popup"></app-popup-menu>
</div>

