import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-company',
  templateUrl: './icon-company.component.html',
  styleUrls: ['./icon-company.component.scss']
})
export class IconCompanyComponent implements OnInit {
  @Input() color?: string = '#0094ff'
  @Input() width?: string = '24'
  @Input() height?: string = '20'
  
  constructor() { }

  ngOnInit(): void {
  }

}
