import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CellClickEvent, CellCloseEvent, GridDataResult, RemoveEvent } from '@progress/kendo-angular-grid';
import { Observable, Subject, combineLatest, firstValueFrom, lastValueFrom, map, takeUntil } from 'rxjs';
import { AppService } from '../../app.service';
import { DataService } from '../../data.service';
import { DbGroup, AppUser, Groups } from '../../models/db-user';
import { HandleUserGroupMembersParams, HandleUsersParameters } from '../../models/handle-users-parameters';
import { UserService } from '../../user.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Keys } from '@progress/kendo-angular-common';
import { StateService, getReorderedConfig, getResizedConfig } from '../../state.service';
import { ColumnReorderEvent, ColumnResizeArgs, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { T7eService } from '../../t7e/t7e.service';
import { ContractService } from '../../contract/contract.service';
import { FlexFieldsService } from '../../util/flex-fields/flex-fields.service';
import { config } from '../../config';
import { ProductionService } from '../../prod/production.service';
import { Contract } from '../../models/contract';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('grid') grid!: GridComponent
  @ViewChild('statusFilter') statusFilter!: DropDownListComponent

  private _destroy$ = new Subject<void>();
  
  allUsers: AppUser[] = []
  allUsers$ = this.userSvc.allUsers$
  allUsersLoading$ = this.userSvc.allUsersLoading$
  saving$ = this.userSvc.savingUser$
  saveError$ = this.userSvc.saveError$
  selectedUser$ = this.userSvc.selectedUser$
  getUserGroups = this.appSvc.getUserGroups()
  getRowClass = this.appSvc.getRowClass
  getContracts = this.contractSvc.getContractsByUser
  contractsLoading$ = this.contractSvc.allContractsLoading$
  
  readonly userStatusesForFilter: { text: string, value: number}[]
  readonly crewUserGroup = { "ugid": 1, "ugname": "CREW", "engugname": "CREW" }

  form = this.getFG()
  isUserEditorOpen = false

  constructor(
    private dataSvc: DataService,
    private appSvc: AppService,
    private userSvc: UserService,
    private contractSvc: ContractService,
    private notifSvc: NotificationService,
    private fb: FormBuilder,
    private stateSvc: StateService,
    private t7e: T7eService,
    private flexSvc: FlexFieldsService,
    private prodSvc: ProductionService,
  ) {
    this.appSvc.pageTitle$.next(this.t7ePageTitle)

    this.userStatusesForFilter = [
      { text: this.t7eStatusActive, value: 1 },
      { text: this.t7eStatusInactive, value: -1 },
      { text: this.t7eStatusPending, value: -2 },
    ]

    userSvc.allUsers$
      .pipe(takeUntil(this._destroy$))
      .subscribe(x => {
        this.filterUsersByStatus(this.statusFilterPreferenceOrDefault)
        //this.allUsers = x?.sort((a, b) => b.usid! - a.usid!) || []
      })
  }

  get debug() { return this.appSvc.debug }
  isDev = this.userSvc.isDev
  getPropValue = this.flexSvc.getValue
  getBoxTitle = this.flexSvc.getBoxTitle
  get statusFilterFC() { return this.form.get('status')! as FormControl }
  
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // set the statusFilter's value to the value in the state
    setTimeout(() => {
      this.statusFilter.value = this.statusFilterPreferenceOrDefault
      this.filterUsersByStatus(this.statusFilterPreferenceOrDefault)
    }, 0)
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  getUsersOtherGroups(user: AppUser): string[] {
    if (!user.ugm?.length) return []
    return user.ugm.filter(ugm => ugm.ugid!==1).map(ugm => ugm.ugname || 'Ismeretlen csoport')
  }

  hasRole(user: AppUser, ug: DbGroup): boolean {
    return user.ugm?.some(x => x.ugid == ug.ugid)
  }

  groupChanged(user: AppUser, ug: DbGroup): void {
    console.log('group changed', user, ug)
    if (!user?.usid) {
      throw 'null user érkezett'
    }
    const params: HandleUserGroupMembersParams = {
      _usid: user.usid,
      _ugid: ug.ugid,
    }
    const hNotifSave = this.notifSvc.addObservableNotif({ msg: this.t7eNotifSaving })
    this.dataSvc.handleUserGroupMembers(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
      next: response => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifSaved, class: 'success', duration: 1000 })
        const changedUser = this.userSvc.allUsers?.find(u => user.usid == u.usid)
        if (!response?.ugid) {
          changedUser?.ugm?.splice(changedUser.ugm.findIndex(x => x.ugid == ug.ugid), 1)
        } else {
          if (!changedUser?.ugm.length) {
            console.log('User registration just accepted')
            this.sendEmailRegAccepted(changedUser!)
          }
          changedUser?.ugm?.push(this.getUserGroups.find(x => x.ugid == ug.ugid)!)
        }
      },
      error: err => {
        console.error(err);
        this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifErrorSavingData, class: 'danger' })
      }
    })
  }

  sendEmailRegAccepted(user: AppUser): void {
    this.appSvc.sendMail({
      sendTo: user.email,
      subject: config.HU.USER.SEND_MAIL_FOR_REG_ACCEPTED.subject.replace('{PRODUCTION_TITLE}', this.prodSvc.prodDefaults?.prname || ''),
      body: config.HU.USER.SEND_MAIL_FOR_REG_ACCEPTED.body.replace('{USERNAME}', user.usname || 'stábtag'),
    })
  }

  disableUser(user: AppUser): void {
    this.modifUserStatus(user, -1)
  }
  enableUser(user: AppUser): void {
    this.modifUserStatus(user, 1)
  }
  modifUserStatus(user: AppUser, newStatus: -1 | 0 | 1): void {
    const params: HandleUsersParameters = {
      _usid: user.usid,
      _usstatus: newStatus
    }
    const hNotifSave = this.notifSvc.addObservableNotif({ msg: this.t7eNotifSaving })
    this.userSvc.handleUser(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
      next: (response) => {
        console.log('mentés utáni válasz', response)
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifSaved, class: 'success', duration: 1000 })
          this.filterUsersByStatus(this.statusFilterPreferenceOrDefault)
      },
      error: (err) => {
        console.error(err);
        this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifErrorSavingData, class: 'danger' })
      }
    })
  }

  removeUser(user: AppUser): void {
    if (!confirm(`${this.t7eConfirmDelete}\r\n${this.t7eName}: ${user.famname || 'n/a'} ${user.usnameOrig || "n/a"}\r\n${user.email}`)) return
    this.modifUserStatus(user, 0)
  }

  editUser(user: AppUser): void {
    this.userSvc.selectedUser$.next(user)
    this.isUserEditorOpen = true
  }

  close(): void {
    this.isUserEditorOpen = false
  }

  reload(): void {
    this.contractSvc.listContracts({})
      .subscribe({
        next: () => {
          this.userSvc.listUsers()
        },
        error: err => {
          console.error(err)
          this.userSvc.listUsers()
        }
    })
  }

  filterUsersByStatus(status: number | null): void {
    this.stateSvc.setManageUsersPageState({ filters: { status } })
    const allUsers = this.userSvc.allUsers$.value?.map(u => ({
      ...u,
      deptAndPosConcat: this.getContracts(u?.usid || -1).map(c => `${c.depname}$${c.role}`).join('$$')
    }))
    switch (status) {
      case 1:
        this.allUsers = allUsers
          ?.filter(x => x.ugm?.length)
          .filter(x => x.usstatus! > 0)
          .sort((a, b) => b.usid! - a.usid!)
          || []
        break;
      case -1: // all inactive users: no groups or status <= 0
        this.allUsers = allUsers
          ?.filter(x => !x.ugm?.length || !x.usstatus || x.usstatus < 0)
          .sort((a, b) => b.usid! - a.usid!)
          || []
        break;
      case -2: // pending prod mgr adding user to group
        this.allUsers = allUsers
          ?.filter(x => x.ugm?.filter(g => g.ugid).length === 0)
          .filter(this.contractSvc.hasBasicData)
          .sort((a, b) => b.usid! - a.usid!)
          || []
        break;
      default:
        this.allUsers = allUsers
          ?.sort((a, b) => b.usid! - a.usid!)
          || []
    }
  }

  /**
 * Szűrőkhöz készít egy FormGroup-ot
 * @returns 
 */
  getFG(): FormGroup {
    return this.fb.group({
      status: [this.filterSettings?.status || 1, Validators.required],
    })
  }

  filterContractNotDisabled(contract: Contract): boolean {
    return contract?.constatus! > 0
  }

  //
  // Felhasználói beállítások kezelése
  //

  get filterSettings() { return this.stateSvc.manageUsersPageState$?.value?.filters }
  get statusFilterPreferenceOrDefault() { return this.filterSettings?.status === undefined ? -2 : this.filterSettings?.status }
  get gridSettings() {
    return this.stateSvc.manageUsersPageState$.value?.grid
  }
  get skip() { return this.gridSettings?.state?.skip || 0 }
  get sort() { return this.gridSettings?.state?.sort || [] }
  get filter() { return this.gridSettings?.state?.filter || { filters: [], logic: 'and' } }
  
  get selectedUserIds() { return this.gridSettings?.selectedUserIds || [] }
  set selectedUserIds(value: number[]) {
    this.stateSvc.setManageUsersPageState({ grid: { selectedUserIds: value } })
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.stateSvc.setManageUsersPageState({ grid: { state } })
  }

  filterChanged(event: CompositeFilterDescriptor): void {
    this.stateSvc.setManageUsersPageState({
      grid: {
        state: { skip: 0 }
      }
    })
  }

  public onColReorder(e: ColumnReorderEvent): void {
    // set the new orderIndex to all affected columns
    const newColumnsConfig: any = getReorderedConfig(this.grid, e)
    this.stateSvc.setManageUsersPageState({ grid: { columnsConfig: newColumnsConfig } })
    console.log(this.stateSvc.manageUsersPageState$.value?.grid?.columnsConfig)
  }

  public onColResized(e: ColumnResizeArgs[]): void {
    const newConfigs = getResizedConfig(e)
    newConfigs.forEach((newConfigs) => {
      this.stateSvc.setManageUsersPageState({ grid: { columnsConfig: newConfigs } });
    })
  }

  getWidth(field: string): number | undefined {
    const columnsConfig = this.gridSettings?.columnsConfig || {}
    return columnsConfig[field]?.width
  }


  /** T7E */
  readonly compName = 'app-users'
  t7ePageTitle = this.t7e.getTranslation(this.compName, 'page-title', 'innerText', null, null, 'Felhasználók kezelése')
  t7eStatusActive = this.t7e.getTranslation(this.compName, 'status', 'active', null, null, 'Aktív')
  t7eStatusInactive = this.t7e.getTranslation(this.compName, 'status', 'inactive', null, null, 'Minden inaktív')
  t7eStatusPending = this.t7e.getTranslation(this.compName, 'status', 'pending', null, null, 'Jóváhagyásra vár')
  t7eNotifErrorSavingData = this.t7e.getTranslation(this.compName, 'notif', 'error-saving-data', null, null, 'Hiba történt mentés közben')
  t7eNotifSaving = this.t7e.getTranslation(this.compName, 'notif', 'saving', null, null, 'Mentés...')
  t7eNotifSaved = this.t7e.getTranslation(this.compName, 'notif', 'saved', null, null, 'Elmentve')
  t7eConfirmDelete = this.t7e.getTranslation(this.compName, 'confirm', 'delete', null, null, 'Biztosan törölni akarod?')
  t7eName = this.t7e.getTranslation(this.compName, 'name', 'innerText', null, null, 'Név')
}
