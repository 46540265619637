<kendo-formfield class="form-field" [formGroup]="formgroup!">
    <kendo-floatinglabel text="User">
        <kendo-dropdownlist #users class="ddUsers" [formControl]="userFC!" [data]="users$ | async" textField="usname"
            valueField="usid" [defaultItem]="{ usid: null, usname: 'all'|t7e:'Összes' }" name="users" [filterable]="true"
            (filterChange)="handleUserFilter($event)">
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div class="left-cell"></div>
                <div class="data">
                    <b class="usname">{{ dataItem.usname }}</b>
                    <p class="dep-role">{{ dataItem.email }}</p>
                </div>
            </ng-template>
        </kendo-dropdownlist>
    </kendo-floatinglabel>
</kendo-formfield>