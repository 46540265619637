import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { ProductionService } from '../production.service';
import { Holiday } from '../../models/dispo';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent implements OnInit, OnDestroy {
  @Input() holidaysFG!: FormGroup<HolidaysFG>

  _destroy$ = new Subject<void>()
  holidays$ = this.prodSvc.holidays$
  holidaysLoading$ = this.prodSvc.holidaysLoading$
  holidaysLoadingError$ = this.prodSvc.holidaysLoadingError$
  holidaySaving$ = this.prodSvc.daySaving$
  saveError$ = this.prodSvc.holidaySavingError$

  constructor(
    private prodSvc: ProductionService,
    private appSvc: AppService,
    private notifSvc: NotificationService,
    private fb: FormBuilder,
  ) { 
    if (!prodSvc.holidays$.value?.length) prodSvc.fetchHolidays()
  }

  ngOnInit(): void {
    if (!this.holidaysFG) throw new Error('HolidaysComponent: holidaysFG is required')
    if (!this.holidaysFG.get('savedHolidays')) throw new Error('HolidaysComponent: holidays is required')
    if (!this.holidaysFG.get('newHoliday')) throw new Error('HolidaysComponent: newHoliday is required')

    this.holidays$
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: holidays => {
          if (!holidays) return
          this.fillHolidaysFA(holidays)
        }
      })
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  get debug() { return this.appSvc.debug }
  get holidaysFA() { return this.holidaysFG.get('savedHolidays') as FormArray<FormGroup<HolidayFG>> }

  fillHolidaysFA(holidays: Holiday[]): void {
    this.holidaysFA.clear()
    setTimeout((holidays) => {
      holidays.forEach(holiday => {
        this.holidaysFA.push(this.getHolidayFG(holiday))
      })      
    }, 0, holidays);
  }

  getHolidayFG(holiday: Holiday): FormGroup<HolidayFG> {
    return this.fb.group({
      dayId: [holiday.dayId],
      day: [holiday.date, [Validators.required]],
    })
  }

  deleteHoliday(dayId: number): void {
    this.prodSvc.deleteHoliday(dayId)
  }

  saveHoliday(date: Holiday | null, e?: Event): void {
    e?.preventDefault()
    if (!date) return
    this.prodSvc.deleteHoliday(date.dayId!)
      .pipe(takeUntil(this._destroy$))
      .subscribe(x => {
        this.prodSvc.saveHoliday(date)
    })
  }

  addHoliday(e?: Event): void {
    e?.preventDefault()
    const date = this.holidaysFG.get('newHoliday')?.value
    if (!date) return
    
    this.prodSvc.saveHoliday({date: date, dayId: null})
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.holidaysFG.get('newHoliday')?.reset())
  }

  trackByIndex = this.appSvc.trackByIndex
}

export interface HolidaysFG {
  savedHolidays: FormArray<FormGroup<HolidayFG>>
  newHoliday: FormControl<Date | null>
}

export interface HolidayFG {
  dayId: FormControl<number | null>
  day: FormControl<Date | null>
}