import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.scss']
})
export class DevComponent implements OnInit {

  constructor(
    private appSvc: AppService,
  ) { }

  ngOnInit(): void {
  }

  impersonate(e?: Event) {
    const target = e?.target as HTMLInputElement;
    if (target && target.value) {
      this.appSvc.impersonate = target.value;
    } else {
      this.appSvc.impersonate = null;
    }
  }


}
