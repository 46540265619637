<div class="write-value-menu">
    <button mat-icon-button type="button" color="secondary" (click)="helpClicked($event)" *ngIf="options?.showHelp">
        <mat-icon class="icon">help_outline</mat-icon>
    </button>
    <button mat-icon-button type="button" color="secondary" (click)="historyClicked($event)" *ngIf="options?.showHistory">
        <mat-icon class="icon">history</mat-icon>
    </button>
    <button mat-icon-button type="button" color="secondary" (click)="cancel($event)">
        <mat-icon class="icon">close</mat-icon>
    </button>
</div>