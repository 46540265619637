<div *ngIf="ts?.tsid" class="grid" [class.zero]="classForHasInvContact">

    <div class="grid-item">
        <span t7e="daily-rate-label">Napidíj</span>
        <button type="button" mat-icon-button kendoPopoverAnchor [popover]="salaryReason" showOn="click">
            <mat-icon>help</mat-icon>
        </button>            
    </div>
    <div></div>
    <div class="grid-item" *ngIf="!hasInvoiceContact">
        <span class="amount" [class.zero]="!ts?.calc_salary" *ngIf="field=='calc'">
            {{ ts?.calc_salary || 0 | number }}
        </span>
        <span class="amount" [class.zero]="!ts?.salary" *ngIf="field=='saved'">
            {{ ts?.salary || 0 | number }}
        </span>
        <span class="currency" [class.zero]="field=='calc' ? !ts?.calc_salary : !ts?.salary">
            {{ ts?.currency }}
        </span>
    </div>

    <ng-container *ngIf="(calcPerdiem || perdiem) && !hasInvoiceContact">
        <div class="grid-item" t7e="perdiem-label">Per Diem</div>
        <div></div>
        <div class="grid-item">
            <span class="amount" [class.zero]="!calcPerdiem" *ngIf="field=='calc'">
                {{ calcPerdiem | number }}
            </span>
            <span class="amount" [class.zero]="!perdiem" *ngIf="field=='saved'">
                {{ perdiem | number }}
            </span>
            <span class="currency" [class.zero]="field=='calc' ? !calcPerdiem : !perdiem">
                {{ ts?.currency }}
            </span>
        </div>
    </ng-container>

    <div class="grid-item">OT
        <button type="button" mat-icon-button kendoPopoverAnchor [popover]="otReason" showOn="click">
            <mat-icon>help</mat-icon>
        </button>            
    </div>
    <div class="grid-item">
        <span class="amount" [class.zero]="!ts?.calc_othours" *ngIf="field=='calc'">
            {{ ts?.calc_othours || 0 | number:'1.0-2' }}
        </span>
        <span class="hours" [class.zero]="!ts?.calc_othours" *ngIf="field=='calc'">
            <span t7e="hours">óra</span>
        </span>
    </div>
    <div class="grid-item" *ngIf="!hasInvoiceContact">
        <span class="amount" [class.zero]="!ts?.calc_overtimefee" *ngIf="field=='calc'">
            {{ ts?.calc_overtimefee || 0 | number:'1.0-0' }}
        </span>
        <span class="amount" [class.zero]="!ts?.overtimefee " *ngIf="field=='saved'">
            {{ ts?.overtimefee || 0 | number:'1.0-0' }}
        </span>
        <span class="currency" [class.zero]="field=='calc' ? !ts?.calc_overtimefee : !ts?.overtimefee">
            {{ ts?.currency }}
        </span>
    </div>

    <div class="grid-item">TA
        <button type="button" mat-icon-button kendoPopoverAnchor [popover]="taReason" showOn="click">
            <mat-icon>help</mat-icon>
        </button>            
    </div>
    <div class="grid-item">
        <span class="amount" [class.zero]="!ts?.calc_tahours" *ngIf="field=='calc'">
            {{ ts?.calc_tahours || 0 | number:'1.0-2' }}
        </span>
        <span class="hours" [class.zero]="!ts?.calc_tahours" *ngIf="field=='calc'">
            <span t7e="hours">óra</span>
        </span>
    </div>
    <div class="grid-item" *ngIf="!hasInvoiceContact">
        <span class="amount" [class.zero]="!ts?.calc_tafee" *ngIf="field=='calc'">
            {{ ts?.calc_tafee || 0 | number:'1.0-0' }}
        </span>
        <span class="amount" [class.zero]="!ts?.tafee" *ngIf="field=='saved'">
            {{ ts?.tafee || 0 | number:'1.0-0' }}
        </span>
        <span class="currency" [class.zero]="field=='calc' ? !ts?.calc_tafee : !ts?.tafee">
            {{ ts?.currency }}
        </span>
    </div>

    <ng-container *ngIf="DISPLAY_KM_PARKING_VIGN">
        <div class="grid-item" t7e="km-fee">km díj</div>
        <!-- Ezt invoice contacttal is mutatni kell, ezért nekik az üres oszlopot rejtem el, hogy ne csússzon szét a táblázat -->
        <div class="grid-item" *ngIf="!hasInvoiceContact">
        </div>
        <div class="grid-item">
            <span class="amount" [class.zero]="!ts?.distandpark">
                {{ ts?.distandpark || 0 | number:'1.0-0' }}
            </span>
            <span class="currency" [class.zero]="!ts?.distandpark">
                {{ ts?.currency }}
            </span>
        </div>

        <div class="grid-item" t7e="parking">Parkolás</div>
        <!-- Ezt invoice contacttal is mutatni kell, ezért nekik az üres oszlopot rejtem el, hogy ne csússzon szét a táblázat -->
        <div class="grid-item" *ngIf="!hasInvoiceContact">
        </div>
        <div class="grid-item">
            <span class="amount" [class.zero]="!ts?.park">
                {{ ts?.park || 0 | number:'1.0-0' }}
            </span>
            <span class="currency" [class.zero]="!ts?.park">
                {{ ts?.currency }}
            </span>
        </div>

        <div class="grid-item" t7e="vignette">Matrica</div>
        <!-- Ezt invoice contacttal is mutatni kell, ezért nekik az üres oszlopot rejtem el, hogy ne csússzon szét a táblázat -->
        <div class="grid-item" *ngIf="!hasInvoiceContact">
        </div>
        <div class="grid-item">
            <span class="amount" [class.zero]="!ts?.vignette">
                {{ ts?.vignette || 0 | number:'1.0-0' }}
            </span>
            <span class="currency" [class.zero]="!ts?.vignette">
                {{ ts?.currency }}
            </span>
        </div>
    </ng-container>

    <ng-container *ngIf="hasMealPenalty">
        <div class="grid-item">MP</div>
        <div class="grid-item">
            <span class="amount" [class.zero]="!ts?.f_mealPenaltyHours">
                {{ ts?.f_mealPenaltyHours || 0 | number:'1.0-2' }}
            </span>
            <span class="hours" [class.zero]="!ts?.f_mealPenaltyHours">
                <span t7e="hours">óra</span>
            </span>
        </div>
        <div class="grid-item" *ngIf="!hasInvoiceContact">
            <span class="amount" [class.zero]="!ts?.f_mealPenalty">
                {{ ts?.f_mealPenalty || 0 | number:'1.0-0' }}
            </span>
            <span class="currency" [class.zero]="!ts?.f_mealPenalty">
                {{ ts?.currency }}
            </span>
        </div>
    </ng-container>

    <ng-container *ngIf="dailyRentals">
        <div class="grid-item" t7e="daily-rentals">Napi bérlet</div>
        <div class="grid-item">
            <span class="amount">
                {{ dailyRentalsCount | number:'1.0-2' }}
            </span>
            <span class="hours">
                <span t7e="db">db</span>
            </span>
        </div>
        <div class="grid-item" *ngIf="!hasInvoiceContact">
            <span class="amount">
                {{ dailyRentals || 0 | number:'1.0-0' }}
            </span>
            <span class="currency">
                {{ ts?.currency }}
            </span>
        </div>
    </ng-container>
    
    <ng-container *ngIf="monthlyRentals">
        <div class="grid-item" t7e="monthly-rentals">Havi bérlet</div>
        <div class="grid-item">
            <span class="amount">
                {{ monthlyRentalsCount | number:'1.0-2' }}
            </span>
            <span class="hours">
                <span t7e="db">db</span>
            </span>
        </div>
        <div class="grid-item" *ngIf="!hasInvoiceContact">
            <span class="amount">
                {{ monthlyRentals || 0 | number:'1.0-0' }}
            </span>
            <span class="currency">
                {{ ts?.currency }}
            </span>
        </div>
    </ng-container>

    <div class="grid-item span-two" t7e="other-allowance-label" *ngIf="!hasInvoiceContact">
        Szerződésen felüli
    </div>
    <div class="grid-item" *ngIf="!hasInvoiceContact">
        <span class="amount" [class.zero]="!ts?.otherfee">
            {{ ts?.otherfee || 0 | number }}
        </span>
        <span class="hours" [class.zero]="!ts?.otherfee">
            {{ ts?.currency }}
        </span>
    </div>

</div>


<kendo-popover #salaryReason [position]="'top'">
    <ng-template kendoPopoverTitleTemplate>
        <div class="pop-salaryreason title" t7e="calc-details">
            Hogy jött ki?
        </div>
    </ng-template>
    <ng-template kendoPopoverBodyTemplate="">
        <div class="pop-salaryreason body">
            <app-salary-reasons [tsid]="ts?.tsid || undefined"
                *ngIf="ts?.tsid; else noSelectedSalaryReason"
                [showSalary]="true"
                [showOt]="false"
                [showTa]="false"></app-salary-reasons>
            <ng-template #noSelectedSalaryReason>
                <div class="error">Error</div>
            </ng-template>
        </div>
    </ng-template>
</kendo-popover>
<kendo-popover #otReason [position]="'top'">
    <ng-template kendoPopoverTitleTemplate>
        <div class="pop-salaryreason title" t7e="calc-details">
            Hogy jött ki?
        </div>
    </ng-template>
    <ng-template kendoPopoverBodyTemplate="">
        <div class="pop-salaryreason body">
            <app-salary-reasons [tsid]="ts?.tsid || undefined"
                *ngIf="ts?.tsid; else noSelectedSalaryReason"
                [showSalary]="false"
                [showOt]="true"
                [showTa]="false"></app-salary-reasons>
            <ng-template #noSelectedSalaryReason>
                <div class="error">Error</div>
            </ng-template>
        </div>
    </ng-template>
</kendo-popover>
<kendo-popover #taReason [position]="'top'">
    <ng-template kendoPopoverTitleTemplate>
        <div class="pop-salaryreason title" t7e="calc-details">
            Hogy jött ki?
        </div>
    </ng-template>
    <ng-template kendoPopoverBodyTemplate="">
        <div class="pop-salaryreason body">
            <app-salary-reasons [tsid]="ts?.tsid || undefined"
                *ngIf="ts?.tsid; else noSelectedSalaryReason"
                [showSalary]="false"
                [showOt]="false"
                [showTa]="true"></app-salary-reasons>
            <ng-template #noSelectedSalaryReason>
                <div class="error">Error</div>
            </ng-template>
        </div>
    </ng-template>
</kendo-popover>