import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss']
})
export class PopupMenuComponent implements OnInit {
  @Input() disabled?: boolean
  @Input() options?: {
    showHelp?: boolean
    showHistory?: boolean
  }
  @Output() onCancelClicked = new EventEmitter<void>()
  @Output() onHistoryClicked = new EventEmitter<void>()
  @Output() onHelpClicked = new EventEmitter<void>()
  constructor() { }

  ngOnInit(): void {
    console.assert(this.disabled != null)
  }

  cancel(e: MouseEvent): void {
    e?.stopPropagation()
    this.onCancelClicked.emit()
  }

  historyClicked(e: MouseEvent): void {
    e?.stopPropagation()
    this.onHistoryClicked.emit()
  }

  helpClicked(e: MouseEvent): void {
    e?.stopPropagation()
    this.onHelpClicked.emit()
  }
}
