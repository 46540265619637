import { JsonPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { BehaviorSubject, filter, map, Observable, Subject, Subscription, take, takeUntil } from 'rxjs';
import { AppService } from '../../app.service';
import { FlexTables } from '../../data.service';
import { Groups } from '../../models/db-user';
import { HandleFlexPropertiesParams, HandleFlexTablePropertiesParams } from '../../models/handle-table-properties-params';
import { FlexProp, FlexTableProperty } from '../../models/table-property';
import { UserService } from '../../user.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { DialogData, EditFlexFieldsFormComponent } from './edit-flex-fields-form/edit-flex-fields-form.component';
import { EditPropFormComponent } from './edit-prop-form/edit-prop-form.component';
import { ManagePropertiesComponent } from './manage-properties/manage-properties.component';
import { EditPropitemsComponent } from '../edit-propitems/edit-propitems.component';

@Component({
  selector: 'app-edit-contract-fields',
  templateUrl: './edit-contract-fields.component.html',
  styleUrls: ['./edit-contract-fields.component.scss']
})
export class EditContractFieldsComponent implements OnInit, OnDestroy {

  @Input() tableId: number = FlexTables.users

  private _destroy$ = new Subject<void>();

  tableProperties$ = this.appSvc.tableProperties$
  saveTablePropertiesError$ = this.appSvc.saveTablePropertiesError$
  isDeveloper: boolean = true || !!this.userSvc.loggedinUser?.ugm.find(g => g.ugid == Groups.DEV) // TODO dinamikusan kell meghatározni
  flexTables$ = this.appSvc.flexTables$
  flexPropsFiltered$ = this.appSvc.flexProps$//.pipe(map(p => p.filter(p2 => p2.propname.includes(this.propFilter))))
  flexEditTypes$ = this.appSvc.flexEditTypes$
  isNewProp: boolean = false
  subSaveSuccess?: Subscription;
  subSaveError?: Subscription;


  //propFilter: string = ''

  constructor(
    private appSvc: AppService,
    private userSvc: UserService,
    private notifSvc: NotificationService,
    private dialog: MatDialog,
  ) {
    this.onSaveEditor = this.onSaveEditor.bind(this)
    this.onCloseEditor = this.onCloseEditor.bind(this)
    this.saveProperty = this.saveProperty.bind(this)

    this.appSvc.pageTitle$.next('Egyedi mezők szerkesztése')

    appSvc.saveFlexPropertiesError$
      .pipe(filter(x => !!x), takeUntil(this._destroy$))
      .subscribe({
        next: (err) => {
          this.notifSvc.addObservableNotif({ msg: 'Saving failed', class: 'danger' })
          console.error(err);
        }
      })
  }

  get isProd() {return this.userSvc.isProdMgr || this.userSvc.isFinance}
  get isDev() { return this.userSvc.isDev}

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  ngOnInit(): void {
  }

  addPropClicked(): void {
    this.dialog.open(ManagePropertiesComponent)
  }


  removeHandler({ dataItem, isNew, rowIndex, sender }: RemoveEvent): void {
    const tablProps = {
      _tpid: dataItem.tpid,
      _tpstatus: 0,
    } as HandleFlexTablePropertiesParams
    this.appSvc.modifyAndSaveTableProperties(tablProps)
  }

  public editHandler(event: EditEvent): void {
    this.dialog.open(EditFlexFieldsFormComponent, {
      data: {
        dataItem: event.dataItem,
        isNew: false,
        onClose: this.onCloseEditor,
        onSave: this.onSaveEditor,

      } as DialogData
    })
  }
  addHandler(event: AddEvent): void {
    this.dialog.open(EditFlexFieldsFormComponent, {
      data: {
        dataItem: event.dataItem,
        isNew: true,
        onClose: this.onCloseEditor,
        onSave: this.onSaveEditor,

      } as DialogData
    })

  }

  addProp(event: AddEvent): void {
    this.dialog.open(EditPropFormComponent, {
      data: {
        dataItem: event.dataItem,
        isNew: true,
        onClose: this.onCloseEditor,
        onSave: this.saveProperty,

      } as DialogData
    })


  }

  removeProp({ dataItem, isNew, rowIndex, sender }: RemoveEvent): void { 

  }
  
  editProp(event: EditEvent): void {
    this.dialog.open(EditPropFormComponent, {
      data: {
        dataItem: event.dataItem,
        isNew: false,
        onClose: this.onCloseEditor,
        onSave: this.saveProperty,

      } as DialogData
    })
  }


  addPropItemClicked(): void {
    this.dialog.open(EditPropitemsComponent, )
  }

  onCloseEditor(): void {
    this.dialog.closeAll()
  }

  onSaveEditor(dataItem: FlexTableProperty): void {
    const hNotifSave = this.notifSvc.addObservableNotif({ msg: 'Mentés...' })
    this.subSaveSuccess = this.appSvc.flexProps$
      .pipe(
        takeUntil(this._destroy$),
        filter(props => !!props?.find(p => p.propname == dataItem.propname)),
      )
      .subscribe({
        next: (props) => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: 'Elmentve', class: 'success', duration: 1000 })
          this.subSaveError?.unsubscribe()
          const propid = props?.find(p => p.propname == dataItem.propname)!.propid
          if (!propid) {
            this.appSvc.saveFlexPropertiesError$.next('A mentett tulajdonság azonosítója null: ' + dataItem.propname)
            return
          }
          dataItem.propid = propid
          this.saveFlexTableRecord(dataItem)
          this.dialog.closeAll()
        },
        error: err => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: 'Mentés nem sikerült. Hiba történt', class: 'danger' })

        }
      })
    this.subSaveError = this.appSvc.saveFlexPropertiesError$
      .pipe(
        takeUntil(this._destroy$),
        filter(x => typeof x === 'string')
      ).subscribe({
        next: (err) => {
          this.subSaveSuccess?.unsubscribe()
        }
      })
  }

  private saveFlexTableRecord(dataItem: FlexTableProperty): void {
    let params: HandleFlexTablePropertiesParams = {
      _tpid: dataItem.tpid,
      _prid: 1,
      _tableid: dataItem.tableid,
      _propid: dataItem.propid,
      _optional: dataItem.optional ? 1 : 0,
      _ord: dataItem.ord,
    }
    this.appSvc.modifyAndSaveTableProperties(params)
  }

  private saveProperty(prop: FlexProp): void {
    const propcode = prop.propcode
      || this.appSvc.normalizeString(prop.propname)
      .replace(/\s/g, '')   // minden szóközt törlök
        .replace(/\W+/g, '')  // minden nem-alfanumerikus char-t törlök
    // a combo-box a begépelt szöveget a prodid-be teszi. Törölni kell
    if (isNaN(prop.propid as number)) prop.propid = null
    let params: HandleFlexPropertiesParams = {
      _propid: prop.propid || null,
      _propname: prop.propname,
      _engpropname: prop.engpropname,
      _propcode: propcode,
      _edittype: prop.edittype || null,
      _level: prop.level,
      _prid: 1,
    }
    this.appSvc.modifyAndSaveFlexProperties(params)
  }

  public removeConfirmation(dataItem: FlexTableProperty | null = null): boolean {
    return confirm('Tényleg eltávolítod?')
  }

  getTableName(tableid: number): string {
    return this.appSvc.flexTables.find(t => t.id == tableid)?.value || 'n/a'

  }

  getEditLevel(level: number | null): string {
    //@ts-ignore
    return this.appSvc.getEditLevels().find(l => l.id == level)?.value || 'n/a'
  }

}
