import { Moment } from "moment-timezone";
import { SqlDataRow } from "../data.service";
import { AppUser } from "./db-user";
import { PropItem } from "./table-property";

export interface Contract extends SqlDataRow {
    "conid": number | null
    "usid"?: number | null
    "usname"?: string | null
    "email"?: string | null
    "phonenumber"?: string | null
    "depid"?: number | null
    "depname"?: string | null
    "role"?: string | null

    "code_salary"?: string | null
    "code_otta"?: string | null
    "code_gas"?: string | null
    "code_park"?: string | null
    "code_vignette"?: string | null
    "code_perdiem"?: string | null
    "code_other"?: string | null
    "doclink"?: string | null     // json string: {fileid: string, ts: Date, user: emailcím, log: string}[]
    "personal"?: number | null
    "entryon"?: Date | null
    "contractfee"?: number | null   // egyszeri díj, nem kötelező
    "startdate"?: string | null       
    "enddate"?: string | null 
    "modusid"?: number | null
    dailyrate?: number | null
    preprodrate?: number | null

    /** string: a picode */
    f_contract_type?: string | null
    f_companyType?: string | null // a string egy számot tartalmaz, ami a piid
    
    f_companyname?: string | null

    f_graceperiod?: number | null
    f_otrate?: string[] | null
    f_sleepoverrate?: number | null
    f_workhours?: number | null
    f_overtimestep?: number | null
    /** Effective (örökölt vagy mentett) TA rate */
    f_tarate?: number | null
    f_shortresthours?: number | null
    f_longresthours?: number | null
    f_mealPenaltyRate?: number | null
    f_currency?: string | null

    f_saved_graceperiod?: number | null
    f_saved_otrate?: string[] | null
    f_saved_sleepoverrate?: number | null
    f_saved_workhours?: number | null
    f_saved_overtimestep?: number | null
    f_saved_tarate?: number | null
    f_saved_shortresthours?: number | null
    f_saved_longresthours?: number | null
    f_saved_mealPenaltyRate?: number | null
    f_saved_currency?: string | null

    f_invoice_contact?: string | null

    f_dailybasedrentalrate1?: number | null
    f_dailybasedrentalrate2?: number | null
    f_dailybasedrentalrate3?: number | null
    f_dailybasedrentalrate4?: number | null
    f_dailybasedrentalrate5?: number | null
    f_dailybasedrentalrate6?: number | null
    f_monthlybasedrentalrate1?: number | null
    f_monthlybasedrentalrate2?: number | null
    f_monthlybasedrentalrate3?: number | null
    f_dailybasedrentalrate1code?: string | null
    f_dailybasedrentalrate2code?: string | null
    f_dailybasedrentalrate3code?: string | null
    f_dailybasedrentalrate4code?: string | null
    f_dailybasedrentalrate5code?: string | null
    f_dailybasedrentalrate6code?: string | null
    f_monthlybasedrentalrate1code?: string | null
    f_monthlybasedrentalrate2code?: string | null
    f_monthlybasedrentalrate3code?: string | null
    f_dailybasedrentalrate1name?: string | null
    f_dailybasedrentalrate2name?: string | null
    f_dailybasedrentalrate3name?: string | null
    f_dailybasedrentalrate4name?: string | null
    f_dailybasedrentalrate5name?: string | null
    f_dailybasedrentalrate6name?: string | null
    f_monthlybasedrentalrate1name?: string | null
    f_monthlybasedrentalrate2name?: string | null
    f_monthlybasedrentalrate3name?: string | null
    f_dailybasedrental1type?: string | null
    f_dailybasedrental2type?: string | null
    f_dailybasedrental3type?: string | null
    f_dailybasedrental4type?: string | null
    f_dailybasedrental5type?: string | null
    f_dailybasedrental6type?: string | null
    f_monthlybasedrental1type?: string | null
    f_monthlybasedrental2type?: string | null
    f_monthlybasedrental3type?: string | null

    f_folderid?: string | null

    "constatus"?: ContractStatus | null
    "constatuscode"?: string | null
    "constatusname"?: string | null
    f_lastinvoicedday?: string | null

    savecomment?: string | null
}

export interface ContractParsed extends Contract {
    //otrates: number[]
    arrDoclink: DocLink[] | null
    dStartdate: Date | null
    dEnddate: Date | null
    dEntryon: Date | null
    contractTypeAsPropItem: PropItem | null
    companyTypeAsPropItem: PropItem | null
    effectiveOtRates: number[] | string[] | null
    lastWtsDay: Moment | null
}

export enum ContractStatus {
    disabled = -1,
    deleted = 0,
    crewMember = 11,
    deptHead = 16,
    prodMgr = 12,
    prodMgrApproved = 15,
    financeApproved = 17,
    readyToSign = 13,
    signed = 14,
}

export type DocLink = {
    fileid: string | null,
    pdfid: string | null,
    folderid?: string | null,
    ts: Date,
    usemail: string,  //email
    log: string | null,
    wtsStartDate?: Date | null,
    wtsEndDate?: Date | null,
}