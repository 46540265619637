<div class="full-page-overlay" *ngIf="saving$ | async">
    <app-page-loading [showText]="false"></app-page-loading>
</div>

<ul class="langs">
    <li *ngFor="let lang of langs" class="lang"
        (click)="selectLang(lang)">
        <div class="selector-wrapper" [class.selected]="isSelectedLang(lang)">
            <div class="selector">
                <mat-icon *ngIf="isSelectedLang(lang); else notSelectedLang">check_circle_outline</mat-icon>
                <ng-template #notSelectedLang>
                    <mat-icon>radio_button_unchecked</mat-icon>
                </ng-template>
            </div>
            <div class="selectable-value">
                {{ lang }}
            </div>
        </div>
        <!-- {{ pos | json }} -->
    </li>
</ul>