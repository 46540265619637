import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { UserService } from '../../user.service';
import { CrewListItem } from '../../models/crew-list';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-crew-list',
  templateUrl: './crew-list.component.html',
  styleUrls: ['./crew-list.component.scss']
})
export class CrewListComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject<void>()
  loading$ = this.userSvc.crewListLoading$
  searchString$ = new BehaviorSubject<string | null>(null)
  form = this.getFG()

  constructor(
    private userSvc: UserService,
    private fb: FormBuilder,
  ) {
    this.userSvc.listCrew()
    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        map(form => form.search?.toLocaleLowerCase() || null)
      )
      .subscribe(this.searchString$)
  }

  crewList$ = combineLatest([this.userSvc.crewList$, this.searchString$])
    .pipe(
      takeUntil(this._destroy$),
      map(([crewList, searchString]) => {
        return crewList?.filter(c => {
          if (!searchString) return true
          const s = `${c.famname?.toLocaleLowerCase()}$$$${c.usname?.toLocaleLowerCase()}$$$${c.email?.toLocaleLowerCase()}$$$${c.phonenumber?.toLocaleLowerCase() }`
          if (s.includes(searchString)) return true
          if (c.details?.some(d => d.depname?.toLocaleLowerCase().includes(searchString) || d.role?.toLocaleLowerCase().includes(searchString))) return true
          return false
        })
      })

  )
    
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  getDepts(user: CrewListItem): { depid: number, depname: string, role: string }[] {
    return user.details || []
  }

  refresh(): void {
    this.userSvc.listCrew()
  }

  getFG(): FormGroup<CrewListFG> {
    return this.fb.group<CrewListFG>({
      search: new FormControl<string | null>(this.searchString$.value),
    })
  }
}

type CrewListFG = {
  search: FormControl<string | null>
}