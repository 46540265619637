<ng-container [formGroup]="locationsFG">
    <div class="locations-inputs" formArrayName="savedLocations">
        <div *ngIf="debug">{{ locationsFG.value | json }}</div>
        <mat-spinner [diameter]="15" *ngIf="locationsLoading$ | async"></mat-spinner>
        <div class="location" *ngFor="let location of locationsFA.controls; let i=index; trackBy:trackByIndex" [formGroupName]="i">
            <div class="name">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Helyszín</mat-label>
                    <input matInput formControlName="locationName" type="text" (keydown.enter)="saveLocations(location.value, $event)">
                </mat-form-field>
            </div>
            <div class="km">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>km</mat-label>
                    <input matInput formControlName="km" type="number" min="0" step="1" (keydown.enter)="saveLocations(location.value, $event)">
                </mat-form-field>
            </div>
            <div class="km-fee">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>km díj</mat-label>
                    <input matInput formControlName="kmFee" type="number" min="0" step="1000" (keydown.enter)="saveLocations(location.value, $event)">
                    <span matSuffix> HUF</span>
                </mat-form-field>
            </div>
            <button mat-icon-button (click)="disableLocation(location.value)" [disabled]="locationSaving$ | async" type="button"
                *ngIf="location.value.enabled">
                <mat-icon>remove_circle_outline</mat-icon>
            </button>
            <button mat-icon-button (click)="enableLocation(location.value)" [disabled]="locationSaving$ | async" type="button"
                *ngIf="!location.value.enabled">
                <mat-icon>check_outline</mat-icon>
            </button>
            <button type="button" (click)="saveLocations(location.value)" mat-raised-button color="primary"
                *ngIf="!(locationsLoading$ | async) && (locations$ | async)?.length && location.value.enabled" 
                [disabled]="locationSaving$ | async">
                <mat-spinner [diameter]="15" *ngIf="locationSaving$ | async"></mat-spinner>
                <span t7e="save">Mentés</span>
            </button>
        </div>
    
    
    </div>
    
    <div class="locations-new" *ngIf="!(locationsLoading$ | async)">
        <!-- new location -->
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Új helyszín</mat-label>
            <input matInput formControlName="newLocation" type="text" (keydown.enter)="addLocation($event)">
        </mat-form-field>
        <!-- new km -->
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>km</mat-label>
            <input matInput formControlName="newKm" type="number" min="0" step="1" (keydown.enter)="addLocation($event)">
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>HUF / km</mat-label>
            <input matInput formControlName="hufPerKm" type="number" [min]="0" [step]="5">
        </mat-form-field>
        <button mat-raised-button (click)="calcKmFee()"><mat-icon>keyboard_arrow_right</mat-icon></button>
        <!-- new km fee -->
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>km díj</mat-label>
            <input matInput formControlName="newKmFee" type="number" min="0" step="0.01" (keydown.enter)="addLocation($event)">
            <span matSuffix> HUF</span>
        </mat-form-field>
    
        <button type="button" (click)="addLocation()" mat-raised-button color="primary"
            [disabled]="(locationSaving$ | async) || locationsFG.invalid">
            <mat-spinner [diameter]="15" *ngIf="locationSaving$ | async"></mat-spinner>
            <span t7e="save">Mentés</span>
        </button>
    </div>
    
    <div class="save-load-error mb-2" *ngIf="((saveError$ | async) || (locationsLoadingError$ | async)) as error">
        <span>{{ error }}</span>
    </div>
</ng-container>
