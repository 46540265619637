import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, distinctUntilKeyChanged, filter, firstValueFrom, map, mergeMap, Observable, tap } from 'rxjs';
import { AppService, ButtonStatus, DbCallError } from '../app.service';
import { DataService, DocType, FileUploadResponse, FlexTables, GenerateDocParams, PrintdocResponse } from '../data.service';
import { Contract, ContractParsed, ContractStatus, DocLink } from '../models/contract';
import { HandleContractsParams, ListContractsParams } from '../models/handle-contracts-params';
import { requiredForForeignCrew, UserService } from '../user.service';
import { DeptService } from '../dept/dept.service';
import { ProductionService } from '../prod/production.service';
import { PropItem, Props } from '../models/table-property';
import { AppUser, Groups } from '../models/db-user';
import { T7eService } from '../t7e/t7e.service';
import { ParserService } from '../parser.service';
import { Department } from '../models/department';
import * as moment from "moment-timezone";
import { SendMailParams } from '../models/mail';
import { config } from '../config';
import { NotificationService } from '../util/notifications/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  readonly REQUIRED_FIELDS_TO_SUBMIT = ['depid', 'role', 'startdate', 'contract_type', 'consentHealthAndSafety', 'consentElectronicData', 'consentDataProcessing']
  readonly FOREIGN_CONTRACT_TYPE_CODE = 'non_hun'
  readonly MEAL_PENALTY = config.HU.TS.MEAL_PENALTY
    
  /** Logged in user's contracts */
  public userContracts$ = new BehaviorSubject<ContractParsed[]>([])
  get userContracts() { return this.userContracts$.value }

  public selectedContract$ = new BehaviorSubject<ContractParsed | null>(null)
  get selectedContract() { return this.selectedContract$.value }

  get contractStatus() { return this.selectedContract?.constatus || null }
  contractStatus$ = new BehaviorSubject(this.selectedContract$.pipe(map(c => c?.constatus || null)) )

  allContracts$ = new BehaviorSubject<ContractParsed[] | null>(null)
  _allContracts: ContractParsed[] | null = null
  get allContracts() { return this._allContracts }
  allContractsLoading$ = new BehaviorSubject<boolean>(true)
  deptContracts$ = new BehaviorSubject<ContractParsed[]>([])
  financeApprovedContracts$ = this.allContracts$.pipe(
    map(c => c?.filter(c => c?.constatus == ContractStatus.financeApproved)))
  finalApprovedContracts$ = this.allContracts$.pipe(
    map(c => c?.filter(c => this.isFinanceApprovalNeededConfig
      ? c?.constatus == ContractStatus.financeApproved
      : c?.constatus == ContractStatus.prodMgrApproved)))
  financePendingContracts$ = this.allContracts$.pipe(
    map(c => c?.filter(c => c?.constatus == ContractStatus.prodMgrApproved)))
  prodMgrPendingContracts$ = this.allContracts$.pipe(
    map(c => c?.filter(c => c?.constatus == ContractStatus.prodMgr )))
  readyToSignContracts$ = this.allContracts$.pipe(
    map(c => c?.filter(c => c?.constatus == ContractStatus.readyToSign )))
  atCrewContracts$ = this.allContracts$.pipe(
    map(c => c?.filter(c => c?.constatus == ContractStatus.crewMember )))
  atCrewOwnContracts$ = this.atCrewContracts$.pipe(
    map(c => {
      return c?.filter(c => this.isOwnContract(c) )
    })
  )
  /** Kiolvassa az adatbázisból (a property itemet), hogy engedélyezve van-e a financeApproved státusz. Ebből lehet tudni, hogy akarják-e használni ezt a funkciót */
  isFinanceApprovalNeededConfig$ = new BehaviorSubject<boolean>(false)
  get isFinanceApprovalNeededConfig() { return this.isFinanceApprovalNeededConfig$.value }
  usersInLoggedInUsersDept$ = new BehaviorSubject<AppUser[] | null>(null)
  loggedUserHasBasicData$ = new BehaviorSubject<boolean>(false)

  saveError$ = new BehaviorSubject<DbCallError>(null)
  saveStatusError$ = new BehaviorSubject<DbCallError>(null)
  savingContract$ = new BehaviorSubject<boolean>(false)
  loadContractError$ = new BehaviorSubject<DbCallError>(null)

  prodDefaultCurrency = this.prodSvc.prodDefaults?.currency
  todaysCurrencyExchangeRate$ = this.prodSvc.todaysCurrencyExchangeRate$
  todaysCurrencyExchangeRateLoading$ = this.prodSvc.todaysCurrencyExchangeRateLoading$
  todaysCurrencyExchangeRateError$ = this.prodSvc.todaysCurrencyExchangeRateError$

  get hasSubmittedContract() {
    return !!this.userContracts$.value?.find(c => c.constatus != ContractStatus.crewMember)
  }

  constructor(private userSvc: UserService,
    private dataSvc: DataService,
    private appSvc: AppService,
    private deptSvc: DeptService,
    private prodSvc: ProductionService,
    private t7e: T7eService,
    private parser: ParserService,
    private notifSvc: NotificationService,
  ) {
    this.parseContracts = this.parseContracts.bind(this)
    this.parseContract = this.parseContract.bind(this)
    this.getContractsByUser = this.getContractsByUser.bind(this)
    this.getContractById = this.getContractById.bind(this)
    this.getDepartmentNamesByUser = this.getDepartmentNamesByUser.bind(this)
    this.getRolesByUser = this.getRolesByUser.bind(this)
    this.getCompaniesByUser = this.getCompaniesByUser.bind(this)
    this.isOwnContract = this.isOwnContract.bind(this)
    this.canProdMgrApprove = this.canProdMgrApprove.bind(this)
    this.canFinanceApprove = this.canFinanceApprove.bind(this)
    this.canSetAtProdMgr = this.canSetAtProdMgr.bind(this)
    this.canSendBackToCrew = this.canSendBackToCrew.bind(this)
    this.canSetReadyToSign = this.canSetReadyToSign.bind(this)
    this.canSetSigned = this.canSetSigned.bind(this)
    this.canDelete = this.canDelete.bind(this)
    this.canDisable = this.canDisable.bind(this)
    this.isAtProdMgr = this.isAtProdMgr.bind(this)
    this.isApproved = this.isApproved.bind(this)
    this.isFinanceApproved = this.isFinanceApproved.bind(this)
    this.isReadyToSign = this.isReadyToSign.bind(this)
    this.isSigned = this.isSigned.bind(this)
    this.handleContracts = this.handleContracts.bind(this)
    this.getStatusName = this.getStatusName.bind(this)
    this.hasDeptHead = this.hasDeptHead.bind(this)
    this.isDeptAdminOf = this.isDeptAdminOf.bind(this)
    this.hasBasicData = this.hasBasicData.bind(this)
    this.getFirstValuableOtRateByContract = this.getFirstValuableOtRateByContract.bind(this)
    this.isApprovedOrAfter = this.isApprovedOrAfter.bind(this)
    this.isFinanceApprovedOrAfter = this.isFinanceApprovedOrAfter.bind(this)
    this.isConsideredFinanceApprovedOrAfter = this.isConsideredFinanceApprovedOrAfter.bind(this)
    this.sameCompanyOrEkho = this.sameCompanyOrEkho.bind(this)
    this.getContractTypeAsPropItem = this.getContractTypeAsPropItem.bind(this)
    this.hasMealPenalty_setAtProdDeptSf = this.hasMealPenalty_setAtProdDeptSf.bind(this)
    this.getMealPenaltyRate_setAtProdDeptSf = this.getMealPenaltyRate_setAtProdDeptSf.bind(this)
    this.getMailParamsForFinanceNotifNewPosition = this.getMailParamsForFinanceNotifNewPosition.bind(this)
    this.getMailParamsForApproved = this.getMailParamsForApproved.bind(this)
    this.getMailParamsForSendBackToCrew = this.getMailParamsForSendBackToCrew.bind(this)

    combineLatest([this.userSvc.loggedinUser$, this.allContracts$])
      .pipe(
        filter(([u, c]) => !!u && !!c?.length),
        map(([u, c]) => c?.filter(c => c?.usid == u?.usid) || []),
        map(c => c?.filter(c => c?.constatus! > 0) || []),
        //tap(contracts => {console.log('contracts', contracts)}),
      )
      .subscribe(this.userContracts$)
    
    this.userContracts$
      .pipe(
        map(c => userSvc.getUserById(c?.[0]?.usid)),
        map(this.hasBasicData)
      )
      .subscribe(this.loggedUserHasBasicData$)

    this.allContracts$
      .pipe(
        map(c => {
          const userDepts = this.userContracts$.value?.map(c => c.depid) || []
          return c?.filter(c => userDepts.includes(c.depid)) || []
        }),
      )
      .subscribe(this.deptContracts$)

    dataSvc.initData$.pipe(
      filter(appData => !!appData?.data?.list_propertyitems && Array.isArray(appData.data.list_propertyitems)),
      map(appData => appData!.data.list_propertyitems!.find(pi => pi.piid == ContractStatus.financeApproved)?.pistatus !== 0)
    ).subscribe(this.isFinanceApprovalNeededConfig$)

    dataSvc.initData$.subscribe(appData => {
      const user = appData?.data?.check_user
      if (!user?.usid) {
        this.userContracts$.next([])
        this.selectedContract$.next(null)
        return
      }

      const contracts = this.parseContracts(appData?.data?.list_contracts || null)
      const userContracts = contracts?.filter(c => c.usid === user.usid) || []
      if (userContracts?.length === 1) {
        if (userContracts[0].conid !== this.selectedContract$.value?.conid) {
          this.selectedContract$.next(userContracts[0])
        }
      }
      userSvc.preferences$.subscribe(p => {
        if (p.conid && p.conid !== this.selectedContract$.value?.conid) {
          this.applySelectedContract(p.conid)
        }
      })


      this.allContractsLoading$.next(false)
      this._allContracts = this.appSvc.replaceDocItems<ContractParsed>(contracts, this._allContracts, 'conid')
      this.allContracts$.next(this._allContracts)
      this.dataSvc.setCache(FlexTables.contracts, this._allContracts)
    })

    userSvc.allUsers$.subscribe(u => {
      if (!this._allContracts || !userSvc.allUsers) return
      this._allContracts = this._allContracts.map(c => ({
        ...c,
        ...u?.find(u => u.usid == c.usid),
      }))
    })

    combineLatest([this.userSvc.allUsers$, this.allContracts$])
      .pipe(
        map(([u, c]) => {
          const deptsOfLoggedInUser = this.getDepartmentsOfUser(this.userSvc.loggedinUserId || -1)
          const usersOfDepts = u?.filter(u => deptsOfLoggedInUser.some(d => this.isUserInDepartment(u.usid, d.depid)))
          return usersOfDepts || []
        }),
      )
      .subscribe(this.usersInLoggedInUsersDept$)

    // Szóljon sikeres mentés után. A megváltozott újra lekérem a contract listát, mert az örökölt értékek módosulhattak
    this.deptSvc.saveError$
      .pipe(
        filter(saveError => saveError === false), // Hibátlanul sikerült a mentés
      )
      .subscribe(_ => {
        this.listContracts({})
      })
  }

  selectContract(id: number | null): void {
    this.applySelectedContract(id)
    this.userSvc.setPreferences({ conid: id })
  }

  private applySelectedContract(id: number | null): void {
    const c = this.allContracts?.find(c => c['conid'] == id) || null
    if (c?.conid !== this.selectedContract$.value?.conid) {
      this.selectedContract$.next(c)
    }
  }

  /**
   * Meghívja a mentő eljárást
   * @param objParams paraméterek
   * @param changeStatus ettől függően tartja meg vagy törli a paramétereket. Nem módosít a státuszon
   * @param oldStatus ha megvan adva, akkor a státuszváltás alapján kiküld egy a emailt
   */
  handleContracts(objParams: HandleContractsParams, changeStatus: boolean | 'saveAsNew' = false, oldStatus?: ContractStatus): Observable<ContractParsed | null> {
    const conid = objParams._conid, constatus = objParams._constatus, savecomment = objParams._savecomment
    this.saveError$.next(null)
    this.saveStatusError$.next(null)
    this.savingContract$.next(true)
    const params = this.getParams(objParams, changeStatus)
    const retVal = this.dataSvc.handleContracts(params)
      .pipe(
        map(response => {
          if (response) return response
          // törölve lett a con
          const con = this.allContracts?.find(c => c.conid == conid)
          if (!con) return null
          return { ...con, constatus: 0 }
        }),
        map(this.parseContract)
      )
    retVal.subscribe({
      next: (result) => {
        this.savingContract$.next(false)
        if (!result) {
          this.saveError$.next(this.t7e.getTranslation("contract.service", "handleContracts", "saveError", "A mentés nem adott vissza start formot, ezért lehet, hogy nem mentődött el") )
          return
        }
        if (changeStatus) {
          this.saveStatusError$.next(false)
          if (oldStatus && conid) {
            if (constatus == ContractStatus.crewMember) {
              const mailParams = this.getMailParamsForSendBackToCrew(conid, savecomment || null)
              mailParams && this.dataSvc.sendMail(mailParams).subscribe({
                next: (response) => {
                  console.log('email sent', response)
                  this.notifSvc.addObservableNotif({msg: 'A stábtagnak emailt küldtünk arról, hogy visszakapta a start formot javításra.', class: 'success', duration: 3000})
                },
                error: (err) => {
                  console.error(err)
                  this.notifSvc.addObservableNotif({msg: 'Nem sikerült a stábtagnak emailt küldeni arról, hogy visszakapta a start formot javításra.', class: 'danger'})
                }
              })
            } else {
              const statusForApprovalEmail = this.isFinanceApprovalNeededConfig
                ? ContractStatus.financeApproved
                : ContractStatus.prodMgrApproved
              if (constatus == statusForApprovalEmail) {
                const mailParams = this.getMailParamsForApproved(conid, savecomment || null)
                mailParams && this.dataSvc.sendMail(mailParams).subscribe({
                  next: (response) => {
                    console.log('email sent', response)
                    // this.notifSvc.addObservableNotif({ msg: mailParams.sendTo + '-nak emailt küldtünk arról, hogy elfogadtad a start formot.', class: 'success', duration: 3000})
                  },
                  error: (err) => {
                    console.error(err)
                    this.notifSvc.addObservableNotif({ msg: 'Nem sikerült ' + mailParams.sendTo + '-nak emailt küldeni arról, hogy elfogadtad a start formot.', class: 'danger'})
                  }
                })
              }
            }
          }
          // Ha most hagyta jóvá a gyv, ...
          if (this.isFinanceApprovalNeededConfig && conid && constatus == ContractStatus.prodMgrApproved) {
            // ... és a pénzügynek nem kell jóváhagynia.
            // pénzügynek nem kell jóváhagynia == pénzügy nem hagyta jóvá, de mégis jóváhagyottnak számít, mert 
            if (!this.isFinanceApprovedOrAfter(result) && this.isConsideredFinanceApprovedOrAfter(this.allContracts, result)) {
              const mailParams = this.getMailParamsForFinanceNotifNewPosition(conid)
              mailParams && this.dataSvc.sendMail(mailParams).subscribe({
                next: (response) => {
                  console.log('email sent', response)
                  this.notifSvc.addObservableNotif({ msg: 'A pénzügynek emailt küldtünk arról, hogy elfogadtad a start formot.', class: 'success', duration: 3000 })
                },
                error: (err) => {
                  console.error(err)
                  this.notifSvc.addObservableNotif({ msg: 'Nem sikerült a pénzügynek emailt küldeni arról, hogy elfogadtad a start formot.', class: 'danger' })
                }
              })
            }
          }
        }
        else {
          this.saveError$.next(false)
        }

        this._allContracts = this.appSvc.replaceDocItems([result], this._allContracts, 'conid')
        this.allContracts$.next(this._allContracts)
        this.dataSvc.setCache(FlexTables.contracts, this._allContracts)

        if (this.selectedContract?.conid == result.conid) {
          this.selectedContract$.next(result)
        }
      },
      error: (err) => {
        this.savingContract$.next(false)
        console.error(err)
        this.saveError$.next(err)
      }
    })
    return retVal
  }

  listContracts(params: ListContractsParams): Observable<ContractParsed[] | null> {
    this.allContractsLoading$.next(true)
    const retVal = this.dataSvc.listContracts(params)
      .pipe(
        map(this.parseContracts),
        map(data => (data || []).map(c => ({
          ...c,
          ...this.userSvc.allUsers?.find(u => u.usid == c.usid),
        }))),
      )
    retVal.subscribe({
      next: contracts => {
        this.allContractsLoading$.next(false)
        this._allContracts = this.appSvc.replaceDocItems<ContractParsed>(contracts, this._allContracts, 'conid')
        this.allContracts$.next(this._allContracts)
        this.dataSvc.setCache(FlexTables.contracts, this._allContracts)
        const selectedContract = this._allContracts?.find(c => c.conid === this.selectedContract$.value?.conid)
        selectedContract && this.selectedContract$.next(selectedContract)
      },
      error: err => {
        this.allContractsLoading$.next(false)
        console.error(err)
      }
    })
    return retVal
  }

  getMailParamsForSendBackToCrew(contractId: number, saveComment: string | null): SendMailParams | null {
    const contract = this.getContractById(contractId)
    console.assert(contract, 'getMailParamsForSendBackToCrew: contract is null')
    if (!contract) return null
    const user = this.userSvc.getUserById(contract.usid)
    const mailParams = JSON.parse(JSON.stringify(config.HU.CONTRACT.SEND_MAIL_FOR_SENDBACK_TO_CREW)) as SendMailParams & { bodyEnding?: string }
    mailParams.sendTo = user?.email || ''
    mailParams.body = mailParams.body.replace('{USERNAME}', user?.usname || 'Stábtag')
    if (saveComment?.trim()) mailParams.body += `<br><br><b>Megjegyzés:</b><br>${saveComment.replace(/\n/g, '<br>')}`
    mailParams.body += mailParams.bodyEnding
    delete mailParams.bodyEnding
    return mailParams
  }
  getMailParamsForApproved(contractId: number, saveComment: string | null): SendMailParams | null {
    if (config.HU.CONTRACT.SEND_MAIL_FOR_APPROVED.enabled === false) return null
    const contract = this.getContractById(contractId)
    console.assert(contract, 'getMailPAramsForApproved: contract is null')
    if (!contract) return null
    const mailParams = JSON.parse(JSON.stringify(config.HU.CONTRACT.SEND_MAIL_FOR_APPROVED)) as SendMailParams & { bodyEnding?: string }
    const startForm = ((this.userSvc.getUserById(contract.usid)?.usname + ', ') || '') + contract.depname  + ', ' + contract.role
    mailParams.body = mailParams.body.replace('{START_FORM}', startForm)
    delete mailParams.bodyEnding
    return mailParams
  }
  getMailParamsForFinanceNotifNewPosition(contractId: number): SendMailParams | null {
    const contract = this.getContractById(contractId)
    console.assert(contract, 'getMailParamsForFinanceNotifNewPosition: contract is null')
    if (!contract) return null
    const mailParams = JSON.parse(JSON.stringify(config.HU.CONTRACT.SEND_MAIL_FINANCE_NOTIF_NEW_POSITION)) as SendMailParams & { bodyEnding?: string }
    const startForm = ((this.userSvc.getUserById(contract.usid)?.usname + ', ') || '') + contract.depname + ', ' + contract.role
    mailParams.body = mailParams.body.replace('{START_FORM}', startForm)
    return mailParams
  }

  saveFolderId(conid: number, folderId: string) {
    const params: HandleContractsParams = {
      _conid: conid,
      _data: JSON.stringify({ folderid: folderId }),
    }
    return this.handleContracts(params, false)
  }

  getUploadFolderName(contract?: Contract | null): string {
    if (!contract) return 'ismeretlen start form'
    // COMPANYNAME_FAMNAME_USNAME_EKHO
    const retVal = []
    const company = contract.f_companyname?.trim().toUpperCase()
    if (company) retVal.push(company)
    const famname = this.userSvc.getUserById(contract.usid)?.famname?.trim().toUpperCase()
    const usname = this.userSvc.getUserById(contract.usid)?.usnameOrig?.trim().toUpperCase()
    const name = famname && usname ? `${famname} ${usname}` : famname || usname || 'ISMERETLEN STÁBTAG'
    if (usname) retVal.push(name)
    const ekho = contract.f_contract_type == "1006" || contract.f_contract_type == 'ekho'
      ? 'EKHO'
      : null
    if (ekho) retVal.push(ekho)
    return retVal.join('_')
  }

  isSaveAndCalcOtRatesEqual(dataItem: Contract): boolean {
    if (!dataItem.f_otrate && !dataItem.f_saved_otrate) return true
    return JSON.stringify(dataItem.f_otrate) === JSON.stringify(dataItem.f_saved_otrate)
  }

  getFirstValuableOtRateByContract = (contract: ContractParsed | null) => this.getFirstValuableOtRate(contract?.effectiveOtRates as string[])
  getFirstValuableOtRate =  (otRates: string[] | null) => Number(otRates?.find((r: any) => Number(r) > 0)) || 0

  getStatusName(status: number | null | undefined): string {
    switch (status) {
      case null:
      case undefined:
        return this.t7e.lang === 'hu' ? 'Nincs beküldve' : 'Not submitted'
      case ContractStatus.disabled:
        return this.t7e.getTranslation("contract.service", "getStatusName", "ContractStatus.disabled",'Letiltva')
      case ContractStatus.deleted:
        return this.t7e.getTranslation("contract.service", "getStatusName", "ContractStatus.deleted",'Törölve')
      case ContractStatus.crewMember:
        return this.t7e.getTranslation("contract.service", "getStatusName", "ContractStatus.crewMember",'Beküldésre vár')
      case ContractStatus.deptHead:
        return this.t7e.getTranslation("contract.service", "getStatusName", "ContractStatus.deptHead", 'Részlegvezetőre vár')
      case ContractStatus.prodMgr:
        return this.t7e.getTranslation("contract.service", "getStatusName", "ContractStatus.prodMgr",'Gyártásvezetőre vár')
      case ContractStatus.prodMgrApproved:
        return this.t7e.getTranslation("contract.service", "getStatusName", "ContractStatus.prodMgrApproved", 'Jóváhagyva')
      case ContractStatus.financeApproved:
        return this.t7e.getTranslation("contract.service", "getStatusName", "ContractStatus.financeApproved", 'Pénzügy jóváhagyta')
      case ContractStatus.readyToSign:
        return this.t7e.getTranslation("contract.service", "getStatusName", "ContractStatus.readyToSign",'Aláírásra kész')
      case ContractStatus.signed:
        return this.t7e.getTranslation("contract.service", "getStatusName", "ContractStatus.signed",'Aláírva')
      default:
        return this.t7e.getTranslation("contract.service", "getStatusName", "default",'Ismeretlen státusz')
    }
  }

  getStatusClass(con?: ContractParsed | null): string {
    let retVal = ''
    switch (con?.constatus) {
      case null:
      case undefined:
        return 'contract-status con-status-notsubmitted disabled'
      case ContractStatus.disabled:
        return 'contract-status con-status-disabled disabled'
      case ContractStatus.deleted:
        return 'contract-status con-status-deleted disabled'
      case ContractStatus.crewMember:
        return 'contract-status con-status-atcrewmember' + (this.isOwnContract(con) || this.isDeptAdminOf(con.depid) ? ' warning' : ' disabled')
      case ContractStatus.deptHead:
        let color = ''
        if (this.deptSvc.isDeptHeadOf(con.depid)) color = ' warning'
        else if (this.isOwnContract(con)) color = ' success'
        else color = ' disabled'
        return 'contract-status con-status-atdepthead' + color
      case ContractStatus.prodMgr:
        retVal = 'contract-status con-status-atprodmgr'
        if (this.userSvc.isProdMgr) return retVal + ' warning'
        else if (this.userSvc.isFinance) return retVal + ' warning'
        else if (this.userSvc.isProducer) return retVal + ' warning'
        else return retVal + ' success'
      case ContractStatus.prodMgrApproved:
        retVal = 'contract-status con-status-prodmgrapproved'
        if (this.userSvc.isFinance) return retVal + ' warning'  
        else if (this.userSvc.isProducer) return retVal + ' warning'
        else if (this.userSvc.isProdMgr) return retVal + ' success'
        else if (this.userSvc.isDeptAdmin || this.userSvc.isDeptHead) return retVal + ' success'
        else return retVal + ' success'
      case ContractStatus.financeApproved:
        retVal = 'contract-status con-status-financeapproved'
        if (this.userSvc.isFinance) return retVal + ' warning'
        else if (this.userSvc.isProducer) return retVal + ' warning'
        else if (this.userSvc.isDeptAdmin || this.userSvc.isDeptHead) return retVal + ' success'
        else if (this.userSvc.isProdMgr) return retVal + ' success'
        else return retVal + ' success'
      case ContractStatus.readyToSign:
        retVal = 'contract-status con-status-readytosign'
        if (this.userSvc.isProdMgr) return retVal + ' success'
        if (this.userSvc.isFinance) return retVal + ' warning'
        if (this.userSvc.isProducer) return retVal + ' warning'
        else return retVal + ' waiting'
      case ContractStatus.signed:
        return 'contract-status con-status-signed success'
      default:
        return 'contract-status con-status-unknown disabled'
    }
  }

  getContractStatuses() {
    return Object.keys(ContractStatus)
      .map((key: any) => ({
        id: ContractStatus[key] as unknown as number,
        code: key as string,
      }
      ))
      .filter(obj => isNaN(obj.code as unknown as number))
      .map(s => ({
        ...s,
        name: this.getStatusName(s.id),
        enabled: this.appSvc.aStatuses$.get(Props.ContractStatus)?.value.find(pi => pi.piid === s.id)?.pistatus !== 0,
      }))

  }

  checkCommonForSetStatus(con: Contract | null): ButtonStatus | null {
    if (this.appSvc.debug) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "debug", 'Debug üzemmódban vagy') }
    if (!con) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "nocontract", 'Nincs létrehozva a start form') }
    if (con.constatus == ContractStatus.deleted) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "ContractStatus.deleted", 'Már törölve lett') }
    if (this.savingContract$.value) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "saving", 'Mentés folyamatban...') }
    return null
  }
  canSetAtDeptHead(con: ContractParsed | null): ButtonStatus {
    const commonChecks = this.checkCommonForSetStatus(con)
    if (commonChecks) return commonChecks
    if (!this.hasDeptHead(con)) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtDeptHead", "ContractStatus.deptHead.noDeptHead", 'Nincs még részlegvezető') }
    if (this.userSvc.isProdMgr || this.userSvc.isFinance) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetAtDeptHead", "ContractStatus.deptHead.send", 'A részlegvezetőnek küldöd') }
    if (con!.constatus == ContractStatus.crewMember) {
      if (this.isDeptAdminOf(con!.depid)  || this.isOwnContract(con) || this.userSvc.isDeptAdmin) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetAtDeptHead", "ContractStatus.crewMember.isOwnContract", 'Beküldöd a részlegvezetőnek') }
      return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtDeptHead", "ContractStatus.crewMember.crewStillEditing", 'Még a stábtag szerkeszti') }
    }
    if (con!.constatus == ContractStatus.deptHead) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtDeptHead", "ContractStatus.atDeptHead", 'A start form már részlegvezetői jóváhagyásra vár') }
    return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtDeptHead", "ContractStatus.deptHeadApproved", 'A start formot a részlegvezető már jóváhagyta') }
  }

  canSetAtProdMgr(con: ContractParsed | null): ButtonStatus {
    const commonChecks = this.checkCommonForSetStatus(con)
    if (commonChecks) return commonChecks
    if (con!.constatus == ContractStatus.prodMgr) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "ContractStatus.prodMgr", 'A start form már jóváhagyásra vár') }
    if (this.userSvc.isProdMgr) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "userSvc.isProdMgr",'Jóváhagyásra küldöd a start formot') }
    if (this.userSvc.isFinance) {
      switch (con!.constatus) {
        case ContractStatus.prodMgrApproved:
        case ContractStatus.financeApproved:
        case ContractStatus.readyToSign:
        case ContractStatus.signed:
          return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "Approved_readyToSign_signed",'Jóváhagyásra küldöd a start formot') }
        default:
          // szándékosan üres
      }
    }
    if (con!.constatus == ContractStatus.crewMember) {
      if (this.hasDeptHead(con)) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "ContractStatus.crewMember.sendToDeptHeadFirst", 'Először a részlegvezetőnek kell küldened')}
      if (this.isOwnContract(con)) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "ContractStatus.crewMember.isOwnContract", 'Beküldöd a produkciónak') }
      if (this.isDeptAdminOf(con!.depid)) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "ContractStatus.crewMember.isOwnContract", 'Beküldöd a produkciónak') }
      return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "ContractStatus.crewMember.notOwnContract", 'Még a stábtag szerkeszti') }
    }
    if (con!.constatus == ContractStatus.deptHead) {
      if (!this.hasDeptHead(con)) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "ContractStatus.deptHead.noDeptHeadSendToProdMgrDirectly", 'Nincs már részlegvezető, ezért beküldheted a produkciónak') }
      if (this.deptSvc.isDeptHeadOf(con!.depid)) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "ContractStatus.deptHead.sendToProdMgr", 'Beküldöd a produkciónak') }
      return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "ContractStatus.deptHead.notDeptHead", 'Még a részlegvezető jóváhagyására vár') }
    }
    return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetAtProdMgr", "norights",'Nincs jogosultságod a start form visszaküldéséhez') }
  }
  canProdMgrApprove(con: ContractParsed | null): ButtonStatus {
    const commonChecks = this.checkCommonForSetStatus(con)
    if (commonChecks) return commonChecks
    if (!con?.conid) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canApprove", "contract-not submitted", null, null, "Jóváhagyás előtt be kell küldeni") }
    if (con!.constatus == ContractStatus.prodMgrApproved) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canApprove", "ContractStatus.prodMgrApproved",'A start form már jóváhagyott státuszban van') }
    if (this.userSvc.isFinance && con!.constatus == ContractStatus.financeApproved) {
      return { disabled: false, title: this.t7e.getTranslation("contract.service", "canApprove", "ContractStatus.signed", 'Jóváhagyott státuszba teszed a start formot') }
    } else if (this.userSvc.isProdMgr) {
      if (this.isOwnContract(con)) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canApprove", "isOwnContract",'Saját start formnak jóváhagyott státuszt adsz') }
      else if (con!.constatus === ContractStatus.crewMember) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canApprove", "ContractStatus.crewMember", 'Javításra váró start form nem hagyható jóvá') }
      else if (con!.constatus == ContractStatus.financeApproved) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canApprove", "ContractStatus.financeApproved", 'Már jóváhagytad.' )}
      return { disabled: false, title: this.t7e.getTranslation("contract.service", "canApprove", "approve",'Jóváhagyod a start formot') }   
    }
    if (this.userSvc.isFinance) {
      switch (con!.constatus) {
        case ContractStatus.readyToSign:
        case ContractStatus.signed:
          return { disabled: false, title: this.t7e.getTranslation("contract.service", "canApprove", "ContractStatus.signed",'Jóváhagyott státuszba teszed a start formot') }
        default:
          // szándékosan üres
      }
    }
    return { disabled: true, title: this.t7e.getTranslation("contract.service", "canApprove", "disabled",'Csak gyártásvezető hagyhat jóvá start formot') } 
  }
  canFinanceApprove(con: ContractParsed | null): ButtonStatus {
    const commonChecks = this.checkCommonForSetStatus(con)
    if (commonChecks) return commonChecks
    if (this.userSvc.isFinance) {
      const allowedFromStatuses = [ContractStatus.prodMgrApproved, ContractStatus.readyToSign, ContractStatus.signed]
      if (allowedFromStatuses.includes(con!.constatus!)) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canFinanceApprove", "ContractStatus.canApprove", 'Jóváhagyott státuszba teszed a start formot') }
      else return { disabled: true, title: this.t7e.getTranslation("contract.service", "canFinanceApprove", "ContractStatus.cannotApprove", 'Jóváhagyott státuszba teszed a start formot') }
    }
    if (this.userSvc.isProdMgr) {
      return { disabled: true, title: this.t7e.getTranslation("contract.service", "canApprove", "ContractStatus.financeApproved", 'Már jóváhagytad.') }
    }
    return { disabled: true, title: this.t7e.getTranslation("contract.service", "canFinanceApprove", "norights", 'Nincs jogosultságod a start form pénzügyi jóváhagyásához') }
  }
  canSendBackToCrew(con: Contract): ButtonStatus {
    const commonChecks = this.checkCommonForSetStatus(con)
    if (commonChecks) return commonChecks
    if (con.constatus == ContractStatus.crewMember) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSendBackToCrew", "ContractStatus.crewMember",'Már ebben a státuszban van') }
    if (this.userSvc.isProdMgr) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSendBackToCrew", "userSvc.isProdMgr", 'A stábtag számára szerkeszthetővé teszi a start form adatait') }
    if (con.constatus == ContractStatus.deptHead && this.userSvc.isDeptHead) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSendBackToCrew", "userSvc.isDeptHead",'A stábtag számára szerkeszthetővé teszi a start form adatait') }
    return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSendBackToCrew", "disabled",'Csak gyártásvezető küldhet vissza start formot') } 
  }
  canSetReadyToSign(con: Contract): ButtonStatus {
    const commonChecks = this.checkCommonForSetStatus(con)
    if (commonChecks) return commonChecks
    if (con.constatus == ContractStatus.crewMember) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetReadyToSign", "ContractStatus.crewMember",'Még a stábtag szerkeszti') }
    if (con.constatus == ContractStatus.prodMgr) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetReadyToSign", "ContractStatus.prodMgr", 'Még a gyártásvezető jóváhagyására vár') }
    if (this.isFinanceApprovalNeededConfig$.value && con.constatus == ContractStatus.prodMgrApproved) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetReadyToSign", "ContractStatus.prodMgrApproved",'Még a pénzügy jóváhagyására vár') }
    if (con.constatus == ContractStatus.readyToSign) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetReadyToSign", "ContractStatus.readyToSign",'Már ebben a státuszban van') }
    if (this.userSvc.isFinance) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetReadyToSign", "userSvc.isFinance",'A start form aláírásra kész státuszba kerül') }
    return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetReadyToSign", "disabled",'Csak a pénzügy teheti aláírásra kész státuszba a start formot') } 
  }
  canSetSigned(con: Contract): ButtonStatus {
    const commonChecks = this.checkCommonForSetStatus(con)
    if (commonChecks) return commonChecks
    if (con.constatus == ContractStatus.crewMember) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetSigned", "ContractStatus.crewMember",'Még a stábtag szerkeszti') }
    if (con.constatus == ContractStatus.prodMgr) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetSigned", "ContractStatus.prodMgr",'Még a gyártásvezető jóváhagyására vár') }
    if (this.isFinanceApprovalNeededConfig$.value && con.constatus == ContractStatus.prodMgrApproved) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetReadyToSign", "ContractStatus.prodMgrApproved", 'Még a pénzügy jóváhagyására vár') }
    if (con.constatus == ContractStatus.signed) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetSigned", "ContractStatus.signed",'Már ebben a státuszban van') }
    if (this.userSvc.isFinance) return { disabled: false, title: this.t7e.getTranslation("contract.service", "canSetSigned", "userSvc.isFinance",'A start form aláírva státuszba kerül') }
    return { disabled: true, title: this.t7e.getTranslation("contract.service", "canSetSigned", "disabled",'Csak a pénzügy teheti aláírt státuszba start formot') } 
  }
  canDelete(con: Contract): ButtonStatus {
    const commonChecks = this.checkCommonForSetStatus(con)
    if (commonChecks) return commonChecks
    return { disabled: false, title: this.t7e.getTranslation("contract.service", "canDelete", "otherwise",'Véglegesen törli a start formot. Nem visszavonható') }
  }
  canDisable(con: Contract): ButtonStatus {
    const commonChecks = this.checkCommonForSetStatus(con)
    if (commonChecks) return commonChecks
    if (con.constatus == ContractStatus.disabled) return { disabled: true, title: this.t7e.getTranslation("contract.service", "canDisable", "ContractStatus.disabled",'Már ebben a státuszban van')}
    return { disabled: false, title: this.t7e.getTranslation("contract.service", "canDisable", "archive",'Archiválja a start formot, hogy ne lehessen hozzá új Timesheetet beküldeni') }
  }
  isAtCrew(con: Contract): boolean {
    if (!con.constatus) return true
    if (con.constatus === ContractStatus.crewMember) return true
    return false
  }
  isAtProdMgr(con: Contract): boolean {
    return con.constatus === ContractStatus.prodMgr
  }
  isApproved(con: Contract): boolean {
    return con.constatus === ContractStatus.prodMgrApproved
  }
  isFinanceApproved(con: Contract): boolean {
    return con.constatus === ContractStatus.financeApproved
  }
  isApprovedOrAfter(contract?: Contract | null): boolean {
    if (!contract?.constatus || contract.constatus <= 0) return false
    if (contract.constatus === ContractStatus.crewMember) return false
    if (contract.constatus === ContractStatus.deptHead) return false
    if (contract.constatus === ContractStatus.prodMgr) return false
    return true
  }
  isFinanceApprovedOrAfter(contract?: Contract | null): boolean {
    if (contract!.constatus === ContractStatus.financeApproved) return true
    if (contract!.constatus === ContractStatus.readyToSign) return true
    if (contract!.constatus === ContractStatus.signed) return true
    return false
  }
  /** Akkor is true, ha a megadott start form nincs pénzügyes által jóváhagyva, 
   * de egy másik start form ugyanarra a cégre (ekho esetén személyre) már igen
   * Azért, mert a pénzügyes jóváhagyása csak a céges és ekhos adatok ellenőrzése miatt kell. */
  isConsideredFinanceApprovedOrAfter(allContracts: Contract[] | null, contract: Contract | null): boolean {
    if (!this.isApprovedOrAfter(contract)) return false
    if (this.isFinanceApprovedOrAfter(contract)) return true
    if (!this.isFinanceApprovalNeededConfig) return contract?.constatus === ContractStatus.prodMgrApproved
    if (!allContracts) return false
    const userOtherContracts = allContracts.filter(c => c.usid === contract?.usid!)
      .filter(c => c.conid !== contract?.conid)
    const otherApprovedContracts = userOtherContracts
      .filter(c => this.isFinanceApprovedOrAfter(c))
      .filter(c1 => {
        return userOtherContracts?.some(c2 => {
          return this.isFinanceApprovedOrAfter(c2) && this.sameCompanyOrEkho(c1, c2)
        })
      })
    return !!otherApprovedContracts?.length
  }

  sameCompanyOrEkho(c1: Contract | null, c2: Contract | null): boolean {
    if (!c1?.conid || !c2?.conid) return false
    if (c1.conid === c2.conid) return true
    const contractTypePicode = this.getContractTypeAsPropItem(c1)?.picode
    if (contractTypePicode !== this.getContractTypeAsPropItem(c2)?.picode) return false
    if (!contractTypePicode) return false
    switch (contractTypePicode) {
      case 'ekho':
      case '1006':
        return c1['taxnumber']?.trim() === c2['taxnumber']?.trim()
      case 'hun_company':
      case '1004':
        return c1['companytaxnumber'] === c2['companytaxnumber']
      default:
        console.warn('Ismeretlen szerződés típus (picode): ' + contractTypePicode, c1)
        return false
    }
  }

  isReadyToSign(con: Contract): boolean {
    return con.constatus === ContractStatus.readyToSign
  }
  isSigned(con: Contract): boolean {
    return con.constatus === ContractStatus.signed
  }

  isForeignCrew(con: ContractParsed): boolean | null {
    switch (con.contractTypeAsPropItem?.picode) {
      case null:
      case undefined:
        return null
      case this.FOREIGN_CONTRACT_TYPE_CODE:
        return true
      default:
        return false
    }
  }

  isOwnContract(con: Contract | null): boolean {
    if (con?.conid === undefined || con?.conid === null) return true
    // find contract in userContracts
    const userContracts = this.userContracts$.value
    if (!userContracts?.length) return false
    const contract = userContracts.find(x => x.conid === con?.conid)
    return !!contract
  }

  getContractsByUser(usid: number): ContractParsed[] {
    return this._allContracts?.filter(c => c.usid === usid) || []
  }

  getContractById(conid: number | null | undefined): ContractParsed | null {
    return this._allContracts?.find(c => c.conid === conid) || null
  }

  getDepartmentsOfUser(usid: number): Department[] {
    const contracts = this.getContractsByUser(usid)
    if (!contracts?.length) return []
    const departments = contracts
      .map(c => this.deptSvc.getDeptById(c.depid || -1))
      .filter(d => d)
    //@ts-ignore
    return departments.filter((c, i) => departments.indexOf(c) === i)
  }

  getDepartmentNamesByUser(usid: number): string[] {
    const contracts = this.getContractsByUser(usid)
    if (!contracts?.length) return []
    const departments = contracts.map(c => c.depname || '').filter(c => c)
    // unique
    return departments.filter((c, i) => departments.indexOf(c) === i)
  }

  isUserInDepartment(usid?: number | null, depid?: number | null): boolean {
    if (!usid || !depid) return false
    const contract = this.allContracts?.find(c => (c.depid === depid && c.usid === usid))
    return !!contract
  }

  isDeptAdminOf(depid?: number | null) {
    if (!this.userSvc.isEnabled) return false
    return this.userSvc.isDeptAdmin && this.isUserInDepartment(this.userSvc.loggedinUser?.usid, depid)
  }


  hasDeptHead(c?: Contract | null): boolean {
    if (!c?.depid) return false
    const dept = this.deptSvc.getDeptById(c.depid)
    return !!dept?.depleaders?.length
  }

  hasMealPenalty_setAtProdDeptSf(c?: Contract | null): boolean {
    if (!this.MEAL_PENALTY) return false
    return !!c?.f_mealPenaltyRate
  }

  getMealPenaltyRate_setAtProdDeptSf(c?: Contract | null): number | null {
    if (!c) return null
    return c?.f_mealPenaltyRate || 0
  }

  /**
   * 
   * @param usid user id
   * @returns Gets the array of position of the user
   */
  getRolesByUser(usid: number): string[] {
    const contracts = this.getContractsByUser(usid)
    if (!contracts?.length) return []
    const roles = contracts.map(c => c.role?.trim() || '').filter(c => c)
    // unique
    return roles.filter((c, i) => roles.indexOf(c) === i)
  }
  getCompaniesByUser(usid: number): string[] {
    const contracts = this.getContractsByUser(usid)
    if (!contracts?.length) return []
    const companies = contracts
      .filter(c => c.constatus !== 0)
      .map(c => c.f_companyname || '')
      .filter(c => c)
    // unique
    return companies.filter((c, i) => companies.indexOf(c) === i)
  }

  
  /**
 * 
 * @param documentType Meghívja a szerződést generáló endpointot
 * @param params start form azonosító
 * @param sendTo false==ne küldd ki; 'string'==a 'string'-re küldd az emailt; null==a szerződőnek küldd az emailt
 * @returns 
 */
  generateDoc(
    documentType: DocType,
    params: GenerateDocParams[],
    sendTo: string | false | null = null,
    isDraft: boolean,
    sendPdf: boolean): Observable<PrintdocResponse | null> {
    const retVal = this.dataSvc.generateDoc(documentType, params, sendTo, isDraft, sendPdf)
    retVal.subscribe({
      next: response => {
        console.log('gen doc válasz:', response)
        params.forEach(async c => {
          const p: ListContractsParams = {
            _conid: c['conid'] as number,
          }
          this.listContracts(p)
        })
      },
      error: err => {
        console.error(err);
      }
    })
    return retVal
  }


  getParams(paramsOrig: HandleContractsParams, changeStatus: boolean | 'saveAsNew'): HandleContractsParams {
    let params: HandleContractsParams
    if (changeStatus === true) {
      params = {
        _conid: paramsOrig._conid,
        _constatus: paramsOrig._constatus,
        _savecomment: paramsOrig._savecomment,
      }
    } else {
      params = { ...paramsOrig }
      if (changeStatus === false) delete params._constatus
    }
    return params
  }

  getDayLen(): number {
    return this.selectedContract$.value?.f_workhours 
      // ||  this.deptSvc.getDeptById(this.selectedContract$.value?.depid || -1)?.workhours || 8
      || this.prodSvc.prodDefaults$.value?.workhours
      || 12
  }

  getCurrency(contract: ContractParsed): string {
    return contract.f_saved_currency || contract.f_currency || ''
  }
  getCurrencyByContractId(conid: number): string {
    const contract = this.getContractById(conid)
    return contract?.f_saved_currency || contract?.f_currency || ''
  }

  parseContracts(contracts: Contract[] | null): ContractParsed[] | null {
    return contracts
      ?.filter(c => c)
      ?.map(this.parseContract)
      || null
  }
  parseContract(contract: Contract | null): ContractParsed {
    const user = this.userSvc.getUserById(contract?.usid)
    const retVal = {
      ...contract,
      conid: contract?.conid || null,
      arrDoclink: contract?.doclink?.length && contract?.doclink?.length < 3 ? null : JSON.parse(contract?.doclink || '[]') as DocLink[],
      dStartdate: contract ? new Date(contract.startdate!) : null,
      dEnddate: contract ? new Date(contract.enddate!) : null,
      dEntryon: contract ? new Date(contract.entryon!) : null,
      //f_contract_type: this.ensureContractTypeId(contract),
      contractTypeAsPropItem: this.getContractTypeAsPropItem(contract),
      companyTypeAsPropItem: this.appSvc.aPropItems$[Props.companyType]?.value?.find(x => x.picode.toString() == contract?.f_companyType)
        || this.appSvc.aPropItems$[Props.companyType]?.value?.find(x => x.piid.toString() == contract?.f_companyType) || null,
      effectiveOtRates: contract?.f_saved_otrate
        ? this.parser.dbArrayToArray(contract?.f_saved_otrate)
        : contract?.f_otrate
          ? this.parser.dbArrayToArray(contract?.f_otrate)
          : null,
      lastWtsDay: contract?.f_lastinvoicedday ? moment(contract?.f_lastinvoicedday) : null,

      ...user,
    } as ContractParsed
    return retVal
  }

  getContractTypeAsPropItem(contract: Contract | null): PropItem | null {
    return this.appSvc.aPropItems$[Props.contractType]?.value?.find(x => x.picode.toString() == contract?.f_contract_type)
      || this.appSvc.aPropItems$[Props.contractType]?.value?.find(x => x.piid.toString() == this.ensureContractTypeId(contract)) || null
  }

  private ensureContractTypeId(contract?: Contract | null): string | null | undefined {
    const ct = contract?.f_contract_type
    if (ct && !Number.isInteger(parseFloat(ct))) {
      const piid = this.appSvc.aPropItems$[Props.contractType].value.find(pi => pi.picode == ct)?.piid.toString()
      return piid || contract.f_contract_type
    }
    return ct
  }

  getQuestionGroupPropCodes(qa: QuestionAnswer[]): QuestionGroup[] {
    const retVal: QuestionGroup[] = [contractQuestionGroups['__default']['__anyAnswer']]
    let origLength: number
    do {
      origLength = retVal.length
      retVal.forEach(qg => {
        qg.questionPropCodes.forEach(question => {
          const answer = qa.find(x => x.question == question)?.answer
          if (!answer) return
          const group = contractQuestionGroups[question]?.[answer]
          if (!group) return
          if (retVal.find(x => x.questionGroupName == group.questionGroupName)) return
          retVal.push(group)
        })
      })
    } while (origLength != retVal.length)
    return retVal
  }

  /** Ez nincs használatban. Valószínű azért, mert az adatbázis konvertál, ha szűkséges */
  convertIfNeeded(contract: ContractParsed | null, fieldName: string): number | null {
    console.assert(fieldName.substring(0, 1) !== 'f_', 'Ide az f_ és az f_saved_ nélküli név kell')
    if (!contract) return null
    const saved_FieldName = 'f_saved_' + fieldName
    const inherited_FieldName = 'f_' + fieldName
    if (contract[saved_FieldName] !== null && contract[saved_FieldName] !== undefined) return contract[saved_FieldName]
    if (!config.MULTIPLE_CURRENCIES || this.prodDefaultCurrency === contract.f_currency) return contract[fieldName]
    else {
      if (!this.todaysCurrencyExchangeRate$.value?.rate) {
        throw new Error('Az árfolyam nem érhető el')
      }
      const inheritedValue = contract[inherited_FieldName]
      const rate = config.MULTIPLE_CURRENCIES === 'fixed-rates'
        ? this.prodSvc.getExchangeRateForFixedRates(contract.f_currency)
        : this.todaysCurrencyExchangeRate$.value.rate
      return Math.round(inheritedValue * 100 / rate) / 100
    }
  }

  hasInvoiceContact(contract: ContractParsed | null): boolean {
    if (!contract) return false
    return !!contract.f_invoice_contact
  }
  getInvoiceContact(contract: ContractParsed | null): string | null {
    if (!contract?.f_invoice_contact) return null
    return contract.f_invoice_contact
  }

  hasAllRequiredContractFields(contract: ContractParsed,  aMissingFields: string[]): boolean {
    const requiredFields = this.getRequiredContractFieldsToSendToProdMgr(contract)
    const missing = requiredFields.filter(x => 
        (contract[x] === null 
        || contract[x] === undefined 
        || contract[x] === '')
        && 
        (contract['f_' + x] === null
        || contract['f_' + x] === undefined
        || contract['f_' + x] === '')
        )
    aMissingFields.push(...missing)
    return !missing.length
  }

  private getRequiredContractFieldsToSendToProdMgr(contract: ContractParsed): string[] {
    const retVal: string[] = [...this.REQUIRED_FIELDS_TO_SUBMIT]

    const contractType = contract.contractTypeAsPropItem?.picode
    if (!contractType) return retVal
    const questionGroups = contractQuestionGroups['contract_type'][contractType]
    if (!questionGroups) return retVal
    questionGroups.questionPropCodes.forEach(q => {
      if (retVal.includes(q)) return
      const tableProperty = this.appSvc.tableProperties$.value?.find(tp => tp.propcode == q)
      if (tableProperty?.optional) return
      retVal.push(q)
    })

    if (contractType == 'hun_company') {
      const companyType = contract.companyTypeAsPropItem?.picode
      if (!companyType) return retVal
      const companyTypeQuestionGroup = contractQuestionGroups['companyType'][companyType]
      if (!companyTypeQuestionGroup) return retVal
      companyTypeQuestionGroup.questionPropCodes.forEach(q => {
        if (retVal.includes(q)) return
        const tableProperty = this.appSvc.tableProperties$.value?.find(tp => tp.propcode == q)
        if (tableProperty?.optional) return
          retVal.push(q)
      })
    }

    return retVal
  }

  hasAllRequiredUserFields(user: AppUser, contract: ContractParsed,  aMissingFields: string[]): boolean {
    const requiredFields = this.isForeignCrew(contract) ? requiredForForeignCrew : this.getRequiredFieldsToSendToProdMgr()
    const missing = requiredFields.filter(x => {
      const userDetails = user.details?.find(userData => userData.propcode == x)
      return  (user[x] === null 
        || user[x] === undefined 
        || user[x] === '')
        && 
        (!userDetails
        || userDetails.propvalue === null
        || userDetails.propvalue === undefined
        || userDetails.propvalue === '')
    })
    aMissingFields.push(...missing)
    return !missing.length
  }

  private getRequiredFieldsToSendToProdMgr(): string[] {
    return this.appSvc.tableProperties$.value
      ?.filter(x => x.tableid == FlexTables.users)
      ?.filter(x => !x.optional)
      ?.map(x => x.propcode)
      || []

  }

  hasBasicData(user: AppUser | null): boolean {
    if (!user?.usid) return false
    if (!user?.usname?.trim()) return false
    if (!user?.phonenumber?.trim()) return false
    if (!this.getDepartmentNamesByUser(user.usid)?.length) return false
    if (!this.getRolesByUser(user.usid)?.length) return false
    return true
  }



}

export type QuestionAnswer = {
  question: string, // propcode
  answer: string, // picode
}

export type QuestionGroup = { // ez a válasz, akkor 
  questionGroupName: string, // jelenjen meg ez a kérdéscsoport
  questionPropCodes: string[], // ezekkel a kérdésekkel
}

export const defaultQuestionGroupName = 'Alapértelmezett'

export const contractQuestionGroups: {
  [propcode: '__default' | string]: { // ha erre a kérdésre...
    [picode: '__anyAnswer' | string]: QuestionGroup
  }
} = {
  __default: { // ez a kérdéscsoport mindig jelenjen meg
    __anyAnswer: {  // ez az alapértelmezett kérdéscsoport
      questionGroupName: defaultQuestionGroupName,
      questionPropCodes: [
        'contract_type',
        'consentHealthAndSafety',
        'consentDataProcessing',
        'consentElectronicData',
      ]
    }

  },
  'contract_type': {
    hun_company: {
      questionGroupName: 'Magyar cég',
      questionPropCodes: [
        'companyname',
        'companyaddresszip',
        'companyaddresscity',
        'companyaddressstreet',
        'companyrepresentative',
        'invoice_contact',
        'companytaxnumber',
        'companybankaccount',
        'bejelento',
        'employeeRegCertReceipt',
        'scopeofactivity',
        'companyType',
      ],
    },
    ekho: {
      questionGroupName: 'Ekho',
      questionPropCodes: [
        'employersCert',
        'birthname',
        'taxnumber',
        'taj',
        'personalBankAccountNr',
        'invoice_contact',
        'birthdate',
        'mothername',
        'addresszip',
        'addresscity',
        'addressstreet',
        'birthplace',
      ],
    },
    non_hun: {
      questionGroupName: 'Foreign Crew or Cast',
      questionPropCodes: [
      ],
    },
  },
  'companyType': {
    kftBtKkt: {
      questionGroupName: 'Kft, Bt, Kkt',
      questionPropCodes: [
        'companyRegNr',
        'companySpecimenSSignature',
        'cegjegyzekDoc',
        'taxAccount',
      ],
    },
    ev: {
      questionGroupName: 'Egyéni vállalkozó',
      questionPropCodes: [
        'evRegNr',
        'evny',
        'taxAccount',
      ],
    },
  },
  'crew_or_cast': {
    crew: {
      questionGroupName: 'Crew',
      questionPropCodes: [
      ],
    },
    cast: {
      questionGroupName: 'Cast',
      questionPropCodes: [
      ],
    },
  },
  'employee_or_contractor': {
    contractor: {
      questionGroupName: 'Contractor',
      questionPropCodes: [
      ],
    },
  },
  'a1VisaNeeded': {
    'yes': {
      questionGroupName: 'A1 Visa',
      questionPropCodes: [
      ],
    },
  }

}
