<h1 class="page-title" t7e="dept-detaults-h1">Részleg alapértelmezések</h1>
<div class="dept-defaults">
    <div class="search">
        <form [formGroup]="formSearch">
            <mat-form-field class="form-field">
                <mat-label><span t7e="seach-textbox-text">Keresés</span></mat-label>
                <input type="text" matInput formControlName="searchString">
            </mat-form-field>
        </form>
    </div>
    <!-- <div>{{ depts$ | async | json}}</div> -->
    <table class="dept-table">
        <thead>
            <tr>
                <th>Részleg</th>
                <th>TA HUF</th>
                <th>OT HUF</th>
                <th>OT lépcső</th>
                <th>Per Diem HUF</th>
                <th *ngIf="isMealPenaltyAtDeptDefault">Meal Penalty</th>
                <th>Grace period</th>
                <th>Aktív</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let dept of depts$ | async | callback: filterDepts">
                <td>
                    <div class="editable-value-container depname">
                        <app-editable-value [field]="editableValueOptions[dept.depid]['depname']"
                            (onSave)="editableValueSaveClicked($event, dept, 'depname')">
                        </app-editable-value>
                    </div>
                </td>
                <td>
                    <div class="editable-value-container">
                        <app-editable-value [field]="editableValueOptions[dept.depid]['f_tarate']"
                            (onSave)="editableValueSaveClicked($event, dept, 'f_tarate')" [suffix]="currency">
                        </app-editable-value>
                    </div>
                </td>
                <td>
                    <div class="editable-value-container otrates">
                        <app-editable-value [field]="editableValueOptions[dept.depid]['f_otrate']"
                            (onSave)="otRateSaved($event, dept)" [suffix]="currency">
                        </app-editable-value>
                    </div>
                </td>
                <td>
                    <div class="editable-value-container overtimesteps">
                        <app-editable-value [field]="editableValueOptions[dept.depid]['f_overtimestep']" 
                            (onSave)="editableValueSaveClicked($event, dept, 'f_overtimestep')" [suffix]="'óra'">
                        </app-editable-value>
                    </div>
                </td>
                <td>
                    <div class="editable-value-container">
                        <app-editable-value [field]="editableValueOptions[dept.depid]['f_sleepoverrate']"
                            (onSave)="editableValueSaveClicked($event, dept, 'f_sleepoverrate')" [suffix]="currency">
                        </app-editable-value>
                    </div>
                </td>
                <td *ngIf="isMealPenaltyAtDeptDefault">
                    <div class="editable-value-container">
                        <app-editable-value [field]="editableValueOptions[dept.depid]['f_mealPenaltyRate']"
                            (onSave)="editableValueSaveClicked($event, dept, 'f_mealPenaltyRate')" [suffix]="currency">
                        </app-editable-value>
                    </div>
                </td>
                <td>
                    <div class="editable-value-container">
                        <app-editable-value [field]="editableValueOptions[dept.depid]['f_graceperiod']"
                            (onSave)="editableValueSaveClicked($event, dept, 'f_graceperiod')" suffix="perc">
                        </app-editable-value>
                    </div>
                </td>
                <td class="text-center">
                    <mat-icon *ngIf="dept?.depstatus !== 0">check</mat-icon>
                </td>
                <td>
                    <button mat-icon-button [matMenuTriggerFor]="statusMenu" aria-label="More options">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #statusMenu="matMenu">
                        <button mat-menu-item (click)="toggleEnable(dept)"
                            [disabled]="saving$ | async"
                            title="Won't show up as option in the start form" class="btnStatusChange">
                            {{ dept?.depstatus === 0 ? 'Enable' : 'Disable' }}
                        </button>
                    </mat-menu>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="6">
                    <form [formGroup]="formAddDept" (submit)="addDept()">
                        <mat-form-field class="form-field">
                            <mat-label><span t7e="add-dept">Új részleg</span></mat-label>
                            <input type="text" matInput formControlName="depname">
                        </mat-form-field>
                    </form>

                </td>
            </tr>
        </tfoot>
    </table>
</div>