<ng-container [formGroup]="holidaysFG">
    <div class="holidays-inputs" formArrayName="savedHolidays">
        <div *ngIf="debug">{{ holidaysFG.value | json }}</div>
        <div class="holiday" *ngFor="let holiday of holidaysFA.controls; let i=index; trackBy:trackByIndex" [formGroupName]="i">
            <div class="date">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Ünnepnap</mat-label>
            <input matInput [matDatepicker]="day" formControlName="day" (keydown.enter)="saveHoliday({dayId: holiday.value.dayId!, date: holiday.value.day!}, $event)">
            <mat-datepicker-toggle matSuffix [for]="day"></mat-datepicker-toggle>
            <mat-datepicker #day></mat-datepicker>
        </mat-form-field>
            </div>
            <button mat-icon-button (click)="deleteHoliday(holiday.value.dayId!)" [disabled]="holidaySaving$ | async" type="button">
                <mat-icon>delete</mat-icon>
            </button>
            <button type="button" (click)="saveHoliday({dayId: holiday.value.dayId!, date: holiday.value!.day!})" mat-raised-button color="primary"
                *ngIf="!(holidaysLoading$ | async) && (holidays$ | async)?.length" [disabled]="holidaySaving$ | async">
                <mat-spinner [diameter]="15" *ngIf="holidaySaving$ | async"></mat-spinner>
                <span t7e="save">Save</span>
            </button>
        </div>
    </div>

    <div class="holidays-new" *ngIf="!(holidaysLoading$ | async)">
        <!-- new holiday -->
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Új ünnepnap</mat-label>
            <input matInput [matDatepicker]="newHoliday" formControlName="newHoliday" (keydown.enter)="addHoliday($event)">
            <mat-datepicker-toggle matSuffix [for]="newHoliday"></mat-datepicker-toggle>
            <mat-datepicker #newHoliday></mat-datepicker>
        </mat-form-field>

        <button type="button" (click)="addHoliday()" mat-raised-button color="primary"
            [disabled]="holidaySaving$ | async">
            <mat-spinner [diameter]="15" *ngIf="holidaySaving$ | async"></mat-spinner>
            <span t7e="save">Save</span>
        </button>
    </div>

    <div class="save-load-error mb-2" *ngIf="((saveError$ | async) || (holidaysLoadingError$ | async)) as error">
        <span>{{ error }}</span>
    </div>
</ng-container>
