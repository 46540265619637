import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { T7eService } from '../../t7e/t7e.service';

@Component({
  selector: 'app-save-comment',
  templateUrl: './save-comment.component.html',
  styleUrls: ['./save-comment.component.scss']
})
export class SaveCommentComponent implements OnInit, AfterViewInit {
  /** T7E */
  t7eQuestionText = this.t7e.getTranslation('app-save-comment', 'question-text', 'innerText', 'Kérlek, indokold meg a változtatást')
  t7eSaveButtonText = this.t7e.getTranslation('app-save-comment', 'save-button-text', 'innerText', 'Mentés')
  t7eCancelButtonText = this.t7e.getTranslation('app-save-comment', 'cancel-button-text', 'innerText', 'Mégse')
  /** T7E END */

  @Input() questionText: string = this.t7eQuestionText
  @Input() saveButtonText: string = this.t7eSaveButtonText
  @Input() cancelButtonText: string = this.t7eCancelButtonText
  @Input() isCommentOptional: boolean = true
  @Output() saveClicked = new EventEmitter<string>()
  @Output() cancelClicked = new EventEmitter<void>()

  @ViewChild('savecomment') saveCommentInput: ElementRef<HTMLTextAreaElement> | undefined
  
  form: FormGroup

  constructor(
    private fb: FormBuilder,
    private t7e: T7eService,
  ) {
    this.form = this.fb.group({
      answer: [null]
    })
  }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    this.saveCommentInput?.nativeElement.focus()
  }

  onSaved(): void {
    this.saveClicked?.emit(this.form.value.answer)
  }

  onCancelled(): void {
    this.cancelClicked?.emit()
  }


}
