import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { BehaviorSubject, Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs';
import { FieldHistoryParsed } from '../../models/field-history';
import { FlexTables } from '../../data.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit, OnDestroy {
  /** 'success' | 'waiting' | 'warning' és ts-status-atprodmgr | string  */
  @Input() statusClass?: string
  @Input() statusName?: string

  _saveComment$ = new BehaviorSubject<string | null>(null)
  @Input()
  get saveComment() { return this._saveComment$.value }
  set saveComment(val: string | null) { if (val !== this.saveComment) this._saveComment$.next(val || null)}

  _statusOf$ = new BehaviorSubject<StatusOf | null>(null)
  @Input()
  get statusOf() { return this._statusOf$.value }
  set statusOf(val: StatusOf | null) { if (val !== this.statusOf) this._statusOf$.next(val || null)}

  private readonly _destroy$ = new Subject<void>()
  whoWhen$?: Observable<FieldHistoryParsed | null>
  showWhoWhen = false
  isHistoryLoading$ = new  BehaviorSubject<boolean>(false)

  constructor(
    private appSvc: AppService,
  ) { 
    this.isStatusOfChanged = this.isStatusOfChanged.bind(this)
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  ngOnInit(): void {
    combineLatest([this._saveComment$, this._statusOf$])
      .pipe(
        takeUntil(this._destroy$),
        distinctUntilChanged((prev, curr) => {
          return (prev[0] === curr[0]) || !this.isStatusOfChanged(prev[1], curr[1])
        }),
        tap(() => {this.showWhoWhen = false}),
        debounceTime(1000),
        )
      .subscribe(([saveComment, statusOf]) => {
          this.showWhoWhen = !!statusOf && !!saveComment
          if (this.showWhoWhen) {
            const colnameMap = new Map([
              [FlexTables.contracts, 'constatus'],
              [FlexTables.timesheets, 'tsstatus'],
            ])
            const colname = colnameMap.get(statusOf!.tableId)
            console.assert(colname)
            if (colname) {
              this.isHistoryLoading$.next(true)
              this.whoWhen$ = this.appSvc.getFixHistory(statusOf!.tableId, statusOf!.id, colname)
              .pipe(
                map(hist => {
                  return hist?.find(x => x.akt) || null
                })
              )
              this.whoWhen$.subscribe({
                next: () => this.isHistoryLoading$.next(false),
                error: () => this.isHistoryLoading$.next(false),
                complete: () => this.isHistoryLoading$.next(false),
              })
            }
          }
        })

  }

  isStatusOfChanged(prev: StatusOf | null, curr: StatusOf | null): boolean {
    if (prev === null || prev === undefined) return !(curr === null || curr == undefined)
    return  !(prev.id == curr?.id && prev.tableId == curr?.tableId)
  }

}

export type StatusOf = { tableId: FlexTables, id: number }