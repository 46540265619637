import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, of, startWith, switchMap } from 'rxjs';
import { AppService, ButtonStatus, DbCallError } from '../../app.service';
import { ContractService } from '../contract.service';
import { FlexTables } from '../../data.service';
import { Contract, ContractParsed, ContractStatus, DocLink } from '../../models/contract';
import { HandleContractsParams, ListContractsParams } from '../../models/handle-contracts-params';
import { UserService } from '../../user.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { DocService } from '../../doc.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RemoveFileClickedEvent } from '../../util/doclink/doclink.component';
import { ContractStatusSelectItem } from '../../util/filters/filter-con-status/filter-con-status.component';
import { PropItem } from '../../models/table-property';
import { StateService, getReorderedConfig, getResizedConfig } from '../../state.service';
import { ColumnReorderEvent, ColumnResizeArgs, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, process } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { KeyValue } from '@angular/common';
import { T7eService } from '../../t7e/t7e.service';
import { config } from '../../config';
import * as moment from 'moment-timezone';
import { SQL_DATE_FORMAT } from '../../parser.service';
import { SendMailParams } from '../../models/mail';

@Component({
  selector: 'app-manage-contracts',
  templateUrl: './manage-contracts.component.html',
  styleUrls: ['./manage-contracts.component.scss']
})
export class ManageContractsComponent implements OnInit, AfterViewInit {
  CONFIG_PERSONAL_END_DATES = config.CONFIG_PERSONAL_END_DATES
  private _userId?: number
  private _userId$ = new BehaviorSubject<number | undefined>(undefined)
  @Input() 
  set userId(val: number | undefined) {
    this._userId$.next(val)
  }
  get userId() { return this._userId }
  
  @Input() showFilters = true
  @Input() height: 'full-height' | null = 'full-height'
  
  @ViewChild('grid') grid!: GridComponent
  
  filtersFG: FormGroup
  contractForGrid$ = new BehaviorSubject<ContractParsed[] | null>(this.contractSvc.allContracts$.value)
  searchFilter$ = new BehaviorSubject<string | null>(null)
  contractStatusFilter$ = new BehaviorSubject<ContractStatusSelectItem[] | null>(null)
  contractTypeFilter$ = new BehaviorSubject<PropItem | null>(null)
  dateApprovalFilter$ = new BehaviorSubject<Date | null>(null)
  filteredContractsForGrid$ = new BehaviorSubject<ContractParsed[] | null>(null)

  allContractsLoading$ = this.contractSvc.allContractsLoading$
  contractSaving$ = this.contractSvc.savingContract$
  getCurrency = this.contractSvc.getCurrency
  isSaveAndCalcOtRatesEqual = this.contractSvc.isSaveAndCalcOtRatesEqual
  isFinance = this.userSvc.isFinance
  isProdMgr = this.userSvc.isProdMgr
  getUserById = this.userSvc.getUserById

  contractAndUserFields: ExcelExportField[] = []
  history?: { tableid: number, id: number, propcode?: string, colname?: string }
  showSaveComment: { contract: ContractParsed, title: string, saveButtonText: string, isCommentOptional: boolean, newStatus: ContractStatus } | null = null
  isOtTaBulkChangerOpen = false

  constructor(
    private appSvc: AppService,
    private stateSvc: StateService,
    private contractSvc: ContractService,
    private notifSvc: NotificationService,
    private docSvc: DocService,
    private userSvc: UserService,
    private fb: FormBuilder,
    private t7e: T7eService,
  ) {
    this.allDataForExcel = this.allDataForExcel.bind(this)

    // create form-group first
    this.filtersFG = this.getEmptyFilterFG();
      
    // then subscribe
    (this.searchFC?.valueChanges as Observable<string | null>)
      .subscribe(this.searchFilter$);

    (this.contractStatusFC?.valueChanges as Observable<ContractStatusSelectItem[] | null>)
      .subscribe(this.contractStatusFilter$);

    (this.contractTypeFC?.valueChanges as Observable<PropItem | null>)
      .subscribe(this.contractTypeFilter$);
    
    (this.dateApprovalFC?.valueChanges as Observable<Date | null>)
      .subscribe(this.dateApprovalFilter$);
    
    // and only then set the initial values to fire valueChanges
    this.filtersFG.setValue(this.initFilterValues);

    // set the user preferences
    this.searchFilter$.subscribe(val => {
      this.stateSvc.setManageContractsPageState({ filters: { search: val } })
    })
    this.contractStatusFilter$.subscribe(val => {
      this.stateSvc.setManageContractsPageState({ filters: { contractStatuses: val } })
    })
    this.contractTypeFilter$.subscribe(val => {
      this.stateSvc.setManageContractsPageState({ filters: { contractType: val } })
    })
    this.dateApprovalFilter$.subscribe(val => {
      this.stateSvc.setManageContractsPageState({ filters: { dateApproval: val } })
    })

    // load the contracts from contractSvc.allContracts or from the server
    this.dateApprovalFilter$.subscribe(val => {
      if (!val) this.contractForGrid$.next(this.contractSvc.allContracts$.value)
      else this.reload(val)
    })

    this.contractSvc.allContracts$.subscribe((contractList => {
      this.contractForGrid$.next(contractList)
    }))

    // Excel exporthoz
    this.appSvc.flexProps$.subscribe(props => {
      this.contractAndUserFields = props?.map(p => ({
        field: 'f_' + p.propcode,
        title: `${p.propname} / ${p.engpropname}`,
      })) || []
      console.log(this.contractAndUserFields)
    })

    this._userId$.subscribe(userId => {
      this._userId = userId
    })
  }

  get debug() { return this.appSvc.debug }
  get contractTypeFC() { return this.filtersFG.get('contractType') as FormControl }
  get contractStatusFC() { return this.filtersFG.get('contractStatus') as FormControl }
  get dateApprovalFC() { return this.filtersFG.get('dateApproval') as FormControl }
  get deptFC() { return this.filtersFG.get('dept') as FormControl }
  get searchFC() { return this.filtersFG.get('search') as FormControl }

  ngOnInit(): void {
    // ha nem másik oldalba van beágyazva, akkor adjuk meg az oldal címét
    if (!this.userId) this.appSvc.pageTitle$.next(this.t7ePageTitle)

    const combined$ = combineLatest([this._userId$, this.searchFilter$, this.contractStatusFilter$, this.contractTypeFilter$, this.contractForGrid$])
    combined$
      .pipe(
        switchMap(([userId, searchValue, contractStatus, contractType, contracts]) => {
          if (searchValue === null || searchValue === undefined) searchValue = ''
          const aFilteredStatusIds = contractStatus?.map(s => s.id)
          const gridData = contracts
            // filter by userId
            ?.filter(c => {
              if (!userId) return true
              return c.usid === userId
            })
            // filter by search value
            ?.filter(c => JSON.stringify(c).toLowerCase().includes((searchValue as string).toLowerCase()))
            // filter by contract status
            .filter(c => {
              if (!contractStatus?.length) {
                return c
              } else {
                return aFilteredStatusIds!.includes(c.constatus!)
              }
            })
            // filter by contract type
            .filter(c => {
              if (!contractType?.piid) {
                return c
              } else {
                return c.f_contract_type == contractType.picode?.toString()
              }
            })
            // add crewColumn: concat usname, email, phonenumber
            .map(c => ({
              ...c,
              crewColumn: c.usname?.trim() + '$' + c.email + '$' + c.phonenumber,
            }))
            || []
          return of(gridData)
        })
      )
      .subscribe(this.filteredContractsForGrid$)
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      return
      this.grid.columns
        .map((col, i) => {
          col.orderIndex = col.orderIndex || i
          return col
        })
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .forEach(col => {
          //@ts-ignore
          const newIndex = this.gridSettings?.columnsConfig?.[col.field]?.orderIndex
          if (newIndex === undefined) return
          this.grid.reorderColumn(col, newIndex, { before: true })
        })
    }, 1)

  }

  private get initFilterValues() {
    const initialStatuses: ContractStatusSelectItem[] = this.isFinance
      ? [
        { "id": 11, "name": this.contractSvc.getStatusName(ContractStatus.crewMember), },
        { "id": 16, "name": this.contractSvc.getStatusName(ContractStatus.deptHead), },
        { "id": 12, "name": this.contractSvc.getStatusName(ContractStatus.prodMgr), },
        { "id": 15, "name": this.contractSvc.getStatusName(ContractStatus.prodMgrApproved), },
        { "id": 13, "name": this.contractSvc.getStatusName(ContractStatus.readyToSign), },
        { "id": 14, "name": this.contractSvc.getStatusName(ContractStatus.signed), },
      ]
      : [
        { "id": 12, "name": this.contractSvc.getStatusName(ContractStatus.prodMgr), },
      ]
    if (this.isFinance && this.contractSvc.isFinanceApprovalNeededConfig) {
      initialStatuses.push({ "id": 17, "name": this.contractSvc.getStatusName(ContractStatus.financeApproved), })
    }
    return {
      contractType: this.filterSettings?.contractType || null,
      contractStatus: this.filterSettings?.contractStatuses || initialStatuses,
      dateApproval: this.filterSettings?.dateApproval || null,
      dept: null,
      search: this.filterSettings?.search || '',
    }
  } 

  private getEmptyFilterFG(): FormGroup {
    const fg = this.fb.group({
      contractType: [null],
      contractStatus: [null],
      dateApproval: [null],
      dept: [null],
      search: [''],
    })
    return fg
  }

  reload(histStartDate?: Date): void {
    histStartDate = histStartDate || this.dateApprovalFilter$.value || undefined
    const params: ListContractsParams = histStartDate
      ? {
        _constatus: this.contractStatusFilter$.value?.map(s => s.id!),
        _histconstatus: [ContractStatus.prodMgrApproved],
        _histstartdate: moment(histStartDate).format(SQL_DATE_FORMAT),
      }
      : {}
    this.contractSvc.allContractsLoading$.next(true)
    this.contractSvc.loadContractError$.next(null)
    this.contractSvc.listContracts(params)
      .subscribe({
        next: contracts => {
          this.contractForGrid$.next(contracts)
          if (contracts?.length! > this.skip) this.resetSkip()
          this.contractSvc.allContractsLoading$.next(false)
          this.contractSvc.loadContractError$.next(false)
        },
        error: err => {
          this.notifSvc.addObservableNotif({ msg: this.t7eNotifErrorLoadingData, class: 'danger' })
          this.contractSvc.allContractsLoading$.next(false)
          this.contractSvc.loadContractError$.next(err)
        }
      })
  }


  removeContract(dataItem: ContractParsed): void {
    const params: HandleContractsParams = {
      _conid: dataItem.conid,
      _constatus: 0,
    }
    const hNotifSave = this.notifSvc.addObservableNotif({ msg: this.t7eNotifSaving })
    this.contractSvc.handleContracts(params, true)
      .subscribe({
        next: contract => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifSaved, class: 'success', duration: 1000 })
          this.handleSavedContract(dataItem, { ...dataItem, constatus: 0 })
        },
        error: err => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifErrorSavingData, class: 'danger' })
          console.error(err);

        }
      })

  }
  generateContracts(): void {
    this.selectedContractIds.forEach(id => {
      const contract = this.contractSvc.allContracts?.find(x => x.conid == id)
      if (contract) this.docSvc.generateContract(contract, false, false)
    })
  }
  
  disableContract(dataItem: Contract): void {
    this.saveNewStatus(dataItem, -1)
  }

  sendBackToCrewClicked(dataItem: ContractParsed): void {
    this.showSaveComment = {
      contract: dataItem,
      title: this.t7eNotifOkReturnToCrew,
      saveButtonText: this.t7eSaveCommentSendBackToCrewOkButton,
      isCommentOptional: true,
      newStatus: ContractStatus.crewMember,
    }
  }

  sendBackToCrew(dataItem?: Contract, saveComment?: string): void {
    if (dataItem) this.saveNewStatus(dataItem, ContractStatus.crewMember, saveComment, dataItem.constatus!)
    else {
      this.selectedContractIds.forEach(id => {
        const contract = this.contractSvc.allContracts?.find(x => x.conid == id)
        if (contract) this.saveNewStatus(contract, ContractStatus.crewMember, undefined, contract.constatus!)
      })
    }
  }

  approve(dataItem?: Contract): void {
     if (dataItem) this.saveNewStatus(dataItem, ContractStatus.prodMgrApproved, undefined, dataItem.constatus!)
     else {
      this.selectedContractIds.forEach(id => {
        const contract = this.contractSvc.allContracts?.find(x => x.conid == id)
        if (contract) this.saveNewStatus(contract, ContractStatus.prodMgrApproved, undefined, contract.constatus!)
      })
     }
  }
  approveFinance(dataItem?: Contract): void {
    if (dataItem) this.saveNewStatus(dataItem, ContractStatus.financeApproved, undefined, dataItem.constatus!)
    else {
      this.selectedContractIds.forEach(id => {
        const contract = this.contractSvc.allContracts?.find(x => x.conid == id)
        if (contract) this.saveNewStatus(contract, ContractStatus.financeApproved, undefined, contract.constatus!)
      })
    }
  }
  sendToProdMgr(dataItem?: Contract): void {
    if (dataItem) this.saveNewStatus(dataItem, ContractStatus.prodMgr)
    else {
      this.selectedContractIds.forEach(id => {
        const contract = this.contractSvc.allContracts?.find(x => x.conid == id)
        if (contract) this.saveNewStatus(contract, ContractStatus.prodMgr)
      })
    }
  }
  setReadyToSign(dataItem?: Contract): void {
    if (dataItem) this.saveNewStatus(dataItem, ContractStatus.readyToSign)
    else {
      this.selectedContractIds.forEach(id => {
        const contract = this.contractSvc.allContracts?.find(x => x.conid == id)
        if (contract) this.saveNewStatus(contract, ContractStatus.readyToSign)
      })
    }
  }
  setSigned(dataItem?: Contract): void {
    if (dataItem) this.saveNewStatus(dataItem, ContractStatus.signed)
    else {
      this.selectedContractIds.forEach(id => {
        const contract = this.contractSvc.allContracts?.find(x => x.conid == id)
        if (contract) this.saveNewStatus(contract, ContractStatus.signed)
      })
    }
  }
  private saveNewStatus(dataItem: Contract, newStatus: number | null, saveComment?: string, oldStatus?: ContractStatus): void {
    if (newStatus === null) {
      console.error('Null státusszal akartál menteni!');
      return
    }
    const params: HandleContractsParams = {
      _conid: dataItem.conid,
      _constatus: newStatus,
      _savecomment: saveComment?.trim() || null,
    }
    const hNotifSave = this.notifSvc.addObservableNotif({ msg: this.t7eNotifSaving, duration: false })
    this.contractSvc.handleContracts(params, true, oldStatus)
      .subscribe({
        next: contract => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifSaved, class: 'success', duration: 1000 })
          this.handleSavedContract(dataItem, contract)
        },
        error: err => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifErrorSavingData, class: 'danger' })
          console.error(err);

        }
      })
  }

  private handleSavedContract(oldContract: Contract, newContract: ContractParsed | null) {
    console.assert(newContract, 'A mentés után visszakapott szerződés nem lehet null!')
    const newContractsForGrid = this.appSvc.replaceDocItems <ContractParsed>([newContract!], this.contractForGrid$.value, 'conid')
    this.contractForGrid$.next(newContractsForGrid)
    const i = this.contractSvc.allContracts?.find(x => x.conid == oldContract.conid)
    if (!i) {
      console.error('A visszaküldött start form nem található az összes start form között. Ez lehetetlen!');
      return
    }
  }

  saveCommentReceived(comment: string): void {
    console.log(comment)
    this.sendBackToCrew(this.showSaveComment?.contract!, comment)
    this.showSaveComment = null
  }

  allDataForExcel(): ExcelExportData {
    const dataWithUsers = this.filteredContractsForGrid$.value?.map(c => ({
      // a szerződés adatai
      ...c,
      // felülírom azokat a mezőket, aminél máshogy kell megjelenítteni az adatot
      ...{
        "f_contract_type": c.contractTypeAsPropItem?.piname,
      },
      // a szerződéshez tartozó felhasználó adatai
      ...this.userSvc.getUserById(c.usid),
      // a szerződéshez tartozó felhasználó flex adatai
      ...this.userSvc.getUserById(c.usid)?.details
        ?.reduce((acc, curr) => {
          acc['f_' + curr.propcode] = curr.propvalue
          return  acc
        }, {} as any) || {},
    }))
    console.log(dataWithUsers)
    const result: ExcelExportData = {
      data: process(
        dataWithUsers || [],
        { sort: this.sort, }).data,
      }
    return result
  }

  openOtTaBulkChanger = () => this.isOtTaBulkChangerOpen = true
  closeOtTaBulkChanger = () => this.isOtTaBulkChangerOpen = false

  showHistory(e: MouseEvent, contract: Contract, propcodeOrColname: string, isFlex: boolean) {
    console.log('Előzmények a start formhoz: ', contract.conid);
    if (!contract.conid) return
    this.history =
    {
      tableid: FlexTables.contracts,
      id: contract.conid,
      propcode: isFlex ? propcodeOrColname : undefined,
      colname: isFlex ? undefined : propcodeOrColname,
    }
  }
  
  getUserData(dataItem: ContractParsed, fieldName: string): string {
    return dataItem.details?.find(field => field.propcode === fieldName)?.propvalue
  }

  trackByFieldCode = (index: number, field: KeyValue<string, any>): string => field.key

  removeFile(event: RemoveFileClickedEvent): void {
    this.docSvc.removeContractFile(event.doc, event.data as ContractParsed)
  }

  canSetReadyToSign=this.contractSvc.canSetReadyToSign
  canSetSigned = this.contractSvc.canSetSigned
  canSetAtProdMgr = this.contractSvc.canSetAtProdMgr
  canApprove = this.contractSvc.canProdMgrApprove
  canFinanceApprove = this.contractSvc.canFinanceApprove
  canSendBackToCrew = this.contractSvc.canSendBackToCrew
  canDelete = this.contractSvc.canDelete
  canDisable = this.contractSvc.canDisable
  isApproved = this.contractSvc.isApproved
  isConsideredFinanceApprovedOrAfter(dataItem: Contract) { return this.contractSvc.isConsideredFinanceApprovedOrAfter(this.contractSvc.allContracts, dataItem) }
  isFinanceApproved = this.contractSvc.isFinanceApproved
  isReadyToSign = this.contractSvc.isReadyToSign
  isSigned = this.contractSvc.isSigned
  isAtProdMgr = this.contractSvc.isAtProdMgr

  canApproveMultiple(): ButtonStatus {
    const okMsg = this.t7eNotifOkSetApprovedSelected
    const nokMsg = this.t7eNotifNokSetApprovedSelected
    return this.canSetMultiple(this.canApprove, okMsg, nokMsg)
  }
  canSetReadyToSignMultiple(): ButtonStatus {
    const okMsg = this.t7eNotifOkSetReadytosignSelected
    const nokMsg = this.t7eNotifNokSetReadytosignSelected
    return this.canSetMultiple(this.canSetReadyToSign, okMsg, nokMsg)
  }
  canSetSignedMultiple(): ButtonStatus {
    const okMsg = this.t7eNotifOkSetSignedSelected
    const nokMsg = this.t7eNotifNokSetSignedSelected
    return this.canSetMultiple(this.canSetSigned, okMsg, nokMsg)
  }
  canSendBackToCrewMultiple(): ButtonStatus {
    const okMsg = this.t7eNotifOkReturnToCrew
    const nokMsg = this.t7eNotifNokReturnToCrew
    return this.canSetMultiple(this.canSendBackToCrew, okMsg, nokMsg)
  }
  canSetAtProdMgrMultiple(): ButtonStatus {
    const okMsg = this.t7eNotifOkReturnToProdMgr
    const nokMsg = this.t7eNotifNokReturnToProdMgr
    return this.canSetMultiple(this.canSetAtProdMgr, okMsg, nokMsg)
  }
  canDeleteMultiple(): ButtonStatus {
    const okMsg = this.t7eNotifOkSetDeletedSelected
    const nokMsg = this.t7eNotifNokSetDeletedSelected
    return this.canSetMultiple(this.canDelete, okMsg, nokMsg)
  }
  canDisableMultiple(): ButtonStatus {
    const okMsg = this.t7eNotifOkSetDisabledSelected
    const nokMsg = this.t7eNotifNokSetDisabledSelected
    return this.canSetMultiple(this.canDisable, okMsg, nokMsg)
  }
  canSetMultiple(checkerFunction: (dataItem: ContractParsed) => ButtonStatus, okMsg: string, nokMsg: string): ButtonStatus {
    if (!this.selectedContractIds.length) return { disabled: true, title: this.t7eNotidNoneSelected }
    const cannotSetReadyToSign = this.selectedContractIds.some(id => {
      const contract = this.contractSvc.allContracts?.find(x => x.conid == id)
      return !contract || checkerFunction(contract).disabled
    })
    if (cannotSetReadyToSign) {
      return {disabled:true, title: nokMsg}
    }
    return { disabled: false, title: okMsg}

  }
  
  getRowClass(event: { dataItem: any, index: number }): string {
    if (event.dataItem.constatus === 0) return 'deleted-row'
    return ''
  }

  /** USER PREFERENCES */

  get selectedContractIds() { return this.gridSettings?.selectedContractIds || [] }
  set selectedContractIds(val: number[]) {
    this.stateSvc.setManageContractsPageState({ grid: { selectedContractIds: val, } })
  }

  get expandedDetailKeys() { return this.gridSettings?.expandedDetailKeys || [] }
  set expandedDetailKeys(val: number[]) {
    this.stateSvc.setManageContractsPageState({ grid: { expandedDetailKeys: val, } })
  }

  get gridSettings() {
    return this.stateSvc.manageContractsPageState$.value?.grid
  }
  get filterSettings() {
    return this.stateSvc.manageContractsPageState$.value?.filters
  }
  get skip() { return this.gridSettings?.state?.skip || 0 }
  get sort() { return this.gridSettings?.state?.sort || [] }
  get filter() { return this.gridSettings?.state?.filter || { filters: [], logic: 'and' } }


  dataStateChange(state: DataStateChangeEvent): void {
    this.stateSvc.setManageContractsPageState({ grid: { state } })
  }

  filterChanged(event: CompositeFilterDescriptor): void {
    this.resetSkip();
  }
  resetSkip(): void {
    this.stateSvc.setManageContractsPageState({
      grid: { state: { skip: 0 } }
    })
  }

  public onColReorder(e: ColumnReorderEvent): void {
    // set the new orderIndex to all affected columns
    const newColumnsConfig: any = getReorderedConfig(this.grid, e)
    this.stateSvc.setManageContractsPageState({ grid: { columnsConfig: newColumnsConfig } })
    //console.log(this.stateSvc.manageContractsPageState$.value?.grid?.columnsConfig)
  }

  public onColResized(e: ColumnResizeArgs[]): void {
    const newConfigs = getResizedConfig(e)
    newConfigs.forEach((newConfigs) => {
      this.stateSvc.setManageContractsPageState({ grid: { columnsConfig: newConfigs } });
    })
  }

  getWidth(field: string): number | undefined {
    const columnsConfig = this.gridSettings?.columnsConfig || {}
    return columnsConfig[field]?.width
  }

  getFolderUrl(dataItem: ContractParsed): string {
    return this.docSvc.getDriveLink(dataItem.f_folderid || null,'folder')
  }

  hasValue(value: any): boolean {
    return value !== null && value !== undefined
  }


  /** T7E */
  readonly compName = 'app-manage-contracts'
  t7ePageTitle = this.t7e.getTranslation(this.compName, 'page-title', 'innerText', null, null, 'Start Formok')
  t7eNotifErrorLoadingData = this.t7e.getTranslation(this.compName, 'notif', 'error-loading-data', null, null, 'Hiba történt a start form lista betöltése közben')
  t7eNotifErrorSavingData = this.t7e.getTranslation(this.compName, 'notif', 'error-saving-data', null, null, 'Hiba történt a start form mentése közben')
  t7eNotifSaving = this.t7e.getTranslation(this.compName, 'notif', 'saving', null, null, 'Mentés...')
  t7eNotifSaved = this.t7e.getTranslation(this.compName, 'notif', 'saved', null, null, 'Elmentve')
  t7eNotifOkSetApprovedSelected = this.t7e.getTranslation(this.compName, 'notif', 'ok-set-approved-selected', null, null, 'Kiválasztott start formok jóváhagyott státuszba helyezése')
  t7eNotifNokSetApprovedSelected = this.t7e.getTranslation(this.compName, 'notif', 'nok-set-approved-selected', null, null, 'Nem minden kiválasztott start form tehető jóváhagyott státuszba')
  t7eNotifOkSetReadytosignSelected = this.t7e.getTranslation(this.compName, 'notif', 'ok-set-readytosign-selected', null, null, 'Kiválasztott start formok "Aláírásra kész" státuszba helyezése')
  t7eNotifNokSetReadytosignSelected = this.t7e.getTranslation(this.compName, 'notif', 'nok-set-readytosign-selected', null, null, 'Nem minden kiválasztott start formra állítható be a "Aláírásra kész" státusz')
  t7eNotifOkSetSignedSelected = this.t7e.getTranslation(this.compName, 'notif', 'ok-set-signed-selected', null, null, 'Kiválasztott start formok "Aláírt" státuszba helyezése')
  t7eNotifNokSetSignedSelected = this.t7e.getTranslation(this.compName, 'notif', 'nok-set-signed-selected', null, null, 'Nem minden kiválasztott start formra állítható be a "Aláírt" státusz')
  t7eNotifOkReturnToCrew = this.t7e.getTranslation(this.compName, 'notif', 'ok-return-to-crew', null, null, 'Kiválasztott start formok visszaküldése a stábtagnak')
  t7eNotifNokReturnToCrew = this.t7e.getTranslation(this.compName, 'notif', 'nok-return-to-crew', null, null, 'Nem minden kiválasztott start form visszaküldhető a stábtagnak')
  t7eNotifOkReturnToProdMgr = this.t7e.getTranslation(this.compName, 'notif', 'ok-return-to-prodmgr', null, null, 'Kiválasztott start formok visszaküldése a gyártásvezetőnek')
  t7eNotifNokReturnToProdMgr = this.t7e.getTranslation(this.compName, 'notif', 'nok-return-to-prodmgr', null, null, 'Nem minden kiválasztott start form visszaküldhető a gyártásvezetőnek')
  t7eNotifOkSetDisabledSelected = this.t7e.getTranslation(this.compName, 'notif', 'ok-set-disabled-selected', null, null, 'Kiválasztott start formok inaktiválása')
  t7eNotifNokSetDisabledSelected = this.t7e.getTranslation(this.compName, 'notif', 'nok-set-disabled-selected', null, null, 'Nem minden kiválasztott start form inaktiválható')
  t7eNotifOkSetDeletedSelected = this.t7e.getTranslation(this.compName, 'notif', 'ok-set-deleted-selected', null, null, 'Kiválasztott start formok törlése')
  t7eNotifNokSetDeletedSelected = this.t7e.getTranslation(this.compName, 'notif', 'nok-set-deleted-selected', null, null, 'Nem minden kiválasztott start form törölhető')
  t7eNotidNoneSelected = this.t7e.getTranslation(this.compName, 'notif', 'none-selected', null, null, 'Nincs kiválasztva egy start form sem')
  t7eEffectiveTitle = this.t7e.getTranslation(this.compName, 'effective-title', 'innerText', null, null, 'Effective value, inherited from department default and producion default')
  t7eFilterContractDateApprovedLabel = this.t7e.getTranslation(this.compName, 'filter', 'contract-date-approved-label', null, null, 'Contract date approved')
  t7eSaveCommentSendBackToCrewOkButton = this.t7e.getTranslation(this.compName, 'save-comment', 'send-back-to-crew-ok-button', null, null, 'Visszaküldöm')
  t7eButtonTitleOpenOtTaBulkChanger = this.t7e.getTranslation(this.compName, 'button-open-otta-bulk-changer', 'title', null, null, 'A kijelöltek OT-ját és TA-ját állíthatod át ezzel a gombbal')
}

type ExcelExportField = {
  field: string,
  title: string,
}
