import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss']
})
export class RefreshButtonComponent implements OnInit {
  @Output() refresh = new EventEmitter<void>()
  constructor() { }

  ngOnInit(): void {
  }

  refreshClicked(e: MouseEvent): void {
    e.stopPropagation()
    this.refresh.emit()
  }
}
