import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductionService } from '../production.service';
import { BehaviorSubject, Subject, map, takeUntil } from 'rxjs';
import { StateService } from '../../state.service';
import { AddEvent, CancelEvent, DataStateChangeEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { ShootingDay } from '../../models/dispo';
import { ParserService } from '../../parser.service';
import { AppService } from '../../app.service';
import * as moment from 'moment-timezone';
import { NotificationService } from '../../util/notifications/notification.service';

@Component({
  selector: 'app-days',
  templateUrl: './days.component.html',
  styleUrls: ['./days.component.scss']
})
export class DaysComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject<void>()
  gridData$ = new BehaviorSubject<ShootingDay[]>([])
  public formGroup?: FormGroup;
  public editedRowIndex?: number;
  locations$ = this.prodSvc.locations$
  loadingGrid$ = this.prodSvc.daysLoading$

  constructor(
    private fb: FormBuilder,
    private prodSvc: ProductionService,
    private stateSvc: StateService,
    private parser: ParserService,
    private appSvc: AppService,
    private notifSvc: NotificationService,
  ) { 
    this.appSvc.pageTitle$.next('Forgatási napok')
    
    if (!this.prodSvc.locations$.value?.length) prodSvc.fetchShootingLocations()
    if (!this.prodSvc.days$.value?.length) prodSvc.fetchDays()
  }
  
  get debug() { return this.appSvc.debug }

  ngOnInit(): void {
    this.prodSvc.days$
      .pipe(
        takeUntil(this._destroy$),
        map(dispos => this.parser.disposToShootingDays(dispos, this.prodSvc.locations$.value || [])),
      )
      .subscribe(this.gridData$)
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  public addHandler(args: AddEvent): void {
    this.closeEditor(args.sender);
    // define all editable fields validators and default values
    this.formGroup = this.createFormGroup()
    // show the new row editor, with the `FormGroup` build above
    args.sender.addRow(this.formGroup);
  }

  public editHandler(args: EditEvent): void {
    // define all editable fields validators and default values
    const { dataItem } = args;
    this.closeEditor(args.sender);

    this.formGroup = this.createFormGroup(dataItem)

    this.editedRowIndex = args.rowIndex;
    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditor(args.sender, args.rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    const day: ShootingDay = formGroup.value;

    this.prodSvc.saveDay(day)
      .pipe(
        takeUntil(this._destroy$),
        map(this.parser.parseDbDispos),
        map(dispos => this.parser.disposToShootingDays(dispos, this.prodSvc.locations$.value || []))
      )
      .subscribe({
      next: (day) => {
        sender.closeRow(rowIndex);
      },
      error: (err) => {
        console.log('err', err)
        const msg = this.parser.parseSqlErrMsg(err)
        this.notifSvc.addObservableNotif({ msg, class: 'danger' })
      }
    })

  }

  public removeHandler(args: RemoveEvent): void {
    // remove the current dataItem from the current data source,
    // `editService` in this example
    const day: ShootingDay = args.dataItem;
    day.cameraWrapTime = day.crewCallTime
    day.locCode = null
    day.locId = null
    this.prodSvc.saveDay(day).subscribe({
      next: (day) => {
      },
      error: (err) => {
        console.log('err', err)
        const msg = this.parser.parseSqlErrMsg(err)
        this.notifSvc.addObservableNotif({ msg, class: 'danger' })
      }
    })
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  get gridSettings() {
    return this.stateSvc.manageContractsPageState$.value?.grid
  }
  get filterSettings() {
    return this.stateSvc.manageContractsPageState$.value?.filters
  }
  get skip() { return this.gridSettings?.state?.skip || 0 }
  get sort() { return this.gridSettings?.state?.sort || [] }
  dataStateChange(state: DataStateChangeEvent): void {
    this.stateSvc.setManageContractsPageState({ grid: { state } })
  }

  createFormGroup(dataItem?: ShootingDay) {
    return this.fb.group({
      shId: [(dataItem?.shId) || null as number | null],
      numDay: [(dataItem?.numDay) || null as number | null],
      date: [(dataItem?.date) || null as Date | null, [Validators.required]],
      crewCallTime: [(dataItem?.crewCallTime) || null as Date | null, [Validators.required]],
      cameraWrapTime: [(dataItem?.cameraWrapTime) || null as Date | null],
      locId: [(dataItem?.locId) || null as number | null],
      locCode: [(dataItem?.locCode) || null as number | null],
    })
  }

  public getFormControl(dataItem: ShootingDay, field: string): FormControl {
    // return the FormControl for the respective column editor
    return <FormControl>(this.formGroup?.get(field) as FormControl)
    
  }
}

