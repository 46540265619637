<h1 class="page-title" t7e="page-title">Weekly OT&TA</h1>

<kendo-grid [data]="weeklyOttas$ | async" 
    [loading]="(isWeeklyOttasLoading$ | async) || false"
    (detailExpand)="weekExpanded($event)"
    class="main-grid has-page-level-refrash-button">
    <kendo-grid-column
        [title]="t7eColWeekTitle"
        field="period">
        <ng-template kendoGridFooterTemplate>
            <button type="button" mat-button class="load-more" (click)="loadMore(4)">
                +4 {{ 'weeks' | t7e: 'weeks'}}...
            </button>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        [title]="t7eColOttaTitle"
        field="approved.grandTotal"
        headerClass="header-align-right">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-otta">
                {{ dataItem.approved.grandTotal | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column 
        [title]="'EUR'" 
        field="approved.grandTotal_dev"
        headerClass="header-align-right">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="col-otta">
                {{ dataItem.approved.grandTotal_dev | number:'1.0-0' }} <span class="unimportant">EUR</span>
            </div>
            <div *ngIf="debug">{{ dataItem | json }}</div>
        </ng-template>
    </kendo-grid-column>

    <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
        <!-- {{ (dailyOttas$[rowIndex] | async) | json}} -->
        <div *ngIf="dailyOttasError$[rowIndex] | async">
            <div class="error-wrapper">
                <mat-icon>error_outline</mat-icon>
                <p>There was an error loading the data.</p>
            </div>
        </div>

        <kendo-grid #subgrid [data]="(dailyOttas$[rowIndex] | async)?.grid || []" [formGroup]="formGroup!"
            (edit)="onEdit($event)" (cancel)="onCancel($event)" (save)="onSave($event)"
            [loading]="(isSaving$ |async) || (isDailyOttasLoading$[rowIndex] | async) || false">
            <kendo-grid-column
                [title]="t7eColDayTitle"
                field="period">
            </kendo-grid-column>
            <kendo-grid-column
                [title]="'Extras'"
                field="extra1"
                [editable]="true"
                editor="numeric"
                [format]="currencyFormat">
            </kendo-grid-column>
            <kendo-grid-column
                [title]="'Other OT/TA 1'"
                field="extra2"
                [editable]="true"
                editor="numeric"
                [format]="currencyFormat">
            </kendo-grid-column>
            <kendo-grid-column
                [title]="'Other OT/TA 2'"
                field="extra3"
                [editable]="true"
                editor="numeric"
                [format]="currencyFormat">
            </kendo-grid-column>
            <kendo-grid-column
                [title]="'CrewTime OT/TA'"
                field="approved.totalCT"
                headerClass="header-align-right">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-otta">
                        {{ dataItem.approved.totalCT | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span>
                    </div>
                    <div *ngIf="debug">{{ dataItem | json }}</div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="'CrewTime OT/TA'" field="approved.total_dev" headerClass="header-align-right">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-otta">
                        {{ dataItem.approved.totalCT_dev | number:'1.0-0' }} <span class="unimportant">EUR</span>
                    </div>
                    <div *ngIf="debug">{{ dataItem | json }}</div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="t7eColDailyOttaTitle" field="approved.grandTotal" headerClass="header-align-right">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-otta">
                        {{ dataItem.approved.grandTotal | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span>
                    </div>
                    <div *ngIf="debug">{{ dataItem | json }}</div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="t7eColDailyOttaTitle" field="approved.grandTotal_dev" headerClass="header-align-right">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="col-otta">
                        {{ dataItem.approved.grandTotal_dev | number:'1.0-0' }} <span class="unimportant">EUR</span>
                    </div>
                    <div *ngIf="debug">{{ dataItem | json }}</div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column title="Commands">
                <ng-template kendoGridCellTemplate>
                    <div class="column-buttons">
                        <button kendoGridEditCommand>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button kendoGridSaveCommand [disabled]="(saving$ |async)!">
                            Mentés
                        </button>
                        <button kendoGridCancelCommand>
                            Mégse
                        </button>
                    </div>
                </ng-template>
            </kendo-grid-command-column>

            <ng-template kendoGridNoRecordsTemplate>
                <div class="no-data-wrapper">
                    <p>No data available.</p>
                </div>
            </ng-template>
        </kendo-grid>

    </ng-template>

</kendo-grid>

<app-refresh-button (refresh)="refresh()"></app-refresh-button>
