import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocService, GenInvRepGroupBy } from '../../doc.service';
import { ContractParsed } from '../../models/contract';
import { TimesheetParsed, TimesheetWeekly } from '../../models/timesheet';
import { RemoveFileClickedEvent, SendPdfClickedEvent } from '../../util/doclink/doclink.component';
import { UserService } from '../../user.service';
import { BehaviorSubject } from 'rxjs';
import { ButtonStatus } from '../../app.service';

@Component({
  selector: 'app-gen-ts-file',
  templateUrl: './gen-ts-file.component.html',
  styleUrls: ['./gen-ts-file.component.scss']
})
export class GenTsFileComponent implements OnInit {
  @Input() dataItem?: TimesheetWeekly
  @Input() generationButtonStatus$?: BehaviorSubject<ButtonStatus>
  @Output() dataItemChange = new EventEmitter<TimesheetParsed>()
  @Output() draftGenerated = new EventEmitter<string | null>()

  getIsGenerating = (dataItem?: TimesheetWeekly) => { 
    return dataItem && this.docSvc.getIsTSGenerating(dataItem.tsids) 
  }
  getGeneratingError = (dataItem?: TimesheetWeekly) => {
    return dataItem && this.docSvc.getGeneratingTsError(dataItem.tsids)
  }
  generatePdf(
    dataItem: TimesheetWeekly[],
    sendEmail: boolean,
    groupBy: GenInvRepGroupBy,
    isDraft?: boolean,
    docId?: string | null,
    savePdf?: boolean,
  ) {
    this.docSvc.generateTSDoc(dataItem, sendEmail, groupBy, isDraft, docId, savePdf)
      ?.subscribe({
        next: res => {
          console.log('generateTSDoc válasz', res);
          this.draftGenerated.emit(res?.html || null)
        },
        error: err => {
          console.error('generateTSDoc error', err);
        }
      })
  }
  isProdMgr = this.userSvc.isProdMgr
  isFinance = this.userSvc.isFinance
  
  canGeneratePdf(dataItem?: TimesheetWeekly, okTitle?: string): ButtonStatus {
    if (this.generationButtonStatus$?.value.disabled) return this.generationButtonStatus$?.value
    let retVal = this.docSvc.canGenerateTSPdf(dataItem)
    if (retVal.disabled == false) retVal.title = okTitle || retVal.title
    return retVal
  } 
  get aGeneratingErrorValues$()  {
    return Object.values(this.docSvc.generatingTSError$)
    // distinct
      .filter((v, i, a) => a.findIndex(v2 => v2.value == v.value) == i)
  }
  trackByIndex = (index: number, item: any) => index

  constructor(
    private docSvc: DocService,
    private userSvc: UserService,
  ) { }

  ngOnInit(): void {
    console.assert(this.dataItem, 'GenFileComponent: @Input() dataItem kötelező')

    if (!this.generationButtonStatus$) this.generationButtonStatus$ = new BehaviorSubject({ disabled: false, title: '' } as ButtonStatus)
  }

  removeFile(event: RemoveFileClickedEvent): void {
    this.docSvc.removeTSFile(event.doc, event.data as TimesheetWeekly)
    this.dataItemChange.emit(event.data as TimesheetWeekly)
  }

  sendOnlyPdf(event: SendPdfClickedEvent): void {
    this.generatePdf([event.data as TimesheetWeekly], true, 'user', false, event.doc.fileid, true)
  }

}
