import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TimesheetService } from '../timesheet.service';
import { HandleTimesheetsParams, TimesheetParsed, TimesheetStatus } from '../../models/timesheet';
import { BehaviorSubject, Observable, Subject, map, of, shareReplay, takeUntil } from 'rxjs';
import { AppService } from '../../app.service';
import { SQL_DATE_FORMAT, SQL_TIME_FORMAT } from '../../parser.service';
import { NotificationService } from '../../util/notifications/notification.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-deleted-timesheets',
  templateUrl: './deleted-timesheets.component.html',
  styleUrls: ['./deleted-timesheets.component.scss']
})
export class DeletedTimesheetsComponent implements OnInit, OnDestroy {
  @Input() userId: number | null = null

private readonly _destroy$ = new Subject<void>()
  deletedTimesheets$ = new BehaviorSubject<TimesheetParsed[] | null>(null)
  
  trackByIndex = this.appSvc.trackByIndex
  SQL_TIME_FORMAT = SQL_TIME_FORMAT
  isTsLoading$ = new BehaviorSubject(false)

  constructor(
    private tsSvc: TimesheetService,
    private appSvc: AppService,
    private notifSvc: NotificationService,
  ) { 
  }
  
  get debug() { return this.appSvc.debug }

  ngOnInit(): void {
    this.reload()
  }
  
  reload() {
    this.isTsLoading$.next(true)
    this.tsSvc.listTs({
      _tsstatus: [TimesheetStatus.deleted],
      _usid: this.userId,
    })
      .pipe(
        //shareReplay(), // enélkül 2x hívódik meg a backend. 
        takeUntil(this._destroy$)
    )
      .subscribe({
        next: (response) => {
          this.isTsLoading$.next(false)
          this.deletedTimesheets$.next(response)
        },
        error: (error) => {
          this.isTsLoading$.next(false)
          this.notifSvc.addObservableNotif({msg: 'Hiba a törölt timesheetek betöltése közben', class: 'danger'})
        }
      })
  }

  restoreTs(ts: TimesheetParsed): void {
    if (!confirm(moment(ts.dStartDate).format(SQL_DATE_FORMAT) + ': Biztos visszaállítod?')) return
    const params: HandleTimesheetsParams = {
      _tsid: ts.tsid,
      _comment: 'Törlésből visszaállítva',
      _tsstatus: TimesheetStatus.atCrewMember,
    }
    this.isTsLoading$.next(true)
    this.tsSvc.handleTs(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this.reload()
          this.isTsLoading$.next(false)
        },
        error: () => {
          this.isTsLoading$.next(false)
          this.notifSvc.addObservableNotif({ msg: 'Hiba történt a timesheet visszaállítás közben', class: 'danger', })
        }
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

}
