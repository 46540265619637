
    <div [formGroup]="form!" *ngFor="let otrate of otratesFA?.value; let i=index; trackBy: trackByIndex">
        <ng-container formArrayName="otrates">

        
        <mat-form-field class="form-field input-number" [formGroupName]="i" appearance="outline">
            <mat-label>{{ t7eNthHour(i+1) }}</mat-label>
            <input matNativeControl formControlName="otrate" type="number" min="0" step="500">
            <span matSuffix>&nbsp;{{ form?.get('currency')?.getRawValue() }}</span>
            <mat-hint *ngIf="i === otratesFA?.value.length-1; else firstHours">
                <span *ngIf="lang == 'hu'">
                    {{ t7eHintTextPrefix }} az {{i+1}}. túlórától
                </span>
                <span *ngIf="lang == 'en'">
                    {{ t7eHintTextPrefix }} from the {{i+1}}{{ t7eNth(i+1) }} overtime hour
                </span>
            </mat-hint>
            <ng-template #firstHours>
                <mat-hint *ngIf="lang =='hu'">{{ t7eHintTextPrefix }} az {{i+1}}. túlórára</mat-hint>
                <mat-hint *ngIf="lang =='en'">{{ t7eHintTextPrefix }} for the {{i+1}}{{t7eNth(i+1)}} OT hour</mat-hint>
            </ng-template>
        </mat-form-field>
        </ng-container>
    </div>
