    <!-- 
    1 combo
    2 int
    3 float
    4 text
    5 timestamp
    6 upload -->
<div [formGroup]="formGroup!" 
    [ngStyle]="formGroupStyles" 
    *ngFor="let flexField of (flexFields$ | async) | callback:isAllowedPropCode" 
    class="field-wrapper">
    <!-- {{ flexFields$ | async | json }} -->

    <div *ngIf="debug">
        <p>FlexField: {{ flexField | json }}</p>
        <p>FormControlName: {{ flexField.propcode }}</p>
        <p>flexField: {{ formGroup?.get(flexField.propcode)?.value | json }}</p>
        <p>Options: {{ (aPropItems$[flexField.propid] | async) | json }}</p>
    </div>
    <ng-container [ngSwitch]="flexField.edittype">
        <ng-container *ngSwitchCase="1">
            <!-- {{formGroup!.get(flexField.propcode)?.getRawValue() | json}} -->
            <mat-form-field [ngStyle]="formFieldStyles" appearance="outline">
                <mat-label>{{ lang=='hu' ? flexField.propname : flexField.engpropname}}</mat-label>
                <mat-select [formControlName]="flexField.propcode">
                    <mat-option [value]="null">{{ t7eSelect }}</mat-option>
                    <mat-option *ngFor="let option of (aPropItems$?.[flexField.propid] | async) | callback:isEnabledPropItem" 
                        [value]="option.picode.toString()">
                        {{ lang == 'hu' ? option.piname : option.pinameeng }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="(validate$ | async) && formGroup?.hasError('required', flexField.propcode)">
                    <span *ngIf="lang=='hu'"><strong>Kötelező</strong> mező</span>
                    <span *ngIf="lang=='en'"><strong>Required</strong></span>
                </mat-error>
                <mat-hint *ngIf="getPropDesc(flexField.propcode)">
                    <div [innerHTML]="getPropDesc(flexField.propcode)"></div>
                </mat-hint>
            </mat-form-field>
            <div class="notification-by-rule" *ngIf="notifByRule(flexField.propcode)">
                <mat-icon>info</mat-icon>
                <div [innerHTML]="notifByRule(flexField.propcode)"></div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="4">
            <mat-form-field [ngStyle]="formFieldStyles" appearance="outline">
                <mat-label>{{ lang=='hu' ? flexField.propname : flexField.engpropname}}</mat-label>
                <input matNativeControl [formControlName]="flexField.propcode" type="text">
                <mat-error *ngIf="(validate$ | async) && formGroup?.hasError('required', flexField.propcode)">
                    <span *ngIf="lang=='hu'"><strong>Kötelező</strong> mező</span>
                    <span *ngIf="lang=='en'"><strong>Required</strong></span>
                </mat-error>
                <mat-hint *ngIf="getPropDesc(flexField.propcode)">
                    <div [innerHTML]="getPropDesc(flexField.propcode)"></div>
                </mat-hint>
            </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="5">
            <mat-form-field [ngStyle]="formFieldStyles" appearance="outline">
                <mat-label>{{ lang=='hu' ? flexField.propname : flexField.engpropname}}</mat-label>
                <input matInput [matDatepicker]="picker" [formControlName]="flexField.propcode">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="(validate$ | async) && formGroup?.hasError('required', flexField.propcode)">
                    <span *ngIf="lang=='hu'"><strong>Kötelező</strong> mező</span>
                    <span *ngIf="lang=='en'"><strong>Required</strong></span>
                </mat-error>
                <mat-hint *ngIf="getPropDesc(flexField.propcode)">
                    <div [innerHTML]="getPropDesc(flexField.propcode)"></div>
                </mat-hint>
            </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="6">
            <div *ngIf="debug">
                <p>FormControlName: {{ flexField.propcode }}</p>
                <p>flexField: {{ formGroup?.get(flexField.propcode)?.value | json }}</p>
                <p>Options: {{ (aPropItems$[flexField.propid] | async) | json }}</p>
            </div>
            <kendo-formfield class="form-field-upload" [ngStyle]="formFieldStyles">
                <kendo-floatinglabel [text]="lang=='hu' ? flexField.propname : flexField.engpropname">
                    <div *ngIf="getUrl(flexField.propcode);else nothingUploaded" class="uploaded-file">
                        <a *ngIf="uploadFolderId" type="button" [href]="getFolderUrl()">
                            Folder
                        </a>
                        &nbsp;&gt;&nbsp;
                        <a [href]="getUrl(flexField.propcode)" title="ctrl + click to open it in a new tab">
                            {{ getFilename(flexField.propcode) }}
                        </a>
                        <mat-spinner [diameter]="20" *ngIf="removingDocMap.get(flexField.propcode) | async"></mat-spinner>
                        <button mat-icon-button type="button" (click)="removeFile(null, flexField.propcode)"
                            [disabled]="removingDocMap.get(flexField.propcode) | async">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="removingDocErrorMap.get(flexField.propcode) | async" class="remove-error">
                        <mat-icon>error</mat-icon>
                        <span>{{ removingDocErrorMap.get(flexField.propcode) | async }}</span>
                    </div>
                    <ng-template #nothingUploaded>
                        <span class="nothing-uploaded" t7e="nothing-uploaded">
                            Még nem töltöttél fel fájlt
                        </span>
                    </ng-template>
                    
                    <kendo-upload #fileUploadElement [formControlName]="flexField.propcode + KENDOCONTROL_NAME" 
                        [saveUrl]="uploadSaveUrl"
                        [removeUrl]="uploadRemoveUrl" [restrictions]="uploadRestrictions" [multiple]="false"
                        (error)="fileUploadedError($event)" (success)="fileUploaded($event, flexField)" 
                        (remove)="removeFile($event, flexField.propcode)">
                        <kendo-upload-messages
                            [dropFilesHere]="t7eDropFilesHere" [clearSelectedFiles]="t7eClearSelectedFiles"
                            [select]="t7eSelectFile" [cancel]="t7eCancel" [pause]="t7ePause" [resume]="t7eResume" [retry]="t7eRetry"
                            [uploadSelectedFiles]="t7eUploadSelectedFiles" [remove]="t7eRemove" 
                            [fileStatusFailed]="t7eFileStatusFailed" [fileStatusUploaded]="t7eFileStatusUploaded"
                            [headerStatusPaused]="t7eHeaderStatusPaused" [headerStatusUploaded]="t7eHeaderStatusUploaded" [headerStatusUploading]="t7eHeaderStatusUploading">
                        </kendo-upload-messages>
                    </kendo-upload>
                </kendo-floatinglabel>
                <!-- <input #fileUploadElement type="file" [formControlName]="flexField.propcode" id="file" 
                    (change)="onFileChanged($event)"> -->
                <kendo-formhint class="kendo-formhint" *ngIf="getPropDesc(flexField.propcode)">
                    <div [innerHTML]="getPropDesc(flexField.propcode)"></div>
                </kendo-formhint>
            </kendo-formfield>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <mat-form-field [ngStyle]="formFieldStyles" appearance="outline">
                <mat-label>{{lang=='hu' ? flexField.propname : flexField.engpropname}}</mat-label>
                <input matNativeControl [formControlName]="flexField.propcode" type="number">
                <mat-error *ngIf="(validate$ | async) && formGroup?.hasError('required', flexField.propcode)">
                    <span *ngIf="lang=='hu'"><strong>Kötelező</strong> mező</span>
                    <span *ngIf="lang=='en'"><strong>Required</strong></span>
                </mat-error>
                <mat-hint *ngIf="getPropDesc(flexField.propcode)">
                    <div [innerHTML]="getPropDesc(flexField.propcode)"></div>
                </mat-hint>
            </mat-form-field>

        </ng-container>
    </ng-container>
</div>