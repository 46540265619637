<div class="loading" *ngIf="loading$ | async; else loaded">
    <mat-spinner [diameter]="50"></mat-spinner>
</div>
<div class="loading" *ngIf="!(loading$ | async) && (loadError$ | async)">
    {{ loadError$ | async}}
</div>

<ng-template #loaded>
    <div class="main-grid">

        <div class="label start" t7e="start-label">
            Kezdés:
        </div>
        <div class="value start">
            <span *ngIf="lang=='hu'">{{ (reasons$ | async)?.dStartdate | date:dateFormat }}-tól</span>
            <span *ngIf="lang=='en'">from {{ (reasons$ | async)?.dStartdate | date:dateFormat }}</span>
        </div>
        <div class="value end">
            <span *ngIf="lang=='hu'">{{ (reasons$ | async)?.dEnddate | date:dateFormat }}-ig</span>
            <span *ngIf="lang=='en'">until {{ (reasons$ | async)?.dEnddate | date:dateFormat }}</span>
        </div>

        <div class="title">
        </div>

        <div class="label daily" *ngIf="showSalary" t7e="daily-fee-label">
            Napidíj:
        </div>
        <div class="contract title" t7e="as-in-contract-label">
            Szerződés szerint
        </div>
        <div class="calc title" t7e="as-calculated-label">
            Kalkulált érték
        </div>
        <div class="saved title" t7e="as-saved-label">
            Elmentett érték
        </div>
        <div class="value saved salary" *ngIf="showSalary && (reasons$ | async)?.saved_salary !== null && !hasInvoiceContact; else noSavedSalary">
            {{ (reasons$ | async)?.saved_salary | number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="saved-daily-fee">elmentett napidíj</span>
        </div>
        <ng-template #noSavedSalary>
            <div class="value saved salary no-saved-value" *ngIf="showSalary && !hasInvoiceContact" t7e="no-saved-value">
                nincs mentett érték
            </div>
        </ng-template>
        <div class="value calc salary" *ngIf="showSalary && !hasInvoiceContact">
            {{ (reasons$ | async)?.calcsal || 0 | number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="calculated-daily-fee">kalkulált napidíj</span>
        </div>
        <div class="value contract salary" *ngIf="showSalary && !hasInvoiceContact">
            {{ (reasons$ | async)?.dailyrate || 0 | number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="normal-daily-rate">normál napidíj</span>
        </div>
        <div class="value contract preprodrate" *ngIf="showSalary && (reasons$ | async)?.preprodrate && !hasInvoiceContact">
            {{ (reasons$ | async)?.preprodrate | number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="during-preprod">előkészítés alatt</span>
        </div>
        <div class="value calc halfday" *ngIf="showSalary && (isHalfDay$ | async); else noHalfDay" t7e="halfday">
            Fél nap munka volt (félnapra nem jár OT)
        </div>
        <ng-template #noHalfDay>
            <div class="value calc halfday" *ngIf="showSalary" t7e="no-halfday">
                Nem volt fél nap
            </div>
        </ng-template>
        <div class="value contract perdiem" *ngIf="showSalary && (isPerdiem$ | async) && !hasInvoiceContact; else noPerdiem">
            {{ (reasons$ | async)?.perdiemrate| number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="perdiem-value">Per Diem díj jár</span>
        </div>
        <ng-template #noPerdiem>
            <div class="value calc perdiem" *ngIf="showSalary && !hasInvoiceContact" t7e="no-perdiem">
                Nem jár Per Diem
            </div>
        </ng-template>
        <div class="value calc travelday" *ngIf="showSalary && (isTravelday$ | async) && !hasInvoiceContact; else notTravelday" t7e="travelday-half-fee">
            Utazónapra fél napidíj jár
        </div>
        <ng-template #notTravelday>
            <div class="value calc travelday" *ngIf="showSalary && !hasInvoiceContact" t7e="not-a-travelday">
                Nem volt utazónap
            </div>
        </ng-template>


        <div class="label ot" *ngIf="showOt">
            OT:
        </div>
        <div class="value calc otrate" *ngIf="showOt && !hasInvoiceContact">
            {{ ((reasons$ | async)?.calcot) || 0 | number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="calculated-ot-fee-value">túlóradíj jár erre a napra</span>
            <span *ngIf="isMealPenalty$ | async"> + Meal Penalty {{ (reasons$ | async)?.mealPenaltyFee }} {{ (reasons$ | async)?.currency
                }}</span>
        </div>
        <div class="value contract otrate" *ngIf="showOt && !hasInvoiceContact">
            {{( (reasons$ | async)?.otrate) || 0 | otRate }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="ot-rate-value">a szerződéses túlóradíj</span>
            <br>
            {{ (reasons$ | async)?.otstep| number:numberFormat }}&nbsp;
            <span t7e="ot-step-value">órás lépésekben</span>
            <br>
            {{ (reasons$ | async)?.graceperiod || 0 | number:numberFormat }}&nbsp;
            <span t7e="minute">perc</span>&nbsp; grace period

        </div>
        <div class="value saved otrate" *ngIf="showOt && (reasons$ | async)?.saved_otfee !== null && !hasInvoiceContact; else  noSavedOtrate">
            {{ (reasons$ | async)?.saved_otfee | number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="saved-ot-value">az elmentett túlóradíj</span>
        </div>
        <ng-template #noSavedOtrate>
            <div class="value saved otrate no-saved-value" *ngIf="showOt && !hasInvoiceContact" t7e="no-saved-value">
                nincs mentett érték
            </div>
        </ng-template>
        <div class="value othours" *ngIf="showOt">
            {{ (reasons$ | async)?.workinghours | number:numberFormat }}&nbsp;
            <span t7e="workhours-value">óra előírt munkaidő</span>
        </div>
        <div class="value calc othours" *ngIf="showOt">
            {{ ((reasons$ | async)?.calcothours) || 0 | number:numberFormat }}&nbsp;
            <span t7e="ot-hours-value">óra túlóra</span>
            <span *ngIf=" isHalfDay$ | async"> (félnapra nem jár OT)</span>
        </div>

        <div class="label ta" *ngIf="showTa">
            TA:
        </div>
        <div class="value calc tarate" *ngIf="showTa && !hasInvoiceContact">
            {{ (reasons$ | async)?.calcta || 0 | number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="calculated-ta-fee-value">kalkulált TA díj</span>
        </div>
        <div class="value calc taday" *ngIf="showTa && (reasons$ | async)?.dPrevenddate; else noPrevDay">
            <span t7e="prev-day-end-value">Előző munkanap vége: </span>
            {{ (reasons$ | async)?.dPrevenddate | date:dateFormat }}
        </div>
        <ng-template #noPrevDay>
            <div class="value calc taday" *ngIf="showTa" t7e="no-prev-day">
                Ez az első munkanap
            </div>
        </ng-template>
        <div class="value calc tahours" *ngIf="showTa">
            {{ ((reasons$ | async)?.calctahours) || 0 | number:numberFormat }}&nbsp;
            <span t7e="ta-hours-value">órával kevesebb pihenőidő volt</span>
        </div>
        <div class="value contract tarate" *ngIf="showTa && !hasInvoiceContact">
            {{ ((reasons$ | async)?.tarate) || 0 | number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="ta-rate-value">TA díj óránként</span>
        </div>
        <div class="value saved tarate" *ngIf="showTa && (reasons$ | async)?.saved_tafee !== null && !hasInvoiceContact; else noSavedTarate">
            {{ (reasons$ | async)?.saved_tafee | number:numberFormat }}&nbsp;
            {{ (reasons$ | async)?.currency }}&nbsp;
            <span t7e="saved-ta-fee">az elmentett TA díj</span>
        </div>
        <ng-template  #noSavedTarate>
            <div class="value saved tarate no-saved-value" *ngIf="showTa && !hasInvoiceContact" t7e="no-saved-value">
                nincs mentett érték
            </div>
        </ng-template>
        <div class="value contract tastep" *ngIf="showTa">
            {{ (reasons$ | async)?.tastep| number:numberFormat }}&nbsp;
            <span t7e="ta-step-value">órás lépésekben</span>
        </div>
        <div class="value calc numworkingdays" *ngIf="showTa && (reasons$ | async)?.tadays">
            <span *ngIf="lang=='hu'">
                {{ (reasons$ | async)?.dFirstworkstartdate| date:dateFormat }}&nbsp;
                óta,&nbsp;
                {{ ((reasons$ | async)?.tadays) || 0 | number:numberFormat }}&nbsp;
                napja nem volt pihenőnap
            </span>
            <span *ngIf="lang=='en'">
                since {{ (reasons$ | async)?.dFirstworkstartdate| date:dateFormat }},&nbsp;
                {{ ((reasons$ | async)?.tadays) || 0 | number:numberFormat }}&nbsp;
                days without restday
            </span>
        </div>
        <div class="value contract shortresthours" *ngIf="showTa">
            {{ (reasons$ | async)?.shortresthours | number:numberFormat }}&nbsp;
            <span t7e="short-rest-hours">órás rövid pihenőidő jár</span>
        </div>
        <div class="value contract longresthours" *ngIf="showTa">
            <span *ngIf="lang=='hu'">
                {{ (reasons$ | async)?.longresthours| number:numberFormat }}&nbsp;
                órás pihenőnap jár
                {{ (reasons$ | async)?.maxworkdayperiod| number:numberFormat }}&nbsp;
                nap után
            </span>
            <span *ngIf="lang=='en'">
                {{ (reasons$ | async)?.longresthours| number:numberFormat }}&nbsp;
                hours restday after
                {{ (reasons$ | async)?.maxworkdayperiod| number:numberFormat }}&nbsp;
                days
            </span>
        </div>
    </div>

</ng-template>