import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-role',
  templateUrl: './icon-role.component.html',
  styleUrls: ['./icon-role.component.scss']
})
export class IconRoleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
