import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppUser } from '../../models/db-user';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ContractParsed } from '../../models/contract';
import { ContractService } from '../contract.service';
import { AppService } from '../../app.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-user-contracts',
  templateUrl: './user-contracts.component.html',
  styleUrls: ['./user-contracts.component.scss']
})
export class UserContractsComponent implements OnInit, OnDestroy {

  @Input() user$?: BehaviorSubject<AppUser | null>
  
  userContracts$ = new BehaviorSubject<ContractParsed[]>([])
  contractsLoading$ = this.contractSvc.allContractsLoading$
  selectedContract$ = new BehaviorSubject<ContractParsed | null>(null)
  
  private _destroy$ = new Subject<void>();
  
  constructor(
    private contractSvc: ContractService,
    private userSvc: UserService,
    private appSvc: AppService,
    private route: ActivatedRoute,
  ) { 

    this.contractSvc.selectedContract$
      .pipe(takeUntil(this._destroy$))
      .subscribe(this.selectedContract$)

  }

  get user() { return this.user$?.value }
  get debug() { return this.appSvc.debug }

  ngOnInit(): void {
    if (!this.user?.usid) {
      //get userId from this.route
      const userId = parseInt(this.route.snapshot.paramMap.get('userId') || 'qwertz')
      if (!isNaN(userId)) {
        const user = this.userSvc.getUserById(userId || null)
        if (!this.user$) this.user$ = new BehaviorSubject<AppUser | null>(user)
        else this.user$?.next(user)
      }
    }

    console.assert(this.user?.usid, 'user-contact-ccomponent: user is null')
    if (!this.user?.usid) {
      this.user$?.next({
        usid: -1,
        usname: 'n/a',
      } as AppUser)
      this.userContracts$.next([])
      this.selectedContract$.next(null)
      return
    }

    const contracts = this.contractSvc.getContractsByUser(this.user.usid)
    this.userContracts$.next(contracts)
    if (contracts.length) {
      this.selectedContract$.next(contracts[0])
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }



}
