<div class="contract"  (click)="selectContract()"
    [class.selected]="(isSelected$ | async)"
    *ngIf="this.contract$ | async" [title]="(contract$ | async)?.['conid']">

    <div class="header">
        <div class="col1 avatar">
            <app-icon-role></app-icon-role>
            </div>
            <div class="col2 name">
                {{(contract$ | async)?.['role']}}
            </div>
            </div>

                <div class="body">
                    <div class="content">
                        <div class="col1 icon">
                            <app-icon-company></app-icon-company>
                        </div>
                        <div class="col2 data">
                            {{(contract$ | async)?.['f_companyname']}}
                        </div>
                    </div>
                    <div class="content">
                        <div class="col1 icon">
                            &nbsp;
                        </div>
                        <div class="col2 data">
                            <app-status 
                                [statusName]="contract$ | async | contractStatusName"
                                [statusClass]="contract$ | async | contractStatusClass" >
                            </app-status>
                        </div>
                    </div>
                </div>

            </div>
