import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { T7eDirective as T7eDirective } from './t7e.directive';
import { T7ePipe } from './t7e.pipe';



@NgModule({
  declarations: [
    T7eDirective,
    T7ePipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    T7eDirective,
    T7ePipe,
  ]
})
export class T7eModule { }
