import { Component,  OnDestroy, OnInit } from '@angular/core';
import {Location} from '@angular/common'; 
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, combineLatest, map, startWith, takeUntil, timeout } from 'rxjs';
import { AppService } from '../../app.service';
import { ContractService } from '../../contract/contract.service';
import { ContractParsed } from '../../models/contract';
import { AppUser } from '../../models/db-user';
import { Department } from '../../models/department';
import { ListTimesheetsParams, Timesheet, TimesheetParsed } from '../../models/timesheet';
import { UserService } from '../../user.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { TimesheetService } from '../timesheet.service';
import { TimesheetStatus } from '../../models/timesheet';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GetTsDatePipe } from '../ts.pipe';
import { TimesheetStatusFilterItem, filterTsstatusesForProdMgr } from '../../util/filters/filter-tsstatus/filter-tsstatus.component';
import { T7eService } from '../../t7e/t7e.service';
import { StateService } from '../../state.service';
import { ProductionService } from '../../prod/production.service';
import * as moment from 'moment-timezone';
import { SQL_DATETIME_FORMAT, SQL_DATE_FORMAT, SQL_TIMEZONE } from '../../parser.service';

@Component({
  selector: 'app-manage-timesheets',
  templateUrl: './manage-timesheets.component.html',
  styleUrls: ['./manage-timesheets.component.scss']
})
export class ManageTimesheetsComponent implements OnInit, OnDestroy {
  readonly tsList$ = new BehaviorSubject<TimesheetParsed[]>([])
  tsListLoading: boolean = false
  readonly tsListFiltered$ = new BehaviorSubject<TimesheetParsed[]>([]) 
  gridSizeChanged$ = new Subject<boolean>()
  depWithPendingTs$ =this.tsSvc.depWithPendingTs$
  contractsByDepartmentWithPendingTs$ = this.tsSvc.contractsByDeptWithPendingTs$
  unapprovedTsOfContract$ = this.tsSvc.unapprovedTsByContract$
  get debug() { return this.appSvc.debug; }

  form: FormGroup

  isDataLoaded = false
  selectedMode?: number
  selectedDeptTab: number = 0
  selectedContractTab: number = 0
  selectedTsTab: number = 0

  fromFilter$ = new BehaviorSubject<Date | null>(null)
  toFilter$ = new BehaviorSubject<Date | null>(null)
  tsStatusFilter$ = new BehaviorSubject<TimesheetStatusFilterItem[] | null>(null)
  userFilter$ = new BehaviorSubject<AppUser | null>(null)
  deptFilter$ = new BehaviorSubject<Department | null>(null)
  workhoursFilter$ = new BehaviorSubject<number | null>(null)

  destroy$ = new Subject()

  constructor(
    private tsSvc: TimesheetService,
    private contractSvc: ContractService,
    private userSvc: UserService,
    private appSvc: AppService,
    private notifSvc: NotificationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private getTsDatePipe: GetTsDatePipe,
    private t7e: T7eService,
    private stateSvc: StateService,
    private prodSvc: ProductionService,
  ) {
    this.appSvc.pageTitle$.next('Timesheetek')
    this.findTabFromRoute = this.findTabFromRoute.bind(this)

    tsSvc.tsListLoading$.subscribe(x => this.tsListLoading = x)

    // create form-group first
    this.form = this.getFG();

    // then subscribe
    (this.fromFC.valueChanges as Observable<Date | null>).subscribe(this.fromFilter$);
    (this.toFC.valueChanges as Observable<Date | null>).subscribe(this.toFilter$);
    (this.statusFC.valueChanges as Observable<TimesheetStatusFilterItem[] | null>).subscribe((val) => {
      this.gridSizeChanged$.next(true)
      this.tsStatusFilter$.next(val)
    });
    (this.userFC.valueChanges as Observable<AppUser | null>).subscribe(this.userFilter$);
    (this.deptFC.valueChanges as Observable<Department | null>).subscribe(this.deptFilter$);
    (this.workhoursFC.valueChanges as Observable<number | null>).subscribe(this.workhoursFilter$);

    // and only then set the initial values to fire valueChanges
    this.form.patchValue(this.initFilterValues);

    // set the user preferences
    this.fromFilter$.subscribe(val => {
      this.stateSvc.setManageTimesheetsPageState({ filters: { from: val } })
    })
    this.toFilter$.subscribe(val => {
      this.stateSvc.setManageTimesheetsPageState({ filters: { to: val } })
    })
    this.tsStatusFilter$.subscribe(val => {
      this.stateSvc.setManageTimesheetsPageState({ filters: { tsStatuses: val } })
    });
    this.userFilter$.subscribe(val => {
      this.stateSvc.setManageTimesheetsPageState({ filters: { user: val || null } })
    });
    this.deptFilter$.subscribe(val => {
      this.stateSvc.setManageTimesheetsPageState({ filters: { dept: val || null } })
    });
    this.workhoursFilter$.subscribe(val => {
      this.stateSvc.setManageTimesheetsPageState({ filters: { workhours: val || null } })
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.reload()

    combineLatest([this.tsList$, (this.workhoursFC.valueChanges as Observable<number | null>).pipe(startWith(null))])
      .pipe(map(([tsList, workhours]) => tsList?.filter(ts => !workhours || isNaN(workhours as number) || ts.workhours == workhours)))
      .subscribe(tsList => {
        this.tsListFiltered$.next(tsList)
      })

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.findTabFromRoute()
      }
    })
    
    this.tsSvc.depWithPendingTs$.subscribe(this.findTabFromRoute)
  }

  private findTabFromRoute(): void {
    let tsIdFromRoute: number | undefined
    let contractIdFromRoute: number | undefined
    let deptIdFromRoute: number | undefined/* = Number(routeParams.get('deptId')) 
      || (contractIdFromRoute ? this.contractSvc.allContracts$.value?.find(x => x.conid == contractIdFromRoute)?.depid : null)
      // todo lecserélni depid-re, ha Zoli beleteszi a depid-et a ts-be
      //|| (tsIdFromRoute ? this.tsSvc.tsList$.value?.find(x => x.tsid == tsIdFromRoute)?.depid : null)
      || (tsIdFromRoute ? this.deptSvc.departments$.value?.find(d => this.tsSvc.tsList$.value?.find(x => x.tsid == tsIdFromRoute)?.depname == d.depname)?.depid : null)*/

    const urlsForMode1 = ['by-dept', 'by-contract', 'by-ts']
    //console.log(this.route.snapshot.children[0]?.url, this.route.snapshot.url)
    const routeEnding = this.route.snapshot.children[0]?.url
    if (routeEnding?.some(x =>  urlsForMode1.some(url => x.path == url))) {
      this.selectedMode = 1

      if (routeEnding[0]?.path == 'by-ts') {
        tsIdFromRoute = Number(routeEnding[1]?.path) 
        contractIdFromRoute = this.tsSvc.tsList$.value?.find(x => x.tsid == tsIdFromRoute)?.conid || undefined
        deptIdFromRoute = this.contractSvc.allContracts$.value?.find(x => x.conid == contractIdFromRoute)?.depid || undefined
      } else if (routeEnding[0]?.path == 'by-contract') {
        contractIdFromRoute = Number(routeEnding[1]?.path) || undefined
        deptIdFromRoute = this.contractSvc.allContracts$.value?.find(x => x.conid == contractIdFromRoute)?.depid || undefined
      } else if (routeEnding[0]?.path == 'by-dept') {
        deptIdFromRoute = Number(routeEnding[1]?.path) || undefined
      }
  
      if (deptIdFromRoute !== undefined) {
        this.selectedDeptTab = this.tsSvc.depWithPendingTs$.value.findIndex(d => d.depid == deptIdFromRoute)
        if (contractIdFromRoute !== undefined) {
          this.selectedContractTab = this.tsSvc.contractsByDeptWithPendingTs$[deptIdFromRoute]?.value
            ?.findIndex(c => c?.conid == contractIdFromRoute)
  
          if (tsIdFromRoute !== undefined) {
            this.selectedTsTab = this.tsSvc.unapprovedTsByContract$[contractIdFromRoute]?.value
              ?.findIndex(t => t?.tsid == tsIdFromRoute)
          }
        }
      }
    }

  }

  selectedModeChanged(event: MatTabChangeEvent): void {
    if (event.index == 0) {
    this.location.go('/ts/list')
    } else if (event.index == 1) {
      this.location.go('/ts/list/by-dept  ')
    }
  }

  selectedDeptChanged(event: MatTabChangeEvent): void {
    this.selectedContractTab = 0
    this.selectedTsTab = 0
    return
    this.selectedDeptTab = event.index
    const dep = this.tsSvc.depWithPendingTs$.value[event.index]
    if (!dep.depid) return
    // this.location.go('/ts/list/by-dept/' + dep.depid.toString())
  }

  selectedContractChanged(event: MatTabChangeEvent): void {
    this.selectedTsTab = 0
    return
    this.selectedContractTab = event.index
    const contract = this.tsSvc.contractsByDeptWithPendingTs$[this.tsSvc.depWithPendingTs$.value[this.selectedDeptTab].depid!]?.value[event.index]
    if (!contract?.conid) return
    // this.location.go('/ts/list/by-contract/' + contract.conid.toString())
  }

  selectedTsChanged(event: MatTabChangeEvent): void {
    return
    this.selectedTsTab = event.index
    const selectedDept = this.tsSvc.depWithPendingTs$.value[this.selectedDeptTab]
    const selectedContract = this.tsSvc.contractsByDeptWithPendingTs$[selectedDept.depid!].value[this.selectedContractTab]
    const ts = this.tsSvc.unapprovedTsByContract$[selectedContract.conid!]?.value[event.index]
    if (!ts?.tsid) return
    // this.location.go('/ts/list/by-ts/' + ts.tsid.toString())
  }

  get fromFC() { return this.form.get('from') as FormControl }
  get toFC() { return this.form.get('to') as FormControl }
  get contractFC() { return this.form.get('contract') as FormControl }
  get userFC() { return this.form.get('user') as FormControl }
  get workhoursFC() { return this.form.get('workhours') as FormControl }
  get deptFC() { return this.form.get('dept') as FormControl }
  get statusFC() { return this.form.get('status') as FormControl }
  get minDate() { return this.prodSvc.prodDefaults$.value?.preparationstartday ? new Date(this.prodSvc.prodDefaults$.value?.preparationstartday) : new Date(0) }
  get maxDate() { return new Date() }

  reload(): void {
    this.isDataLoaded = true
    console.log('reloading with filters: ', this.form?.value)

    this.tsSvc.listTs(this.getSPParams())
      .pipe(takeUntil(this.destroy$),
        map(tsList => tsList?.map(this.tsSvc.parseTs)),
        map(tsList => tsList?.sort((a, b) => (b.dStartDate?.getTime() || 0) - (a.dStartDate?.getTime() || 0))))
      .subscribe({
        next: data => {
          this.tsList$.next(data || [])
        },
        error: err => {
          this.notifSvc.addObservableNotif({ msg: 'Hiba történt a Timesheet lista betöltése közben', class: 'danger' })
        }
      })
  }

  getSPParams(): ListTimesheetsParams {
    const f = this.form?.value
    const selectedStatusIds: number[] = this.getStatusIds()
    const retVal: ListTimesheetsParams = {
      _startdate: f.from && moment(f.from).tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT),
      _enddate: f.to && moment(this.appSvc.getEndOfDay(f.to)).tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      _conid: f?.contract?.conid || null,
      _depid: f?.dept?.depid || null,
      _usid: f.user?.usid || null,
      _tsstatus: selectedStatusIds?.length ? selectedStatusIds : null,
    }
    return retVal
  }

  getFG(): FormGroup {
    return this.fb.group({
      from: [null],
      to: [null],
      contract: [null],
      user: [null],
      dept: [null],
      workhours: [null],
      status: [this.initFilterValues.status]
    })
  }
  private get initFilterValues() {
    return {
      from: this.filterSettings?.from || null,
      to: this.filterSettings?.to || null,
      user: this.filterSettings?.user || null,
      dept: this.filterSettings?.dept || null,
      workhours: this.filterSettings?.workhours || null,
      status: this.filterSettings?.tsStatuses
        || (this.userSvc.isFinance
          ? [
            { "id": 62, "name": this.tsSvc.getStatusName(62), nameHun: this.tsSvc.getStatusName(62) },
            { "id": 60, "name": this.tsSvc.getStatusName(60), nameHun: this.tsSvc.getStatusName(60) },
          ]
          : [filterTsstatusesForProdMgr[0]])
    }
  }

  getStatusIds(): number[] {
    const f = this.form?.value
    if (this.userSvc.isFinance) {
      return f?.status?.map((s: any) => s.id) || null
    } else {
      const selectedStatusIds = f?.status?.map((s: TimesheetStatusFilterItem) => s.id) || null
      return [
        ...selectedStatusIds?.includes(1) ? [TimesheetStatus.atProdMgr, TimesheetStatus.deptHeadApproved] : [],
        ...selectedStatusIds?.includes(2) ? this.tsSvc.statusesApprovedOrHigher : [],
        ...selectedStatusIds?.includes(3) ? [TimesheetStatus.disabled] : [],
      ]
    }
  }

  nextTab(step: 1 | -1 = 1, doStep: boolean = true): string {
    let retVal: string = ''
    // if (!this.selectedDeptTab) this.selectedDeptTab = 0
    // if (!this.selectedContractTab) this.selectedContractTab = 0
    // if (!this.selectedTsTab) this.selectedTsTab = 0
    const selectedDept = this.tsSvc.depWithPendingTs$.value[this.selectedDeptTab]
    const selectedContract = this.tsSvc.contractsByDeptWithPendingTs$[selectedDept.depid!].value[this.selectedContractTab]
    const isLastContract = this.selectedContractTab == (step === 1 ? this.tsSvc.contractsByDeptWithPendingTs$[selectedDept.depid!].value?.length - 1 : 0)
    const isLastTs = this.selectedTsTab == (step === 1 ? this.tsSvc.unapprovedTsByContract$[selectedContract.conid!].value?.length - 1 : 0)
    
    if (!isLastTs) {
      retVal = this.getTsDatePipe.transform(this.tsSvc.unapprovedTsByContract$[selectedContract.conid!].value?.[this.selectedTsTab! + step])
      if (doStep) this.selectedTsTab = this.selectedTsTab! + step
    } else {
      if (!isLastContract) {
        retVal = this.getContractLabel(this.tsSvc.contractsByDeptWithPendingTs$[selectedDept.depid!].value?.[this.selectedContractTab! + step])
        if (doStep) this.selectedContractTab = this.selectedContractTab! + step
      } else {
        const isLastDept = this.selectedDeptTab == (step === 1 ? this.tsSvc.depWithPendingTs$.value.length - 1   : 0)
        if (isLastDept) {
          this.selectedDeptTab = step === 1 ? 0 : this.tsSvc.depWithPendingTs$.value.length - 1
        } else {
          retVal = this.tsSvc.depWithPendingTs$.value[this.selectedDeptTab! + step].depname!
          if (doStep) this.selectedDeptTab = this.selectedDeptTab! + step
        }
        setTimeout(() => {this.selectedContractTab = step === 1 ? 0 : this.tsSvc.contractsByDeptWithPendingTs$[selectedDept.depid!].value?.length - 1 }, 0)
      }
    }
    return retVal
  }

  getContractLabel = (c?: ContractParsed) => {
    if (!c?.usid) return 'unknown'
    if (this.contractSvc.getContractsByUser(c.usid)?.length > 1) {
      return `${c?.usname} - ${c?.role}`
    } else {
      return `${c?.usname}`
    }
  }

  trackByDepid = (index: number, item: Department) => item.depid
  trackByConid = (index: number, item?: ContractParsed) => item?.conid
  trackByTsId = (index: number, item: Timesheet) => item.tsid

  reloadWaitingForProdMgr(): void {
    this.tsSvc.listTs({
      _tsstatus: [TimesheetStatus.atProdMgr]
    })
      .pipe(takeUntil(this.destroy$),
        map(tsList => tsList?.map(this.tsSvc.parseTs)),
        map(tsList => tsList?.sort((a, b) => (b.dStartDate?.getTime() || 0) - (a.dStartDate?.getTime() || 0))))
      .subscribe({
        next: data => {
        },
        error: err => {
          this.notifSvc.addObservableNotif({ msg: 'Hiba történt a Timesheet lista betöltése közben', class: 'danger' })
        }
      })
  }

  //
  // Felhasználói beállítások kezelése
  //

  get filterSettings() { return this.stateSvc.manageTimesheetsPageState$?.value?.filters }
  get statusFilterPreferenceOrDefault() { return this.filterSettings?.tsStatuses === undefined ? -2 : this.filterSettings?.tsStatuses }

  
  /** T7E */
  t7eReload = this.t7e.getTranslation('pipe', 'reload', 'innerText', null, null, 'Frissítés')
  t7eLoad = this.t7e.getTranslation('pipe', 'load', 'innerText', null, null, 'Betöltés')
  t7eTabLabelTableView = this.t7e.getTranslation('app-manage-timesheets', 'tab-label-table-view', 'innerText', null, null, 'Táblázatos nézet')
  t7eTabLabelPageView = this.t7e.getTranslation('app-manage-timesheets', 'tab-label-page-view', 'innerText', null, null, 'Teljes nézet')
}
