import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, delay, filter, map } from 'rxjs';
import { NotifOptions, NotificationService } from './notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifList$ = this.notifSvc.appNotifList$
  visibleNotifList$ = this.notifList$.pipe(delay(0), map(notifs => notifs.filter(n => !n.hidden)))

  constructor(
    private notifSvc: NotificationService,
  ) { }

  ngOnInit(): void {
  }

  close(notifHandler: number): void {
    this.notifSvc.hideNotif(notifHandler)
  }

  test() {
    const notifList: NotifOptions[] = []
    this.notifSvc.addObservableNotif({
      msg: 'TESZT üzenet',
      buttonText: 'OKÉKA',
      buttonColor: 'primary',
      class: 'success',
    })
    this.notifSvc.addObservableNotif({
      msg: 'TESZT üzenet',
      buttonText: 'OKÉKA',
      buttonColor: 'accent',
      class: 'info',
    })
    this.notifSvc.addObservableNotif({
      msg: 'TESZT üzenet',
      buttonText: 'OKÉKA',
      buttonColor: 'warn',
      class: 'warning',
    })
    this.notifSvc.addObservableNotif({
      msg: 'TESZT üzenet',
      buttonText: 'OKÉKA',
      buttonColor: undefined,
      class: 'danger',
    })

    //this.notifSvc.openSnackBar('snackbar teszt üzenet', 'gombba', 'osztaly')

    //this.notifSvc.alert('')
  }
}
