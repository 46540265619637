import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, delay, filter, map, takeUntil } from 'rxjs';
import { TimesheetService } from '../timesheet.service';
import { ListTimesheetsParams, TimesheetParsed, TimesheetStatus } from '../../models/timesheet';
import { ContractService } from '../../contract/contract.service';
import { ContractParsed } from '../../models/contract';
import { AppService } from '../../app.service';
import { NotifOptions, NotificationService } from '../../util/notifications/notification.service';
import { T7eService } from '../../t7e/t7e.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-dept-ts',
  templateUrl: './dept-ts.component.html',
  styleUrls: ['./dept-ts.component.scss']
})
export class DeptTsComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject<void>()
  allowSelectUnapproved = !this.contractSvc.isFinanceApprovalNeededConfig
  selectedTs$ = new BehaviorSubject<TimesheetParsed | null>(null)
  selectedDays$ = new BehaviorSubject<Date[] | null>(null)
  
  /** a bal oldal által leszűrt TS-ek listája. null == várakozás az első értékre: nincs szűrő beállítva vagy nem töltött be a ts lista */
  filteredTsList$ = new BehaviorSubject<TimesheetParsed[] | null>(null)
  notAllowedNotifHandler: number | null = null
  form = this.fb.group({
    searchContracts: [null as string | null],
    disabledContracts: [false],
  })
  deptTsList$ = this.tsSvc.deptTsList$
  allContracts$ = new BehaviorSubject<ContractParsed[]>([])
  selectedContract$ = this.contractSvc.selectedContract$
  allContractsFiltered$ = new BehaviorSubject<ContractParsed[]>([])
  contractsLoading$ = this.contractSvc.allContractsLoading$
  existDisableContracts$ = this.allContracts$.pipe(map(all => all?.some(c => !this.contractSvc.isConsideredFinanceApprovedOrAfter(this.allContracts$.value, c))))

  constructor(
    private appSvc: AppService,
    private notifSvc: NotificationService,
    private tsSvc: TimesheetService,
    private contractSvc: ContractService,
    private t7e: T7eService,
    private fb: FormBuilder,
  ) {
    this.reloadTsList()

    combineLatest([this.deptTsList$, this.selectedDays$, this.selectedContract$])
      .pipe(
        takeUntil(this._destroy$),
        filter(([deptTsList, selectedDays, selectedContract]) => (!!deptTsList && !!selectedDays && !!selectedContract)),
        map(([deptTsList, selectedDays, selectedContract]) => {
          //console.count('combineLatest([this.deptTsList$, this.selectedDays$, this.selectedContract$])')
          return (deptTsList || [])
            .filter(ts => ts.conid == selectedContract?.conid)
            .filter(ts => selectedDays?.some(day => this.tsSvc.isSameDay(ts, day)))
        }))
      .subscribe(this.filteredTsList$)

    this.filteredTsList$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(tsList => {
      if (tsList?.length == 1) {
        this.selectedTs$.next(tsList[0])
      } else if (tsList?.length == 0) {
        this.selectedTs$.next(this.emptyTsForSelectedDates)
      } else {
        this.selectedTs$.next(null)
      }
    })

    // Először feliratkozom
    combineLatest([this.allContracts$, this.form.valueChanges])
      .pipe(
        takeUntil(this._destroy$),
        map(([allContracts, formValues]) => {
          const searchText: string = formValues.searchContracts || ''
          const disabledContracts = formValues.disabledContracts
          if (!searchText && disabledContracts) return allContracts
          const retVal = allContracts
            .filter(c => {
            if (c.usname?.toLowerCase().includes(searchText.toLowerCase())) return true
            if (c.role?.toLowerCase().includes(searchText.toLowerCase())) return true
            return false
            })
          if (!disabledContracts) {
            return retVal.filter(c => this.contractSvc.isConsideredFinanceApprovedOrAfter(this.allContracts$.value, c))
          }
          return retVal
        })
      )
      .subscribe(this.allContractsFiltered$)
    // Utána adok neki értéket
    this.contractSvc.deptContracts$
      .pipe(
        takeUntil(this._destroy$),
        map(contracts => [...contracts].sort((a, b) => ((a.usname || '').localeCompare(b.usname || ''))))
      )
      .subscribe(x => {
        this.allContracts$.next(x)
        this.aIsContractClickable.clear()

        // Ez azért kell, mert az allContracts$.next() nem triggereli a `combineLatest([this.allContracts$, this.form.valueChanges])`-t. 
        // Nem értem, miért nem, de a setValue triggereli
        this.form.get('searchContracts')?.setValue(this.form.get('searchContracts')?.getRawValue())
      })

  }
    
  get filtersSlected(): boolean {
    return !!this.selectedDays$.value && !!this.selectedContract$.value
  }
  get debug() { return this.appSvc.debug }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  reloadContracts(): void {
    this.contractSvc.listContracts({})
  }

  resetSearchContracts(): void {
    this.form.get('searchContracts')?.setValue('')
  }

  onSelectedDateChanged(dates: Date[]): void {
    console.log('Kiválasztott napok:', dates)
    this.selectedDays$.next(dates)
  }

  onTsSelected(ts: TimesheetParsed): void {
    this.selectedTs$.next(ts)
  }

  reloadTsList(): void {
    const params = this.getListTsParams()
    this.tsSvc.listTs(params)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe({
        next: tsList => {
          //@ts-ignore
          //console.log(tsList?.map(x => ({ d: x.dStartDate })))
        },
        error: err => {
          this.notifSvc.addObservableNotif({ class: 'danger', msg: this.t7eMsgLoadError, duration: 5000 })
        }
      })
  } 
  
  private getListTsParams(): ListTimesheetsParams {
    const statusesToList = [
      TimesheetStatus.disabled,
      TimesheetStatus.atCrewMember,
      TimesheetStatus.atDeptHead,
      TimesheetStatus.deptHeadApproved,
      TimesheetStatus.atProdMgr,
      TimesheetStatus.approved,
      TimesheetStatus.attGenerating,
      TimesheetStatus.attSent,
      TimesheetStatus.invAccepted,
    ]
    return {
      _tsstatus: statusesToList,
    }
  }


  addNewTs(): void {
    this.selectedTs$.next(this.emptyTsForSelectedDates)
  }

  selectContract(contract: ContractParsed): void{
    if (this.contractIndicatorClass(contract) === 'none'
      && !this.allowSelectUnapproved
      && !this.contractSvc.isConsideredFinanceApprovedOrAfter(this.contractSvc.allContracts, contract)) {
      
      const msg = 'Ez a tevékenység még nincs jóváhagyva, ezért nem vihetsz rá be timesheetet: ' + contract.role
      const notifOptions: NotifOptions = { class: 'warning', msg }
      if (this.notAllowedNotifHandler === null) this.notAllowedNotifHandler = this.notifSvc.addObservableNotif(notifOptions)
      else this.notifSvc.modifyNotif(this.notAllowedNotifHandler, notifOptions)
      this.contractSvc.selectContract(null)
      return
    }
    if (this.notAllowedNotifHandler !== null) {
      this.notifSvc.closeNotif(this.notAllowedNotifHandler)
      this.notAllowedNotifHandler = null
    }
    this.contractSvc.selectContract(contract.conid)
  }

  get emptyTsForSelectedDates(): TimesheetParsed | null {
    if (!this.selectedDays$.value?.[0]) return null
    if (!this.selectedContract$.value) return null
    return {
      tsid: 0,
      conid: this.selectedContract$.value.conid,
      usid: this.selectedContract$.value.usid,
      dStartDate: this.selectedDays$.value?.[0],
    }
  }

  contractIndicatorClass(contract: ContractParsed): string {
    // if there is ts for the contract for the selected day, 
    // then return the ts status class, otherwise return 'none'
    //console.count('contractIndicatorClass')
    const ts = this.deptTsList$.value
      ?.filter(ts => ts.conid == contract.conid) // ha előbb leszűrjük a szerződésekre, akkor nem kell olyan sokszor meghívni az tsSvc.isSameDay-t
      ?.filter(ts => this.selectedDays$.value?.some(day => this.tsSvc.isSameDay(ts, day)))
      ?.find(ts => ts.conid == contract.conid)
    if (!ts) return 'none'
    return this.tsSvc.getStatusClass(ts)

  }

  // <conid, isClickable>
  aIsContractClickable = new Map<number, boolean>()
  isContractClickable(contract: ContractParsed): boolean {
    if (this.contractIndicatorClass(contract) !== 'none') return true
    if (this.allowSelectUnapproved) return true
    if (!contract?.conid) return false
    const cached = this.aIsContractClickable.get(contract.conid!)
    if (cached !== undefined) return cached
    const retVal = this.contractSvc.isConsideredFinanceApprovedOrAfter(this.contractSvc.allContracts, contract)
    this.aIsContractClickable.set(contract.conid, retVal)
    return retVal
  }

  /** T7E */
  t7eMsgLoadError = this.t7e.getTranslation('app-day-selector', 'notif-msg', 'load-error', 'Hiba a timesheetek betöltésekor')

}
