<div *ngIf="(selectedContract$ | async)?.conid && (isSelectedApproved$ | async); else noContract">

  <app-edit-timesheet [timesheet]="selectedTs" [dates]="dates" 
    (selectedDateChange)="onSelectedDateChanged($event)"></app-edit-timesheet>
  <!-- {{ selectedContract$ | async | json }} -->
</div>
<ng-template #noContract>
    <section class="select-contract">
      <h1 t7e="selected-contract-title">Selected Start Form</h1>
      <app-select-contract 
        [showAddButton]="false"
        [hideStatus]="false"
        [allowSelectUnapproved]="false"
        class="section-box">
      </app-select-contract>
    </section>
</ng-template>
