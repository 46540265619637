<div class="my-contract" *ngIf="!(contractsLoading$ | async); else contractsLoading">
    <h1 class="page-title">{{ user?.usname }} szerződései</h1>

    <app-select-contract [user]="user" *ngIf="(userContracts$ | async)?.length"
         [allowSelectUnapproved]="true">
    </app-select-contract>


    <div *ngIf="debug">
        <hr>
        {{ selectedContract$ | async | json }}
        <hr>
    </div>

    <app-edit-contract [contract]="selectedContract$ | async" [showContractStatus]="false"></app-edit-contract>
</div>

<ng-template #contractsLoading>
    <div class="loading-indicator">
        <span class="loading">
            <mat-spinner diameter="50"></mat-spinner>
        </span>
    </div>
</ng-template>