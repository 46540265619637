import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import { HandleProdParameters } from '../../models/production';
import { NotificationService } from '../../util/notifications/notification.service';
import { ProductionService } from '../production.service';
import { ParserService } from '../../parser.service';
import { Subject, forkJoin, map, take, takeUntil } from 'rxjs';
import { ExchangeRate } from '../../models/dispo';
import * as moment from 'moment-timezone';
import { config } from '../../config';
import { LocationsFG } from '../locations/locations.component';
import { NumericValidator } from '../../util/validators/validators';

@Component({
  selector: 'app-prod-defaults',
  templateUrl: './prod-defaults.component.html',
  styleUrls: ['./prod-defaults.component.scss']
})
export class ProdDefaultsComponent implements OnInit {
  MULTIPLE_CURRENCIES = config.MULTIPLE_CURRENCIES
  MEAL_PENALTY = config.HU.TS.MEAL_PENALTY

  form: FormGroup
  holidaysForm: FormGroup
  locationsForm: FormGroup
  exchRatesForm: FormGroup
  _destroy$ = new Subject<boolean>()

  constructor(
    private fb: FormBuilder,
    private appSvc: AppService,
    private notifSvc: NotificationService,
    private prodSvc: ProductionService,
    private parser: ParserService,
  ) {
    this.appSvc.pageTitle$.next('Produkció alapértelmezései')
    this.form = this.getFG()
    this.holidaysForm = this.getHolidaysFG()
    this.locationsForm = this.getLocationsFG()
    this.exchRatesForm = this.getExchRateFG()

    this.prodSvc.prodDefaults$
      .pipe(takeUntil(this._destroy$))
      .subscribe({
      next: prod => {
        if (!prod) return
        if (prod.otrate?.length) prod['otrates'] = prod.otrate.map((x:number) => ({otrate: x}))
          this.form.patchValue(prod)
      },
      error: err => {

      }
      })
    
    if (config.MULTIPLE_CURRENCIES) {
      this.prodSvc.fetchCurrencyExchangeRates()?.subscribe(days => {
        if (!days?.length) return
        this.fillExchangeRateFA(this.exchangeRatesFA, days)
      })
    }
  }

  trackByIndex = this.appSvc.trackByIndex // enélkül nem szerkeszthető a formarray
  get otratesFA() { return this.form.get('otrates') as FormArray }
  get exchangeRatesFA() { return this.exchRatesForm.get('exchangeRates') as FormArray }
  getExchangeRateFG(index: number) { return this.exchangeRatesFA.controls[index] as FormGroup }
  get locationsFG() { return this.locationsForm }
  get holidaysFG() { return this.holidaysForm }
  get debug() { return this.appSvc.debug }
  saveError$ = this.prodSvc.saveProdDefaultsError$
  days$ = this.prodSvc.currencyExchangeRates$
  daysLoading$ = this.prodSvc.currencyExchangeRatesLoading$
  daysLoadingError$ = this.prodSvc.currencyExchangeRatesError$
  daySaving$ = this.prodSvc.daySaving$
  daySavingError$ = this.prodSvc.daysSavingError$
  allExchRates$ = this.prodSvc.allCurrencyExchangeRates$
  allExchRatesForDropdown$ = this.prodSvc.allCurrencyExchangeRates$.pipe(map(cxr => Object.keys(cxr || {})))

  ngOnInit(): void {
  }

  onSubmit(): void {
    const hSaveNotif = this.notifSvc.addObservableNotif({msg: 'Alapadatok mentése folyamatban...', class: 'info'})
    const params = this.getSaveParams()
    this.prodSvc.handleProd(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: prod => {
          this.notifSvc.modifyNotif(hSaveNotif, { msg: 'Alapadatok mentése kész', class: 'success', duration: 2000, })
          console.log('Prod mentés válasz', prod);
          
        }, 
        error: err => {
          this.notifSvc.modifyNotif(hSaveNotif, { msg: 'Hiba történt az alapadatok mentése közben', class: 'danger', })
          console.error(err);
          
        }
    })
  }

  getSaveParams(): HandleProdParameters {
    let otrates = this.form.value.otrates
      ?.map((x: { otrate: number | null }) => x.otrate)
      .filter((x: number | null) => x !== null)
    if (!otrates?.length) otrates = null
    const retVal: HandleProdParameters = {
      _prid: 1,
      _prname: this.form.get('prname')?.value,
      _preparationstartday: this.parser.dateForSql(this.form.get('dPreparationstartday')?.value)!,
      _shootingstart: this.parser.dateForSql(this.form.get('dShootingstart')?.value)!,
      _shootingend: this.parser.dateForSql(this.form.get('dShootingend')?.value)!,
      _ottabudget: this.form.get('ottabudget')?.value,
      _data: JSON.stringify({
        otrate: otrates,
        workhours: this.form.get('workhours')?.value,
        overtimestep: this.form.get('overtimestep')?.value,
        graceperiod: this.form.get('graceperiod')?.value,
        tastep: this.form.get('tastep')?.value,
        maxworkdayperiod: this.form.get('maxworkdayperiod')?.value,
        shortresthours: this.form.get('shortresthours')?.value,
        longresthours: this.form.get('longresthours')?.value,
        currency: this.form.get('currency')?.value,
        tarate: this.form.get('tarate')?.value,
        sleepoverrate: this.form.get('sleepoverrate')?.value,
        mealPenaltyRate: this.form.get('mealPenaltyRate')?.value,
        codeOtta:this.form.get('codeOtta')?.value,
        codeGas:this.form.get('codeGas')?.value,
        codePark:this.form.get('codePark')?.value,
        codeVignette:this.form.get('codeVignette')?.value,
      })
    }
    return retVal
  }

  getFG(): FormGroup {
    return this.fb.group({
      prname: ['', Validators.required],
      prid: ['', Validators.required],
      dPreparationstartday: ['', Validators.required],
      dShootingstart: ['', Validators.required],
      dShootingend: ['', Validators.required],
      ottabudget: ['', Validators.required],

      // Flex Fields
      otrates: this.fb.array([
        this.fb.group({ otrate: [null] }),
        this.fb.group({ otrate: [null] }),
        this.fb.group({ otrate: [null] }),
        this.fb.group({ otrate: [null] }),
      ]),
      workhours: ['', Validators.required],
      overtimestep: ['', Validators.required],
      graceperiod: [''],
      tastep: ['', Validators.required],
      maxworkdayperiod: ['', Validators.required],
      shortresthours: ['', Validators.required],
      longresthours: ['', Validators.required],
      currency: ['', Validators.required],
      tarate: ['', Validators.required],
      sleepoverrate: ['', Validators.required],
      mealPenaltyRate: [''],

      codeOtta: [''],
      codeGas: [''],
      codePark: [''],
      codeVignette: [''],
    })
  }

  getLocationsFG(): FormGroup {
    return this.fb.group({
      savedLocations: this.fb.array([]),
      newLocation: [null, [Validators.required]],
      newKm: [null, [Validators.required, NumericValidator]],
      newKmFee: [null, [Validators.required, NumericValidator]],
      hufPerKm: [75],
    })
  }

  getExchRateFG(): FormGroup {
    return this.fb.group({
      exchangeRates: this.fb.array([]),
      dNewExchangeRateDate: [null],
      newExchangeRate: [null],
    })
  }

  getHolidaysFG(): FormGroup {
    return this.fb.group({
      savedHolidays: this.fb.array([]),
      newHoliday: [null],
    })
  }

  private fillExchangeRateFA(fa: FormArray | null, rates: ExchangeRate[]) {
    if (!fa) throw new Error('FormArray is null')
    fa.clear()
    setTimeout((rates) => {
      rates.forEach(rate => {
        const fg = this.fb.group({
          dayId: [rate.dayId],
          date: [rate.date],
          rate: [rate.rate],
        })
        fa.push(fg)
      })
    }, 0, rates)
  }

  newExchangeRateChanged(e: Event) {
    e.preventDefault()
    this.addExchangeRate()
  }
  addExchangeRate() {
    const date = this.exchRatesForm.get('dNewExchangeRateDate')?.value
    const rate = this.exchRatesForm.get('newExchangeRate')?.value
    if (!date || !rate) return
    const fg = this.fb.group({
      date: [date],
      rate: [rate],
    })
    // this.exchangeRatesFA.push(fg)
    this.exchRatesForm.get('dNewExchangeRateDate')?.reset()
    this.exchRatesForm.get('newExchangeRate')?.reset()
  
    // save to db
    const day: ExchangeRate = { dayId: null, date, rate }
    this.prodSvc.saveCurrencyExchangeRate(day.dayId, day.rate!, moment(day.date))
      .subscribe(x => this.prodSvc.fetchCurrencyExchangeRates()?.subscribe(days => {
        if (!days?.length) return
        this.fillExchangeRateFA(this.exchangeRatesFA, days)
      }))
  }
  saveExchangeRates(e?: KeyboardEvent) {
    e?.preventDefault()
    const rates = this.exchangeRatesFA.value
    const observables = rates.map((rate: ExchangeRate) => {
      return this.prodSvc.saveCurrencyExchangeRate(rate.dayId, rate.rate!, moment(rate.date))
    })
    forkJoin(observables).subscribe({
      next: (result) => {
        this.prodSvc.fetchCurrencyExchangeRates()?.subscribe(days => {
          if (!days?.length) return
          this.fillExchangeRateFA(this.exchangeRatesFA, days)
        })
      },
      error: (error) => {
        console.error('Error while saving currency exchange rates:', error)
      }
    })
  }
  deleteExchangeRate(day: ExchangeRate) {
    const dayId = day.dayId
    const date = moment(day.date)
    this.prodSvc.saveCurrencyExchangeRate(dayId, null, date)
      .subscribe(x => this.prodSvc.fetchCurrencyExchangeRates()?.subscribe(days => {
        this.fillExchangeRateFA(this.exchangeRatesFA, days)
      }))
  }

  test() {
    console.log(this.form);
    
  }
}
