<div class="loading-indicator" *ngIf="(isAppDataLoading$ | async) || !(pageFullyLoaded$ | async)">
  <app-header></app-header>
  <div class="proton-cinema-logo">
    <div class="proton-cinema-logo-wrapper">
      <div class="production-title-by">
        <span class="production-title">{{ productionTitle }}</span>
        <span class="production-by">by</span>
      </div>
      <img src="assets/proton-cinema-logo.svg" alt="">
      <app-page-loading [showText]="false"></app-page-loading>
    </div>
  </div>
</div>
<div *ngIf="(pageFullyLoaded$ | async)&& ((user$ | async) || (isAppLoadingError$ | async)); else notLoggedIn">
  <ng-container *ngIf="(pageFullyLoaded$ | async) ">
    <app-header></app-header>
    <div class="update-notification" *ngIf="(newVersionType$ | async) === 'critical'">
      <div class="title-wrapper">
        <span t7e="new-version-avaliable">Új verzió elérhető. Kérlek frissíts!</span>
        <button mat-raised-button (click)="updateApp()" t7e="update" t7e-title>Frissítés</button>
      </div>
      <div class="details" *ngIf="(isUpdateAvailable$ | async)?.whatsNew">
        <span [innerHTML]="(isUpdateAvailable$ | async)?.whatsNew"></span>
      </div>
    </div>
    <div class="maintenance-notification"
      *ngIf="(isAppLoadingError$ | async) && (maintenanceNotifHu || maintenanceNotifEn)">
      <div class="maintenance-notification-title">
        Karbantartás // Maintenance
      </div>
      <div class="maintenance-notification-body">
        {{maintenanceNotifHu}}
      </div>
      <div class="maintenance-notification-body">
        {{maintenanceNotifEn}}
      </div>
    </div>
    <app-notifications></app-notifications>
  </ng-container>

  <router-outlet *ngIf="!(isAppDataLoading$ | async)"></router-outlet>

</div>

<ng-template #notLoggedIn>
  <div class="login-content" *ngIf="(pageFullyLoaded$ | async)">
    <div class="login-buttons">
      <button mat-raised-button [disabled]="(isAuthenticated$ | async)" color="primary" type="button"
        (click)="login('hu')">Regisztrálj vagy lépj be</button>
      <button mat-raised-button color="primary" type="button" (click)="login('en')">
        Register or log in!
      </button>
    </div>
    <div class="login-instructions">
      A gombra kattintva regisztrálj a <b>CrewTime</b> használatához!
    </div>
  </div>
  <div>
  </div>
</ng-template>