import { Component, Input, OnInit, Output } from '@angular/core';
import { T7eService } from '../../t7e/t7e.service';
import { UserService } from '../../user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {
  @Input() keysValues$ = new BehaviorSubject < RadioButtonKeyValue[]>([])
  @Input() selected$?: BehaviorSubject<RadioButtonKeyValue | null>

  constructor(
    private userSvc: UserService,
    private t7e: T7eService,
  ) { }

  ngOnInit(): void {
    if (!this.selected$) {
      this.selected$ = new BehaviorSubject<RadioButtonKeyValue | null>(null)
    }
  }

  selectLang(keyValue: RadioButtonKeyValue) {
    this.selected$!.next(keyValue)
  }

  isSelected(keyValue: RadioButtonKeyValue) {
    return this.selected$!.value?.key === keyValue.key
  }

}

export type RadioButtonKeyValue = { key: string, value: string }
