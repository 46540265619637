import { Component, OnInit } from '@angular/core';
import { TimesheetService } from '../timesheet.service';
import { BehaviorSubject, Subscription, filter, take } from 'rxjs';
import { ListTimesheetsParams, TimesheetParsed, TimesheetStatus } from '../../models/timesheet';
import { ContractService } from '../../contract/contract.service';
import { UserService } from '../../user.service';
import { AppService } from '../../app.service';
import { T7eService } from '../../t7e/t7e.service';
import { NotificationService } from '../../util/notifications/notification.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-my-timesheets',
  templateUrl: './my-timesheets.component.html',
  styleUrls: ['./my-timesheets.component.scss']
})
export class MyTimesheetsComponent implements OnInit {
  aSelectedTs$ = new BehaviorSubject<TimesheetParsed[]>([])
  selectedTs$ = new BehaviorSubject<TimesheetParsed | null>(null)
  selectedDatesForTsEditor:  Date[] = [new Date()]
  selectedDatesForDaySelector$ = new BehaviorSubject<Date[] | null>(this.selectedDatesForTsEditor)

  initTsSubscr: Subscription

  constructor(
    private tsSvc: TimesheetService,
    private contractSvc: ContractService,
    private userSvc: UserService,
    private appSvc: AppService,
    private notifSvc: NotificationService,
    private t7e: T7eService,
  ) { 
    this.appSvc.pageTitle$.next(this.t7ePageTitleMyTs)
    this.initTsSubscr = tsSvc.tsList$
      .pipe(
        filter(tsList => !!tsList ),
      )
      .subscribe(tsList => {
        this.onSelectedDateChanged([new Date()])
      })

    tsSvc.tsList$
      .pipe(
        filter(tsList => !!tsList ),
      )
      .subscribe(tsList => {
        this.onSelectedDateChanged()
      })

    this.contractSvc.selectContract(null)
    this.userSvc.selectUserById(this.userSvc.loggedinUserId)
    
    this.reload()
  }

  tsList$ = this.tsSvc.ownTsList$
  getTsStatusClass = this.tsSvc.getStatusClass
  getTsStatusName = this.tsSvc.getStatusName
  ownTsList$ = this.tsSvc.ownTsList$
  isOwnTs = this.tsSvc.isOwnTs
  getRolesByUser = this.contractSvc.getRolesByUser
  loggedinUserId = this.userSvc.loggedinUserId!

  get debug() { return this.appSvc.debug }
  
  ngOnInit(): void {
  }

  onSelectedDateChanged(dates?: Date[]): void {
    this.initTsSubscr?.unsubscribe()
    if (dates) this.selectedDatesForTsEditor = dates
    else dates = this.selectedDatesForTsEditor
    console.log('onSelectedDateChanged', dates, this.tsSvc.getTsForDates(dates, this.userSvc.loggedinUser?.usid, false, true));
    this.aSelectedTs$.next(this.tsSvc.getTsForDates(dates, this.userSvc.loggedinUser?.usid, false, true))
    switch (this.aSelectedTs$.value.length) {
      case 0:
        this.selectedTs$.next({ tsid: 0 })
        break
      case 1:
        this.selectedTs$.next(this.aSelectedTs$.value[0])
        break
      default:
        this.selectedTs$.next(null)
    }
  }

  onSelectedDateChangedInTsEditor(dates: Date[]): void {
    this.selectedDatesForDaySelector$.next(dates)
  }

  onTsSelected(ts: TimesheetParsed): void {
    console.log('onTsSelected', ts);
    this.selectedTs$.next(ts)
    this.scrollTsSelectorIntoView()

    const selectedDates = this.selectedDatesForDaySelector$.value
    if (selectedDates?.length !== 1 || !moment(selectedDates[0]).isSame(ts.dStartDate, 'day')) {
      this.selectedDatesForDaySelector$.next([ts.dStartDate!])
    }
  }

  private scrollTsSelectorIntoView(): void {
    setTimeout(() => {
      document.getElementById('selected-timesheets')?.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"})
    }, 0);
  }

  get canAddTs(): boolean {
    if (!this.aSelectedTs$.value?.length) return true
    return this.aSelectedTs$.value.length < 1
  }

  addNewTs(): void {
    this.selectedTs$.next({tsid:0})
    this.scrollTsSelectorIntoView()
  }


  reload(): void {
    const params = this.getListTsParams()
    this.tsSvc.listTs(params).subscribe({
      next: tsList => {
        //@ts-ignore
        //console.log(tsList?.map(x => ({ d: x.dStartDate })))
      },
      error: err => {
        this.notifSvc.addObservableNotif({ class: 'danger', msg: this.t7eMsgLoadError, duration: 5000 })
      }
    })
  }

  private getListTsParams(): ListTimesheetsParams {
    const statusesToList = [
      TimesheetStatus.disabled,
      TimesheetStatus.atCrewMember,
      TimesheetStatus.atDeptHead,
      TimesheetStatus.deptHeadApproved,
      TimesheetStatus.atProdMgr,
      TimesheetStatus.approved,
      TimesheetStatus.attGenerating,
      TimesheetStatus.attSent,
      TimesheetStatus.invAccepted,
    ]
    return {
      _usid: this.userSvc.loggedinUserId,
      _tsstatus: statusesToList,
    }
  }


  /** T7E */
  t7ePageTitleMyTs = this.t7e.getTranslation('app-my-timesheets', 'page-title-my-ts', 'title', 'Timesheetjeim') 
  t7eMsgLoadError = this.t7e.getTranslation('app-day-selector', 'notif-msg', 'load-error', 'Hiba a timesheetek betöltésekor')

}
