import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { ProfileComponent } from './admin/profile/profile.component';
import { UsersComponent } from './admin/users/users.component';
import { EditContractComponent } from './contract/edit-contract/edit-contract.component';
import { EditContractFieldsComponent } from './admin/edit-contract-fields/edit-contract-fields.component';
import { ErrorComponent } from './error/error.component';
import { ManageContractsComponent } from './contract/manage-contracts/manage-contracts.component';
import { MyContractComponent } from './contract/my-contract/my-contract.component';
import { NewTimesheetComponent } from './ts/new-timesheet/new-timesheet.component';
import { EditTimesheetComponent } from './ts/edit-timesheet/edit-timesheet.component';
import { GenInvoiceReportComponent } from './ts/gen-invoice-report/gen-invoice-report.component';
import { ManageTimesheetsComponent } from './ts/manage-timesheets/manage-timesheets.component';
import { ProdDefaultsComponent } from './prod/prod-defaults/prod-defaults.component';
import { MindenComponent } from './util/minden/minden.component';
import { DeptDefaultsComponent } from './dept/dept-defaults/dept-defaults.component';
import { MyTimesheetsComponent } from './ts/my-timesheets/my-timesheets.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { CrewListComponent } from './contract/crew-list/crew-list.component';
import { IsProductionTeamMemberGuard } from './util/guards/is-production-team-member.guard';
import { IsDevGuard } from './util/guards/is-dev.guard';
import { IsEnabledGuard } from './util/guards/is-enabled.guard';
import { GrandOttaComponent } from './report/grand-otta/grand-otta.component';
import { WeeklyOttaComponent } from './report/weekly-otta/weekly-otta.component';
import { environment as env } from '../environments/environment';
import { DevComponent } from './util/dev/dev.component';
import { IsDeptAdminOrHeadGuard } from './util/guards/is-dept-admin-guard';
import { UserContractsComponent } from './contract/user-contract/user-contracts.component';
import { EditProfileComponent } from './admin/profile/edit-profile/edit-profile.component';
import { MyProfileComponent } from './admin/profile/my-profile/my-profile.component';
import { DeptContractsComponent } from './contract/dept-contracts/dept-contracts.component';
import { DeptTsComponent } from './ts/dept-ts/dept-ts.component';
import { DaysComponent } from './prod/days/days.component';
import { SalaryReportComponent } from './report/salary-report/salary-report.component';
import { TimesheetSummaryComponent } from './report/timesheet-summary/timesheet-summary.component';

const routes: Routes = [
  {
    path: 'my',
    canActivateChild: [
      AuthGuard,
    ],
    children: [
      {
        path: 'contracts',
        component: MyContractComponent,
        title: env.htmlTitlePrefix + 'Start Form',
      },
      {
        path: 'timesheets',
        component: MyTimesheetsComponent,
        title: env.htmlTitlePrefix + 'Timesheet',
      },
      {
        path: 'timesheets/new',
        component: NewTimesheetComponent,
        title: env.htmlTitlePrefix + '+ Timesheet',
      },
      {
        path: 'profile',
        component: MyProfileComponent,
        title: env.htmlTitlePrefix + 'Profile',
      }
    ],
  },
  {
    path: 'dept',
    canActivateChild: [
      AuthGuard,
      IsDeptAdminOrHeadGuard,
    ],
    children: [
      {
        path: 'users',
        component: EditProfileComponent,
        title: env.htmlTitlePrefix + 'Személyes adatok / User Profiles',
      },
      {
        path: 'contracts',
        component: DeptContractsComponent,
        title: env.htmlTitlePrefix + 'Start Formok / Start Forms',
      },
      {
        path: 'ts',
        component: DeptTsComponent,
        title: env.htmlTitlePrefix + 'Részleg Timesheetjei / Department TSs',
      },
      {
        path: 'timesheets',
        component: DeptTsComponent,
        title: env.htmlTitlePrefix + 'Részleg Timesheetjei / Department TSs',
      },
    ],
  },
  {
    path: 'user',
    component: EditProfileComponent,
    title: env.htmlTitlePrefix + 'User Profiles',
    canActivateChild: [
      AuthGuard,
      IsDeptAdminOrHeadGuard,
    ],
    children: [
      {
        path: ':userId',
        component: EditProfileComponent,
        title: env.htmlTitlePrefix + 'User Profile',
        children: [
          {
            path: 'contracts',
            component: UserContractsComponent,
            title: env.htmlTitlePrefix + 'User Contracts',
          }
        ],
      }
    ],
  },
  {
    path: 'ts',
    children: [
      {
        path: 'list',
        component: ManageTimesheetsComponent,
        children: [
          {
            path: 'by-dept',
            component: ManageTimesheetsComponent,
            title: env.htmlTitlePrefix + 'Timesheet',
          },
          {
            path: 'by-dept/:deptId',
            component: ManageTimesheetsComponent,
            title: env.htmlTitlePrefix + 'Timesheet',
          },
          {
            path: 'by-contract/:contractId',
            component: ManageTimesheetsComponent,
            title: env.htmlTitlePrefix + 'Timesheet',
          },
          {
            path: 'by-ts/:tsId',
            component: ManageTimesheetsComponent,
            title: env.htmlTitlePrefix + 'Timesheet',
          },
        ],
      },
      {
        path: 'generate-invrep',
        component: GenInvoiceReportComponent,
        title: env.htmlTitlePrefix + 'WTS',
        canActivate: [
          AuthGuard,
          IsProductionTeamMemberGuard,
        ],
      },
      {
        path: ':tsid',
        component: EditTimesheetComponent,
        title: env.htmlTitlePrefix + 'Timesheet',
        canActivate: [
          AuthGuard,
        ],
      },
    ],          
  },
  {
    path: 'contract',
    canActivateChild: [
      AuthGuard,
      IsProductionTeamMemberGuard,
    ],
    children: [
      {
        path: 'list',
        component: ManageContractsComponent,
        title: env.htmlTitlePrefix + 'Start Form',
      },
      {
        path: ':contractId',
        component: EditContractComponent,
        title: env.htmlTitlePrefix + 'Start Form',
      },
      {
        path: 'new',
        component: EditContractComponent,
        title: env.htmlTitlePrefix + '+ Start Form',
      }
    ],    
  },
  {
    path: 'dispo',
    canActivateChild: [
      AuthGuard,
      IsProductionTeamMemberGuard,
    ],
    children: [
      {
        path: 'list',
        component: DaysComponent,
        title: env.htmlTitlePrefix + 'Dispo',
      },
    ],
  },
  {
    path: 'report',
    canActivateChild: [
      AuthGuard,
      IsProductionTeamMemberGuard,
    ],
    children: [
      {
        path: 'otta',
        component: GrandOttaComponent,
        title: env.htmlTitlePrefix + 'OT&TA Grand Total',
      },
      {
        path: 'otta/weekly',
        component: WeeklyOttaComponent,
        title: env.htmlTitlePrefix + 'Weekly OT&TA',
      },
      {
        path: 'salary',
        component: SalaryReportComponent,
        title: env.htmlTitlePrefix + 'Napidíjak'
      },
      {
        path: 'ts-summary',
        component: TimesheetSummaryComponent,
        title: env.htmlTitlePrefix + 'Díjak összesítése (EKHO)'
      },
    ],
  },
  {
    path: 'admin',
    canActivateChild: [
      AuthGuard,
      IsProductionTeamMemberGuard,
    ],
    children: [
      {
        path: 'users',
        component: UsersComponent,
        title: env.htmlTitlePrefix + 'User',
      },
      {
        path: 'contract-fields',
        component: EditContractFieldsComponent,
        title: env.htmlTitlePrefix + 'Custom Fields',
      },
      {
        path: 'prod-defaults',
        component: ProdDefaultsComponent,
        title: env.htmlTitlePrefix + 'Production',
      },
      {
        path: 'dept-defaults',
        component: DeptDefaultsComponent,
        title: env.htmlTitlePrefix + 'Departments',
      },
    ],
  },
  {
    path: 'dev',
    canActivateChild: [
      AuthGuard,
      IsDevGuard,
    ],
    children: [
      {
        path: 'tools',
        component: DevComponent,
      },
      {
        path: 'minden',
        component: MindenComponent,
      },
    ],
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],  // Protect a route by registering the auth guard in the `canActivate` hook
  },
  {
    path: 'crew-list',
    component: CrewListComponent,
    canActivate: [
      AuthGuard,
      IsEnabledGuard,
    ],  // Protect a route by registering the auth guard in the `canActivate` hook
    title: env.htmlTitlePrefix + 'Crew List',
  },
  {
    path: 'error/:msg',
    component: ErrorComponent,
    canActivate: [AuthGuard],  // Protect a route by registering the auth guard in the `canActivate` hook
    title: env.htmlTitlePrefix + 'Error',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
