import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { T7eService } from '../../../t7e/t7e.service';

@Component({
  selector: 'app-filter-date-range',
  templateUrl: './filter-date-range.component.html',
  styleUrls: ['./filter-date-range.component.scss']
})
export class FilterDateRangeComponent implements OnInit {
  @Input() formgroup?: FormGroup
  @Input() fromFC?: FormControl
  @Input() toFC?: FormControl
  @Input() min: Date = new Date(2000,0,1)
  @Input() max: Date = new Date()
  @Input() canReset: boolean = false

  constructor(
    private t7e: T7eService,
  ) { }

  ngOnInit(): void {
    console.assert(this.fromFC, 'DateRange komponense: A fromFC megadása kötelező')
    console.assert(this.toFC, 'DateRange komponense: A toFC megadása kötelező')
    console.assert(this.formgroup, 'DateRange komponense: A formgroup megadása kötelező')
  }

  reset() {
    this.fromFC?.setValue(null)
    this.toFC?.setValue(null)
  }


  /** T7E */
  t7eStartLabel = this.t7e.getTranslation('app-filter-date-range', 'start-label', 'innerText', null, null, 'Első nap')
  t7eEndLabel = this.t7e.getTranslation('app-filter-date-range', 'end-label', 'innerText', null, null, 'Utolsó nap')
}
