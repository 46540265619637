<div class="my-contract">
    <h1 class="page-title" t7e="my-contracts">Saját Start Formjaim</h1>
    
    <app-select-contract *ngIf="(userContracts$ | async)?.length"
        [allowSelectUnapproved]="true"></app-select-contract>
    
    <div *ngIf="debug">
        <hr>
        {{ selectedContract$ | async | json }}
        <hr>
    </div>
    
    <app-edit-contract  [contract]="contract" [showContractStatus]="false"></app-edit-contract>
</div>