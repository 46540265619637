import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TimesheetParsed } from '../../models/timesheet';
import { TimesheetService } from '../timesheet.service';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit {
  @Input() showRole: boolean = true
  @Input() showDept: boolean = true
  @Input() timesheet?: TimesheetParsed
  @Input() isSelected: boolean = false
  
  tsList$: BehaviorSubject<TimesheetParsed[]> = new BehaviorSubject<TimesheetParsed[]>([])
  selectedTs$: BehaviorSubject<TimesheetParsed | null> = new BehaviorSubject<TimesheetParsed | null>(null)

  constructor(
    private tsSvc: TimesheetService,
  ) { 
  }

  isOwnTs = this.tsSvc.isOwnTs
  getTsStatusClass = this.tsSvc.getStatusClass
  getTsStatusName = this.tsSvc.getStatusName


  ngOnInit(): void {
  }


}
