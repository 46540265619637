<kendo-grid [kendoGridBinding]="(properties$ | async) || []" [pageable]="true" [sortable]="true" [filterable]="true"
    [navigable]="true" [reorderable]="true" [resizable]="true" class="grid prop-grid" (add)="addProp($event)"
    (remove)="removeProp($event)" (edit)="editProp($event)">
    <ng-template kendoGridToolbarTemplate>
        <h1>Available fields</h1>
        <div>
            <button kendoGridAddCommand type="button">Add field</button>
        </div>
    </ng-template>

    <kendo-grid-column field="propcode" title="Kérdés kódja">
    </kendo-grid-column>
    <kendo-grid-column field="propname" title="Kérdés magyarul"> </kendo-grid-column>
    <kendo-grid-column field="engpropname" title="Kérdés angolul"> </kendo-grid-column>
    <kendo-grid-column field="edittypename" title="Válasz típus" [editable]="false"> </kendo-grid-column>
    <kendo-grid-column field="level" title="Módosíthatja" editor="boolean" [editable]="isDeveloper">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ getEditLevel(dataItem.level) }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="" [width]="220">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button mat-icon-button [matMenuTriggerFor]="statusMenu" aria-label="More options">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #statusMenu="matMenu">
                <button mat-menu-item kendoGridEditCommand [primary]="true">Edit</button>
                <button mat-menu-item kendoGridRemoveCommand>Remove</button>
            </mat-menu>

        </ng-template>
    </kendo-grid-command-column>

</kendo-grid>