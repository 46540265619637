<div class="profilePage">
    <h1 t7e="h1_001" class="page-title" >Felhasználói adatok</h1>

    <ng-container *ngIf="showUserGroups">
        <div class="role-wrapper" *ngIf="userGroups; else noGroups">
            <span t7e="s_001" >Regisztráció elfogadva:</span>
            <span>{{ userGroups }}</span>
        </div>
        <ng-template #noGroups>
            <div class="no-group" t7e="you have no usergroup" >
                Még nem fogadta el a gyártásvezető a regisztrációdat
            </div>
        </ng-template>
    </ng-container>

    <button (click)="invalidControls()" *ngIf="debug">TESZT</button>

    <div class="form-wrapper" *ngIf="!(loadingUserAllData$ | async); else userLoading">
    <form [formGroup]="form" (ngSubmit)="submit()" class="dense-3">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label t7e="ml_001">Email</mat-label>
            <input matNativeControl formControlName="email">
            <mat-hint t7e="mh_001">Az email cím nem módosítható</mat-hint>
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label t7e="ml_002_2">Vezetéknév</mat-label>
            <input matNativeControl formControlName="famname">
            <mat-error t7e="me_001" *ngIf="form?.hasError('required', 'famname')">
                A neved megadása <strong>kötelező</strong>
            </mat-error>
            <mat-hint t7e="name-hint">Your name in your ID. Személyi igazolványban szereplő név</mat-hint>
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label t7e="ml_002">Keresztnév</mat-label>
            <input matNativeControl formControlName="name">
            <mat-error t7e="me_001" *ngIf="form?.hasError('required', 'name')">
                A neved megadása <strong>kötelező</strong>
            </mat-error>
            <mat-hint t7e="name-hint">Your name in your ID. Személyi igazolványban szereplő név</mat-hint>
        </mat-form-field>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label t7e="ml_003" >Telefonszám</mat-label>
            <input matNativeControl formControlName="phonenumber">
            <mat-hint t7e="tel-hint">Pl +36 30 123 1234</mat-hint>
            <mat-error t7e="me_002" *ngIf="form?.hasError('required', 'phonenumber')">
                A telefonszám megadása <strong>kötelező</strong>
            </mat-error>
            <mat-error t7e="tel-pattern-error" *ngIf="form.get('phonenumber')!.hasError('pattern') && form.get('phonenumber')!.touched">
                A helyes formátum: +36 30 123 1234
            </mat-error>
        </mat-form-field>

        <app-flex-fields [formGroup]="flexFieldsFG" [tableid]="usersFlexTable"
            [allowedPropCodes]="allowedFields$ | async"
            [showReadonlyFields]="true"
            [formFieldStyles]="formFieldStyles"
            [formGroupStyles]="formGroupStyles" class="flex-form-fields">
        </app-flex-fields>

        <section class="save-buttons">
            <app-save-button color="primary" [disabled]="!form.valid" [saveError$]="saveError$"
            [errorText]="t7eSaveButtonErrorText" [showErrMsg]="true"
            [successText]="t7eSaveButtonSuccessText" >
            </app-save-button>
            <button mat-raised-button color="secondary" (click)="navToMyContracts()" 
                *ngIf="(saveError$ | async) === false && showGotoContractButton" type="button">
                <span t7e="go-to-contract">Tovább a szerződéshez</span>
                <mat-icon>forward</mat-icon>
            </button>
        </section>
    </form>
    </div>

    <ng-template #userLoading>
        <div class="loading-indicator">
            <span class="loading">
                <mat-spinner diameter="50"></mat-spinner>
            </span>
        </div>
    </ng-template>

</div>
