<ul class="options" *ngIf="(keysValues$ | async)?.length">
    <li *ngFor="let keyValue of keysValues$ | async" class="lang" [class.selected]="isSelected(keyValue)" (click)="selectLang(keyValue)">
        <div class="selector">
            <mat-icon *ngIf="isSelected(keyValue); else notSelectedLang">check_circle_outline</mat-icon>
            <ng-template #notSelectedLang>
                <mat-icon>radio_button_unchecked</mat-icon>
            </ng-template>
        </div>
        <div class="selectable-value">
            {{ keyValue.value}}
        </div>
    </li>
</ul>