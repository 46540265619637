<ng-container *ngIf="multiCurrency">
  <div *ngIf="multiCurrency === 'fixed-rates'" class="mb-1">
    <h2 class="exchange-rate" *ngFor="let currency of allCurrencyExchRatesFiltered$ | async | keyvalue; ">
        1&nbsp;<span>{{ currency.key }}</span>&nbsp;=&nbsp;
        <span>{{ currency.value }}</span>&nbsp;HUF
    </h2>
  </div>

  <h2 class="exchange-rate" *ngIf="multiCurrency !== 'fixed-rates' && !(todaysCurrencyExchangeRateError$ | async)">
    <div>1 EUR =&nbsp;</div>
    <div *ngIf="(todaysCurrencyExchangeRateError$ | async) === false; else todaysCurrencyExchangeRateLoading">{{
      (todaysCurrencyExchangeRate$ | async)?.rate | number:'1.0-0' }}</div>
    <div>&nbsp;{{ currency }}</div>
    <ng-template #todaysCurrencyExchangeRateLoading>
      <mat-spinner [diameter]="15"></mat-spinner>
    </ng-template>
  </h2>
</ng-container>


<div class="filters toolbar" (submit)="reload()">
  <app-filter-date-range [formgroup]="form" [fromFC]="fromFC" [toFC]="toFC" [min]="minDate" [max]="maxDate"
    class="form-field ml-1">
  </app-filter-date-range>

  <div class="divider"></div>
  <button mat-raised-button (click)="exportToExcel()" color="primary" type="button"
    [disabled]="!(gridData$ | async)?.data?.length">
    <mat-icon>file_download</mat-icon>
    <span>{{ 'export-to-excel' | t7e:'Export to Excel' }}</span>
  </button>
  <button mat-raised-button (click)="reload()" color="primary" type="submit" [disabled]="!form.valid"
    class="mr-1"><mat-icon>refresh</mat-icon></button>
</div>

<div class="grid">
  <kendo-grid #grid [data]="gridData$ | async " [loading]="(loading$ | async) || false" [pageSize]="pageSize"
    [skip]="skip" [sort]="sort" [filter]="filter" [sortable]="true" [filterable]="false">
    <kendo-grid-column field="dDate" [title]="'ts-grid.field.dDate' | t7e:'Dátum'"
      [width]="getWidth('dDate') || 170" [format]="'yyyy-MM-dd'">
    </kendo-grid-column>
    <kendo-grid-column field="usname" [title]="'ts-grid.field.usname' | t7e:'Stábtag'"
      [width]="getWidth('name') || 170">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem.usname; else deletedUser">
          {{ dataItem.usname }}
        </ng-container>
        <ng-template #deletedUser>
          <span class="deleted-user">{{ 'deleted-user' | t7e: 'Törölt felhasználó' }}</span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="depname" [title]="'ts-grid.field.depname' | t7e:'Részleg'"
      [width]="getWidth('depname') || 170">
    </kendo-grid-column>
    <kendo-grid-column field="role" [title]="'ts-grid.field.role' | t7e:'Munkakör'" [width]="getWidth('role') || 170">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="cell-with-link-only-on-hover">
          <div>{{dataItem.role}}</div>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="salary" title="{{ 'salary' | t7e }}" [width]="getWidth('dailyRate') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.salary | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.salary| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="overtimefee" title="OT" [width]="getWidth('overtimefee') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.overtimefee | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.overtimefee| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="tafee" title="TA" [width]="getWidth('tafee') || 200" filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.tafee | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.tafee| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="perdiem" title="Per Diem" [width]="getWidth('perdiem') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.perdiem | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.perdiem| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="mealpenalty" title="Meal Penalty" [width]="getWidth('mealpenalty') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.mealpenalty | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.mealpenalty| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="otherfee" title="{{ 'otherfee' | t7e: 'Egyéb díj' }}" [width]="getWidth('otherfee') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.otherfee | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.otherfee| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="vignette" title="{{ 'Vignette' | t7e: 'Matrica' }}" [width]="getWidth('vignette') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.vignette | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.vignette| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="gas" title="{{ 'Gas' | t7e: 'Üzemanyag' }}" [width]="getWidth('gas') || 200" filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.gas | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.gas| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="park" title="{{ 'Parking' | t7e: 'Parkolás' }}" [width]="getWidth('park') || 200" filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.park | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.park| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="dailyrentalrate1" title="{{ 'Dailyrentalrate' | t7e: 'Napi bérleti díj' }} 1" [width]="getWidth('dailyrentalrate1') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.dailyrentalrate1 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.dailyrentalrate1| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="dailyrentalrate2" title="{{ 'Dailyrentalrate' | t7e: 'Napi bérleti díj' }} 2" [width]="getWidth('dailyrentalrate2') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.dailyrentalrate2 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.dailyrentalrate2| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="dailyrentalrate3" title="{{ 'Dailyrentalrate' | t7e: 'Napi bérleti díj' }} 3" [width]="getWidth('dailyrentalrate3') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.dailyrentalrate3 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.dailyrentalrate3| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="dailyrentalrate4" title="{{ 'Dailyrentalrate' | t7e: 'Napi bérleti díj' }} 4" [width]="getWidth('dailyrentalrate4') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.dailyrentalrate4 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.dailyrentalrate4| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="dailyrentalrate5" title="{{ 'Dailyrentalrate' | t7e: 'Napi bérleti díj' }} 5" [width]="getWidth('dailyrentalrate5') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.dailyrentalrate5 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.dailyrentalrate5| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="dailyrentalrate6" title="{{ 'Dailyrentalrate' | t7e: 'Napi bérleti díj' }} 6" [width]="getWidth('dailyrentalrate6') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.dailyrentalrate6 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.dailyrentalrate6| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="monthlyrentalrate1" title="{{ 'Monthlyrentalrate' | t7e: 'Havi bérleti díj' }} 1" [width]="getWidth('monthlyrentalrate1') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.monthlyrentalrate1 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.monthlyrentalrate1| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="monthlyrentalrate2" title="{{ 'Monthlyrentalrate' | t7e: 'Havi bérleti díj' }} 2" [width]="getWidth('monthlyrentalrate2') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.monthlyrentalrate2 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.monthlyrentalrate2| number }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="monthlyrentalrate3" title="{{ 'Monthlyrentalrate' | t7e: 'Havi bérleti díj' }} 3" [width]="getWidth('monthlyrentalrate3') || 200"
      filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.monthlyrentalrate3 | number }}&nbsp;<span class="currency">{{ dataItem.currency }}</span>
      </ng-template>
      <ng-template kendoGridFooterTemplate let-dataItem>
        Total: {{ (totals$ | async)?.monthlyrentalrate3| number }}
      </ng-template>
    </kendo-grid-column>

    
    <kendo-grid-excel fileName="Díjak.xlsx" [fetchData]="allDataForExcel">
      <kendo-excelexport-column field="dDate" title="Dátum / Date" [width]="170"></kendo-excelexport-column>
      <kendo-excelexport-column field="usid" title="Stábtag azonosító / User ID"
        *ngIf="debug"></kendo-excelexport-column>
      <kendo-excelexport-column field="usname" title="Stábtag neve / User Name"></kendo-excelexport-column>
      <kendo-excelexport-column field="depid" title="Részleg azonosító / Department ID"
        *ngIf="debug"></kendo-excelexport-column>
      <kendo-excelexport-column field="depname" title="Részleg neve / Department Name"></kendo-excelexport-column>
      <kendo-excelexport-column field="role" title="Munkakör / Position"></kendo-excelexport-column>
      <kendo-excelexport-column field="currency" title="Pénznem / Currency"></kendo-excelexport-column>
      <kendo-excelexport-column field="salary" title="Napidíj / Daily rate"></kendo-excelexport-column>
      <kendo-excelexport-column field="overtimefee" title="OT"></kendo-excelexport-column>
      <kendo-excelexport-column field="tafee" title="TA"></kendo-excelexport-column>
      <kendo-excelexport-column field="perdiem" title="Per Diem"></kendo-excelexport-column>
      <kendo-excelexport-column field="mealpenalty" title="Meal penalty"></kendo-excelexport-column>
      <kendo-excelexport-column field="otherfee" title="Egyéb díj / Other fee"></kendo-excelexport-column>
      <kendo-excelexport-column field="vignette" title="Matrica / Vignette"></kendo-excelexport-column>
      <kendo-excelexport-column field="gas" title="Üzemanyag / Gas"></kendo-excelexport-column>
      <kendo-excelexport-column field="park" title="Parkolás / Parking"></kendo-excelexport-column>
      <kendo-excelexport-column field="dailyrentalrate1" title="Napi bérleti díj 1 / Daily rental 1"></kendo-excelexport-column>
      <kendo-excelexport-column field="dailyrentalrate2" title="Napi bérleti díj 2 / Daily rental 2"></kendo-excelexport-column>
      <kendo-excelexport-column field="dailyrentalrate3" title="Napi bérleti díj 3 / Daily rental 3"></kendo-excelexport-column>
      <kendo-excelexport-column field="dailyrentalrate4" title="Napi bérleti díj 4 / Daily rental 4"></kendo-excelexport-column>
      <kendo-excelexport-column field="dailyrentalrate5" title="Napi bérleti díj 5 / Daily rental 5"></kendo-excelexport-column>
      <kendo-excelexport-column field="dailyrentalrate6" title="Napi bérleti díj 6 / Daily rental 6"></kendo-excelexport-column>
      <kendo-excelexport-column field="monthlyrentalrate1" title="Havi bérleti díj 1 / Monthly rental 1"></kendo-excelexport-column>
      <kendo-excelexport-column field="monthlyrentalrate2" title="Havi bérleti díj 2 / Monthly rental 2"></kendo-excelexport-column>
      <kendo-excelexport-column field="monthlyrentalrate3" title="Havi bérleti díj 3 / Monthly rental 3"></kendo-excelexport-column>
    </kendo-grid-excel>

  </kendo-grid>
</div>