import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AppService } from '../../app.service';
import { T7eService } from '../../t7e/t7e.service';

@Component({
  selector: 'app-ot-rate-inputs',
  templateUrl: './ot-rate-inputs.component.html',
  styleUrls: ['./ot-rate-inputs.component.scss']
})
export class OtRateInputsComponent implements OnInit {
  @Input() form?: FormGroup
  @Input() otratesFA?: FormArray
  @Input() dataLevel?: 'prod' | 'dept' | 'contract'

  constructor(
    private appSvc: AppService,
    private t7e: T7eService,
  ) { }

  trackByIndex = this.appSvc.trackByIndex // enélkül nem szerkeszthető a formarray
  lang = this.t7e.lang

  ngOnInit(): void {
    if (!this.form) throw new Error('form is required')
    if (!this.otratesFA) throw new Error('otratesFA is required')
  }

  /** T7E */
  t7eNthHour(nth: number) {
    if (this.t7e.lang == 'en') {
      return this.t7eNth(nth) + ' hour';
    } else {
      return `${nth}. óra`
    }
  }

  t7eNth (nth: number) {
    if (this.t7e.lang == 'en') {
      if (nth == 1) return 'st';
      if (nth == 2) return 'nd';
      if (nth == 3) return 'rd';
      return 'th';
    } else {
      return `${nth}. óra`
    }
  }

  get t7eHintTextPrefix() {
    switch (this.dataLevel) {
      case 'prod':
      case 'dept':
        return this.lang === 'hu'
          ? 'Alapértelmezett túlóradíj'
          : 'Default overtime rate'
      case 'contract':
        return this.lang === 'hu'
          ? 'Túlóradíj'
          : 'Overtime rate'
      default:
        console.error('dataLevel is required')
        return ''
    }
  }


}
