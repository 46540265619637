import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../user.service';
import { ContractService } from '../../../contract/contract.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  constructor(
    private userSvc: UserService,
    private contractSvc: ContractService,
  ) { }

  users$ = this.contractSvc.usersInLoggedInUsersDept$
  get users() { return this.userSvc.allUsers }
  
  selectedUser$ = this.userSvc.selectedUser$
  get selectedUser() { return this.userSvc.selectedUser }
  ngOnInit(): void {
  }

}
