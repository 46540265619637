import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { BehaviorSubject, filter, map } from 'rxjs';
import { AppService } from '../../app.service';
import { ProductionService } from '../../prod/production.service';
import { GrandOtta } from '../../models/grand-otta';
import { config } from '../../config';

@Component({
  selector: 'app-grand-otta',
  templateUrl: './grand-otta.component.html',
  styleUrls: ['./grand-otta.component.scss']
})
export class GrandOttaComponent implements OnInit {
  multiCurrency = config.MULTIPLE_CURRENCIES
  otta$ = this.reportSvc.otta$
  all$ = this.otta$.pipe(
    filter(otta => !!otta),
    map(otta => otta!.periods[0].all)
  )
  extraSum$ = this.otta$.pipe(
    filter(otta => !!otta),
    map(otta => ({
      total: (otta!.periods[0].extra?.extraotta1 || 0) + (otta!.periods[0].extra?.extraotta2 || 0) + (otta!.periods[0].extra?.extraotta3 || 0),
      total_dev: (otta!.periods[0].extra?.extraotta1_dev || 0) + (otta!.periods[0].extra?.extraotta2_dev || 0) + (otta!.periods[0].extra?.extraotta3_dev || 0),
    }))

  )
  approved$ = this.otta$.pipe(
    filter(otta => !!otta),
    map(otta => otta!.periods[0].approved)
  )
  classAll$ = this.all$.pipe(
    map(all => all.remaining < 0 ? 'danger' : 'good')
  )
  classApproved$ = this.approved$.pipe(
    map(approved => approved.remaining < 0 ? 'danger' : 'good')
  )
  isReportLoading$ = this.reportSvc.isGrandOttaLoading$
  currency = this.prodSvc.getCurrency()
  todaysCurrencyExchangeRate$ = this.prodSvc.todaysCurrencyExchangeRate$
  todaysCurrencyExchangeRateLoading$ = this.prodSvc.todaysCurrencyExchangeRateLoading$
  todaysCurrencyExchangeRateError$ = this.prodSvc.todaysCurrencyExchangeRateError$
  allCurrencyExchRates$ = this.prodSvc.allCurrencyExchangeRates$

  constructor(
    private reportSvc: ReportService,
    private prodSvc: ProductionService,
    private appSvc: AppService,
  ) { 
    this.appSvc.pageTitle$.next('OT & TA Grand Total')
    this.loadGrandOtta()

    if (this.multiCurrency === true) {
      this.prodSvc.fetchTodaysExchangeRate()
    }
  }

  get debug() { return this.appSvc.debug }

  ngOnInit(): void {
  }

  private loadGrandOtta(): void {
    this.reportSvc.getGrandOtta()
  }
    
  refresh(): void {
    this.loadGrandOtta()
  }
}
