<h1 class="page-title" t7e="title-h1">OT&TA Grand Total</h1>
<ng-container *ngIf="multiCurrency">
    <div *ngIf="multiCurrency === 'fixed-rates'" class="mb-1">
        <h2 class="exchange-rate" *ngFor="let currency of allCurrencyExchRates$ | async | keyvalue; " >
            <ng-container *ngIf="currency.key !== 'HUF'">             
                1&nbsp;<span>{{ currency.key }}</span>&nbsp;=&nbsp;
                <span>{{ currency.value }}</span>&nbsp;HUF
            </ng-container>
        </h2>
    </div>

    <h2 class="exchange-rate" *ngIf="multiCurrency !== 'fixed-rates' && !(todaysCurrencyExchangeRateError$ | async)">
        <div>1 EUR =&nbsp;</div>
        <div *ngIf="(todaysCurrencyExchangeRateError$ | async) === false; else todaysCurrencyExchangeRateLoading">{{ (todaysCurrencyExchangeRate$ | async)?.rate | number:'1.0-0' }}</div> 
        <div>&nbsp;{{ currency }}</div>
        <ng-template #todaysCurrencyExchangeRateLoading>
            <mat-spinner [diameter]="15"></mat-spinner>
        </ng-template>
    </h2>
</ng-container>

<table *ngIf="!(isReportLoading$ | async); else loading" class="table has-page-level-refrash-button">
    <thead>
        <tr>
            <th class="col1"></th>
            <th class="col2" t7e="col2_header">Requested</th>
            <th class="col3" t7e="col3_header">Remaining</th>
            <th class="col4" t7e="col4_header">Spent %</th>
            <th class="col2" t7e="col2_header">Requested Budget EUR</th>
            <th class="col3" t7e="col3_header">Remaining EUR</th>
            <th class="col4" t7e="col4_header">Spent % EUR</th>
        </tr>
    </thead>
    <tbody>
        <tr class="budget">
            <td class="col1" t7e="col1_header">OT + TA TOTAL GL Budget</td>
            <td class="col2">{{ (otta$ | async)?.ottabudget | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
            <td class="col2">{{ (otta$ | async)?.ottabudget | number:'1.0-0' }} <span class="unimportant">EUR</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
        </tr>
        <tr class="ot all">
            <td class="col1" t7e="requested_ot" title="CrewTime + Extra">Igényelt OT költség</td>
            <td class="col2">{{ (((all$ | async)?.otfee || 0) + ((extraSum$ | async)?.total || 0)) | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
            <td class="col2">{{ (((all$ | async)?.otfee_dev || 0) + ((extraSum$ | async)?.total_dev || 0)) | number:'1.0-0' }} <span class="unimportant">EUR</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
        </tr>
        <tr class="ta all">
            <td class="col1" t7e="requested_ta">Igényelt TA költség</td>
            <td class="col2">{{ (all$ | async)?.tafee | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
            <td class="col2">{{ (all$ | async)?.tafee_dev | number:'1.0-0' }} <span class="unimportant">EUR</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
        </tr>
        <tr class="total all">
            <td class="col1" t7e="requested_total" title="CrewTime + Extra">Összes igényelt</td>
            <td class="col2">{{ (all$ | async)?.grandTotal | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span></td>
            <td class="col3" [ngClass]="classAll$ | async">{{ (all$ | async)?.remaining | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span></td>
            <td class="col4" [ngClass]="classAll$ | async">{{ (all$ | async)?.percent | percent }}</td>
            <td class="col2">{{ (all$ | async)?.grandTotal_dev | number:'1.0-0' }} <span class="unimportant">EUR</span></td>
            <td class="col3" [ngClass]="classAll$ | async">{{ (all$ | async)?.remaining_dev | number:'1.0-0' }} <span class="unimportant">EUR</span></td>
            <td class="col4" [ngClass]="classAll$ | async">{{ (all$ | async)?.percent | percent }}</td>
        </tr>
        <tr class="ot approved">
            <td class="col1" t7e="approved_ot">Jóváhagyott OT költség / total approved OT</td>
            <td class="col2">{{ (((approved$ | async)?.otfee || 0) + ((extraSum$ | async)?.total || 0)) | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
            <td class="col2">{{ (((approved$ | async)?.otfee_dev || 0) + ((extraSum$ | async)?.total_dev || 0)) | number:'1.0-0' }} <span class="unimportant">EUR</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
        </tr>
        <tr class="ta approved">
            <td class="col1"t7e="approved_ta">Jóváhagyott TA költség / total approved TA</td>
            <td class="col2">{{ (approved$ | async)?.tafee | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
            <td class="col2">{{ (approved$ | async)?.tafee_dev | number:'1.0-0' }} <span class="unimportant">EUR</span></td>
            <td class="col3"></td>
            <td class="col4"></td>
        </tr>
        <tr class="total approved">
            <td class="col1" t7e="accepted_total">Összes jóváhagyott / TOTAL APPROVED OT + TA</td>
            <td class="col2">{{ (approved$ | async)?.grandTotal | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span></td>
            <td class="col3" [ngClass]="classApproved$ | async">{{ (approved$ | async)?.remaining | number:'1.0-0' }} <span class="unimportant">{{ currency }}</span></td>
            <td class="col4" [ngClass]="classApproved$ | async">{{ (approved$ | async)?.percent | percent }}</td>
            <td class="col2">{{ (approved$ | async)?.grandTotal_dev | number:'1.0-0' }} <span class="unimportant">EUR</span></td>
            <td class="col3" [ngClass]="classApproved$ | async">{{ (approved$ | async)?.remaining_dev | number:'1.0-0' }} <span class="unimportant">EUR</span></td>
            <td class="col4" [ngClass]="classApproved$ | async">{{ (approved$ | async)?.percent | percent }}</td>
        </tr>
    </tbody>
</table>

<div *ngIf="debug">
    all:
    {{ all$ | async | json }}
    <hr>
    extraSum:
    {{ extraSum$ | async | json }}
    <hr>
    approved:
    {{ approved$ | async | json }}
</div>

<app-refresh-button (refresh)="refresh()"></app-refresh-button>

<ng-template #loading>
    <app-page-loading></app-page-loading>
</ng-template>
