<div class="full-page-overlay" *ngIf="saving$ | async">
    <app-page-loading [showText]="false"></app-page-loading>
</div>
<form class="form" [formGroup]="form" (submit)="saveClicked()">

    <div class="lang-selector">
        <app-lang-selector></app-lang-selector>
    </div>

    <div class="crew-selector" *ngIf="HAS_FOREIGN_CREW">
        <app-radio-button [keysValues$]="crewOptions$" [selected$]="selectedCrewOption$"
            [ngClass]="{'crewType-error': form.get('crewType')?.touched && form.hasError('required', 'crewType')}"></app-radio-button>
    </div>

    <div class="email-form-field">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Email</mat-label>
            <input matNativeControl formControlName="email">
        </mat-form-field>
    </div>

    <div class="name-form-field" *ngIf="showUserData">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{t7eFamNameLabel}}</mat-label>
            <input matNativeControl formControlName="famname">
            <mat-hint>{{t7eNameHint}}</mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'famname')">
                {{t7eReqFieldError}}
            </mat-error>
        </mat-form-field>
    </div>
    
    <div class="name-form-field" *ngIf="showUserData">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{t7eNameLabel}}</mat-label>
            <input matNativeControl formControlName="usname">
            <mat-hint>{{t7eNameHint}}</mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'usname')">
                {{t7eReqFieldError}}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="tel-form-field" *ngIf="showUserData">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{t7eTelLabel}}</mat-label>
            <input matNativeControl formControlName="tel">
            <mat-hint>{{t7eTelHint}}</mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'tel')">
                {{t7eReqFieldError}}
            </mat-error>
            <mat-error *ngIf="form.get('tel')!.hasError('pattern') && form.get('phonenumber')?.touched">
                {{ t7eTelPatternError }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="dept-selector" *ngIf="showContracts">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{t7eDeptLabel}}</mat-label>
            <mat-select matNativeControl formControlName="department">
                <mat-option *ngFor="let option of departments$ | async" [value]="option">
                    {{ option.depname }}
                </mat-option>
            </mat-select>
            <mat-hint>
                {{t7eDeptHint}}
            </mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'department')">
                {{t7eReqFieldError}}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="position-form-field" *ngIf="showContracts">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{t7eRoleLabel}}</mat-label>
            <input matNativeControl formControlName="position">
            <mat-hint>{{t7eRoleHint}}</mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'position')">
                {{t7eReqFieldError}}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- <div class="errors">form.invalid:{{form.invalid}}; form.valid:{{form.valid}}; {{ errors }}</div> -->
    <button mat-raised-button type="submit" color="primary"
        [disabled]="saving$ | async">
        <mat-icon>save</mat-icon>
        {{t7eBtnSave}}
    </button>
</form>