<h1 class="page-title" t7e="page-title">Stáblista</h1>

<form class="filter" [formGroup]="form">
    <mat-form-field class="">
        <mat-label>{{ 'search' | t7e:'Keresés'}}</mat-label>
        <input matNativeControl formControlName="search">
        <mat-icon matSuffix class="search-icon">search</mat-icon>
    </mat-form-field>
</form>

<div class="grid" *ngIf="!(loading$ | async);else loading">
    <div class="cell header" t7e="name">Name</div>
    <div class="cell header">Email</div>
    <div class="cell header" t7e="phone">Phone</div>
    <div class="cell header" t7e="department">Department</div>
    <div class="cell header" t7e="role">Position</div>

    <ng-container *ngFor="let user of crewList$ | async">
        <div class="cell first">{{ user.famname }}&nbsp;{{user.usname}}</div>
        <div class="cell">
            <a [href]="'mailto:' + user.email">
                {{user.email}}
            </a>
        </div>
        <div class="cell">
            <a [href]="'tel:' + user.phonenumber">
                {{user.phonenumber}}
            </a>
        </div>
        <div class="cell before-last depts">
            <div *ngFor="let dep of getDepts(user) | distinctBy:'depname'">
                {{dep.depname}}
            </div>
        </div>
        <div class="cell last roles">
            <div *ngFor="let dep of getDepts(user)">
                {{dep.role}}
            </div>
        </div>
        <div class="cell last depts-roles">
                <div *ngFor="let dep of getDepts(user)">
                    <span>{{dep.depname}}</span>
                    /
                    <span>{{dep.role}}</span>
                </div>
        </div>
    </ng-container>
</div>

<app-refresh-button (refresh)="refresh()"></app-refresh-button>

<ng-template #loading>
    <app-page-loading></app-page-loading>
</ng-template>