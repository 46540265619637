import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService, getReorderedConfig, getResizedConfig } from '../../state.service';
import { AggregateDescriptor, CompositeFilterDescriptor, GroupDescriptor, groupBy, process } from '@progress/kendo-data-query';
import { ColumnReorderEvent, ColumnResizeArgs, DataStateChangeEvent, GridComponent, GridDataResult, GroupKey } from '@progress/kendo-angular-grid';
import { ReportService } from '../report.service';
import { AppService } from '../../app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest, filter, map, switchMap, tap } from 'rxjs';
import { ProductionService } from '../../prod/production.service';
import { config } from '../../config';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { TimesheetBase, TimesheetBaseParsed } from '../../models/timesheet';

@Component({
  selector: 'app-salary-report',
  templateUrl: './salary-report.component.html',
  styleUrls: ['./salary-report.component.scss']
})
export class SalaryReportComponent implements OnInit {
  @ViewChild('grid') grid!: GridComponent

  multiCurrency = config.MULTIPLE_CURRENCIES
  form = this.getFG()
  gridData$ = new BehaviorSubject<GridDataResult>({ data: [], total: 0 })
  reload$ = new Subject<boolean>()

  constructor(
    private stateSvc: StateService,
    private reportSvc: ReportService,
    private appSvc: AppService,
    private prodSvc: ProductionService,
    private fb: FormBuilder,
  ) {
    this.allDataForExcel = this.allDataForExcel.bind(this)
    
    this.appSvc.pageTitle$.next('Napidíjak')

    if (this.multiCurrency === true) {
      this.prodSvc.fetchTodaysExchangeRate()
    }

    combineLatest([this.form.valueChanges, this.reload$])
      .pipe(
        filter(([form, reloadTrigger]) => this.form.valid && reloadTrigger),
        tap(() => this.reload$.next(false)),
        tap(console.log   ),
        switchMap(([form, _]) => this.reportSvc.getSalaryReport(form.from, form.to)),
      // map to GridDataResult
        map((report, _) => ({
          data: report as TimesheetBase[],
          total: report?.length || 0,
        })),
    )
    .subscribe(this.gridData$)
  }

  loading$ = this.reportSvc.isSalaryReportLoading$

  currency = this.prodSvc.getCurrency()
  todaysCurrencyExchangeRate$ = this.prodSvc.todaysCurrencyExchangeRate$
  todaysCurrencyExchangeRateLoading$ = this.prodSvc.todaysCurrencyExchangeRateLoading$
  todaysCurrencyExchangeRateError$ = this.prodSvc.todaysCurrencyExchangeRateError$
  allCurrencyExchRatesFiltered$ = this.prodSvc.allCurrencyExchangeRates$
    .pipe(
      tap(x => console.log(x)),
      // go through all the rates and filter out the ones with falsy keys, and where the key is HUF
      map(rates => rates
        && Object.fromEntries(Object.entries(rates).filter(([key, value]) => key && key !== 'HUF'))),
    )

  initTotals = {
    salary: 0,
    overtimefee: 0,
    tafee: 0,
    perdiem: 0,
    mealpenalty: 0,
    otherfee: 0,
    vignette: 0,
    gas: 0,
    park: 0,
    dailyrentalrate1: 0,
    dailyrentalrate2: 0,
    dailyrentalrate3: 0,
    dailyrentalrate4: 0,
    dailyrentalrate5: 0,
    dailyrentalrate6: 0,
    monthlyrentalrate1: 0,
    monthlyrentalrate2: 0,
    monthlyrentalrate3: 0,
  }

  totals$ = combineLatest([this.gridData$, this.todaysCurrencyExchangeRate$, this.allCurrencyExchRatesFiltered$])
    .pipe(
      map(([tsListGridDate, todaysRate, allRates]) => {
        const tsList = tsListGridDate?.data as Partial<TimesheetBaseParsed[]> || null
        switch (this.multiCurrency) {
          case false:
            return (tsList)?.reduce((prev, curr, i, arr) => ({
              salary:       prev?.salary + (curr?.salary || 0),
              overtimefee:  prev?.overtimefee + (curr?.overtimefee || 0),
              tafee:       prev?.tafee + (curr?.tafee || 0),
              perdiem:     prev?.perdiem + (curr?.perdiem || 0),
              mealpenalty: prev?.mealpenalty + (curr?.mealpenalty || 0),
              otherfee:    prev?.otherfee + (curr?.otherfee || 0),
              vignette:    prev?.vignette + (curr?.vignette || 0),
              gas:         prev?.gas + (curr?.gas || 0),
              park:        prev?.park + (curr?.park || 0),
              dailyrentalrate1: prev?.dailyrentalrate1 + (curr?.dailyrentalrate1 || 0),
              dailyrentalrate2: prev?.dailyrentalrate2 + (curr?.dailyrentalrate2 || 0),
              dailyrentalrate3: prev?.dailyrentalrate3 + (curr?.dailyrentalrate3 || 0),
              dailyrentalrate4: prev?.dailyrentalrate4 + (curr?.dailyrentalrate4 || 0),
              dailyrentalrate5: prev?.dailyrentalrate5 + (curr?.dailyrentalrate5 || 0),
              dailyrentalrate6: prev?.dailyrentalrate6 + (curr?.dailyrentalrate6 || 0),
              monthlyrentalrate1: prev?.monthlyrentalrate1 + (curr?.monthlyrentalrate1 || 0),
              monthlyrentalrate2: prev?.monthlyrentalrate2 + (curr?.monthlyrentalrate2 || 0),
              monthlyrentalrate3: prev?.monthlyrentalrate3 + (curr?.monthlyrentalrate3 || 0),
            }), this.initTotals)
          case 'fixed-rates':
            return (tsList)?.reduce((prev, curr, i, arr) => {
              const rate = (!curr?.currency || !allRates?.[curr?.currency] || curr?.currency === this.currency) ? 1 : allRates[curr?.currency]
              return {
                salary:       prev?.salary + (curr?.salary || 0) * rate!,
                overtimefee:  prev?.overtimefee + (curr?.overtimefee || 0) * rate!,
                tafee:        prev?.tafee + (curr?.tafee || 0) * rate!,
                perdiem:      prev?.perdiem + (curr?.perdiem || 0) * rate!,
                mealpenalty:  prev?.mealpenalty + (curr?.mealpenalty || 0) * rate!,
                otherfee:     prev?.otherfee + (curr?.otherfee || 0) * rate!,
                vignette:     prev?.vignette + (curr?.vignette || 0) * rate!,
                gas:          prev?.gas + (curr?.gas || 0) * rate!,
                park:         prev?.park + (curr?.park || 0) * rate!,
                dailyrentalrate1: prev?.dailyrentalrate1 + (curr?.dailyrentalrate1 || 0) * rate!,
                dailyrentalrate2: prev?.dailyrentalrate2 + (curr?.dailyrentalrate2 || 0) * rate!,
                dailyrentalrate3: prev?.dailyrentalrate3 + (curr?.dailyrentalrate3 || 0) * rate!,
                dailyrentalrate4: prev?.dailyrentalrate4 + (curr?.dailyrentalrate4 || 0) * rate!,
                dailyrentalrate5: prev?.dailyrentalrate5 + (curr?.dailyrentalrate5 || 0) * rate!,
                dailyrentalrate6: prev?.dailyrentalrate6 + (curr?.dailyrentalrate6 || 0) * rate!,
                monthlyrentalrate1: prev?.monthlyrentalrate1 + (curr?.monthlyrentalrate1 || 0) * rate!,
                monthlyrentalrate2: prev?.monthlyrentalrate2 + (curr?.monthlyrentalrate2 || 0) * rate!,
                monthlyrentalrate3: prev?.monthlyrentalrate3 + (curr?.monthlyrentalrate3 || 0) * rate!,
              }
            }, this.initTotals)
          case true:
            return (tsList)?.reduce((prev, curr, i, arr) => {
              const rate = (!todaysRate?.rate || curr?.currency === this.currency) ? { rate: 1, dayId: 0, date: new Date() } : todaysRate
              return {
                salary:       prev?.salary + (curr?.salary || 0) * rate.rate!,
                overtimefee:  prev?.overtimefee + (curr?.overtimefee || 0) * rate.rate!,
                tafee:        prev?.tafee + (curr?.tafee || 0) * rate.rate!,
                perdiem:      prev?.perdiem + (curr?.perdiem || 0) * rate.rate!,
                mealpenalty:  prev?.mealpenalty + (curr?.mealpenalty || 0) * rate.rate!,
                otherfee:     prev?.otherfee + (curr?.otherfee || 0) * rate.rate!,
                vignette:     prev?.vignette + (curr?.vignette || 0) * rate.rate!,
                gas:          prev?.gas + (curr?.gas || 0) * rate.rate!,
                park:         prev?.park + (curr?.park || 0) * rate.rate!,
                dailyrentalrate1: prev?.dailyrentalrate1 + (curr?.dailyrentalrate1 || 0) * rate.rate!,
                dailyrentalrate2: prev?.dailyrentalrate2 + (curr?.dailyrentalrate2 || 0) * rate.rate!,
                dailyrentalrate3: prev?.dailyrentalrate3 + (curr?.dailyrentalrate3 || 0) * rate.rate!,
                dailyrentalrate4: prev?.dailyrentalrate4 + (curr?.dailyrentalrate4 || 0) * rate.rate!,
                dailyrentalrate5: prev?.dailyrentalrate5 + (curr?.dailyrentalrate5 || 0) * rate.rate!,
                dailyrentalrate6: prev?.dailyrentalrate6 + (curr?.dailyrentalrate6 || 0) * rate.rate!,
                monthlyrentalrate1: prev?.monthlyrentalrate1 + (curr?.monthlyrentalrate1 || 0) * rate.rate!,
                monthlyrentalrate2: prev?.monthlyrentalrate2 + (curr?.monthlyrentalrate2 || 0) * rate.rate!,
                monthlyrentalrate3: prev?.monthlyrentalrate3 + (curr?.monthlyrentalrate3 || 0) * rate.rate!,
              }
            }, this.initTotals)
        }
      }
      )
    )


  get debug () { return this.appSvc.debug }
  get fromFC() { return this.form.get('from') as FormControl }
  get toFC() { return this.form.get('to') as FormControl }
  get minDate() { return this.prodSvc.prodDefaults$.value?.dPreparationstartday || new Date(2000, 0, 1) }
  get maxDate() { return new Date() }

  ngOnInit(): void {
  }

  getFG() {
    return this.fb.group<FilterFG>({
      from: this.fb.control(this.filterSettings?.from || null, Validators.required),
      to: this.fb.control(this.filterSettings?.to || null, Validators.required),
    })
  }

  reload(): void {
    this.reload$.next(true)
  }

  exportToExcel(): void {
    this.grid.saveAsExcel()
  }

  allDataForExcel(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(
        this.gridData$.value.data || [],
        { sort: this.sort, }).data,
    }
    return result
  }


  /** USER PREFERENCES */

  get gridSettings() {
    return this.stateSvc.salaryReportPageState$.value?.grid
  }
  get filterSettings() {
    return this.stateSvc.salaryReportPageState$.value?.filters
  }
  get skip() { return this.gridSettings?.state?.skip || 0 }
  get sort() { return this.gridSettings?.state?.sort || [] }
  get filter() { return this.gridSettings?.state?.filter || { filters: [], logic: 'and' } }
  get pageSize() { return this.gridSettings?.state?.take || 100 }
  get expandedGroupKeys() { return this.gridSettings?.expandedGroupKeys || [] }
  set expandedGroupKeys(val: Array<GroupKey>) {
    this.stateSvc.setSalaryReportPageState({ grid: { expandedGroupKeys: val } })
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.stateSvc.setSalaryReportPageState({ grid: { state } })
  }

  filterChanged(event: CompositeFilterDescriptor): void {
    this.resetSkip();
  }
  resetSkip(): void {
    this.stateSvc.setSalaryReportPageState({
      grid: { state: { skip: 0 } }
    })
  }

  public onColReorder(e: ColumnReorderEvent): void {
    // set the new orderIndex to all affected columns
    const newColumnsConfig: any = getReorderedConfig(this.grid, e)
    this.stateSvc.setSalaryReportPageState({ grid: { columnsConfig: newColumnsConfig } })
    //console.log(this.stateSvc.salaryReportPageState$.value?.grid?.columnsConfig)
  }

  public onColResized(e: ColumnResizeArgs[]): void {
    const newConfigs = getResizedConfig(e)
    newConfigs.forEach((newConfigs) => {
      this.stateSvc.setSalaryReportPageState({ grid: { columnsConfig: newConfigs } });
    })
  }

  getWidth(field: string): number | undefined {
    const columnsConfig = this.gridSettings?.columnsConfig || {}
    return columnsConfig[field]?.width
  }

}

type FilterFG = {
  from: FormControl<Date | null>,
  to: FormControl<Date | null>,
}