import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionGroup, defaultQuestionGroupName } from '../../contract/contract.service';
import { FileUploadResponse, FlexTables } from '../../data.service';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ErrorEvent } from "@progress/kendo-angular-upload";

@Component({
  selector: 'app-flex-field-group',
  templateUrl: './flex-field-group.component.html',
  styleUrls: ['./flex-field-group.component.scss']
})
export class FlexFieldGroupComponent implements OnInit {
  @Input() questionGroup?: QuestionGroup
  @Input() fullFormGroup: FormGroup | null = null
  @Input() tableid: number = 0
  @Input() formFieldStyles: { [key: string]: string } = {}
  @Input() formGroupStyles: { [key: string]: string } = {}
  @Input() showReadonlyFields: boolean = false
  @Input() isEditable: boolean = true
  /**
   * Ha false, akkor nem validálom a mezőket
   */
  @Input() validate$?: BehaviorSubject<boolean>
  @Input() uploadFolderName?: string
  @Input() uploadFolderId?: string
  @Output() fileUploadSuccess = new EventEmitter<FileUploadResponse>()
  @Output() fileUploadError = new EventEmitter<ErrorEvent>()

  form?: FormGroup
  flexFieldsFG$ = new BehaviorSubject<FormGroup | undefined>(undefined)
  allowedPropCodes: string[] = []
  defaultQuestionGroupName = defaultQuestionGroupName

  constructor() { }
  
  readonly contractFlexTable = FlexTables.contracts
  get flexFieldsFG() {
    return this.fullFormGroup as FormGroup
  }

  ngOnInit(): void {
    if (!this.fullFormGroup) {
      throw 'form: FormGroup nélkül nem tudok űrlapot készíteni'
    }
    if (!this.questionGroup) {
      return
    }
    this.allowedPropCodes = this.questionGroup.questionPropCodes
  }

  onFileUploadSuccess(event: FileUploadResponse) {
    this.fileUploadSuccess.emit(event)
  }
  onFileUploadError(event: ErrorEvent) {
    this.fileUploadError.emit(event)
  }
}
