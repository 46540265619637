import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocService, DocType } from '../../doc.service';
import { Contract, ContractParsed, DocLink } from '../../models/contract';
import { Timesheet, TimesheetWeekly } from '../../models/timesheet';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-doclink',
  templateUrl: './doclink.component.html',
  styleUrls: ['./doclink.component.scss']
})
export class DoclinkComponent implements OnInit {
  @Input() data?: TimesheetWeekly | ContractParsed
  @Output() removeFileClicked = new EventEmitter<RemoveFileClickedEvent>()
  @Output() sendOnlyPdfClicked = new EventEmitter<SendPdfClickedEvent>()

  isGenerating = this.docSvc.isContractGenerating // azon a conid-k listája, aminek a generálása elkezdődött
  isRemovingDoc = this.docSvc.isRemovingDoc // azon a fileid-k listája, aminek a Google Drive törlése elkezdődött
  generatingError$ = this.docSvc.generatingContractError$ // azon a conid-k listája, aminek a generálása hibára futott
  removingDocError$ = this.docSvc.removingDocError$ // azon a fileid-k listája, amineka Google Drive törlése hibára futott
  isJustGenerated = this.docSvc.isContractJustGenerated // azon a conid-k listája, aminek a generálása most történt
  
  getDriveLink = this.docSvc.getDriveLink

  getIsGenerating = (conid: number | null) => { return this.docSvc.getIsContractGenerating(conid) }
  getIsRemovingDoc = this.docSvc.getIsRemovingDoc
  generatePdf = this.docSvc.generateContract
  canGeneratePdf = this.docSvc.canGenerateContractPdf
  getUserName = this.userSvc.getuUserByEmail

  constructor(
    private docSvc: DocService,
    private userSvc: UserService,
  ) { }

  ngOnInit(): void {
  }
  
  get docType(): DocType {
    return !!this.data?.tsids ? 'weekly-ts' : 'contract'
  }

  removeFile(doc: DocLink, data: ContractParsed | TimesheetWeekly): void {
    this.removeFileClicked.emit({ doc, data })
  }

  sendOnlyPdf(doc: DocLink, data: ContractParsed | TimesheetWeekly): void {
    this.sendOnlyPdfClicked.emit({ data, doc })
  }


}

export type RemoveFileClickedEvent = {
  doc: DocLink
  data: ContractParsed | TimesheetWeekly
}

export type SendPdfClickedEvent = {
  doc: DocLink
  data: ContractParsed | TimesheetWeekly
}