import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { Observable, Subject, filter, map, mergeMap, takeUntil } from 'rxjs';
import { UserService } from './user.service';
import { T7eService, T7eLangs } from './t7e/t7e.service';
import { DataService } from './data.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppService, SwAppData, VersionUpdateTarget, VersionUpdateType } from './app.service';
import { ContractService } from './contract/contract.service';
import { DeptService } from './dept/dept.service';
import { NotificationService } from './util/notifications/notification.service';
import { productionTitle } from './config'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  readonly maintenanceNotifHu = null // "Október 21-23 között karbantartás miatt az app nem lesz elérhető. A türelmeteket előre is köszönjük"
  readonly maintenanceNotifEn = null //"Beware of the maintenance break between 21-23 October. The application will be unavailable. Thank you for your patience."

  user$ = this.userSvc.user$
  isAuthenticated$ = this.userSvc.isAuthenticated$
  
  tableProperties$ = this.appSvc.tableProperties$
  departments$ = this.deptSvc.departments$
  dbUser$ = this.userSvc.loggedinUser$
  isAppDataLoading$ = this.appSvc.isDataLoading$
  isAppLoadingError$ = this.dataSvc.initDataError$
  isAppStable$ = this.appSvc.isAppStable$
  isUpdateAvailable$ = this.appSvc.isUpdateAvailable$
  auth0UserLoaded = this.userSvc.auth0UserLoaded
  pageFullyLoaded$ = this.userSvc.pageFullyLoaded$
  newVersionType$ = this.isUpdateAvailable$.pipe(map(this.getUpdateTypeForUser.bind(this)))
  get lang() { return this.t7e.lang }
  get productionTitle() { return productionTitle }

  constructor(@Inject(DOCUMENT) public document: Document,
    private route: ActivatedRoute,
    private router: Router,
    public userSvc: UserService,
    private dataSvc: DataService,
    private appSvc: AppService,
    private deptSvc: DeptService,
    private contractSvc: ContractService,
    private notifSvc: NotificationService,
    private t7e: T7eService,
  ) { 
    this.updateApp = this.updateApp.bind(this)
    this.isAppLoadingError$
      .pipe(filter(err => !!err))
      .subscribe(_ => {
      this.isAppDataLoading$.next(false)
      this.pageFullyLoaded$.next(true)
    })
  }
  
  ngOnInit(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        console.log('route:', e.url);
        if (e.url == '/') {
            this.router.navigate(['dashboard'])
          }
        }
    })

    this.newVersionType$
      .pipe(filter(type => type === 'important'))
      .subscribe(_ => {
        const msg = this.t7e.getTranslation('div', 'new-version-avaliable', 'innerText', null) + this.isUpdateAvailable$.value?.whatsNew || ''
        const buttonText = this.t7e.getTranslation('div', 'update', 'innerText', null)
        msg && this.notifSvc.addObservableNotif({ msg, class:'info', buttonText, onAction: this.updateApp })
      })
  }

  login(lang: T7eLangs): void {
    this.userSvc.login();
  }

  logout(): void {
    this.userSvc.logout(this.document.location.origin);
  }

  private getUpdateTypeForUser(newVersionAppData: SwAppData | null): false | 'critical' | 'important' {
    if (!newVersionAppData) return false
    const fn = (type: VersionUpdateType | VersionUpdateType[] | undefined) => {
      if (type) {
        if (type === true) return true
        if (typeof type === 'string') type = [{ target: 'email', value: type }]
        if (!Array.isArray(type)) type = [type]
        const retVal = (type as VersionUpdateTarget[]).some(targetGroup => {
          switch (targetGroup.target) {
            case 'email':
              if (targetGroup.value === this.userSvc.auth0User?.email) return true
              break
            case 'deptId':
              if (this.contractSvc.isUserInDepartment(this.userSvc.loggedinUserId, targetGroup.value as number)) return true
              break
            case 'userGroupId':
              if (this.userSvc.roles.find(r => r.ugid == targetGroup.value)) return true
          }
          return false
        })
        if (retVal) return true
      }
      return false
    }
    try {
      if (fn(newVersionAppData.isCritical)) return 'critical'
      if (fn(newVersionAppData.isImportant)) return 'important'
    } catch (_) {
      return 'important'
    }
    return false
  }

  updateApp() {
    document.location.reload()
  }
}
