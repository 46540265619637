<div class="form-field" [formGroup]="formgroup!">
    <kendo-floatinglabel [text]="label">
        <kendo-datepicker [min]="min" [max]="max" [formControl]="fc!">
        </kendo-datepicker>
    </kendo-floatinglabel>

    <button mat-icon-button (click)="fc.reset()" type="button" *ngIf="!!fc.value">
        <span class="k-icon k-i-x"></span>
    </button>

</div>