<div class="form-field" [formGroup]="formgroup!">
    <kendo-daterange>
        <kendo-floatinglabel [text]="t7eStartLabel">
            <kendo-dateinput kendoDateRangeStartInput [formControl]="fromFC!" [min]="min" [max]="max"></kendo-dateinput>
        </kendo-floatinglabel>
        <kendo-floatinglabel [text]="t7eEndLabel">
            <kendo-dateinput kendoDateRangeEndInput [formControl]="toFC!" [min]="min" [max]="max"></kendo-dateinput>
        </kendo-floatinglabel>
    </kendo-daterange>
    
    <div class="reset-button" *ngIf="canReset" (click)="reset()" [title]="'Törlés'">x</div>
</div>
