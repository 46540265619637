import { GridDataResult } from "@progress/kendo-angular-grid"
import { ProcRequestParams, SqlDataRow } from "../data.service"

export interface GrandOtta {
    "ottabudget"?: number | null,
    periods: GrandOttaPeriod[]
}
export interface GrandOttaPeriod {
    "period": string,
    "approved": GrandOttaPeriodAmounts,
    "all": GrandOttaPeriodAmounts
    "extra"?: GrandOttaPeriodExtra
}
export interface GrandOttaPeriodAmounts {
    "otfee": number,
    "otfee_dev": number,
    "tafee": number,
    "tafee_dev": number,
    "totalCT": number,
    "totalCT_dev": number,
    "grandTotal": number,
    "grandTotal_dev": number,
    "remaining": number,
    "remaining_dev": number,
    "percent": number,
}

export interface GrandOttaPeriodExtra {
    "shid"?: number | null,
    "extraotta1"?: number | null,
    "extraotta1_dev"?: number | null,
    "extraotta2"?: number | null,
    "extraotta2_dev"?: number | null,
    "extraotta3"?: number | null,
    "extraotta3_dev"?: number | null,
}

export interface GrandOttaResponse extends SqlDataRow {
    "ottabudget"?: number | null,
    "ottabudget_dev"?: number | null,
    "items"?: ({
        "item"?: string,
        "approved"?: {
            "otfee"?: number | null,
            "otfee_dev"?: number | null,
            "tafee"?: number | null,
            "tafee_dev"?: number | null,
        },
        "notapproved"?: {
            "otfee"?: number | null,
            "otfee_dev"?: number | null,
            "tafee"?: null
            "tafee_dev"?: null
        },
        "extra": {
            "shid"?: number | null,
            "extraotta1"?: number | null,
            "extraotta1_dev"?: number | null,
            "extraotta2"?: number | null,
            "extraotta2_dev"?: number | null,
            "extraotta3"?: number | null,
            "extraotta3_dev"?: number | null,
        }
    } | null)[]
}

export interface GrandOttaParams extends ProcRequestParams {
 _prid?: number | null //  integer DEFAULT 1,
 _categ?: number | null // integer DEFAULT 1,
 _startdate?: string | null // timestamp without time zone DEFAULT NULL::timestamp without time zone,
 _enddate?: string | null //   timestamp without time zone DEFAULT NULL::timestamp without time zone,
}

export enum GrandOttaCateg {
    All = 0,
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
}

export function parseGrandOtta (data: GrandOttaResponse): GrandOtta  {
    const result: GrandOtta = {ottabudget: data.ottabudget, periods: []}
    for (let i = 0; i < (data.items?.length || 0); i++) {
        const item = data.items![i]
        if (!item) continue
        const period = item.item ? item.item : "Full Budget"
        const notapproved = {
            otfee: item.notapproved?.otfee ? item.notapproved.otfee : 0,
            otfee_dev: item.notapproved?.otfee_dev ? item.notapproved.otfee_dev : 0,
            tafee: item.notapproved?.tafee ? item.notapproved.tafee : 0,
            tafee_dev: item.notapproved?.tafee_dev ? item.notapproved.tafee_dev : 0,
        }
        const extra = item.extra
            ? {
                shid: item.extra.shid,
                extraotta1: item.extra.extraotta1,
                extraotta1_dev: item.extra.extraotta1_dev,
                extraotta2: item.extra.extraotta2,
                extraotta2_dev: item.extra.extraotta2_dev,
                extraotta3: item.extra.extraotta3,
                extraotta3_dev: item.extra.extraotta3_dev,
            }
            : {
                shid: null,
                extraotta1: null,
                extraotta1_dev: null,
                extraotta2: null,
                extraotta2_dev: null,
                extraotta3: null,
                extraotta3_dev: null,
            }
        const approvedTotalCT = (item.approved?.otfee || 0) + (item.approved?.tafee || 0)
        const approvedTotalCT_dev = (item.approved?.otfee_dev || 0) + (item.approved?.tafee_dev || 0)
        const approvedGrandTotal = approvedTotalCT + (extra.extraotta1 || 0) + (extra.extraotta2 || 0) + (extra.extraotta3 || 0)
        const approvedGrandTotal_dev = approvedTotalCT_dev + (extra.extraotta1_dev || 0) + (extra.extraotta2_dev || 0) + (extra.extraotta3_dev || 0)
        const approved: GrandOttaPeriodAmounts = item.approved
            ? {
                otfee: item.approved.otfee ? item.approved.otfee : 0,
                otfee_dev: item.approved.otfee_dev ? item.approved.otfee_dev : 0,
                tafee: item.approved.tafee ? item.approved.tafee : 0,
                tafee_dev: item.approved.tafee_dev ? item.approved.tafee_dev : 0,
                totalCT: approvedTotalCT,
                totalCT_dev: approvedTotalCT_dev,
                grandTotal: approvedGrandTotal,
                grandTotal_dev: approvedGrandTotal_dev,
                remaining: data.ottabudget ? data.ottabudget - (approvedGrandTotal) : 0,
                remaining_dev: data.ottabudget_dev ? data.ottabudget_dev - (approvedGrandTotal_dev) : 0,
                percent: data.ottabudget ? (approvedGrandTotal) / data.ottabudget : 0,
            }
            : {
                otfee: 0,
                otfee_dev: 0,
                tafee: 0,
                tafee_dev: 0,
                totalCT: 0,
                totalCT_dev: 0,
                grandTotal: 0,
                grandTotal_dev: 0,
                remaining: data.ottabudget ? data.ottabudget : 0,
                remaining_dev: data.ottabudget_dev ? data.ottabudget_dev : 0,
                percent: 0,
            }
        const notapprovedTotalCT = notapproved.otfee + notapproved.tafee
        const notapprovedTotalCT_dev = notapproved.otfee_dev + notapproved.tafee_dev
        const allGrandTotal = approved.grandTotal + notapprovedTotalCT   // nincs olyan, hogy notapproved extra, ezért azt nem kell hozzáadni
        const allGrandTotal_dev = approved.grandTotal_dev + notapprovedTotalCT_dev
        const all = item.notapproved
            ? {
                otfee: approved.otfee + notapproved.otfee,
                otfee_dev: approved.otfee_dev + notapproved.otfee_dev,
                tafee: approved.tafee + notapproved.tafee,
                tafee_dev: approved.tafee_dev + notapproved.tafee_dev,
                totalCT: approvedTotalCT + notapprovedTotalCT,
                totalCT_dev: approvedTotalCT_dev + notapprovedTotalCT_dev,
                grandTotal: allGrandTotal,
                grandTotal_dev: allGrandTotal_dev,
                remaining: data.ottabudget ? data.ottabudget - (allGrandTotal) : 0,
                remaining_dev: data.ottabudget_dev ? data.ottabudget_dev - (allGrandTotal_dev) : 0,
                percent: data.ottabudget ? (allGrandTotal) / data.ottabudget : 0,

            }
            : approved
        result.periods.push({ period, approved, all, extra })
    }
    return result
}

export function mapToGridDataResult (data: GrandOtta | null): GridDataResult {
    return <GridDataResult>{
        data: data?.periods
            // Kilapítjuk a formgroup-ot. Egyszerűbb így, mint a hierarchikus formgroup-ot a gridben és a kontrollerben kezelni.
            .map(x => ({ ...x, extra1: x.extra?.extraotta1, extra2: x.extra?.extraotta2, extra3: x.extra?.extraotta3 })),
        total: data?.periods.length,
    }
}