import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FlexProp, FlexTableProperty } from '../../../models/table-property';
import { AppService } from '../../../app.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-prop-form',
  templateUrl: './edit-prop-form.component.html',
  styleUrls: ['./edit-prop-form.component.scss']
})
export class EditPropFormComponent implements OnInit {
  flexPropsFiltered$ = this.appSvc.flexProps$//.pipe(map(p => p.filter(p2 => p2.propname.includes(this.propFilter))))
  formGroup: FormGroup
  dataItem: FlexProp
  flexEditTypes$ = this.appSvc.flexEditTypes$
  editLevels = this.appSvc.getEditLevels()
  isNewProp: boolean = false
  isPropCodeUnique: boolean = true

  constructor(
    private fb: FormBuilder,
    private appSvc: AppService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.formGroup = this.createFG(data)
    this.dataItem = data.dataItem
   }

  get propidFC() { return this.formGroup.get('propid') as FormControl }
  get edittypeFC() { return this.formGroup.get('edittype') as FormControl }
  get propcodeFC() { return this.formGroup.get('propcode') as FormControl }
  get propnameFC() { return this.formGroup.get('propname') as FormControl }
  get propnameEngFC() { return this.formGroup.get('engpropname') as FormControl }
  get propdescFC() { return this.formGroup.get('propdesc') as FormControl }
  get propdescEngFC() { return this.formGroup.get('propdesceng') as FormControl }
  get levelFC() { return this.formGroup.get('level') as FormControl }

  saving$ = this.appSvc.savingFlexProperties$
  saveError$ = this.appSvc.saveFlexPropertiesError$

  ngOnInit(): void {
  }

  createFG(args: DialogData): FormGroup {
    if (args.isNew) {
      args.dataItem = this.createNewProp()
    }
    this.isNewProp = args.isNew
    const item = args.dataItem;

    this.formGroup = this.fb.group({
      propid: [item.propid],
      propname: [item.propname],
      engpropname: [item.engpropname],
      propdesc: [item.propdesc],
      propdesceng: [item.propdesceng],
      propcode: [item.propcode],
      // modusid: [item.modusid],
      // entryon: [item.entryon],
      level: [item.level],
      edittype: [item.edittype],
      //edittypename: [item.edittypename],
    });

    if (!args.isNew) {
      this.formGroup.get('propcode')?.disable()
      this.formGroup.get('edittype')?.disable()
    }
    console.log(this.formGroup)
    console.table(this.formGroup.value)
    return this.formGroup;
  }

  createNewProp(): FlexProp {
    return {
      propid: 0,
      propname: '',
      engpropname: '',
      propcode: '',
      modusid: null,
      entryon: null,
      level: null,
      edittype: 0,
      edittypename: 'text',
      propdesc: null,
      propdesceng: null,
    } as FlexProp
  }

  onPropChange() {
    const value = this.formGroup.get('propcode')?.value
    const selectedProp = this.appSvc.flexProps.find(p => p.propcode == value)
    if (selectedProp) {
      this.formGroup.patchValue(selectedProp)
      this.isPropCodeUnique = false
    } else {
      this.isPropCodeUnique = true
    }
  }

  save(): void {
    let saveVal: FlexTableProperty = this.formGroup.getRawValue()
    this.data.onSave(saveVal)
  }

  cancel(): void {
    this.data.onClose()
  }

  handleFilter(value: string) {
    this.flexPropsFiltered$.next(this.appSvc.flexProps.filter(
      p2 => p2.propname.toLowerCase().includes(value.toLowerCase()))
    )
  }

  getTableName(tableid: number): string {
    return this.appSvc.flexTables.find(t => t.id == tableid)?.value || 'n/a'
  }

}

export interface DialogData {
  dataItem: FlexProp
  isNew: boolean
  onClose: () => void
  onSave: (dataItem: FlexTableProperty) => void
}