import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, delay, of } from 'rxjs';
import { EditableFieldOptions, FieldValue } from '../editable-value/editable-value.component';

@Component({
  selector: 'app-minden',
  templateUrl: './minden.component.html',
  styleUrls: ['./minden.component.scss']
})
export class MindenComponent implements OnInit {

  editableValueFieldText: EditableFieldOptions = {
    type: 'text',
    label: 'Ez egy címke',
    valueTitle: 'Ide jöhet info, hogy miért nem szerkeszthető',
    disabled: false,
    hint: 'Ide jöhet magyarázat, hogy hogy kell kitölteni',
    value$: new BehaviorSubject<FieldValue>('Ide jön az érték, ami nagyon hosszú is lehet'),
    isSuccess: new BehaviorSubject<boolean | null>(null),
    isError: new BehaviorSubject<string | null>(null),

  }
  
  editableValueFieldNumber: EditableFieldOptions = {
    type: 'number',
    label: 'Ez egy címke',
    valueTitle: 'Mentéskor hibát adok!',
    disabled: false,
    hint: 'Ide jöhet magyarázat, hogy hogy kell kitölteni',
    value$: new BehaviorSubject<FieldValue>(12341234.25),
    numberOptions:{ min: 0, max: 1000, step: 500, },
    isSuccess: new BehaviorSubject<boolean | null>(null),
    isError: new BehaviorSubject<string | null>(null),
  }

  editableValueFieldDate: EditableFieldOptions = {
    type: 'date',
    label: 'Ez egy címke',
    valueTitle: 'Ide jöhet info, hogy miért nem szerkeszthető',
    disabled: false,
    hint: 'Ide jöhet magyarázat, hogy hogy kell kitölteni',
    value$: new BehaviorSubject<FieldValue>(new Date()),
    dateOptions: { },
    isSuccess: new BehaviorSubject<boolean | null>(null),
    isError: new BehaviorSubject<string | null>(null),
  }

  editableValueFieldTime: EditableFieldOptions = {
    type: 'time',
    label: 'Ez egy címke',
    valueTitle: 'Ide jöhet info, hogy miért nem szerkeszthető',
    disabled: false,
    hint: 'Ide jöhet magyarázat, hogy hogy kell kitölteni',
    value$: new BehaviorSubject<FieldValue>(new Date()),
    dateOptions: {},
    isSuccess: new BehaviorSubject<boolean | null>(null),
    isError: new BehaviorSubject<string | null>(null),
  }

  saveTextValue(value: FieldValue, makeSuccessful: boolean) {
    of(value).pipe(delay(1000)).subscribe(v => {
      this.editableValueFieldText.isSuccess.next(makeSuccessful)
      if (makeSuccessful) {
        this.editableValueFieldText.isError.next(null)
        this.editableValueFieldText.value$?.next(v)
      } else {
        this.editableValueFieldText.isError.next('Vmi hiba történt')
      }
    })
  }

  saveNumValue(value: FieldValue, makeSuccessful: boolean) {
    of(value).pipe(delay(1000)).subscribe(v => {
      this.editableValueFieldNumber.isSuccess.next(makeSuccessful)
      if (makeSuccessful) {
        this.editableValueFieldNumber.isError.next(null)
        this.editableValueFieldNumber.value$?.next(v)
      } else {
        this.editableValueFieldNumber.isError.next('Vmi hiba történt')
      }
    })
  }

  saveDateValue(value: FieldValue, makeSuccessful: boolean) {
    of(value).pipe(delay(1000)).subscribe(v => {
      this.editableValueFieldDate.isSuccess.next(makeSuccessful)
      if (makeSuccessful) {
        this.editableValueFieldDate.isError.next(null)
        this.editableValueFieldDate.value$?.next(v)
      } else {
        this.editableValueFieldDate.isError.next('Vmi hiba történt')
      }
    })
  }

  saveTimeValue(value: FieldValue, makeSuccessful: boolean) {
    of(value).pipe(delay(1000)).subscribe(v => {
      this.editableValueFieldTime.isSuccess.next(makeSuccessful)
      if (makeSuccessful) {
        this.editableValueFieldTime.isError.next(null)
        this.editableValueFieldTime.value$?.next(v)
      } else {
        this.editableValueFieldTime.isError.next('Vmi hiba történt')
      }
    })
  }

  toggleDisabled(): void {
    this.editableValueFieldText.disabled = !this.editableValueFieldText.disabled
    this.editableValueFieldNumber.disabled = !this.editableValueFieldNumber.disabled
    this.editableValueFieldDate.disabled = !this.editableValueFieldDate.disabled
    this.editableValueFieldTime.disabled = !this.editableValueFieldTime.disabled
  }

  selectedDateDayselector$?= new BehaviorSubject<Date[] | null>(null)
  daySelectorSelectedDateChanged(dates: Date[]) {
    this.selectedDateDayselector$?.next(dates)
  }

  selectedDateCalendar?: Date
  constructor() { 
  }

  ngOnInit(): void {
  }

  trackByIndex = (index: number) => index;

}
