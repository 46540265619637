import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-warning',
  templateUrl: './page-warning.component.html',
  styleUrls: ['./page-warning.component.scss']
})
export class PageWarningComponent implements OnInit {
  @Input() title?: string
  @Input() description?: string
  @Input() icon?: string
  @Input() cssClass?: 'warning' | 'danger' | 'info' | 'success' = 'info'
  @Output() onClosed?: EventEmitter<void> = new EventEmitter<void>()
  
  showMe: boolean = true

  constructor() { }

  ngOnInit(): void {
    if (!this.icon) {
      switch (this.cssClass) {
        case 'warning': this.icon = 'warning'; break;
        case 'danger': this.icon = 'error-outline'; break;
        case 'info': this.icon = 'info'; break;
        case 'success': this.icon = 'check_circle_outline'; break;
      }
    }
  }

  closeMe(e: MouseEvent): void {
    this.showMe = false
    e?.stopPropagation()
    this.onClosed?.emit()
  } 

}
