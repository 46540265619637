import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TimesheetService } from '../../../ts/timesheet.service';
import { UserService } from '../../../user.service';
import { T7eService } from '../../../t7e/t7e.service';

@Component({
  selector: 'app-filter-tsstatus',
  templateUrl: './filter-tsstatus.component.html',
  styleUrls: ['./filter-tsstatus.component.scss']
})
export class FilterTsstatusComponent implements OnInit {
  @Input() formgroup?: FormGroup
  @Input() statusFC?: FormControl
  @Input() style?: Object

  getStatusName = this.tsSvc.getStatusName

  statuses: TimesheetStatusFilterItem[]

  constructor(
    private tsSvc: TimesheetService,
    private userSvc: UserService,
    private t7e: T7eService,
  ) { 
    if (this.userSvc.isFinance) {
      this.statuses = tsSvc.getTimesheetStatuses()
        .map(s => ({ ...s, name: tsSvc.getStatusName(s.id), nameHun: tsSvc.getStatusName(s.id) }))
    } else {
      this.statuses = filterTsstatusesForProdMgr
    }
  }


  ngOnInit(): void {
    console.assert(this.statusFC, 'FilterTsstatusComponent: @Input statusFC kötelező')
    console.assert(this.formgroup, 'FilterTsstatusComponent: A formgroup megadása kötelező')
  }


  /** T7E */
  statusTextFieldName = this.t7e.statusTextFieldName
  t7eLabel = this.t7e.getTranslation('app-filter-tsstatus', 'label', 'innerText', null, null, 'Státusz')

}

export const filterTsstatusesForProdMgr: TimesheetStatusFilterItem[] = [
  { id: 1, name: 'Waiting for Approval', nameHun: 'Jóváhagyásra vár' },
  { id: 2, name: 'Approved', nameHun: 'Jóváhagyva' },
  { id: 3, name: 'Disabled', nameHun: 'Letiltva' },
]

export type TimesheetStatusFilterItem = { id: number | null, name: string, nameHun: string }
