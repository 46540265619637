<svg [attr.width]="width" [attr.height]="height" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M8.09 3.855C8.16 3.855 8.21 3.855 8.26 3.855C9.17 3.855 10.09 3.855 11 3.855C11.49 3.855 11.87 4.155 11.97 4.625C11.99 4.695 11.99 4.775 11.99 4.845C11.99 7.065 11.99 9.285 11.99 11.505C11.99 11.995 11.69 12.365 11.23 12.465C11.13 12.485 11.03 12.485 10.92 12.485C9.89 12.485 8.87 12.485 7.84 12.485C6.21 12.485 4.59 12.485 2.96 12.485C2.31 12.485 1.67 12.485 1.02 12.485C0.41 12.485 0 12.085 0 11.455C0 8.155 0 4.855 0 1.545C0 0.914999 0.41 0.514999 1.04 0.514999C3.04 0.514999 5.05 0.514999 7.05 0.514999C7.69 0.514999 8.09 0.924999 8.09 1.555C8.09 2.275 8.09 2.985 8.09 3.705V3.855ZM5.86 11.645H7.26V11.505C7.26 8.205 7.26 4.905 7.26 1.605C7.26 1.375 7.24 1.355 7.01 1.355C5.04 1.355 3.07 1.355 1.1 1.355C0.87 1.355 0.85 1.375 0.85 1.605C0.85 3.445 0.85 5.275 0.85 7.115C0.85 8.555 0.85 10.005 0.85 11.445C0.85 11.625 0.88 11.655 1.06 11.655C1.32 11.655 1.58 11.655 1.84 11.655C1.97 11.655 2.1 11.655 2.24 11.655C2.24 11.595 2.24 11.545 2.24 11.505C2.24 10.515 2.24 9.515 2.24 8.525C2.24 8.055 2.53 7.765 3 7.765C3.7 7.765 4.39 7.765 5.09 7.765C5.59 7.765 5.87 8.045 5.87 8.545C5.87 9.525 5.87 10.505 5.87 11.495C5.87 11.545 5.87 11.595 5.87 11.655L5.86 11.645ZM8.1 11.655H8.23C9.13 11.655 10.03 11.655 10.94 11.655C11.14 11.655 11.17 11.635 11.17 11.435C11.17 9.265 11.17 7.095 11.17 4.915C11.17 4.725 11.14 4.695 10.95 4.695C10.04 4.695 9.14 4.695 8.23 4.695C8.19 4.695 8.15 4.695 8.11 4.695V11.645L8.1 11.655ZM5.02 11.645V8.605H3.08V11.645H5.02Z"
        [attr.fill]="color" />
    <path
        d="M2.7902 2.325C2.7902 2.595 2.7902 2.845 2.7902 3.105C2.7902 3.125 2.7502 3.155 2.7202 3.155C2.4902 3.155 2.2602 3.155 2.0302 3.155C1.9702 3.155 1.9502 3.125 1.9502 3.065C1.9502 2.845 1.9502 2.625 1.9502 2.405C1.9502 2.375 1.9802 2.325 2.0002 2.325C2.2602 2.325 2.5102 2.325 2.7802 2.325H2.7902Z"
        [attr.fill]="color" />
    <path d="M3.62988 3.15501V2.33501H4.44988V3.15501H3.62988Z" [attr.fill]="color" />
    <path
        d="M6.1398 2.325C6.1398 2.595 6.1398 2.845 6.1398 3.095C6.1398 3.115 6.0998 3.165 6.0698 3.165C5.8398 3.165 5.5998 3.165 5.3698 3.165C5.3498 3.165 5.2998 3.115 5.2998 3.095C5.2998 2.865 5.2998 2.635 5.2998 2.405C5.2998 2.375 5.3398 2.335 5.3598 2.335C5.6098 2.335 5.8598 2.335 6.1298 2.335L6.1398 2.325Z"
        [attr.fill]="color" />
    <path
        d="M3.62988 4.275H4.46988C4.46988 4.535 4.46988 4.795 4.46988 5.045C4.46988 5.065 4.41988 5.095 4.39988 5.095C4.16988 5.095 3.93988 5.095 3.70988 5.095C3.68988 5.095 3.63988 5.055 3.63988 5.025C3.63988 4.775 3.63988 4.525 3.63988 4.265L3.62988 4.275Z"
        [attr.fill]="color" />
    <path
        d="M4.46012 7.05496C4.19012 7.05496 3.95012 7.05496 3.70012 7.05496C3.67012 7.05496 3.62012 7.00496 3.62012 6.98496C3.62012 6.75496 3.62012 6.52496 3.62012 6.29496C3.62012 6.26496 3.67012 6.22496 3.70012 6.22496C3.93012 6.22496 4.15012 6.22496 4.38012 6.22496C4.41012 6.22496 4.45012 6.26496 4.45012 6.28496C4.45012 6.53496 4.45012 6.78496 4.45012 7.05496H4.46012Z"
        [attr.fill]="color" />
    <path
        d="M2.78988 4.69501C2.78988 4.80501 2.78988 4.90501 2.78988 5.01501C2.78988 5.08501 2.76988 5.11501 2.69988 5.11501C2.47988 5.11501 2.25988 5.11501 2.04988 5.11501C1.97988 5.11501 1.94988 5.08501 1.95988 5.01501C1.95988 4.80501 1.95988 4.58501 1.95988 4.37501C1.95988 4.30501 1.97988 4.27501 2.05988 4.27501C2.27988 4.27501 2.49988 4.27501 2.70988 4.27501C2.78988 4.27501 2.80988 4.30501 2.79988 4.37501C2.79988 4.48501 2.79988 4.58501 2.79988 4.69501H2.78988Z"
        [attr.fill]="color" />
    <path
        d="M6.14055 4.27501C6.14055 4.54501 6.14055 4.79501 6.14055 5.03501C6.14055 5.05501 6.10055 5.10501 6.07055 5.10501C5.84055 5.10501 5.61055 5.10501 5.38055 5.10501C5.35055 5.10501 5.31055 5.05501 5.31055 5.03501C5.31055 4.80501 5.31055 4.58501 5.31055 4.35501C5.31055 4.32501 5.36055 4.27501 5.38055 4.27501C5.63055 4.27501 5.88055 4.27501 6.14055 4.27501Z"
        [attr.fill]="color" />
    <path
        d="M2.3802 7.05495C2.2702 7.05495 2.1702 7.05495 2.0602 7.05495C1.9902 7.05495 1.9502 7.03495 1.9502 6.95495C1.9502 6.74495 1.9502 6.52495 1.9502 6.31495C1.9502 6.24495 1.9802 6.21495 2.0502 6.21495C2.2702 6.21495 2.4802 6.21495 2.7002 6.21495C2.7702 6.21495 2.7902 6.23495 2.7902 6.30495C2.7902 6.52495 2.7902 6.74495 2.7902 6.96495C2.7902 6.99495 2.7402 7.04495 2.7102 7.04495C2.6002 7.04495 2.4902 7.04495 2.3802 7.04495V7.05495Z"
        [attr.fill]="color" />
    <path
        d="M6.1398 6.63495C6.1398 6.74495 6.1398 6.84495 6.1398 6.95495C6.1398 7.02495 6.1198 7.05495 6.0498 7.05495C5.8298 7.05495 5.6198 7.05495 5.3998 7.05495C5.3298 7.05495 5.2998 7.02495 5.2998 6.95495C5.2998 6.74495 5.2998 6.52495 5.2998 6.31495C5.2998 6.24495 5.3198 6.21495 5.3998 6.21495C5.6198 6.21495 5.8298 6.21495 6.0498 6.21495C6.1198 6.21495 6.1498 6.24495 6.1398 6.31495C6.1398 6.42495 6.1398 6.52495 6.1398 6.63495Z"
        [attr.fill]="color" />
    <path
        d="M9.20996 9.705C9.47996 9.705 9.72996 9.705 9.98996 9.705C10.01 9.705 10.04 9.755 10.05 9.775C10.05 10.005 10.05 10.235 10.05 10.465C10.05 10.495 9.99996 10.535 9.96996 10.535C9.73996 10.535 9.51996 10.535 9.28996 10.535C9.25996 10.535 9.20996 10.495 9.20996 10.475C9.20996 10.225 9.20996 9.975 9.20996 9.705Z"
        [attr.fill]="color" />
    <path
        d="M9.20996 6.63498C9.20996 6.36498 9.20996 6.11498 9.20996 5.85498C9.20996 5.83498 9.24996 5.80498 9.26996 5.80498C9.49996 5.80498 9.73996 5.80498 9.96996 5.80498C9.99996 5.80498 10.04 5.85498 10.04 5.88498C10.04 6.11498 10.04 6.33498 10.04 6.56498C10.04 6.59498 9.99996 6.63498 9.97996 6.63498C9.72996 6.63498 9.47996 6.63498 9.20996 6.63498Z"
        [attr.fill]="color" />
    <path
        d="M9.20996 8.17499C9.20996 8.06499 9.20996 7.95499 9.20996 7.84499C9.20996 7.78499 9.22996 7.75499 9.29996 7.75499C9.51996 7.75499 9.73996 7.75499 9.95996 7.75499C10.03 7.75499 10.05 7.77499 10.05 7.84499C10.05 8.06499 10.05 8.28499 10.05 8.50499C10.05 8.53499 9.99996 8.58499 9.96996 8.58499C9.74996 8.58499 9.51996 8.58499 9.29996 8.58499C9.23996 8.58499 9.20996 8.56499 9.21996 8.50499C9.21996 8.39499 9.21996 8.28499 9.21996 8.17499H9.20996Z"
        [attr.fill]="color" />
</svg>