import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-editable-value',
  templateUrl: './editable-value.component.html',
  styleUrls: ['./editable-value.component.scss']
})
export class EditableValueComponent implements OnInit, OnDestroy {
  @Input() field?: EditableFieldOptions
  @Input() suffix?: string
  @Input() readWidth?: string
  @Input() editWidth?: string

  @Output() onSave = new EventEmitter<FieldValue>()
  // setter szükséges, mert ngIf-en belül van az #inputTag. Nincs renderelve kattintáskor
  @ViewChild('inputTag') set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.inputTag = content;
    }
  }
  inputTag?: ElementRef;
  
  isEditing: boolean = false
  showMenu: boolean = false
  saveStatus$ = new BehaviorSubject<SaveStatus>('pristine')
  form: FormGroup
  get inp(): FormControl { return this.form.get('inp') as FormControl }
  get formValue(): FieldValue { return this.form.get('inp')?.value }

  origDate?: Moment

  constructor(
    private appSvc: AppService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group < { inp: FieldValue }>({
      inp: '',
    })

  }

  trackByIndex = this.appSvc.trackByIndex // enélkül nem szerkeszthető a formarray

  ngOnInit(): void {
    console.assert(this.field, 'EditableValueComponent: field mező kötelező')
    if (!this.field) return
    this.field?.value$?.subscribe(value => {
      if (this.field?.type === 'time') this.inp.setValue(moment(value)?.format('HH:mm'))
      else if (this.field?.type === 'otrates' && value) this.inp.setValue((value as number[]).join(' / '))
      else this.inp.setValue(value)
      if (this.field?.type === 'date') this.origDate = moment(value)
      if (this.field?.type === 'time') this.origDate = moment(value)
      //console.log('Orig value to edit' ,value);
    })

    this.field?.isError.subscribe(msg => {
      if (!msg) return
      this.saveStatus$.next('save-error')
    })
    this.field?.isSuccess.subscribe(isSuccess => {
      if (isSuccess) {
      this.saveStatus$.next('save-success')
        this.isEditing = false
        this.showMenu = false
      } 
    })

    this.field.valueTitle = this.field.valueTitle || ''

    if (!this.field?.popup) this.field.popup = {}
    if (this.field?.popup?.showHelp !== false) this.field.popup.showHelp = true
    if (this.field?.popup?.showHelp) this.field.popup.showHelp = true
  }

  ngOnDestroy(): void {
  //   return
  //   this.unsubscribeAll()
  //   this.field?.value$?.complete()
  //   this.field?.calcValue$?.complete()
  //   this.field?.isError.complete()
  //   this.field?.isSuccess.complete()
  // }

  // unsubscribeAll(): void {
  //   this.field?.value$?.unsubscribe()
  //   this.field?.calcValue$?.unsubscribe()
  //   this.field?.isError.unsubscribe()
  //   this.field?.isSuccess.unsubscribe()
  }

  editClicked(e: Event): void {
    if (this.field?.disabled) {
      if (this.field?.popup?.showHelp || this.field?.popup?.showHistory) {
        this.showMenu = true
      }
      return
    }
    this.showMenu = true
    this.isEditing = !this.isEditing
    setTimeout(() => {
    const elInput = <HTMLInputElement>this.inputTag?.nativeElement
    if (elInput){
        elInput.focus()
        elInput.select()
      }
    }, 0);
  }

  save(): void {
    this.saveStatus$.next('saving')
    console.log('Saving value', this.getReturnValue(this.formValue));
    this.onSave.emit(this.getReturnValue(this.formValue))
  }
  
  cancel(): void {
    this.isEditing = false
    this.showMenu = false
  }

  getReturnValue(value: FieldValue): FieldValue {
    switch (this.field?.type) {
      case 'date':
        const newDate = moment(value)?.format('YYYY-MM-DD')
          + 'T'
          + this.origDate?.format('HH:mm')
          + 'Z';
        return newDate
      case 'time':
        const newDatetime = this.origDate?.format('YYYY-MM-DD')
          + 'T'
          + value
          + 'Z';
        return newDatetime
      default:
        return value
    }
  }

  getViewClass(): string[] {
    const retVal: string[] = []
    if (this.saveStatus$.value === 'save-success') retVal.push('saved')
    const val = this.field?.value$?.value
    const calcVal = this.field?.calcValue$?.value
    if (val != null) retVal.push('has-value')
    if (val != calcVal) retVal.push('override')
    if (calcVal != null) retVal.push('has-calc')
    switch (this.field?.type) {
      case 'date':
        retVal.push('editable-field-date')
        break;
      case 'time':
        retVal.push('editable-field-time')
        break;
      case 'number':
        retVal.push('editable-field-number')
        break;
      case 'text':
        retVal.push('editable-field-text')
        break;
      case 'otrates':
        retVal.push('editable-field-otrates')
        break;
      default:
    }
    return retVal
  }

  getEditorClass(): string[] {
    const retVal: string[] = []
    if (this.saveStatus$.value === 'save-success') retVal.push('saving')
    const val = this.field?.value$?.value
    const calcVal = this.field?.calcValue$?.value
    if (val != null) retVal.push('has-value')
    if (val != calcVal) retVal.push('override')
    if (calcVal != null) retVal.push('has-calc')
    return retVal
  }

  getValueClass(value: FieldValue): string[] {
    if (!value) return ['zero']
    else return []
  }
}

export type EditableFieldOptions = {
  label?: string | null
  value$?: BehaviorSubject<FieldValue>
  calcValue$?: BehaviorSubject<FieldValue>
  valueTitle?: string | null
  disabled?: boolean
  type: EditableFieldType
  numberOptions?: {
    min?: number
    max?: number
    step?: number
  }
  dateOptions?: {
    min?: Date
    max?: Date
  }
  hint?: string
  isError: BehaviorSubject<string | null>
  isSuccess: BehaviorSubject<boolean | null>
  popup?: {
    showHelp?: boolean
    showHistory?: boolean
  }
}

export type EditableFieldType = 'text' | 'number' | 'date' | 'time' | 'otrates'

export type FieldValue = string | number | Date | null | number[] | string[] | {}

export type SaveStatus = 'pristine' | 'saving' | 'save-success' | 'save-error'
