import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, delay, firstValueFrom, interval, map, of, take, takeUntil } from 'rxjs';
import { RadioButtonKeyValue } from '../../util/radio-button/radio-button.component';
import { DeptService } from '../../dept/dept.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HandleUsersParameters } from '../../models/handle-users-parameters';
import { FOREIGN_CREW_COUNTRY_CODE, HUN_CREW_COUNTRY_CODE, UserService } from '../../user.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { HandleContractsParams } from '../../models/handle-contracts-params';
import { ContractService } from '../../contract/contract.service';
import { T7eService } from '../../t7e/t7e.service';
import { config } from '../../config';

@Component({
  selector: 'app-basic-user-data',
  templateUrl: './basic-user-data.component.html',
  styleUrls: ['./basic-user-data.component.scss']
})
export class BasicUserDataComponent implements OnInit {
  @Output() savedOk = new EventEmitter<void>()
  crewOptions$ = new BehaviorSubject<RadioButtonKeyValue[]>([
    { key: '1', value: 'Magyar stáb' },
    { key: '2', value: 'Foreign Crew or Cast' },
  ])
  selectedCrewOption$ = new BehaviorSubject<RadioButtonKeyValue | null>(null)
  private readonly _destroy$ = new Subject<void>();

  // FIRST: the number of contracts the user has. getFG needs this nr
  readonly numContracts = this.contractSvc.userContracts$.value?.length
  readonly HAS_FOREIGN_CREW = config.HAS_FOREIGN_CREW
  
  // SECOND: the form creation
  readonly form: FormGroup = this.getFG()
  readonly saving$: Observable<boolean>

  constructor(
    private fb: FormBuilder,
    private deptSvc: DeptService,
    private userSvc: UserService,
    private contractSvc: ContractService,
    private notifSvc: NotificationService,
    private t7e: T7eService,
  ) {
    this.saving$ = combineLatest([userSvc.savingUser$, contractSvc.savingContract$])
      .pipe(
        takeUntil(this._destroy$),
        map(([savingUser, savingContract]) => savingUser || savingContract))
    
    this.saving$
      .pipe(takeUntil(this._destroy$))
      .subscribe(isSaving => {
        if (isSaving) {
          this.form.disable()
        } else {
          this.form.enable()
          this.form.get('email')?.disable()
        }
      })
    
    this.userSvc.loggedinUser$.subscribe(user => {
      if (user) {
        this.form.patchValue({
          usname: user.usnameOrig,
          famname: user.famname,
          tel: user.phonenumber,
          email: user.email,
        })
      }
    })
  }

  readonly departments$ = this.deptSvc.departments$
    .pipe(
      takeUntil(this._destroy$),
      map(x => x?.sort((a, b) => a.depname!.localeCompare(b.depname!)))
    )

  get showContracts() { return !this.numContracts }
  get email() { return this.userSvc.loggedinUser$.value?.email }
  get usname() { return this.userSvc.loggedinUser$.value?.usnameOrig }
  get famname() { return this.userSvc.loggedinUser$.value?.famname }
  get tel() { return this.userSvc.loggedinUser$.value?.phonenumber }
  get crewCountry() {
    const cc = this.userSvc.loggedinUser$.value?.details?.find(d => d.propcode === 'crew_country')?.propvalue as string | null
    const co = this.crewOptions$.value
    return cc === HUN_CREW_COUNTRY_CODE ? co.find(x => x.key == '1') : cc === FOREIGN_CREW_COUNTRY_CODE ? co.find(x => x.key == '2') : null
  }
  get showUserData() {
    return !this.usname || !this.famname || !this.tel
  }

  ngOnInit(): void {
    this.selectedCrewOption$
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: (selected) => {
        console.log('selected crew type', selected)
        this.form.patchValue({ crewType: selected?.key })
      }
    })
    
    if (!this.crewCountry) {
      const crewValues = this.HAS_FOREIGN_CREW
      ? [1, 2, 1, 2, 1, 2, 1, null,]
      : [1]
      const crewValues$ = interval(300)
        .pipe(
          takeUntil(this._destroy$),
          take(crewValues.length), map(i => crewValues[i]))
      const s = crewValues$.subscribe(selectedKey => {
        this.selectedCrewOption$.next(this.crewOptions$.value.find(x => x.key == selectedKey?.toString()) || null)
        })
    }

  }

  async saveClicked(): Promise<void> {
    if (this.form.valid) {
      try {
        await this.save()
      } catch (error) {
        console.error(error)
        this.notifSvc.error('Hiba a felhasználói adatok mentése közben')
        return
      }
      this.savedOk.emit()
    } else {
      this.validateAllFormFields(this.form)
    }
  }

  async save(): Promise<void> {
    if (this.showUserData) {
      console.log('saving basic user data')
      const crewCountryCode = this.form.value.crewType == 1 ? HUN_CREW_COUNTRY_CODE : FOREIGN_CREW_COUNTRY_CODE
      this.userSvc.crewCountry$.next(crewCountryCode)
      const userParams: HandleUsersParameters = {
        _usid: this.userSvc.loggedinUserId,
        _usname: this.form.value.usname,
        _famname: this.form.value.famname,
        _phonenumber: this.form.value.tel,
        _data: JSON.stringify({ crew_country: crewCountryCode }),
      }
      const savedUser = await firstValueFrom(this.userSvc.handleUser(userParams))
      console.log('saved user', savedUser)
    }

    if (!this.showContracts) {
      return
    }
    const contractParams: HandleContractsParams = {
      _conid: null,
      _usid: this.userSvc.loggedinUserId,
      _depid: this.form.value.department.depid,
      _role: this.form.value.position,
      _data: this.form.value.crewType == 1 ? undefined : JSON.stringify({ contract_type: this.contractSvc.FOREIGN_CONTRACT_TYPE_CODE }),
    }
    const savedContract = await firstValueFrom(this.contractSvc.handleContracts(contractParams))
    console.log('saved contract', savedContract)
  }

  private getFG(): FormGroup {
    const crewType = this.crewCountry || null
    this.selectedCrewOption$.next(crewType)
    return this.fb.group({
      department: ['', !this.showContracts ? undefined : [Validators.required]],
      position: ['', !this.showContracts ? undefined : [Validators.required]],
      crewType: [crewType?.key, !this.showContracts ? undefined : [Validators.required]],
      usname: [this.usname, !this.showUserData ? undefined : [Validators.required]],
      famname: [this.famname, !this.showUserData ? undefined : [Validators.required]],
      tel: [this.tel, !this.showUserData ? undefined : [Validators.required, Validators.pattern("^([+])[\\s0-9]{7,20}$")]],
      email: [this.email],
    })
  }

  get errors() {
    return Object.keys(this.form.controls).forEach(key => {
      const controlErrors = this.form.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /** T7E */
  t7eDeptLabel = this.t7e.getTranslation('app-edit-contract', 'dept', 'innerText', null)
  t7eDeptHint = this.t7e.getTranslation('app-edit-contract', 'dept-hint', 'innerText', null)
  t7eRoleLabel = this.t7e.getTranslation('app-edit-contract', 'role', 'innerText', null)
  t7eRoleHint = this.t7e.getTranslation('app-edit-contract', 'role-hint', 'innerText', null)
  t7eNameLabel = this.t7e.getTranslation('app-profile', 'ml_002', 'innerText', null)
  t7eFamNameLabel = this.t7e.getTranslation('app-profile', 'ml_002_2', 'innerText', null)
  t7eNameHint = this.t7e.getTranslation('app-profile', 'name-hint', 'innerText', null)
  t7eTelLabel = this.t7e.getTranslation('app-profile', 'ml_003', 'innerText', null)
  t7eTelHint = this.t7e.getTranslation('app-profile', 'tel-hint', 'innerText', null)
  t7eBtnSave = this.t7e.getTranslation('app-save-button', 'button-text', 'innerText', null)
  
  t7eTelPatternError = this.t7e.getTranslation('div', 'tel-pattern-error', 'innerText', null)
  t7eReqFieldError = this.t7e.getTranslation('app-edit-contract', 'required-field-error', 'innerText', null)
}
