import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../user.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  loggedinUser$ = this.userSvc.loggedinUser$
  
    constructor(
    private userSvc: UserService,
  ) { }

  ngOnInit(): void {
  }

}
