import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user.service';
import { first, map, tap } from 'rxjs/operators';
import { T7eService } from '../../t7e/t7e.service';

@Injectable({
  providedIn: 'root'
})
export class IsProductionTeamMemberGuard implements CanActivate, CanActivateChild {
  constructor(
    private userSvc: UserService,
    private router: Router,
    private t7e: T7eService,
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userSvc.loggedinUser$.pipe(
      first(user => !!user),
      map(user => this.userSvc.isMoreThanDeptHead || this.router.createUrlTree(['/', 'error', this.t7eError]))
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userSvc.loggedinUser$.pipe(
      first(user =>  !!user),
      map(user => {
        return this.userSvc.isMoreThanDeptHead || this.router.createUrlTree(['/', 'error', this.t7eError])})
    );
  }


  /** T7E */
  t7eError = this.t7e.getTranslation('guard', 'error', 'innerText', null, null, 'Magasabb szintű jogosultság szükséges')
}

