import { NgModule } from '@angular/core';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PopoverModule } from '@progress/kendo-angular-tooltip';

@NgModule({
    declarations: [],
    imports: [
        GridModule,
        ExcelModule,
        DropDownsModule,
        FloatingLabelModule, LabelModule,
        UploadsModule,
        InputsModule, 
        DialogsModule,
        DateInputsModule,
        PopoverModule,
    ],
    exports: [
        GridModule,
        ExcelModule,
        DropDownsModule,
        FloatingLabelModule, LabelModule,
        UploadsModule,
        InputsModule,
        DialogsModule,
        DateInputsModule,
        PopoverModule,
    ],
})
export class KendoModule { }