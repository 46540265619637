<app-page-warning class="not-all-contracts-clickable" *ngIf="!(isAllClickable$ | async) && !(loadContractError$ | async)"
    [title]="t7eNotAllClickableTitle" [description]="t7eNotAllClickableDesc" cssClass="warning">
</app-page-warning>

<div class="select-contract" *ngIf="(contracts$ | async) != null; else loadingContainer">
    <app-page-warning class="no-contract" *ngIf="!(contracts$ | async)?.length && !(loadContractError$ | async)" 
        [title]="t7eNoContractWarningTitle"
        [description]="t7eNoContractWarningDesc"
        cssClass="warning">
    </app-page-warning>

    <div class="contract" *ngFor="let contract of contracts$ | async | callback:selectedOnly" 
        (click)="selectContract(contract)" 
        [class.selected]="(activeContract$ | async)?.conid === contract['conid']"
        [class.small]="hideStatus"
        [class.not-clickable]="!isContractClickable(contract)"
        >

        <div class="contract-with-radio">
            <div class="ml-05" [title]="contract['conid']">{{contract['role']}}</div>
        </div>
        <div *ngIf="showName" class="ml-05">{{contract['usname']}}</div>
        <div class="ml-05">{{contract['f_companyname' || '&nbsp;']}}</div>
        <div *ngIf="!hideStatus">
            <app-status 
                [statusName]="contract | contractStatusName"
                [statusClass]="contract | contractStatusClass" >
            </app-status>
        </div>
    </div>
    <div class="contract add-contract" 
        [class.selected]="!(activeContract$ | async)?.conid"
        (click)="addContract()"
        *ngIf="selectable && showAddButton">
            <div>
                <mat-icon>add_circle_outline</mat-icon>
            </div>
            <div class="ml-05" t7e="new-role">Új szerepkör</div>
    </div>
</div>
<ng-template #loadingContainer>
    <span t7e="loading-contracts">A start formok töltődnek...</span>
</ng-template>