import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { T7eService } from '../../../t7e/t7e.service';

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss']
})
export class FilterDateComponent implements OnInit {
  @Input() formgroup!: FormGroup
  @Input() fc!: FormControl
  @Input() label: string = ''
  @Input() min: Date = new Date(2000, 0, 1)
  @Input() max: Date = new Date()

  constructor() { }

  ngOnInit(): void {
    console.assert(this.fc, 'DateRange komponense: A fromFC megadása kötelező')
    console.assert(this.formgroup, 'DateRange komponense: A formgroup megadása kötelező')
  }

}
