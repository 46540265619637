import { Directive, ElementRef, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { T7eService as T7eService } from './t7e.service';

@Directive({
  selector: '[t7e]'
})
export class T7eDirective {
  @Input() t7e: string | null = null

  private _el: HTMLLIElement
  private _componentName: string = ''
  private _htmlElemId: string = ''

  private T7E_DIRECTIVE_NAME = 't7e-'
  private DEFAULT_TEXT_FIELD = 'innerText'

  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private t7eSvc: T7eService,
    private renderer: Renderer2,
    private _view: ViewContainerRef,
  ) {
    this._el = el.nativeElement
    const lcont = (<any>this._view)._lContainer
    // Lehet, hogy ezt le kell cserélni, mert elég esetleges
    try {
      this._componentName =
           (lcont[0] && lcont[0][3] && lcont[0][3][0] && lcont[0][3][0].localName)
      || (lcont[3] && lcont[3][0] && lcont[3][0].localName)
      || (lcont[3] && lcont[3][3] && lcont[3][3][0] && lcont[3][3][0].localName)
    } catch (error) {
      this._componentName = lcont[0] && lcont[0][8] && lcont[0][8].localName
    }
  }

  ngOnInit() {
    if (!this._componentName) {
      console.error('Ismeretlen component. Nem tudok rá fordítást keresni.')
      console.log('route:', this.route)
      console.log('HTML element:', this._el)
      console.log('t7e directive:', this.t7e)
      return
    }

    this.startTranslate()

  }

  private startTranslate(): void {
    this._htmlElemId = this._el.id || this.t7e || this._el.tagName
    const attrTranslations = this.setAttribValues()
    const origInnerText = this._el.innerText;
    //console.info(`Az attributumokat fordítom: ${this._componentName} > ${this._htmlElemId} > `, attrTranslations)
    const innerText = this.t7eSvc.getTranslation(this._componentName, this._htmlElemId, this.DEFAULT_TEXT_FIELD, this.t7e, null, origInnerText)
    //console.info(`Az ${this.DEFAULT_TEXT_FIELD}-t fordítom: ${this._componentName} > ${this._htmlElemId} > `, attrTranslations)
    if (innerText) this.setElementText(innerText)
  }

  private setAttribValues() {
    const attrs = Object.fromEntries(
      Array.from(
        this._el.attributes)
        .filter((attr: any) => attr.name.startsWith(this.T7E_DIRECTIVE_NAME))
        .map((item: any) => [item.name, item.value]))
    const attrToTranslate = Object.keys(attrs)
    const translations = attrToTranslate
      .map(a => [
        a.substring(this.T7E_DIRECTIVE_NAME.length),
        this.t7eSvc.getTranslation(this._componentName,
          this._htmlElemId,
          a.substring(this.T7E_DIRECTIVE_NAME.length),
          attrs[a],
          null,
          this.getAttribValue(a)
        )
      ])
    translations.forEach(a => this.setAttribValue(a[0], a[1]))
    return translations
  }

  getAttribValue(attribName: string): string {
    return this._el.attributes[attribName as any].value
  }

  private setAttribValue(attribName: string, value: string): void {
    this.renderer.setAttribute(this._el, attribName, value)
  }

  private setElementText(s: string): void {
    this._el.innerText = s
  }
}
