// Ez alapján: https://github.com/armanozak/angular-dynamic-locale/tree/master
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { CldrIntlService } from '@progress/kendo-angular-intl';
import { Subscription, noop } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private locale: string = 'hu';

  get currentLocale(): string {
    return this.locale;
  }

  constructor(
    private router: Router,
    @Optional() @SkipSelf() otherInstance: LocaleService,
  ) {
    if (otherInstance) throw 'LocaleService should have only one instance.';
    //this.initLocale();
  }

  setLocale(localeId: string): void {
    if (!localeId) return;
    this.locale = localeId;
    void this.refreshApplicationLocaleId();
  }

  private initLocale(): void {
    let locale: string
    try {
      // Init with browser's locale
      locale = navigator.language;
      // Here, do any initialization necessary (fetching in LocalStorage, from a setting service, etc.)...
    } catch (e) {
      locale = 'hu';
    }
    this.setLocale(locale);
  }

  /**
   * Renavigate to current URL, forcing all directives to re-create.
   */
  private async refreshApplicationLocaleId(): Promise<void> {
    return
    const fnShouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigated = false;
    await this.router.navigateByUrl(this.router.url).catch(noop);
    this.router.routeReuseStrategy.shouldReuseRoute = fnShouldReuseRoute;
  }
}

@Injectable()
export class FirstDayIntlService extends CldrIntlService {
  public override firstDay(): number {
    return 1;
  }
}