import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, map } from 'rxjs';
import { ContractService } from '../../../contract/contract.service';
import { AppUser } from '../../../models/db-user';
import { UserService } from '../../../user.service';

@Component({
  selector: 'app-filter-user',
  templateUrl: './filter-user.component.html',
  styleUrls: ['./filter-user.component.scss']
})
export class FilterUserComponent implements OnInit {
  @Input() userFC?: FormControl
  @Input() formgroup?: FormGroup

  users$ = new BehaviorSubject<AppUser[]>([])

  constructor(
    private userSvc: UserService,
    private contractSvc: ContractService,
  ) { 
  }

  ngOnInit(): void {
    console.assert(this.userFC, 'User Filter komponens: @Input userFC kötelező')
    console.assert(this.formgroup, 'User Filter komponens: A formgroup megadása kötelező')

    this.userSvc.allUsers$.subscribe(data => this.users$.next(data || []))
  }

  handleUserFilter(value: string) {
    this.users$.next(this.userSvc.allUsers?.filter(u => (
      u.usname?.toLowerCase().includes(value.toLowerCase())
    )) || [])
  }

  /** T7E */


}
