<mat-accordion *ngIf="data">
    <mat-expansion-panel hideToggle *ngFor="let doc of data.arrDoclink; let i=index; ">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <ng-container *ngIf="!doc?.wtsStartDate; else showWtsStartDate">
                    <span t7e="version">Verzió</span>&nbsp;{{i+1}}
                </ng-container>
                <ng-template #showWtsStartDate>
                    {{ doc?.wtsStartDate | date:'MMM dd' }} - {{ doc?.wtsEndDate | date:'MMM dd' }}
                </ng-template>
            </mat-panel-title>
            <mat-panel-description *ngIf="!doc?.wtsStartDate">
                {{ doc?.ts | date:'MMM dd' }}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="doc?.fileid; else noFileid" [ngClass]="{'bg-success text-white px-3 my-1': isJustGenerated.includes(doc?.fileid!)}">
            <!-- {{ doc | json }} -->
            <div>
                <span t7e="gen-time">Készítés ideje</span>: 
                <span *ngIf="doc?.wtsStartDate">{{ doc?.ts | date:'MMM dd' }}</span>
                {{ doc?.ts | date:'HH:mm' }}
            </div>
            <div><span t7e="gen-by">Készítette</span>: <a [href]="'mailto:' + doc?.usemail">{{ getUserName(doc?.usemail)?.usname }}</a></div>
            <div *ngIf="doc?.log"><span t7e="gen-log">Napló</span>: {{ doc?.log }}</div>
            <div class="open-buttons mb-05 mt-05">
                <a mat-raised-button [href]="getDriveLink(doc?.fileid!, 'edit', docType)" title="Megnyitás szerkesztésre (ctrl + klikk = új fülön)"
                    class="doc-link" t7e="btn-edit" t7e-title>
                    Szerkesztés
                </a>
                <a mat-raised-button [href]="getDriveLink(doc?.fileid!, 'view', docType)" title="Megnyitás csak olvasásra (ctrl + klikk = új fülön)"
                    class="doc-link" t7e="btn-preview" t7e-title>
                    Előnézet
                </a>
                <a mat-raised-button [href]="getDriveLink(doc?.pdfid!, 'file', docType)" title="A kiküldött PDF megnyitása (ctrl + klikk = új fülön)"
                    *ngIf="doc?.pdfid" class="doc-link" t7e="btn-preview">
                    Kiküldött PDF
                </a>
                <a mat-raised-button [href]="getDriveLink(doc?.folderid!, 'folder', docType)" title="A fájl mappájának megnyitása (ctrl + klikk = új fülön)"
                     *ngIf="doc?.folderid" class="doc-link" t7e="btn-folder">
                    Mappa
                </a>
            </div>
        </div>
        <ng-template #noFileid>
            <p t7e="unknown-file">Ismeretlen file</p>
        </ng-template>
        <p class="other-buttons">
            <button mat-raised-button type="button" (click)="sendOnlyPdf(doc, data)"
                title="Kiküldés PDF-ként" t7e-title>
                <mat-icon>send</mat-icon>
                <span t7e="btn-pdf">Kiküldés PDF-ként</span>
            </button>
            <button mat-raised-button type="button" (click)="removeFile(doc, data)" class="ml-1" color="warn">
                <mat-icon>delete</mat-icon>
            </button>
        </p>
        <div *ngIf="doc?.fileid && getIsRemovingDoc(doc?.fileid!)" class="bg-info text-white px-3 my-1 d-flex gap-05">
            <mat-spinner [diameter]="20"></mat-spinner>
            <div t7e="removing">A fájl törlése folyamatban...</div>
        </div>
        <p *ngIf="doc?.fileid && (removingDocError$[doc?.fileid!] | async)" class="bg-danger text-white px-3 my-1">
            {{ removingDocError$[doc?.fileid!] | async }}
        </p>
    </mat-expansion-panel>
</mat-accordion>