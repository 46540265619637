import { Injectable } from '@angular/core';
//import * as moment from 'moment';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DbCallError, ButtonStatus, AppService } from './app.service';
import { ContractService } from './contract/contract.service';
import { DataService, GenerateDocParams, PrintdocResponse } from './data.service';
import { ContractParsed, DocLink, ContractStatus } from './models/contract';
import { HandleContractsParams } from './models/handle-contracts-params';
import { HandleTimesheetsParams, TimesheetParsed, TimesheetStatus, TimesheetWeekly } from './models/timesheet';
import { TimesheetService } from './ts/timesheet.service';
import { UserService } from './user.service';
import { NotificationService } from './util/notifications/notification.service';
import { T7eService } from './t7e/t7e.service';
import { SQL_DATETIME_FORMAT, SQL_DATE_FORMAT, SQL_TIMEZONE } from './parser.service';

@Injectable({
  providedIn: 'root'
})
export class DocService {
  get debug() { return this.appSvc.debug }

  isContractGenerating: number[] = [] // azon a conid-k listája, aminek a generálása elkezdődött
  isRemovingDoc: string[] = [] // azon a fileid-k listája, aminek a Google Drive törlése elkezdődött
  generatingContractError$: { [conid: number]: BehaviorSubject<DbCallError> } = {} // azon a conid-k listája, aminek a generálása hibára futott
  isContractJustGenerated: string[] = [] // azon a conid-k listája, aminek a generálása most történt

  /** azon a ts-ek listája, aminek a generálása elkezdődött */
  isTSGenerating: number[] = [] 
  /** azon a tsid-k listája, aminek a generálása hibára futott  */
  generatingTSError$: { [tsid: number]: BehaviorSubject<DbCallError> } = {} 
  /** azon fileiid-k listája, aminek a generálása most történt */
  isTSJustGenerated: string[] = [] 

  removingDocError$: { [fileid: string]: BehaviorSubject<DbCallError> } = {} // azon a fileid-k listája, amineka Google Drive törlése hibára futott

  constructor(
    private dataSvc: DataService,
    private appSvc: AppService,
    private contractSvc: ContractService,
    private tsSvc: TimesheetService,
    private notifSvc: NotificationService,
    private userSvc: UserService,
    private t7e: T7eService,
  ) {
    this.getIsContractGenerating = this.getIsContractGenerating.bind(this)
    this.canGenerateContractPdf = this.canGenerateContractPdf.bind(this)
    this.generateContract = this.generateContract.bind(this)
    this.getIsRemovingDoc = this.getIsRemovingDoc.bind(this)
    this.removeContractFile = this.removeContractFile.bind(this)
    this.getIsTSGenerating = this.getIsTSGenerating.bind(this)
    this.generateTSDoc = this.generateTSDoc.bind(this)
    this.getErrAsTitle = this.getErrAsTitle.bind(this)
    this.canGenerateTSPdf = this.canGenerateTSPdf.bind(this)
    this.validateFormForContractGeneration = this.validateFormForContractGeneration.bind(this)
    this.validateFormForTSGeneration = this.validateFormForTSGeneration.bind(this)
    this.removeTSFile = this.removeTSFile.bind(this)
    this.getDriveLink = this.getDriveLink.bind(this)
    this.getErrAsTitle = this.getErrAsTitle.bind(this)
    
  }

  generateContract(
    dataItem: ContractParsed | undefined,
    sendEmail: boolean,
    isDraft: boolean,
    docId: string | null = null,
    sendPdf: boolean = false): Observable<PrintdocResponse | null> | undefined {
    const aErr: string[] = []
    if (!this.validateFormForContractGeneration(dataItem, aErr)) {
      this.notifSvc.addObservableNotif({ msg: aErr.join('<br>'), class: 'danger' })
      return
    }
    const sendTo = sendEmail ? null : false // null==a szerződés szerint, false==senkinek
    const hNotifGenerate = this.notifSvc.addObservableNotif({ msg: this.t7e.getTranslation("doc.service", "generateContract", "hNotifGenerate",'Generálás folyamatban. Ez eltarthat egy ideig...') })
    this.isContractGenerating.push(dataItem!.conid!)
    this.setGeneratingContractError(dataItem!.conid!, null)
    const params: GenerateDocParams = {
      conid: dataItem!.conid!,
      docId: docId || undefined,
    }
    const retVal = this.contractSvc.generateDoc('contract', [params], sendTo, isDraft, sendPdf)
    retVal.subscribe({
        next: response => {
          this.setFinishedContractGenerating(dataItem!.conid!)
          response?.data?.[0]?.docID && this.isContractJustGenerated.push(response?.data?.[0]?.docID)
          this.setGeneratingContractError(dataItem!.conid!, null)
          this.notifSvc.modifyNotif(hNotifGenerate, { msg: this.t7e.getTranslation("doc.service", "generateContract", "genReady", 'Generálás kész'), class: 'success', duration: 5000 })
          const folderId = response?.data?.[0]?.folderID
          folderId && this.contractSvc.saveFolderId(dataItem!.conid!, folderId)
        },
        error: err => {
          this.setFinishedContractGenerating(dataItem!.conid!)
          this.setGeneratingContractError(dataItem!.conid!, err?.statusText || this.t7e.getTranslation("doc.service", "generateContract", "unknownerror",'Ismeretlen hiba történt a szerződés generálása közben'))
          this.notifSvc.modifyNotif(hNotifGenerate, { msg: this.t7e.getTranslation("doc.service", "generateContract", "genError",'A generálás nem sikerült. Hiba történt'), class: 'danger' })
        }
      })
    return retVal
  }

  private validateFormForContractGeneration(dataItem: ContractParsed | undefined, aErr: string[]): boolean {
    if (!dataItem?.conid) {
      aErr.push(this.t7e.getTranslation("doc.service", "validateFormForContractGeneration", "undefined",'Ismeretlen szerződés vagy szerződés azonosító: undefined vagy null'))
      return false
    }
    if (!dataItem.f_contract_type) {
      aErr.push(this.t7e.getTranslation("doc.service", "validateFormForContractGeneration", "notemplate",'Nincs megadva, hogy szerződés sablont kell használni'))
      return false
    }
    return true
  }

  private setFinishedContractGenerating(conid: number | null): void {
    if (!conid) return
    this.isContractGenerating.splice(this.isContractGenerating.indexOf(conid), 1)
  }

  getIsContractGenerating(conid: number | null): boolean {
    if (!conid) return false
    return this.isContractGenerating.includes(conid)
  }

  private setGeneratingContractError(conid: number, value: DbCallError): void {
    if (!this.generatingContractError$[conid]) this.generatingContractError$[conid!] = new BehaviorSubject<DbCallError>(value)
    else this.generatingContractError$[conid].next(value)
  }

  private setFinishedRemovingDoc(fileid: string): void {
    this.isRemovingDoc.splice(this.isRemovingDoc.indexOf(fileid), 1)
  }

  getIsRemovingDoc(fileid: string): boolean {
    return this.isRemovingDoc.includes(fileid)
  }

  private setRemovingDocError(fileid: string, value: DbCallError): void {
    if (!this.removingDocError$[fileid]) this.removingDocError$[fileid!] = new BehaviorSubject<DbCallError>(value)
    else this.removingDocError$[fileid].next(value)
  }

  removeContractFile(doc: DocLink, dataItem: ContractParsed): Observable<DocLink | null> {
    if (!doc.fileid) {
      this.notifSvc.addObservableNotif({ msg: this.t7e.getTranslation("doc.service", "removeContractFile", "unabletoremove",'Nem lehet törölni: Ismeretlen fájl.'), class: 'warning', duration: 2000 })
      return of(null)
    }
    this.isRemovingDoc.push(doc.fileid)
    this.setRemovingDocError(doc.fileid, null)
    const retVal = this.dataSvc.removeDoc(doc.fileid)
    retVal.subscribe({
        next: _ => {
          const newDocLinks = this.removeDocFromDocLink(dataItem.arrDoclink, doc.fileid)
          const params: HandleContractsParams = { _conid: dataItem.conid, _doclink: JSON.stringify(newDocLinks) }
          this.contractSvc.handleContracts(params)
            .subscribe({
              next: _ => {
                this.setFinishedRemovingDoc(doc.fileid!)
                this.setRemovingDocError(doc.fileid!, null)
              },
              error: err => {
                this.setFinishedRemovingDoc(doc.fileid!)
                this.setRemovingDocError(doc.fileid!, this.t7e.getTranslation("doc.service", "removeContractFile", "setRemovingDocError",'A fájl eltávolítása sikerült, de az adatbázis nem sikerült frissíteni'))
                console.error('A fájl eltávolítása sikerült, de az adatbázis nem sikerült frissíteni: ' + doc?.fileid + ': ' + err);
              }
            })
        },
        error: err => {
          this.setFinishedRemovingDoc(doc.fileid!)
          this.setRemovingDocError(doc.fileid!, this.t7e.getTranslation("doc.service", "removeContractFile", "setRemovingDocErrorFile",'Nem sikerült eltávolítani a fájlt'))
          console.error('Nem lehetett eltávolítani a fájlt: ' + doc?.fileid + ': ' + err);
        }
    })
    return retVal
  }

  canGenerateContractPdf(dataItem?: ContractParsed): ButtonStatus {
    if (this.debug) return { disabled: false, title: this.t7e.getTranslation("doc.service", "canGenerateContractPdf", "debug",'DEBUG mód aktív!!!') }
    if (!dataItem?.conid) return {disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateContractPdf", "nullcontract",'Szerződés null vagy ismeretlen szerződés azonosító')}
    if (dataItem.constatus == ContractStatus.deleted) return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateContractPdf", "ContractStatus.deleted",'Már törölve lett') }
    if (this.isContractGenerating.includes(dataItem.conid!)) return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateContractPdf", "geninprogress",'A generálás folyamatban van') }
    try {
      if (dataItem.arrDoclink?.some(doc => this.isRemovingDoc.includes(doc?.fileid!))) {
        return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateContractPdf", "deleteinprogress",'A fájl törlés folyamatban van') }
      }
    } catch (_) { }

    if (dataItem.constatus == ContractStatus.prodMgr) {
      return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateContractPdf", "notapproved",'A gyártásvezető még nem hagyta jóvá') }
    }
    if (this.contractSvc.isFinanceApprovalNeededConfig && dataItem.constatus == ContractStatus.prodMgrApproved) {
      return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateContractPdf", "notfinanceapproved", 'A gyártásvezető még nem hagyta jóvá') }
    }
    const canGenerateStatuses = [
      this.contractSvc.isFinanceApprovalNeededConfig ? ContractStatus.financeApproved : ContractStatus.prodMgrApproved,
      ContractStatus.readyToSign,
      ContractStatus.signed]
    if (canGenerateStatuses.includes(dataItem.constatus!)) {
      const aErr: string[] = []
      const valid = this.validateFormForContractGeneration(dataItem, aErr)
      if (!valid) {
        return { disabled: true, title: this.getErrAsTitle(aErr) }
      }
      return { disabled: false, title: this.t7e.getTranslation("doc.service", "canGenerateContractPdf", "contracttogoogle",'Ezzel szerződést generálsz a Google Drive-ra') }
    } else {
      return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateContractPdf", "crewdidnotsend",'A stábtag még nem küldte be az adatait a produkciónak') }
    }
  }

  generateTSDoc(
    dataItem: TimesheetWeekly[],
    sendEmail: boolean,
    groupBy: GenInvRepGroupBy,
    isDraft?: boolean,
    docId?: string | null,
    savePdf?: boolean,
  ): Observable<PrintdocResponse | null> | undefined {
    const aErr: string[] = []
    if (!dataItem || !dataItem.length) {
      aErr.push(this.t7e.getTranslation("doc.service", "generateTSDoc", "notsselected",'Nincs kiválasztva egyetlen timesheet sem'))
      return
    }
    if (!dataItem[0].startDate || !dataItem[0].endDate) { 
      aErr.push(this.t7e.getTranslation("doc.service", "generateTSDoc", "missingdate",'Hiányzik a kezdő vagy a vége dátum'))
      return
    }
    if (!this.validateFormForTSGeneration(dataItem, aErr)) {
      const msg = aErr.join('')
      this.notifSvc.addObservableNotif({ msg, class: 'danger' })
      return
    }
    if (dataItem.some(TSs => TSs.isSent !== 'no') && !docId ) { // akkor csak akkor engedjük, ha nem csak pdf kiküldés van === van docId
      if (!confirm(this.t7e.getTranslation("doc.service", "generateTSDoc", "gentsagain",'Van olyan timesheet, amelyet már legeneráltál. Biztos, hogy újabb számlamellékletet generálsz?'))) {
        return
      }
    }
    let sendTo: false | null | string = sendEmail ? null : false // null==a szerződés szerint, false==senkinek
    if (savePdf !== true && savePdf !== false) savePdf = (sendTo === null || !!sendTo)
    // If there is invoice contact, send the email to that contact instead of the contract email
    const invoiceContact = this.getInvoiceContact(dataItem)
    if (invoiceContact && sendTo === null) sendTo = invoiceContact
    if (isDraft) sendTo = false
    const hNotifGenerate = this.notifSvc.addObservableNotif({ msg: this.t7e.getTranslation("doc.service", "generateTSDoc", "geninprogress",'Generálás folyamatban. Ez több percig is eltarthat...') })
    dataItem.forEach(TSs => {
      this.isTSGenerating.push(...TSs.tsids)
      this.setGeneratingTSError(TSs.tsids, null)
    })
    const params: GenerateDocParams[] = dataItem.map(x => ({
      conid: groupBy === 'role' ? x.conid! : undefined,
      usid: groupBy === 'user' ? x.usid! : undefined,
      startdate: moment(dataItem[0].startDate).tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      enddate: moment(dataItem[0].endDate).tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      docId: docId || undefined,
    }))
    const retVal = this.tsSvc.generateDoc('invoice', params, sendTo, !!isDraft, savePdf)
    retVal.subscribe({
        next: response => {
          dataItem.forEach(TSs => {
            this.setFinishedTSGenerating(TSs.tsids)
            this.setGeneratingTSError(TSs.tsids!, null)
          })
          response?.data?.forEach(responseData => {
            if (!responseData.docID) {
              this.notifSvc.modifyNotif(hNotifGenerate, { msg: this.t7e.getTranslation("doc.service", "generateTSDoc", "nodocid",'Nem kaptuk vissza a legenerált számla melléklet doc ID-ját'), class: 'danger' })
            } else {
              this.isTSJustGenerated.push(responseData.docID)
            }
            if (isDraft) {
              window.open(this.getDriveLink(responseData.docID, 'edit', 'weekly-ts'), '_blank')
            }
          })
          this.notifSvc.modifyNotif(hNotifGenerate, { msg: this.t7e.getTranslation("doc.service", "generateTSDoc", "generationok",'Generálás kész'), class: 'success', duration: 5000 })
        },
        error: err => {
          dataItem.forEach(TSs => {
            this.setFinishedTSGenerating(TSs.tsids)
            this.setGeneratingTSError(TSs.tsids, err?.statusText || this.t7e.getTranslation("doc.service", "generateTSDoc", "unknowngenerror",'Ismeretlen hiba történt a szerződés generálása közben'))
          })
          this.notifSvc.modifyNotif(hNotifGenerate, { msg: this.t7e.getTranslation("doc.service", "generateTSDoc", "unabletogen",'A generálás nem sikerült. Hiba történt'), class: 'danger' })
        }
    })
    return retVal
  }

  getInvoiceContact(dataItem: TimesheetWeekly[]): string | null{
    // get the list of contracts from dataItem[i].tsList[o].conid
    const contractIds: number[] = []
    for (var i = 0; i < dataItem.length; i++) {
      for (var o = 0; o < dataItem[i].tsList.length; o++) {
        if (!contractIds.includes(dataItem[i].tsList[o].conid!)) contractIds.push(dataItem[i].tsList[o].conid!)
      }
    }
    const contracts = contractIds.map(id => this.contractSvc.getContractById(id))
    // get the list of distinct invoice contacts from contracts
    const invoiceContacts = contracts
      ?.map(con => con?.f_invoice_contact)
      ?.filter(x => !!x)
      ?.filter((v, i, a) => a.indexOf(v) === i)
    if (!invoiceContacts?.length) return null
    if (invoiceContacts?.length > 1) throw new Error('Több különböző számlázási cím van a szerződések között')
    return invoiceContacts[0] || null
  }

  validateFormForTSGeneration(dataItem: TimesheetWeekly[], aErr: string[]): boolean {
    return dataItem.every(TSs => {
      if (TSs.isApproved !== 'yes') {
        if (this.t7e.lang === 'en') aErr.push(`Not all timesheets of ${TSs.usname} has been approved`)
        else aErr.push(`Nincs jóváhagyva ${TSs.usname} összes timesheetje`)
        return false
      }
      return true
    })
  }

  canGenerateTSPdf(dataItem?: TimesheetWeekly): ButtonStatus {
    if (this.debug) return { disabled: false, title: this.t7e.getTranslation("doc.service", "canGenerateTSPdf", "debug",'DEBUG mód aktív!!!') }
    if (!dataItem) return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateTSPdf", "notsselected",'Nincs kiválasztva egyetlen timesheet sem') }
    if (dataItem.groupBy == 'role') {
      if (!dataItem.conid) return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateTSPdf", "nullcontract",'Szerződés null vagy ismeretlen szerződés azonosító') }
    } else if(dataItem.groupBy == 'user') {
      if (!dataItem.usid) return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateTSPdf", "nullcrewmember",'Stábtag null vagy ismeretlen stábtag azonosító') }
    }
    if (dataItem.tsstatus == TimesheetStatus.deleted) return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateTSPdf", "deleted",'Már törölve lett') }
    if (this.getIsTSGenerating(dataItem.tsids)) return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateTSPdf", "genprogress",'A generálás folyamatban van') }
    if (dataItem.isCurrencyMismatch) return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateTSPdf", "isCurrencyMismatch", null, null, 'Egynél több pénznem nem szerepelhet egy számlán') }
    try {
      if (dataItem.arrDoclink?.some(doc => this.getIsRemovingDoc(doc?.fileid!))) {
        return { disabled: true, title: this.t7e.getTranslation("doc.service", "canGenerateTSPdf", "delprogress",'A fájl törlés folyamatban van') }
      }
    } catch (_) { }
    const aErr: string[] = []
    const valid = this.validateFormForTSGeneration([dataItem], aErr)
    if (!valid) {
      return { disabled: true, title: this.getErrAsTitle(aErr) }
    }
    return { disabled: false, title: this.t7e.getTranslation("doc.service", "canGenerateTSPdf", "contracttogoogle",'Ezzel WTS-t generálsz a Google Drive-ra') }
  }

  private setFinishedTSGenerating(aTs: number[]): void {
    aTs.forEach(tsid => {
      this.isTSGenerating.splice(this.isTSGenerating.indexOf(tsid), 1)
    })
  }

  getIsTSGenerating(aTsId: number[]): boolean {
    return aTsId.some(id => this.isTSGenerating.includes(id))
  }

  private setGeneratingTSError(aTsId: number[], value: DbCallError): void {
    aTsId.forEach(tsid => {
      if (!this.generatingTSError$[tsid]) this.generatingTSError$[tsid!] = new BehaviorSubject<DbCallError>(value)
      else this.generatingTSError$[tsid].next(value)
    })
  }

  getGeneratingTsError(aTsId: number[]): BehaviorSubject<DbCallError>[] {
    return aTsId.map(tsid => this.generatingTSError$[tsid])
  }

  removeTSFile(doc: DocLink, dataItem: TimesheetWeekly): void {
    if (!doc.fileid) {
      this.notifSvc.addObservableNotif({ msg: this.t7e.getTranslation("doc.service", "removeTSFile", "fileunknown",'Nem lehet törölni: Ismeretlen fájl.'), class: 'warning', duration: 2000 })
      return
    }
    this.isRemovingDoc.push(doc.fileid)
    this.setRemovingDocError(doc.fileid, null)
    this.dataSvc.removeDoc(doc.fileid)
      .subscribe({
        next: _ => {
          dataItem.tsList?.forEach(ts => {
            const newDocLinks = this.removeDocFromDocLink(ts.arrDoclink || null, doc.fileid)
            const params: HandleTimesheetsParams = { _tsid: ts.tsid, _doclink: JSON.stringify(newDocLinks) }
            this.tsSvc.handleTs(params)
              .subscribe({
                next: _ => {
                  this.setFinishedRemovingDoc(doc.fileid!)
                  this.setRemovingDocError(doc.fileid!, null)
                },
                error: err => {
                  this.setFinishedRemovingDoc(doc.fileid!)
                  this.setRemovingDocError(doc.fileid!, this.t7e.getTranslation("doc.service", "removeTSFile", "filedelok",'A fájl eltávolítása sikerült, de az adatbázis nem sikerült frissíteni'))
                  console.error('A fájl eltávolítása sikerült, de az adatbázis nem sikerült frissíteni: ' + doc?.fileid + ': ' + err);
                }
              })
          })
        },
        error: err => {
          this.setFinishedRemovingDoc(doc.fileid!)
          this.setRemovingDocError(doc.fileid!, this.t7e.getTranslation("doc.service", "removeTSFile", "filedelerror",'Nem sikerült eltávolítani a fájlt'))
          console.error('Nem lehetett eltávolítani a fájlt: ' + doc?.fileid + ': ' + err);
        }
      })
  }

  private removeDocFromDocLink(arrDoclink: DocLink[] | null, fileid: string | null): DocLink[] | null {
    const i = arrDoclink?.findIndex(c => c?.fileid === fileid)
    if (i === undefined || i < 0 || !arrDoclink) return null
    const newArr = [
      ...arrDoclink.slice(0, i),
      ...arrDoclink.slice(i + 1)
    ]
    return newArr
  }

  getDriveLink(fileid: string | null, linkType: 'view' | 'edit' | 'pdf' | 'file' | 'folder', docType: DocType = 'weekly-ts'): string {
    if (!fileid) return (this.t7e.getTranslation("doc.service", "getDriveLink", "unknownfile",'/error/Ismeretlen Google Drive fájl'))
    let linkEnding: string
    switch (linkType) {
      case 'edit':
        linkEnding = 'edit'
        break
      case 'view':
        linkEnding = 'preview'
        break
      case 'pdf':
        linkEnding = 'export?format=pdf&attachment=false'
        break
      case 'file':
        return `https://drive.google.com/file/d/${fileid}/view?usp=sharing`
      case 'folder':
        return `https://drive.google.com/drive/folders/${fileid}?usp=drive_link`
    }
    const docLinkType = docType === 'contract' ? 'document' : 'spreadsheets'
    return `https://docs.google.com/${docLinkType}/d/${fileid}/${linkEnding}`
  }

  private getErrAsTitle(err: string[] | null): string {
    if (!err?.length) return this.t7e.getTranslation("doc.service", "getDriveLink", "noerror",'Nincs hiba')
    return err.join('&#10;') // TODO  ez nem biztos, hogy működik
  }


}

export type GenInvRepGroupBy = 'user' | 'role'
export type DocType = 'contract' | 'weekly-ts';
