import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import * as moment from 'moment';
import { ListTimesheetsParams, Timesheet, TimesheetParsed, TimesheetStatus } from '../../models/timesheet';
import { TimesheetService } from '../timesheet.service';
import { BehaviorSubject, Subject, combineLatest, delay, map, takeUntil } from 'rxjs';
import { ProductionService } from '../../prod/production.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { UserService } from '../../user.service';
import { T7eService } from '../../t7e/t7e.service';
import { ContractService } from '../../contract/contract.service';
import { ContractParsed } from '../../models/contract';

@Component({
  selector: 'app-day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.scss']
})
export class DaySelectorComponent implements OnInit, OnDestroy {
  /**
   * Azt állítja, hogy csak az számít-e hogy van-e arra a napra timesheet. Ha false, akkor a TS státusza is számít
   */
  @Input() isYesNo: boolean = true
  @Input() isDefaultToday: boolean = true
  @Input() selectedDates$?: BehaviorSubject<Date[] | null>
  @Input() tsList$!: BehaviorSubject<TimesheetParsed[] | null>
  @Output() selectedDateChange = new EventEmitter<Date[]>()
  @Output() reload = new EventEmitter<void>()

  private _destroy = new Subject<void>()
  loading$ = this.tsSvc.tsListLoading$.pipe(delay(0))
  dayProps: { [date: number]: undefined | { class: string | null, title?: string } } = {}
  minDate: Date = new Date(new Date().getTime()- 1000 * 60 * 60 * 24)
  maxDate: Date = new Date()

  constructor(
    private tsSvc: TimesheetService,
    private prodSvc: ProductionService,
    private notifSvc: NotificationService,
    private contractSvc: ContractService,
    private userSvc: UserService,
    private t7e: T7eService,
  ) {
  }
  
  ngOnInit(): void {
    if (!this.tsList$) throw new Error('tsList$ is required')
    if (!this.selectedDates$) this.selectedDates$ = new BehaviorSubject<Date[] | null>(null)

    if (!this.selectedDates$.value && this.isDefaultToday) {
      this.selectedDates$.next([new Date()])
      this.selectedDateChange.emit(this.selectedDates$.value!)
    }

    combineLatest([this.tsList$, this.selectedDates$])
      .pipe(takeUntil(this._destroy))
      .subscribe(([tsList, selectedDates]) => {
        //const p1: number[] = [], p2: number[] = [], p3: number[] = []

        // Az alábbi módszer túl lassú, mert minden tsList elemre lefuttat még egy tsList.filter-t
        // tsList?.forEach(ts => {
        //   if (!ts.dStartDate) return
        //   const date = ts.dStartDate.getTime()
        //   const dateWithoutTime = new Date(ts.dStartDate.getFullYear(), ts.dStartDate.getMonth(), ts.dStartDate.getDate()).getTime()
        //   const isSelected = selectedDates?.some(d => moment(date).isSame(d, 'day'))
        //   const selectedClass = isSelected ? 'selected-day ' : ''

        //   let start = performance.now();
        //   const tsDays = this.tsList$.value?.filter(ts => moment(ts.dStartDate).isSame(date, 'day')) || []
        //   p1.push(performance.now() - start);

        //   if (this.isYesNo) {
        //     this.dayProps[dateWithoutTime] = tsDays?.length ? {  class: selectedClass + 'ts-exists', title: this.t7eTsExists } : { class: null, title: '' }
        //   } else {
        //     if (!tsDays?.length) this.dayProps[dateWithoutTime] =  { class: null, title: '' }
        //     const statusClass = 'calendar-' + this.tsSvc.getStatusClass(tsDays)
        //     this.dayProps[dateWithoutTime] = { class: selectedClass + 'ts-exists ' + statusClass, title: this.tsSvc.getStatusNames(tsDays) }
        //   }
        // })

        // Ez a módszer gyorsabb, mert csak egy tlList.sort fut le, utána egy tsList.forEach, utána pedig csak naponta egy tsDays.forEach
        //let start = performance.now();
        const tsListSorted = tsList ? [...tsList].sort((a, b) => a.dStartDate!.getTime() - b.dStartDate!.getTime()) : []
        let tsDays: Record<number, TimesheetParsed[]> = []
        tsListSorted.forEach((curr) => {
          if (!curr?.dStartDate) return 
          const dateWithoutTime = new Date(curr.dStartDate.getFullYear(), curr.dStartDate.getMonth(), curr.dStartDate.getDate()).getTime()
          tsDays[dateWithoutTime] = tsDays[dateWithoutTime] || []
          tsDays[dateWithoutTime].push(curr)
        })
        //p2.push(performance.now() - start);
        //start = performance.now();
        // Az eredeti cache-t ürítem (ts törlés után ne ragadjon be)
        const obj = this.dayProps
        Object.getOwnPropertyNames(obj).forEach(function (prop) {
            //@ts-ignore
            delete obj[prop];
          });
        Object.keys(tsDays).forEach(sDateTimestamp => {
          const dateWithoutTimeTS = parseInt(sDateTimestamp)
          const isSelected = selectedDates?.some(d => moment(dateWithoutTimeTS).isSame(d, 'day'))
          const selectedClass = isSelected ? 'selected-day ' : ''
          const statusClass = 'calendar-' + this.tsSvc.getStatusClass(tsDays[dateWithoutTimeTS])
          this.dayProps[dateWithoutTimeTS] = { class: selectedClass + 'ts-exists ' + statusClass, title: this.tsSvc.getStatusNames(tsDays[dateWithoutTimeTS]) }
        })
        //p3.push(performance.now() - start);

        //console.log('p1', p1.reduce((prev, curr) => prev + curr, 0), p1, 'p2', p2.reduce((prev, curr) => prev + curr, 0), p2, 'p3', p3.reduce((prev, curr) => prev + curr, 0)), p3
      })
    
    this.tsList$.pipe(takeUntil(this._destroy)).subscribe(tsList => { this.minDate = this.getMinDate(tsList)})

  }

  ngOnDestroy(): void {
    this._destroy.next()
  }

  getDayProps(date: Date): { class: string | null, title?: string } | null {
    const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
    return this.dayProps[dateWithoutTime] || null
  }

  reloadClicked(): void {
    this.reload.emit()
  }

  _selectedDateChange(e: any): void {
    this.selectedDateChange.emit(e)
    this.selectedDates$?.next(e)
  }

  private getMinDate(tsList: TimesheetParsed[] | null): Date {
    let contracts: ContractParsed[]
    if (!tsList?.length) {
      contracts = this.contractSvc.userContracts
    } else { 
      const userIds = tsList.map(ts => ts.usid) || [this.userSvc.loggedinUserId]
      console.assert(userIds.length, 'Ismeretlen user')
      contracts = userIds.flatMap(usid => this.contractSvc.getContractsByUser(usid || -1))
    }
    console.assert(contracts.length, 'Ismeretlen contract')
    const contractStartDates = contracts.reduce((prev, curr) => {
      const startDate = curr.dStartdate?.getTime() || Number.MAX_SAFE_INTEGER
      return startDate < prev ? startDate : prev
    }, Number.MAX_SAFE_INTEGER)
    const prodStartDate = this.prodSvc.prodDefaults$.value?.dPreparationstartday || new Date(2000, 0, 1)
    return new Date(Math.max(contractStartDates, prodStartDate.getTime()))
  }

  /** T7E */
  t7eTsExists = this.t7e.getTranslation('app-day-selector', 'status-exists', 'innerText', 'Timesheet exists for this day')
  
}
