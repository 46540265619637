<div class="wrapper">
    <!-- <button (click)="test()">TEST értesítés</button> -->
    <div *ngFor="let notif of (visibleNotifList$ | async); let index = index" class="notif" [ngClass]="notif.class">
            <div class="icon">
                <app-icon-success *ngIf="notif.class=='success'"></app-icon-success>
                <app-icon-warning *ngIf="notif.class=='warning'"></app-icon-warning>
                <app-icon-info *ngIf="notif.class=='info'"></app-icon-info>
                <app-icon-danger *ngIf="notif.class=='danger'"></app-icon-danger>
            </div>

            <div class="message">{{ notif?.msg }}</div>

            <div class="spacer"></div>

            <div *ngIf="notif.buttonText" class="action-button">
                <button mat-raised-button [color]="notif.buttonColor" 
                    (click)="notif?.onAction({index: index, metaData: notif.metaData})">{{ notif.buttonText }}</button>
            </div>

            <div class="close-btn">
                <app-close-button (click)="close(notif.notifHandler)"></app-close-button>
            </div>
    </div>
</div>