import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';


@NgModule({
    declarations: [],
    imports: [
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatSnackBarModule,
        MatChipsModule,
        MatDialogModule,
        MatMenuModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatTabsModule,
    ],
    exports: [
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatSnackBarModule,
        MatChipsModule,
        MatDialogModule,
        MatMenuModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatTabsModule,
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['YYYY-MM-DD'],
                },
                display: {
                    dateInput: 'YYYY-MM-DD ddd',
                    monthYearLabel: 'YYYY',
                    dateA11yLabel: 'L',
                    monthYearA11yLabel: 'YYYY-MMMM',
                },
            },
        },
    ],
})
export class MaterialModule { }