import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService, DbCallError } from '../../app.service';
import { FieldHistory } from '../../models/field-history';
import { T7eService } from '../../t7e/t7e.service';
import { FlexTables } from '../../data.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @Input() tableid?: FlexTables
  @Input() id?: number
  @Input() colname?: string
  @Input() propcode?: string
  @Input() title?: string | boolean

  history?: FieldHistory[] | null
  history$ = new Observable<FieldHistory[] | null>()
  loading$ = new BehaviorSubject<boolean>(true)
  loadError$ = new BehaviorSubject<DbCallError>(null)

  constructor(
    private appSvc: AppService,
    private t7e: T7eService,
  ) {

  }

  ngOnInit(): void {
    if (!this.tableid) {
      console.error('HistoryComponent: A tableid input kötelező');
      return
    }
    if (!this.id) {
      console.error('HistoryComponent: A id input kötelező');
      return
    }
    if (!this.colname && !this.propcode) {
      console.error('HistoryComponent: A colname vagy propcode input kötelező');
      return
    }

    if (this.title === true) this.title = this.t7ePageTitleHistory
    this.getHistory()
  }

  getHistory() {
    this.loading$.next(true)
    if (this.propcode) {
      this.history$ = this.appSvc.getFlexHistory(this.tableid!, this.id!, this.propcode)
    } else if (this.colname) {
      this.history$ = this.appSvc.getFixHistory(this.tableid!, this.id!, this.colname)
    } else {
      // nem kell semmit tenni, már írtam a konzolra
      return
    }

    this.history$.subscribe({
      next: hist => {
        this.history = hist
        this.loading$.next(false)
        this.loadError$.next(false)
      },
      error: err => {
        this.loading$.next(false)
        const msg = err.message || this.t7eErrorLoadingHistory
        this.loadError$.next(msg)
      }
    })
  }

  /** T7E */
  t7ePageTitleHistory = this.t7e.getTranslation('app-history', 'page-title', 'innerText', 'Előzmények')
  t7eErrorLoadingHistory = this.t7e.getTranslation('app-history', 'notif-msg', 'error-loading-history', 'Hiba az előzmények betöltése közben')
}
