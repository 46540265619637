import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, filter, forkJoin, map, of, takeUntil } from 'rxjs';
import { ContractParsed } from '../../models/contract';
import { ContractService } from '../contract.service';
import { AppService, DbCallError } from '../../app.service';
import { FormBuilder, FormControl, MaxValidator, MinValidator, Validators } from '@angular/forms';
import { ProductionService } from '../../prod/production.service';
import { NumericValidator } from '../../util/validators/validators';
import { HandleContractsParams } from '../../models/handle-contracts-params';
import { NotificationService } from '../../util/notifications/notification.service';
import { ParserService } from '../../parser.service';
import { T7eService } from '../../t7e/t7e.service';

@Component({
  selector: 'app-ot-ta-bulk-changer',
  templateUrl: './ot-ta-bulk-changer.component.html',
  styleUrls: ['./ot-ta-bulk-changer.component.scss']
})
export class OtTaBulkChangerComponent implements OnInit, OnDestroy {
  contractsIds$ = new BehaviorSubject<number[] | null>(null)
  saving$ = this.contractSvc.savingContract$

  @Input()
  get contractsIds() { return this.contractsIds$?.value }
  set contractsIds(value: number[] | null) {
    this.contractsIds$?.next(value)
  }

  _destroy = new Subject<void>()
  contracts$ = new BehaviorSubject<(ContractParsed | null)[] | null>(null)
  /** <conid, save állapot> */
  contractSaveStatus = new Map<number, 'init' | 'saved' | 'saving' | string>()
  currency: string | null = null
  isCurrencyMismatch$ = this.contracts$.pipe(
    map(contracts => {
      if (!contracts) return false
      this.currency = null as string | null
      for (let i = 0; i < contracts.length; i++) {
        if (this.currency === null) this.currency = contracts[i]?.f_currency || this.prodSvc.prodDefaults?.currency || 'HUF'
        if (this.currency !== contracts[i]?.f_currency) return true
      }
      return false
    })
  )

  otForm = this.fb.group({ ot: [null as string | null, [Validators.required]] })
  taForm = this.fb.group({ ta: [null as number | null, [Validators.required, NumericValidator]]})
  
  constructor(
    private contractSvc: ContractService,
    private appSvc: AppService,
    private prodSvc: ProductionService,
    private fb: FormBuilder,
    private notif: NotificationService,
    private parser: ParserService,
    private t7e:T7eService,
  ) {
    this.contracts$
      .pipe(
        takeUntil(this._destroy),
    )
      .subscribe(contracts => {
        contracts?.forEach(c => {
          if (c?.conid && !this.contractSaveStatus.has(c.conid)) {
            this.contractSaveStatus.set(c.conid, 'init')
          }
        })
    })
   }

  trackByIndex = this.appSvc.trackByIndex
  getCurrency = this.contractSvc.getCurrency

  ngOnInit(): void {
    if (!this.contractsIds$) throw 'contractsIds$ kötelező';
    combineLatest([this.contractSvc.allContracts$, this.contractsIds$])
      .pipe(
        takeUntil(this._destroy),
        filter(([allContracts, conids]) => !!conids),
        map(([allContracts, conids]) => conids!
          .map(conid => allContracts?.find(c => c.conid === conid) || null)
          .sort((a, b) => (a?.depname || '').localeCompare(b?.depname || ''))
        ),
    )
    .subscribe(this.contracts$)
  }

  ngOnDestroy(): void {
    this._destroy.next()
    this._destroy.complete()
    this.contractSvc.saveError$.next(null)
  }

  isDeptDifferentFromPrevious(contractIndex: number): boolean {
    return this.contracts$?.value?.[contractIndex]?.depid !== this.contracts$?.value?.[contractIndex - 1]?.depid
  }

  saveOt(): void {
    this.contractSaveStatus.forEach((value, key, map) => {
      this.contractSaveStatus.set(key, 'saving')
    })
    let errorNotifHandler: number | null = null
    if (!this.contracts$.value) {
      this.notif.addObservableNotif({ msg: 'Ismeretlen start formok. Nem menthető', class: 'danger' })
      return
    }
    const otRates = this.parser.textToDbArray(this.otForm.getRawValue().ot)
    this.contracts$.value.forEach(c => {
      if (!c?.conid) {
        console.error(c?.conid, 'Null conid. Ez lehetetlen')
        if (errorNotifHandler === null) errorNotifHandler = this.notif.addObservableNotif({ msg: 'Legalább 1 start form hibás, nem menthető', class: 'danger' })
        return of(null)
      }
      const conid = c.conid
      const params: HandleContractsParams = {
        _conid: conid,
        _data: JSON.stringify({ otrate: otRates })
      }
      return this.contractSvc.handleContracts(params)
        .pipe(
          takeUntil(this._destroy),
      )
        .subscribe({
          next: (result) => {
            if (!result) {
              this.notif.addObservableNotif({ msg: 'A mentésről nem érkezett eredmény a szerverről. Lehet, hogy nem sikerült a mentés' })
              return
            }
            console.assert(conid===result?.conid, 'A kérdés és a válasz conid-ja nem egyezik meg: ', conid, result?.conid)
            if (!result?.conid) return
            this.contractSaveStatus.set(conid, 'saved')
          },
          error: (error) => {
            this.contractSaveStatus.set(conid, this.parser.parseSqlErrMsg(error) || 'HIBA')
          }
        })
    })
  }

  saveTa(): void {
    this.contractSaveStatus.forEach((value, key, map) => {
      this.contractSaveStatus.set(key, 'saving')
    })
    let errorNotifHandler: number | null = null
    if (!this.contracts$.value) {
      this.notif.addObservableNotif({ msg: 'Ismeretlen start formok. Nem menthető', class: 'danger' })
      return
    }
    this.contracts$.value.forEach(c => {
      if (!c?.conid) {
        console.error(c?.conid, 'Null conid. Ez lehetetlen')
        if (errorNotifHandler === null) errorNotifHandler = this.notif.addObservableNotif({ msg: 'Legalább 1 start form hibás, nem menthető', class: 'danger' })
        return of(null)
      }
      const conid = c.conid
      const params: HandleContractsParams = {
        _conid: conid,
        _data: JSON.stringify({ tarate: this.taForm.getRawValue().ta })
      }
      return this.contractSvc.handleContracts(params)
        .pipe(
          takeUntil(this._destroy),
        )
        .subscribe({
          next: (result) => {
            if (!result) {
              this.notif.addObservableNotif({ msg: 'A mentésről nem érkezett eredmény a szerverről. Lehet, hogy nem sikerült a mentés' })
              return
            }
            console.assert(conid === result?.conid, 'A kérdés és a válasz conid-ja nem egyezik meg: ', conid, result?.conid)
            if (!result?.conid) return
            this.contractSaveStatus.set(conid, 'saved')
          },
          error: (error) => {
            this.contractSaveStatus.set(conid, this.parser.parseSqlErrMsg(error) || 'HIBA')
          }
        })
    })
  }

  getMinMaxErrorMsg(element: HTMLInputElement): string {
    const minMax = this.getFcMinMax(element)
    if (!minMax.min && minMax.min !== 0) return `Maximum: ${minMax.max}`
    if (!minMax.max && minMax.max !== 0) return `Minimum: ${minMax.min}`
    return `Minimum: ${minMax.min}, maximum: ${minMax.max}`
  }
  getFcMinMax(element: HTMLInputElement): { min: number, max: number } {
    return {
      min: parseFloat(element.min),
      max: parseFloat(element.max),
    }
  }

  t7eOtRatesHint = this.t7e.getTranslation('app-edit-contract', 'ot-rate-hint', 'innerText', null)
}

type otFormGroup = {
  ot: FormControl<number | null>
}

type taFormGroup = {
  ta: FormControl<number | null>
}