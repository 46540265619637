import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { AuthMockService } from './auth-mock.service';
import { BehaviorSubject, combineLatest, distinctUntilChanged, distinctUntilKeyChanged, filter, firstValueFrom, map, mergeMap, Observable, tap } from 'rxjs';
import { DataService, FlexTables } from './data.service';
import { DbGroup, AppUser, Groups } from './models/db-user';
import { HandleUsersParameters, UserPreferences } from './models/handle-users-parameters';
import { AppService, DbCallError, SESSION_STORAGE_IMPERSONATE } from './app.service';
import { NotificationService } from './util/notifications/notification.service';
import { DateAdapter } from '@angular/material/core';
import { CrewListItem } from './models/crew-list';
import { T7eLangs, T7eService } from './t7e/t7e.service';
import { ParserService } from './parser.service';
import { FlexTableProperty } from './models/table-property';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user$ = this.authSvc.user$.pipe(filter(u => !!u))
  public isAuthenticated$ = this.authSvc.isAuthenticated$
  private _auth0User: User | null = null
  get auth0User() { return this._auth0User }
  public auth0UserLoaded = false
  public pageFullyLoaded$ = new BehaviorSubject<boolean>(false)
  preferences$ = new BehaviorSubject<UserPreferences>({ lang: 'hu'})
  get pic() { return this._auth0User?.picture }
  crewCountry$ = new BehaviorSubject<CrewCountryCode  | null>(null)

  get hasRole() { return this.loggedinUser?.ugm?.length }
  get isEnabled() { return this.loggedinUser?.usstatus === null || this.loggedinUser?.usstatus == 1 }
   private _roles?: DbGroup[]
  get roles() { return this._roles || [] }
  private set roles(roles: DbGroup[]) { this._roles = roles }
  get isDev() { return this.isEnabled && !!this.loggedinUser?.ugm.find(g => g.ugid == Groups.DEV) }
  get isProdMgr() { return this.isEnabled && !!this.loggedinUser?.ugm.find(g => g.ugid == Groups.PRODMGR) }
  get isFinance() { return this.isEnabled && !!this.loggedinUser?.ugm.find(g => g.ugid == Groups.FINANCE) }
  get isProducer() { return this.isEnabled && !!this.loggedinUser?.ugm.find(g => g.ugid == Groups.PRODUCER) }
  get isCrew() { return this.isEnabled && !!this.loggedinUser?.ugm.find(g => g.ugid == Groups.CREW) }
  get isDeptAdmin() { return this.isEnabled && !!this.loggedinUser?.ugm.find(g => g.ugid == Groups.DEPT_ADMIN) }
  get isOnlyDeptAdmin() { return this.isDeptAdmin && !this.isDeptHead && !this.isProducer && !this.isFinance && !this.isProdMgr}
  get isDeptHead() { return this.isEnabled && !!this.loggedinUser?.ugm.find(g => g.ugid == Groups.DEPT_HEAD) }
  
  get isMoreThanCrew() {
    if (!this.isEnabled) return false
    return this.isDev || this.isProdMgr || this.isFinance || this.isProducer || this.isDeptAdmin || this.isDeptHead
  }
  get isMoreThanDeptHead() {
    if (!this.isEnabled) return false
    return this.isDev || this.isProdMgr || this.isFinance || this.isProducer
  }

  private _loggedinUserId: number | null = null
  get loggedinUserId() { return this._loggedinUserId }
  loggedinUser$ = new BehaviorSubject<AppUser | null>(null)
  get loggedinUser() { return this._allUsers?.find(u => u.usid == this._loggedinUserId) }
  saveError$ = new BehaviorSubject<DbCallError>(null)
  savingUser$ = new BehaviorSubject<boolean>(false)
  
  /** Dept Adminok választhatnak ki szerkesztésre usert */
  selectedUser$ = new BehaviorSubject<AppUser | null>(null)
  get selectedUser() { return this.selectedUser$.value }
  set selectedUser(user: AppUser | null) { this.selectedUser$.next(user) }
  selectUserById(usid?: number | null) {
    const user = this.getUserById(usid)
    if (!user) {
      console.error('Nincs ilyen felhasználó:', usid)
      return
    }
    this.selectedUser$.next(user)
  }

  get _allUsers() { return this.allUsers$.value }
  get allUsers() { return this._allUsers }
  readonly allUsers$ = new BehaviorSubject<AppUser[] | null>(null)
  private _allUsersLoading: boolean = true
  get allUsersLoading() { return this._allUsersLoading }
  readonly allUsersLoading$ = new BehaviorSubject<boolean>(true)

  isCrew$ = new BehaviorSubject<boolean>(false)
  hasRole$ = new BehaviorSubject<boolean>(false)
  
  crewList$ = new BehaviorSubject<CrewListItem[] | null>(null)
  crewListLoading$ = new BehaviorSubject<boolean>(false)
  crewListError$ = new BehaviorSubject<DbCallError>(null)

  isProfileComplete(user?: AppUser) {
    user = user || this.loggedinUser
    if (!user) return false
    if (!user.usname) return false
    if (!user.email) return false
    if (!user.phonenumber) return false
    
    return true
  }

  isLangSelected = false

  constructor(
    public authSvc: AuthService,
    private dataSvc: DataService,
    private notifSvc: NotificationService,
    private dateAdapter: DateAdapter<Date>,
    private parser: ParserService,
    private t7e: T7eService,
  ) {
    this.getUserById = this.getUserById.bind(this)
    this.getuUserByEmail = this.getuUserByEmail.bind(this)
    
    combineLatest([this.loggedinUser$, this.allUsers$])
      .pipe(
        map(([loggedUser, allUsers]) => { 
          return !!(loggedUser?.ugm?.find(g => g.ugid == Groups.CREW))
            && (loggedUser?.usstatus === null || loggedUser?.usstatus == 1)
      }
      ))
      .subscribe(this.isCrew$)

    combineLatest([this.loggedinUser$, this.allUsers$])
      .pipe(
        map(([loggedUser, allUsers]) => {
          return !!(loggedUser?.ugm?.filter(g => !!g).length && (loggedUser?.usstatus === null || loggedUser?.usstatus == 1))
        })
      )
      .subscribe(this.hasRole$)
    
    this.loggedinUser$.pipe(map(u => u?.details?.find(d => d.propcode === 'crew_country')?.propvalue as CrewCountryCode | null))
      .subscribe(this.crewCountry$)
      
    authSvc.error$.pipe(
      // TODO: A GenericError nem ismert, így nem lehet szűrni
      // filter((e) => e instanceof GenericError && e.error === 'login_required'),
      mergeMap(() => this.authSvc.loginWithRedirect())
    ).subscribe((error) => console.error(error));

    authSvc.user$
      .pipe(distinctUntilChanged())
      .subscribe(async user => {
        this.auth0UserLoaded = true
        this._auth0User = user || null
        if (!user) {
          this.initUser()
          return
        }
        const email = user.email
        if (!email) {
          console.error('Üres emailcím');
          console.error(user);
          return
        }

        dataSvc.getAppData()
      })

    dataSvc.initData$.subscribe(appData => {
      this.allUsersLoading$.next(false)
      this.roles = appData?.data?.check_user?.ugm || []
      const user = this.getuUserByEmail(sessionStorage.getItem(SESSION_STORAGE_IMPERSONATE)) || appData?.data?.check_user
      this.handleLoadedDbUser(user)
      this.allUsers$.next(parser.parseUsersEmptyToNull(appData?.data?.list_users || null))
    })
    dataSvc.initDataError$.subscribe(err => {

    })

    this.allUsersLoading$.subscribe(x => this._allUsersLoading = x)
    this.allUsers$.subscribe(x => {
      this._allUsers?.length && dataSvc.setCache(FlexTables.users, this._allUsers || [])
    })
    this.allUsers$.next(dataSvc.getCache(FlexTables.users))

    this.preferences$.subscribe(pref => {
      this.dateAdapter.setLocale(pref.lang);
      this.t7e.setLang(pref.lang as T7eLangs)
    })

  }

  handleLoadedDbUser(loggedUser?: AppUser | null): void {
    const user = this._auth0User
    if (!loggedUser) {
      //console.error('Üres dbUser');
      return
    }
    console.log('user:', loggedUser)
    loggedUser.usname = loggedUser?.usname || (user?.name === user?.email ? null : user?.name) || null
    this._loggedinUserId = loggedUser?.usid || null
    this.loggedinUser$.next(loggedUser)
    if (!this.selectedUser) this.selectedUser$.next(loggedUser)
    try {
      const preferences = JSON.parse(loggedUser?.preferences || '{}')
      this.isLangSelected = !!preferences.lang
      this.applyUserPreferences(preferences)
    } catch (error) {
      console.error('preferences parse error:', error)
    } finally {
      this.pageFullyLoaded$.next(true)
    }
  }

  private applyUserPreferences(preferences: UserPreferences): void {
    const p: UserPreferences = {
      ...this.preferences$.value,
    }
    if (preferences.lang && preferences.lang !== this.preferences$.value?.lang) {
      p.lang = preferences.lang
    }
    this.preferences$.next(p)
  }

  initUser(): void {
    this._loggedinUserId = null
    this.loggedinUser$.next(null)
  }

  login(): void {
    this.authSvc.loginWithRedirect();
  }

  logout(returnTo: string): void {
    this.authSvc.logout({
      returnTo: returnTo,
    });
  }

  setLoggedOnUserByEmail(email: string): void {
    const user = this.getuUserByEmail(email)
    if (!user) {
      console.error('Nincs ilyen felhasználó:', email)
      return
    }
    this.handleLoadedDbUser(user)
  }

  handleUser(user: HandleUsersParameters): Observable<AppUser> {
    this.savingUser$.next(true)
    const retVal = this.dataSvc.handleUsers(user)
    retVal.subscribe({
      next: (savedUser) => {
        this.savingUser$.next(false)
        if (!savedUser) { // törölt user
          savedUser = {
            ...this._allUsers?.find(u => u.usid == user._usid),
            usstatus: 0,
          } as AppUser
        }
        this.saveError$.next(false)
        if (savedUser.usid == this._loggedinUserId) {
          let loggedUser = this._allUsers?.find(u => u.usid == this._loggedinUserId)
          loggedUser = { ...loggedUser, ...savedUser }
          this.loggedinUser$.next(loggedUser!)
          this.allUsers$.next(AppService.replaceDocItems<AppUser>([loggedUser!], this._allUsers, 'usid'))
        } else {
          this.allUsers$.next(AppService.replaceDocItems<AppUser>([savedUser], this._allUsers, 'usid'))
        }
      },
      error: (err) => {
        this.savingUser$.next(false)
        this.saveError$.next(err)
      }
    })
    return retVal
  }

  listUsers(): Observable<AppUser[]> {
    this.allUsersLoading$.next(true)
    const retVal = this.dataSvc.listUsers({})
    retVal.subscribe({
      next: (data) => {
        // if data is {}, then make it []
        if (!data?.length) data = []
        this.allUsers$.next(data)
        this.allUsersLoading$.next(false)
        const loggedUser = data.find(u => u.usid == this._loggedinUserId)
        if (loggedUser) this.handleLoadedDbUser(loggedUser)
      },
      error: (err) => {
        this.allUsersLoading$.next(false)
        this.saveError$.next(err)
      }
    })
    return retVal
  }

  getUserById(usid?: number | null): AppUser | null {
    // console.table(this._allUsers)
    // console.table(this._allUsers?.map(u => u.details))
    return this._allUsers?.find(u => u.usid === usid) || null
  }

  /**
   * Kikeresi az allUsers-ből az email alapján a felhasználót. Csak azok a userek között keres, akinek a megtekintéséhez van jogosultsága.
   * @param email 
   * @returns DbUser vagy null
   */
  getuUserByEmail(email?: string | null): AppUser | null {
    if (!email?.trim()) return null
    return this._allUsers?.find(u => u.email == email) || null
  }

  getProfilePicUrl(userId: number | null) {
    console.error('getProfilePicUrl: Not implemented yet')
  }

  setPreferences(preferences: UserPreferences): Observable<AppUser> {
    const prefToSave = {
      ...this.preferences$.value,
    }
    if (preferences.lang) prefToSave.lang = preferences.lang
    if (preferences.conid !== undefined) prefToSave.conid = preferences.conid

    this.preferences$.next(prefToSave)

    const saveUserParams: HandleUsersParameters = {
      _usid: this._loggedinUserId,
      _preferences: JSON.stringify(prefToSave as UserPreferences),
    }
    const retVal = this.handleUser(saveUserParams)
    return retVal
  }

  listCrew(): Observable<CrewListItem[] | null> {
    this.crewListLoading$.next(true)
    const retVal = this.dataSvc.listCrew()
      .pipe(
        map(c => c
          ?.sort((a, b) => ((a?.famname || '') + (a?.usname || ''))?.localeCompare(((b?.famname || '') + (b?.usname || ''))) || -1)
        ),
        tap(_ => {
          this.crewListLoading$.next(false)
        }),
      )
    retVal.subscribe({
        next: (data) => {
          this.crewList$.next(data)
        },
        error: (err) => {
          this.crewListError$.next(err)
        }
      })
    return retVal
  }

  isCrewByUser(user?: AppUser | null): boolean {
    return this.isEnabledByUser(user) && !!user?.ugm.find(g => g.ugid == Groups.CREW)
  }

  isEnabledByUser(user?: AppUser | null): boolean { 
    return user?.usstatus === null || user?.usstatus == 1
  }

  isToDisplayByCrewCountry(prop: FlexTableProperty, crewCountry: CrewCountryCode  | null): boolean {
    if (!crewCountry) return true
    if (userQuestionGroups['__default']['__anyAnswer']?.questionPropCodes?.includes(prop.propcode)) return true
    return userQuestionGroups['crew_country'][crewCountry]?.questionPropCodes?.includes(prop.propcode) || false
  }

  getFullName(obj: { usname: string | null, famname: string | null }) {
    return (obj.famname?.toUpperCase() || '') + (!!obj.famname ? ' ' : '') + (obj.usname || '')
  }
}

export type QuestionAnswer = {
  question: string, // propcode
  answer: string, // picode
}

export type QuestionGroup = { // ez a válasz, akkor 
  questionGroupName: string, // jelenjen meg ez a kérdéscsoport
  questionPropCodes: string[], // ezekkel a kérdésekkel
}

export const requiredForForeignCrew = [
  'birthdate',
  'addresscity',
  'addresszip',
  'addressstreet',
  'nationality',
]

export const defaultQuestionGroupName = 'Alapértelmezett'

export const userQuestionGroups: {
  [propcode: '__default' | string]: { // ha erre a kérdésre...
    [picode: '__anyAnswer' | string]: QuestionGroup
  }
} = {
  __default: { // ez a kérdéscsoport mindig jelenjen meg
    __anyAnswer: {  // ez az alapértelmezett kérdéscsoport
      questionGroupName: defaultQuestionGroupName,
      questionPropCodes: [
        'crew_country',
      ]
    }

  },
  'crew_country': {
    hun_crew: {
      questionGroupName: 'Magyar stáb',
      questionPropCodes: [
        'mothername',
        'birthplace',
        'birthdate',
        'addresscity',
        'addresszip',
        'addressstreet',
        'vehiclenumber',
        'idnumber',
        'secnumber',
        'taxnumber',
        'emergencycontact',
      ]
    },
    foreign_crew_or_cast: {
      questionGroupName: 'Foreign crew or cast',
      questionPropCodes: [
        'birthdate',
        'mothername',
        'addresscity',
        'addresszip',
        'addressstreet',
        'nationality',
      ]
    },
  }
}
      
export const FOREIGN_CREW_COUNTRY_CODE = 'foreign_crew_or_cast'
export const HUN_CREW_COUNTRY_CODE = 'hun_crew'
export type CrewCountryCode = typeof FOREIGN_CREW_COUNTRY_CODE | typeof HUN_CREW_COUNTRY_CODE
