<div style="background-color: red;" *ngIf="saveTablePropertiesError$ | async">
    {{ saveTablePropertiesError$ | async}}
</div>
{{ '' }}
<kendo-grid [kendoGridBinding]="(tableProperties$ | async) || []" [pageable]="true" [sortable]="true"
    [filterable]="true" [navigable]="true" (add)="addHandler($event)" (remove)="removeHandler($event)"
    (edit)="editHandler($event)" [reorderable]="true" [groupable]="true" [resizable]="true"
    *ngIf="isProd || isDev; else unauth"
    class="grid table-prop-grid">
    <ng-template kendoGridToolbarTemplate>
        <div class="grid-header">
            <div>
                <h1>Data levels</h1>
            </div>
            <div class="grid-header-buttons">
                <button kendoGridAddCommand type="button">Add to...</button>
                <button type="button" mat-button color="secondary" (click)="addPropClicked()">Edit Fields</button>
                <button type="button" mat-button color="secondary" (click)="addPropItemClicked()">Edit Dropdown Values</button>
            </div>
        </div>
    </ng-template>

    <kendo-grid-column field="tableid" title="Kérdés helye">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ getTableName(dataItem.tableid) }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="propcode" title="Kérdés kódja">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.propname}} <span class="unimportant">({{ dataItem.propcode}})</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="optional" title="Opcionális" editor="boolean">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.optional ? '&check;' : '' }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="modusid" title="modusid"> </kendo-grid-column>
    <kendo-grid-column field="entryon" title="Utolsó módosítás" [editable]="false" [format]="{ date: 'short' }">
    </kendo-grid-column>
    <kendo-grid-column field="ord" title="ord" editor="numeric" format="{0:n0}"> </kendo-grid-column>

    <kendo-grid-command-column title="" [width]="220">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button mat-icon-button [matMenuTriggerFor]="statusMenu" aria-label="More options">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #statusMenu="matMenu">
                <button mat-menu-item kendoGridEditCommand [primary]="true">Edit</button>
                <button mat-menu-item kendoGridRemoveCommand>Remove</button>
            </mat-menu>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

<ng-template #unauth>
    <div class="unauth">
        <h1>Unauthorized</h1>
        <p>You are not authorized to view this page.</p>
    </div>
</ng-template>