import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ContractService } from '../../../contract/contract.service';
import { T7eService } from '../../../t7e/t7e.service';

@Component({
  selector: 'app-filter-con-status',
  templateUrl: './filter-con-status.component.html',
  styleUrls: ['./filter-con-status.component.scss']
})
export class FilterConStatusComponent implements OnInit {

  @Input() formgroup?: FormGroup
  @Input() statusFC?: FormControl

  getStatusName = this.contractSvc.getStatusName

  statuses: ContractStatusSelectItem[]

  constructor(
    private contractSvc: ContractService,
    private t7e: T7eService,
  ) {
    this.statuses = contractSvc.getContractStatuses()
      .filter(s => s.id !== 0 && s.enabled)      
  }


  ngOnInit(): void {
    console.assert(this.statusFC, 'FilterTsstatusComponent: @Input statusFC kötelező')
    console.assert(this.formgroup, 'FilterTsstatusComponent: A formgroup megadása kötelező')
  }


  /** T7E */
  t7eLabel = this.t7e.getTranslation('app-filter-con-status', 'label', 'innerText', null, null, 'Státusz')

}

export type ContractStatusSelectItem = {
  id: number | null,
  name: string,
  nameHun?: string,
}