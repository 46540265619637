import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { AppService } from '../../app.service';
import { ContractParsed, ContractStatus } from '../../models/contract';
import { UserService } from '../../user.service';
import { ContractService } from '../contract.service';

@Component({
  selector: 'app-my-contract',
  templateUrl: './my-contract.component.html',
  styleUrls: ['./my-contract.component.scss']
})
export class MyContractComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  get debug() { return this.appSvc.debug }

  readonly selectedContract$ = this.contractSvc.selectedContract$.pipe(takeUntil(this._destroy$))
  readonly contract$ = new BehaviorSubject<ContractParsed | null>(null)
  get contract() { return this.contract$.value }

  constructor(
    private contractSvc: ContractService,
    private appSvc: AppService,
    private usersSvc: UserService,

  ) {
    contractSvc.selectedContract$
      .pipe(
        takeUntil(this._destroy$),
        filter(c => this.contractSvc.isOwnContract(c)),
      )
      .subscribe(c => {
        if (!c) {
          c = {usid: usersSvc.loggedinUserId} as ContractParsed
        }
      this.contract$.next(c)
    })
    appSvc.pageTitle$.next('Start Formjaim')
  }

  userContracts$ = this.contractSvc.userContracts$

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }


}
