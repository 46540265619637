import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { T7eLangs, T7eService } from '../../t7e/t7e.service';
import { NotificationService } from '../notifications/notification.service';
import { Subject, delay, takeUntil } from 'rxjs';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss']
})
export class LangSelectorComponent implements OnInit {
  langs: T7eLangs[] = [
    'hu',
    'en',
  ]
  private _destroy$ = new Subject<void>();

  constructor(
    private userSvc: UserService,
    private t7e: T7eService,
    private notifSvc: NotificationService
  ) { }

  saving$ = this.userSvc.savingUser$.pipe(delay(0))

  ngOnInit(): void {
  }

  selectLang(lang: T7eLangs /* todo: remove string */) {
    const userPreferencesSaved$ = this.userSvc.setPreferences({ lang: lang })
    userPreferencesSaved$
      .pipe(takeUntil(this._destroy$))
      .subscribe({
      next: (savedUser) => {
        this.notifSvc.success(lang === 'hu' ? 'Nyelvválasztás elmentve' : 'Language selection saved')
        // reload page to apply new language, ask for confirmation
        const message = lang === 'hu'
          ? 'A kiválasztott nyelv újratöltés után lesz aktív.'
          : 'The page needs to be reloaded to apply the selected language.'
        const title = lang === 'hu'
          ? 'Újratöltés most?'
          : 'Reload now?'
        const okButtonText = lang === 'hu'
          ? 'Újratöltés'
          : 'Reload'
        const cancelButtonText = lang === 'hu'
          ? 'Később'
          : 'Later'
        this.notifSvc.confirm({
          message, title,
          okButtonText, cancelButtonText,
          okCallback: () => window.location.reload()
        })
      },
      error: (err) => {
        console.error('Hiba a felhasználói preferenciák mentése közben')
        console.error(err)
        this.notifSvc.alert(lang == 'hu' ? "Hiba a felhasználói preferenciák mentése közben" : "Error while saving user preferences", lang == 'hu' ? "Hiba" : "Error")
      }
    })

    this.t7e.setLang(lang as T7eLangs)
  }

  isSelectedLang(lang: string) {
    return this.userSvc.preferences$.value?.lang === lang
  }
}
