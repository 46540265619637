import { Directive } from '@angular/core';

@Directive({
  selector: '[appPageWarningDescription]'
})
export class PageWarningDescriptionDirective {

  constructor() { }

}
