import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-workhours',
  templateUrl: './filter-workhours.component.html',
  styleUrls: ['./filter-workhours.component.scss']
})
export class FilterWorkhoursComponent implements OnInit {
  @Input() workhoursFC?: FormControl
  @Input() formgroup?: FormGroup

  workhours = [10, 10.5, 12]

  constructor() { }

  ngOnInit(): void {
    console.assert(this.workhoursFC, 'Workhours Filter komponens: @Input workhoursFC kötelező')
    console.assert(this.formgroup, 'Workhours komponense: A formgroup megadása kötelező')

  }

}
