<span class="some-status" [ngClass]="statusClass">
    {{ statusName }}
    <br *ngIf="saveComment">
    <span *ngIf="saveComment" [innerText]="saveComment" class="unimportant save-comment"></span>
    <span class="who-when" *ngIf="showWhoWhen">
        @<span class="who">{{ (whoWhen$ | async)?.fullName }}</span>
        <span class="when">{{ (whoWhen$ | async)?.dTs | date:'MMM dd HH:mm' }}</span>
        <mat-spinner *ngIf="isHistoryLoading$| async" [diameter]="15" class="spinner-inline-block"></mat-spinner>
    </span>
</span>
